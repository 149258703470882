import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_CAR_OTA_WISE_REPORT = gql`
  query getCarReport($fromDate: String, $toDate: String, $isHostel: Boolean) {
    getCarReport(fromDate: $fromDate, toDate: $toDate, isHostel: $isHostel) {
      _id
      ota
      non_ota
      TotalRevenue
      TotalRoomSold
      TotalRoomSold
      TotalRevenue
      TotalADR
      hostelName
    }
  }
`;

export const useGetCarOTAwiseReport = (fromDate, toDate, isHostel) => {
  console.log(fromDate, toDate, isHostel, "fromDate,toDate,isHostel");
  const { loading, error, data, refetch } = useQuery(GET_CAR_OTA_WISE_REPORT, {
    variables: {
      fromDate,
      toDate,
      isHostel,
    },
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
