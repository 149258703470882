import React from "react";
import { Card, CardHeader, CardTitle, Button, Table, Badge } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Edit } from "react-feather";
import { reactFeatherIcon } from "../../styles/reactFeatherIcon";
// import { dateFormatHandler } from "../../utility/Utils";

const headers = [
  "Action",
  "Refund ID",
  "Request Date & Time",
  "Refund Processed Date & Time",
  "Hostel Name",
  "Reservation ID",
  "Guest Name",
  "Refund Amount",
  "Reason",
  "Booking Source",
  "Refund Type",
  "Priority",
  "Status",
  "Settle on Ezee",
  "Cancel on Ezee",
  "Initiated by",
];

const RefundRequestPreStayTable = ({
  openModal,
  setId,
  data,
  dataCount,
  loading,
}) => {
  const userRole = localStorage.getItem("role");
  // console.log(data, "DATA");
  return (
    <div>
      <Card style={{ overflowX: "auto" }}>
        <CardHeader>
          <CardTitle>
            <div>
              <h2>Pre-Stay Refunds - {dataCount} Requests</h2>
            </div>
          </CardTitle>
          {/* {userRole === "Reservation Executive" && ( */}
          <Button.Ripple
            color="light"
            onClick={(e) => {
              e.preventDefault();
              openModal();
            }}
          >
            Add refund request
          </Button.Ripple>
          {/* )} */}
        </CardHeader>
      </Card>

      {/* <div style={{ maxHeight: "400px", overflowY: "auto" }}> */}
      {loading ? (
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Please wait...
        </h2>
      ) : data?.length > 0 ? (
        <div style={{ overflowX: "auto" }}>
          <Table hover>
            <thead
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#fff",
              }}
            >
              <tr>
                {headers.map((headerItem) => (
                  <th key={uuidv4()}>{headerItem}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={item._id || index}>
                  <td>
                    <Button
                      href="#"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td>{item?.refundID ? item?.refundID : "-"}</td>
                  {/* <td>
                  {item?.createdAt ? dateFormatHandler(item?.createdAt) : "-"}
                </td> */}
                  <td>
                    {item?.createdAt
                      ? new Date(item?.createdAt).toLocaleString("en-IN", {
                          timeZone: "Asia/Kolkata",
                          hour12: true,
                        })
                      : "-"}
                  </td>
                  {/* <td>
                  {item?.refundStartTime
                    ? dateFormatHandler(item?.refundStartTime)
                    : "-"}
                </td> */}
                  <td>
                    {item?.refundStartTime
                      ? new Date(item?.refundStartTime).toLocaleString(
                          "en-IN",
                          {
                            timeZone: "Asia/Kolkata",
                            hour12: true,
                          }
                        )
                      : "-"}
                  </td>
                  <td>{item?.hostel?.name ? item?.hostel?.name : "-"}</td>
                  <td>{item?.reservationID ? item?.reservationID : "-"}</td>
                  <td>{item?.guestName ? item?.guestName : "-"}</td>
                  <td>{item?.refundAmount > 0 ? item?.refundAmount : "-"}</td>
                  <td>{item?.refundReason ? item?.refundReason : "-"}</td>
                  <td>{item?.bookingSource ? item?.bookingSource : "-"}</td>
                  <td>{item?.refundType}</td>
                  <td>
                    <Badge
                      color={
                        item?.priority === "Low"
                          ? "success"
                          : item?.priority === "Medium"
                          ? "warning"
                          : "danger"
                      }
                    >
                      {item?.priority ? item?.priority : "-"}
                    </Badge>
                  </td>
                  <td>
                    <Badge
                      color={
                        item?.status?.includes("Approved")
                          ? "success"
                          : item?.status?.includes("Rejected")
                          ? "danger"
                          : "warning"
                      }
                    >
                      {item?.status ? item?.status : "-"}
                    </Badge>{" "}
                  </td>
                  <td>{item?.markedOnEzee ? "Yes" : "No"}</td>
                  <td>{item?.cancelOnEzee ? "Yes" : "No"}</td>
                  <td>{item?.initiatedBy ? item?.initiatedBy : "-"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          No refund request to show
        </h2>
      )}
    </div>
  );
};

export default RefundRequestPreStayTable;
