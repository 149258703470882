import { useEffect, useState } from "react";
import { Edit, Send } from "react-feather";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Button,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { CSVLink } from "react-csv";
import { dateFormatHandler } from "@utils";
import { useSendPaymentLink } from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Payment ID",
  "Source",
  "Reservation No.",
  "GuestName",
  "Due Amount",
  "Deposit",
  "Arrival Date - Departure Date",
  "Reservation Date",
  "Mobile",
  "Hostel Name",
  // "Last Log",
  "Status",
];

const OTACancellationTable = ({
  openModal,
  setId,
  data,
  refetch,
  headers,
  // exportData,
  page,
}) => {
  const [tableData, setTableData] = useState(data);
  const [sendPaymentLinkHandler, { data: paymentLinkResponse }] =
    useSendPaymentLink();

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  useEffect(() => {
    if (paymentLinkResponse?.sendPaymentLinktoUnpaidBooking?.status === true)
      toaster(
        "success",
        `${paymentLinkResponse?.sendPaymentLinktoUnpaidBooking?.message}`
      );
    if (paymentLinkResponse?.sendPaymentLinktoUnpaidBooking?.status === false)
      toaster(
        "error",
        `${paymentLinkResponse?.sendPaymentLinktoUnpaidBooking?.message}`
      );
    refetch();
  }, [paymentLinkResponse, refetch]);

  return (
    <Row>
      <Col>
        <Card>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CardHeader>
              <CardTitle>
                <div>
                  <h2> OTA Cancellations</h2>
                </div>
              </CardTitle>
            </CardHeader>
            {/* <CSVLink
              headers={headers}
              data={exportData}
              filename={`OTACancellationTable_pg${page}.csv`}
            >
              <Button.Ripple
                className="btn-icon mb-2 ml-1"
                color="light"
                type="button"
                style={{ marginTop: "20px", marginRight: "20px" }}
              >
                Export
              </Button.Ripple>
            </CSVLink> */}
          </div>
          <Table responsive hover bordered size="sm">
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr key={uuidv4()}>
                  <td>
                    <Button
                      href="/"
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td>
                    {item?.dueAmountPaid === true ? (
                      <>
                        <h6 style={{ color: "green" }}>Paid </h6>
                        <strong>
                          <i>{item?.paymentId}</i>
                        </strong>
                      </>
                    ) : (
                      <a
                        href="/"
                        // className="rounded-circle btn-icon"
                        style={{ color: "blue" }}
                        color="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          sendPaymentLinkHandler(item?.uniqueId);
                        }}
                      >
                        {/* <Send size={20} /> */}
                        <u>Send</u>
                        {/* <Badge pill color="light-success">
                          0
                        </Badge> */}
                      </a>
                    )}
                  </td>

                  {/* <td>
                    <h6>{item?.paymentId}</h6>
                  </td> */}
                  <td>{item?.Source}</td>
                  <td>{item?.uniqueId}</td>

                  {/* <td>{item?.uniqueId}</td> */}
                  <td>{item?.GuestName}</td>
                  <td>{item?.DueAmount?.toFixed(2)}</td>
                  <td>{item?.Deposit?.toFixed(2)}</td>
                  <td>
                    {item?.ArrivalDate
                      ? dateFormatHandler(item?.ArrivalDate)
                      : ""}
                    -
                    {item?.DepartureDate
                      ? dateFormatHandler(item?.DepartureDate)
                      : ""}
                  </td>

                  <td>
                    {item?.ReservationDate
                      ? dateFormatHandler(item?.ReservationDate)
                      : ""}
                  </td>
                  <td>
                    {item?.Mobile
                      ? item?.Mobile?.replace(/\s/g, "")
                      : item?.Phone?.replace(/\s/g, "")}
                  </td>

                  <td>{item?.hostelName}</td>
                  {/* <td>{item?.logs?.slice(-1)}</td> */}
                  <td>{item?.Status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default OTACancellationTable;
