import RefundRequestPostStay from "../../views/RefundRequestPostStay";

const RefundRequestPostStayRoutes = [
  {
    path: "/refundRequestPostStay",
    component: RefundRequestPostStay,
    exact: true,
  },
];

export default RefundRequestPostStayRoutes;
