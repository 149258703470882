import React, { useEffect, useState } from 'react'
import SlideModal from '../../@core/components/common/slide-modal'
import { Col, Input, Row, Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";



const tableHeaderData = [
    "Fare",
    "Seat",
    "Name",
];

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
    const [bookingPNR, setBookingPNR] = useState("")
    const [inventoryItems, setInventoryItems] = useState([])
    const [paymentID, setPaymentID] = useState("")
    const [journeyDate, setJourneyDate] = useState("")
    const [boardingAddress, setBoardingAddress] = useState("")
    const [boardingLandmark, setBoardingLandmark] = useState("")
    const [boardingTime, setBoardingTime] = useState("")
    const [droppingAddress, setDroppingAddress] = useState("")
    const [droppingLandmark, setDroppingLandmark] = useState("")
    const [droppingTime, setDroppingTime] = useState("")
    const [busName, setBusName] = useState("")
    const [busType, setBusType] = useState("")





    useEffect(() => {
        if (editData?._id) {
            setBookingPNR(editData?.bookingPNR)
            setInventoryItems(editData?.inventoryItems)
            setPaymentID(editData?.razorpayPaymentId)
            setJourneyDate(editData?.journeyDate)
            setBoardingAddress(editData?.boardingAddress)
            setDroppingAddress(editData?.droppingAddress)
            setBoardingLandmark(editData?.boardingAddress)
            setDroppingLandmark(editData?.droppingLandmark)
            setBoardingTime(editData?.boardingTime)
            setDroppingTime(editData?.droppingTime)
            setBusName(editData?.busName)
            setBusType(editData?.busType)
        } else {
            setBookingPNR("")
            setInventoryItems([])
            setPaymentID("")
            setJourneyDate("")
            setBoardingAddress("")
            setDroppingAddress("")
            setBoardingLandmark("")
            setDroppingLandmark("")
            setBoardingTime("")
            setDroppingTime("")
            setBusName("")
            setBusType("")
        }
    }, [editData]);

    return (
        <SlideModal
            open={open}
            handleToggle={handleToggle}
        // submitDataHandler={() => onSubmitHandler()}
        >
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <label>Booking PNR</label>
                            <Input
                                type="text"
                                value={bookingPNR}
                                id="bookingPNR"
                                name="bookingPNR"
                                placeholder="bookingPNR"
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Payment ID</label>
                            <Input
                                type="text"
                                value={paymentID}
                                id="paymentID"
                                name="paymentID"
                                placeholder="paymentID"
                                disabled
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <label>journeyDate</label>
                            <Input
                                type="text"
                                value={journeyDate}
                                id="journeyDate"
                                name="journeyDate"
                                placeholder="journeyDate"
                                disabled />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>Bus Name</label>
                            <Input
                                type="text"
                                value={busName}
                                id="busName"
                                name="busName"
                                placeholder="busName"
                                disabled />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label> BusType</label>
                            <Input
                                type="text"
                                value={busType}
                                id="busType"
                                name="busType"
                                placeholder="busType"
                                disabled />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>boarding Location</label>
                            <Input
                                type="text"
                                value={boardingAddress}
                                id="boardingAddress"
                                name="boardingAddress"
                                placeholder="boardingAddress"
                                disabled />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label>boardingLandmark</label>
                            <Input
                                type="text"
                                value={boardingLandmark}
                                id="boardingLandmark"
                                name="boardingLandmark"
                                placeholder="boardingLandmark"
                                disabled />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label> boardingTime</label>
                            <Input
                                type="text"
                                value={boardingTime}
                                id="boardingTime"
                                name="boardingTime"
                                placeholder="boardingTime"
                                disabled />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label> droppingAddress</label>
                            <Input
                                type="text"
                                value={droppingAddress}
                                id="droppingAddress"
                                name="droppingAddress"
                                placeholder="droppingAddress"
                                disabled />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label> droppingLandmark</label>
                            <Input
                                type="text"
                                value={droppingLandmark}
                                id="droppingLandmark"
                                name="droppingLandmark"
                                placeholder="droppingLandmark"
                                disabled />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label> droppingTime</label>
                            <Input
                                type="text"
                                value={droppingTime}
                                id="droppingTime"
                                name="droppingTime"
                                placeholder="droppingTime"
                                disabled />
                        </Col>
                    </Row>

                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                    {tableHeaderData.map((item) => {
                                        return <th key={uuidv4()}>{item}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    inventoryItems?.map((item) => {
                                        return (
                                            <tr>
                                                <td>{item?.fare}</td>
                                                <td>{item?.seatName}</td>
                                                {
                                                    item?.passenger?.map((passenger) => {
                                                        return (
                                                            <td>
                                                                {passenger?.name}
                                                            </td>
                                                        )
                                                    })
                                                }
                                                <hr />
                                            </tr>)

                                    })
                                }
                            </tbody>
                        </Table>
                    </Row>
                </Col>
            </Row>
        </SlideModal>
    )
}

export default AddEditModal