import React, { useEffect, useState, Fragment, useRef } from "react";
import SlideModal from "../../../@core/components/common/slide-modal";
import { useGetAllCafeMasterList } from "../../../api/Unbox/UnboxCafeMaster/Queries";
import { useGetCafeMenuByCafeIdActive } from "../../../api/Unbox/UnboxCafewise/Queries";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { useGetCafeByHostelId } from "../../../api/Unbox/UnboxCafeMaster/Queries";
import Select from "react-select";
import toaster from "@components/common/Toaster";
import { Minus, Plus, X } from "react-feather";
import { useReactToPrint } from "react-to-print";
import PrintComponent from "../OrderDashboard/PrintComponent";
import { v4 as uuidv4 } from "uuid";

import {
  useCancelOrder,
  useCreateOrder,
  useSendInvoice,
  useUpdateOrderStatus,
} from "../../../api/Unbox/UnboxOrder/Mutations";

export const statusList = [
  { label: "Pending", value: "Pending" },
  { label: "Preparing", value: "Preparing" },
  { label: "Ready", value: "Ready" },
  { label: "Cancelled", value: "Cancelled" },
  { label: "Delivered", value: "Delivered" },
];

export const discountList = [
  { label: "0%", value: 0 },
  { label: "5%", value: 5 },
  { label: "10%", value: 10 },
  { label: "15%", value: 15 },
  { label: "20%", value: 20 },
];
export const paymentModeList = [
  { label: "Cash", value: "Cash" },
  { label: "Ezee Meal", value: "Ezee Meal" },
  { label: "Complimentary", value: "Complimentary" },
];

//const hostel = ["640591ce14e7f40795dff51e"];
const authorizedPersonArray = [
  "morris@thehosteller.com",
  "aryaman@thehosteller.com",
];

const AddEditModal = ({ open, editData, handleToggle, refetch }) => {
  const hostelId = JSON.parse(localStorage?.getItem("hostels")) || [];
  const getEmail = localStorage.getItem("email") || "";
  const bccMail = getEmail;
  const getModules = JSON.parse(localStorage.getItem("modules")) || [];
  // let options;
  // let extras;
  const componentRef = useRef(null);
  // const inputRef = useRef(null);
  const [guestName, setGuestName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [reservationId, setResevationId] = useState("");
  // const [cafeId, setCafeId] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [cafeName, setCafeName] = useState(null);
  const [paymentMode, setPaymentMode] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [previousOrderId, setPreviousOrderId] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(null);
  const [addedDiscountAmount, setAddedDiscountAmount] = useState("");
  const [cancellationRemark, setCancellationRemark] = useState("");

  const [items, setItems] = useState([
    {
      tempId: uuidv4(),
      _id: "",
      itemName: "",
      itemCategory: "",
      quantity: 1,
      option: {},
      extra: [],
    },
  ]);
  const [itemList, setItemList] = useState([]);
  const [menu, setMenu] = useState([]);
  const [cafeList, setCafeList] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [orderAmount, setOrderAmount] = useState("");
  const [orderId, setOrderId] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [razorpayPaymentId, setRazorpayPaymentId] = useState("");
  const [cafeRemark, setCafeRemark] = useState("");
  const [itemOptions, setItemOptions] = useState([]);
  const [itemExtras, setItemExtras] = useState([]);

  const [printData, setPrintData] = useState(null);
  const [discountPercent, setDiscountPercent] = useState(null);
  const [cancellationAmount, setCancellationAmount] = useState(null);
  const [cancellationDate, setCancellationDate] = useState(null);
  const [refundId, setRefundId] = useState(null);
  const [refundStatus, setRefundStatus] = useState(null);
  const [guestEmail, setGuestEmail] = useState("");
  const [sendEmailLoading, setSendEMailLoading] = useState(false);
  const [sendInvoiceHandler, { data: InvoiceRes }] = useSendInvoice();
  const { data: allCafe } = useGetAllCafeMasterList();
  const { data: cafes } = useGetCafeByHostelId(hostelId);
  const { data: allMenu } = useGetCafeMenuByCafeIdActive(cafeName?.value);
  const [updateHandler, { data: updateStatus }] = useUpdateOrderStatus();
  const [createOrderHandler, { data: createOrderData }] = useCreateOrder();
  const [cancelOrderHandler, { data: cancelOrderData }] = useCancelOrder();
  const handlePrint = useReactToPrint({
    pageStyle: `

          @media print {
            html, body {
              margin: 0;
              padding: 0;

            }

             .print-component {
          padding: 3mm;
          box-sizing: border-box;
        }
       


          } 
    `,
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (updateStatus || cancelOrderData) {
      refetch();
      handleToggle();
    }
  }, [updateStatus, cancelOrderData]);

  useEffect(() => {
    if (createOrderData?.createOrder?.orderId) {
      handlePrint();
      //setPrintData(null);
      handleToggle();
      refetch();
    }
  }, [createOrderData?.createOrder]);

  useEffect(() => {
    if (hostelId?.length !== 0 && cafes?.getCafeByHostelId?.length > 0) {
      // if (cafes?.getCafeByHostelId?.length === 1) {
      //   setCafeName({
      //     label: cafes?.getCafeByHostelId[0]?.cafeName,
      //     value: cafes?.getCafeByHostelId[0]?._id,
      //   });
      // } else {
      let cafe = cafes?.getCafeByHostelId?.map((el) => {
        return { label: el?.cafeName, value: el?._id };
      });
      setCafeList(cafe);
      //}
    } else {
      const cafeArray = allCafe?.getAllCafeMasterList?.map((cafe) => {
        return { label: cafe?.cafeName, value: cafe?._id };
      });

      setCafeList(cafeArray);
    }
    if (allMenu?.getCafeMenuByCafeIdStatusActive?.length > 0) {
      let menus = allMenu?.getCafeMenuByCafeIdStatusActive?.map((el) => {
        return { label: el?.item?.itemName, value: el?.item?._id };
      });
      setMenu(menus);
    }
  }, [
    cafes?.getCafeByHostelId,
    allMenu?.getCafeMenuByCafeIdStatusActive,
    allCafe?.getAllCafeMasterList,
    hostelId?.length,
  ]);

  useEffect(() => {
    if (editData?._id) {
      setGuestName(editData?.guestName !== null ? editData?.guestName : "");
      setMobileNo(editData?.mobileNo !== null ? editData?.mobileNo : "");
      setResevationId(
        editData?.reservationId !== null ? editData?.reservationId : ""
      );
      setCafeName(
        editData?.cafe?.cafeName !== null
          ? { label: editData?.cafe?.cafeName, value: editData?.cafe?._id }
          : null
      );
      setPaymentStatus(
        editData?.paymentStatus !== null ? editData?.paymentStatus : ""
      );
      setOrderStatus(
        editData?.status !== null
          ? { label: editData?.status, value: editData?.status }
          : null
      );
      setOrderAmount(
        editData?.orderAmount !== null ? editData?.orderAmount : ""
      );
      setOrderId(editData?.orderId !== null ? editData?.orderId : "");
      setOrderDate(
        editData?.orderDate !== null
          ? new Date(editData?.orderDate).toLocaleDateString()
          : ""
      );
      setCancellationRemark(
        editData?.cancellationRemark !== null
          ? editData?.cancellationRemark
          : ""
      );
      setRazorpayPaymentId(editData?.razorpayPaymentId || "");
      setItemList(editData?.items !== null ? editData?.items : []);
      setCafeRemark(editData?.cafeRemark !== null ? editData?.cafeRemark : "");
      setPaymentMode(
        editData?.paymentMode !== null
          ? { label: editData?.paymentMode, value: editData?.paymentMode }
          : null
      );
      setCouponCode(editData?.couponCode !== null ? editData?.couponCode : "");
      setCouponDiscount(editData?.discountAmount || 0);
      setPreviousOrderId(
        editData?.previousOrderId !== null ? editData?.previousOrderId : ""
      );
      setCancellationAmount(
        editData?.cancellationAmount !== null
          ? editData?.cancellationAmount
          : ""
      );
      setCancellationDate(
        editData?.cancellationDate !== null
          ? new Date(editData?.cancellationDate).toLocaleDateString()
          : ""
      );
      setRefundId(editData?.refundId !== null ? editData?.refundId : "");
      setRefundStatus(
        editData?.refundStatus !== null ? editData?.refundStatus : ""
      );
    } else {
      setGuestName("");
      setMobileNo("");
      setResevationId("");
      setCafeName(null);
      setPaymentStatus("");
      setOrderStatus(null);
      setOrderAmount("");
      setOrderDate("");
      setItems([
        {
          tempId: uuidv4(),
          _id: "",
          itemName: "",
          itemCategory: "",
          quantity: 1,
          option: {},
          extra: [],
        },
      ]);
      setRazorpayPaymentId("");
      setItemList([]);
      setCafeRemark("");
      setCancellationRemark("");
      setPaymentMode(null);
      setCouponCode("");
      setCouponDiscount(null);
      setPreviousOrderId("");
      setDiscountPercent(null);
      setRefundId(null);
      setCancellationAmount(null);
      setRefundStatus(null);
      setCancellationDate(null);
    }
    setAddedDiscountAmount("");
  }, [editData]);

  const getPrice = (data) => {
    const d = new Date();
    let day = d.getDay();
    let price;
    if (day >= 1 && day <= 4) price = data?.weekDayPrice;
    else price = data?.weekEndPrice;

    return Number(price);
  };

  const handleSelectItem = (item, id) => {
    const findItem = allMenu?.getCafeMenuByCafeIdStatusActive?.find(
      (i) => i?.item?._id === item?.value
    );
    const temp = uuidv4();
    setItemOptions((prevOptions) => [
      ...prevOptions?.filter((option) => option?.id !== id),
      { id: temp, options: findItem?.item?.options || [] },
    ]);
    setItemExtras((prevExtras) => [
      ...prevExtras?.filter((extra) => extra?.id !== id),
      { id: temp, extras: findItem?.item?.extras || [] },
    ]);

    setItems(
      items?.map((menu) =>
        menu?.tempId === id
          ? {
              ...menu,
              tempId: temp,
              _id: item?.value,
              itemName: item?.label,
              itemCategory: findItem?.item?.itemCategory,
              seasonCharge: findItem?.seasonCharge,
              itemPrice: getPrice(findItem?.item),
              quantity: 1,
              option: {},
              extra: [],
            }
          : menu
      )
    );
  };

  const handleAddOptions = (name, id) => {
    setItems(
      items?.map((menu) =>
        menu?.tempId === id ? { ...menu, option: { name: name } } : menu
      )
    );
  };

  const handleAddExtras = (name, id, checked) => {
    let object = { name: name };
    setItems(
      items?.map((menu) =>
        menu?.tempId === id
          ? {
              ...menu,
              extra: checked
                ? [...menu?.extra, object]
                : menu?.extra?.filter((extra) => extra?.name !== name),
            }
          : menu
      )
    );
  };

  const handleAddNumber = (value) => {
    try {
      if (!isNaN(value) && !value.includes(".")) {
        setMobileNo(value);
      } else {
        toaster("error", "Enter Number Only");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    let total = items?.reduce((acc, item) => {
      let options = 0;
      let extras = 0;
      options = item?.itemPrice + (item?.seasonCharge || 0);
      if (item?.option?.name) {
        itemOptions?.forEach((option) => {
          if (option?.id === item?.tempId) {
            options =
              getPrice(
                option?.options?.find((i) => i?.name === item?.option?.name)
              ) + item?.seasonCharge || 0;
          }
        });
      }
      if (item?.extra?.length) {
        item?.extra?.forEach((extra) => {
          itemExtras?.forEach((e) => {
            if (e?.id === item?.tempId) {
              let element = e?.extras?.find((i) => i?.name === extra?.name);
              extras += getPrice(element) || 0;
            }
          });
        });
      }
      return acc + (options + extras) * item?.quantity;
    }, 0);
    if (total) {
      setTotalPrice(total);
      setTotalTax(total * 0.05);
    }
  }, [itemOptions, items, itemExtras]);

  const handleAddItems = () => {
    const newItemId = uuidv4();
    if (cafeName?.value && items?.find((item) => item?.itemName !== "")) {
      setItems([
        ...items,
        {
          tempId: newItemId,
          _id: "",
          itemName: "",
          itemCategory: "",
          quantity: 1,
          option: {},
          extra: [],
        },
      ]);
    } else {
      toaster("error", "Please select cafe and item");
    }
    // setItemOptions((prevOptions) => [
    //   ...prevOptions,
    //   { id: newItemId, options: [] },
    // ]);
    // setItemExtras((prevExtras) => [
    //   ...prevExtras,
    //   { id: newItemId, extras: [] },
    // ]);
  };

  const handleAddQuantity = (id) => {
    const updatedItem = items?.map((menu) =>
      menu?.tempId === id ? { ...menu, quantity: menu?.quantity + 1 } : menu
    );

    setItems(updatedItem);
  };

  const handelSetAllStateToDefault = () => {
    setGuestName("");
    setMobileNo("");
    setResevationId("");
    setCafeName(null);
    setPaymentStatus("");
    setOrderStatus(null);
    setOrderAmount("");
    setOrderDate("");
    setRazorpayPaymentId("");
    setItemList([]);
    setCafeRemark("");
    setItemExtras([]);
    setItemOptions([]);
    setItems([
      {
        tempId: uuidv4(),
        _id: "",
        itemName: "",
        itemCategory: "",
        quantity: 1,
        option: {},
        extra: [],
      },
    ]);
    setTotalPrice(0);
    setTotalTax(0);
    setCafeName(null);
    setPaymentMode(null);
    setCouponCode("");
    setCouponDiscount(null);
    setDiscountPercent(null);
    setPreviousOrderId("");
    setAddedDiscountAmount("");
    setRefundId(null);
    setRefundStatus(null);
    setCancellationAmount(null);
    setCancellationDate(null);
  };

  //+==> to reduce quantity
  const handleReduceQuantity = (id) => {
    const updatedItem = items?.map((menu) =>
      menu?.tempId === id ? { ...menu, quantity: menu?.quantity - 1 } : menu
    );

    setItems(updatedItem);
    const findItemQuantity = items?.find((item) => item?.tempId === id);
    if (findItemQuantity?.quantity <= 1) {
      handleRemoveItems(id);
    }
  };

  const handleRemoveItems = (id) => {
    setItems(items?.filter((item) => item?.tempId !== id));

    setItemOptions(itemOptions?.filter((option) => option?.id !== id));
    setItemExtras(itemExtras?.filter((extra) => extra?.id !== id));
  };

  const handleAddDiscountAmount = (val) => {
    if (!isNaN(val) && !val?.includes("-")) {
      setAddedDiscountAmount(val);
    } else {
      toaster("error", "Enter Number Only");
    }
  };

  const handleSendInvoice = (
    documentId,
    guestEmail,
    guestName,
    orderId,
    bccMail
  ) => {
    try {
      if (!guestEmail) {
        toaster("error", "Please provide the email");
      } else if (guestEmail && documentId && guestName && orderId && bccMail) {
        setSendEMailLoading(true);
        sendInvoiceHandler(guestEmail, documentId, guestName, orderId, bccMail);
        toaster("success", "Sending Email");
        setGuestEmail("");
      } else {
        toaster("error", "Not able to get document Id");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitDataHandler = () => {
    let data = {
      guestName,
      mobileNo,
      discountPercent:
        paymentMode?.value === "Complimentary"
          ? 100
          : paymentMode?.value === "Ezee Meal"
          ? 0
          : discountPercent?.value,
      cafeId: cafeName?.value,
      //status: "Preparing",
      //cafeRemark,
      paymentMode: paymentMode?.value,
      addedDiscountAmount:
        paymentMode?.value === "Ezee Meal" ? Number(addedDiscountAmount) : 0,
      paymentStatus: "success",
      items: items?.map((item) => {
        delete item?.tempId;
        delete item?.seasonCharge;
        delete item?.itemPrice;
        return item;
      }),
    };

    if (
      (data?.cafeId,
      data?.guestName?.trim() !== "",
      data?.mobileNo?.trim() !== "")
      // &&
      // !items?.find((item) => Object.keys(item?.option).length === 0)
    ) {
      if (editData?._id) {
        if (orderStatus?.value !== "Cancelled") {
          updateHandler(editData?._id, {
            status: orderStatus?.value,
            cafeRemark: cafeRemark || "",
          });
          toaster("success", "Order updated successfully");
        } else if (orderStatus?.value === "Cancelled" && cancellationRemark) {
          cancelOrderHandler({
            orderId: orderId,
            cancellationRemark: cancellationRemark,
          });
          toaster("success", "Order Cancelled successfully");
        }
        handelSetAllStateToDefault();
      } else if (items?.find((item) => item?.itemName === "")) {
        toaster("error", "Please make a selection for item");
      }
      // else if (
      //   paymentMode?.value === "Complimentary" &&
      //   !previousOrderId?.toUpperCase()?.includes("THUC")
      // ) {
      //   //console.log(inputRef.current.value);
      //   toaster("error", "Please enter previous Order Id correctly");
      // } else if (
      //   paymentMode?.value === "Ezee Meal" &&
      //   Number(addedDiscountAmount) > 30
      // ) {
      //   toaster("error", "Please enter discount Amount with in 30");
      // }
      else {
        createOrderHandler(
          paymentMode?.value !== "Complimentary"
            ? { ...data, reservationId: reservationId }
            : {
                ...data,
                previousOrderId: previousOrderId.toUpperCase(),
              }
        );
        setIsPrinting(true);
        toaster("success", "Order created successfully");
        handelSetAllStateToDefault();
      }
    } else {
      toaster("error", "Please fill all details");
    }
  };

  const submitFormHandler = () => {
    if (editData?._id) {
      if (orderStatus?.value === "Cancelled" && !cancellationRemark) {
        toaster("error", "Cancellation remark is mandatory");
      } else {
        submitDataHandler();
      }
    } else if (items?.find((item) => item?.itemName === "")) {
      toaster("error", "Please make a selection for item");
    } else if (paymentMode?.value === "Cash") {
      submitDataHandler();
    } else if (
      paymentMode?.value === "Complimentary" &&
      !previousOrderId?.toUpperCase()?.includes("THUC")
    ) {
      //console.log(inputRef.current.value);
      toaster("error", "Please enter previous Order Id correctly");
    } else if (
      paymentMode?.value === "Complimentary" &&
      previousOrderId?.toUpperCase()?.includes("THUC")
    ) {
      submitDataHandler();
    } else if (
      paymentMode?.value === "Ezee Meal" &&
      Number(addedDiscountAmount) > 0 &&
      Number(addedDiscountAmount) > 100
    ) {
      toaster(
        "error",
        "Please enter discount amount less than or equal to 100"
      );
    } else if (
      paymentMode?.value === "Ezee Meal" &&
      Number(addedDiscountAmount) > 0 &&
      Number(addedDiscountAmount) <= 100
    ) {
      submitDataHandler();
    } else {
      toaster("error", "Please fill required details");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => submitFormHandler()}
    >
      <form onSubmit={() => console.log()}>
        {/* {guestName && ( */}
        <div style={{ display: "none" }}>
          <PrintComponent
            item={createOrderData?.createOrder}
            ref={componentRef}
          />
        </div>
        <Col sm="13">
          <FormGroup className="w-100">
            <Label className="form-label" for="cafeName">
              Cafe Name
            </Label>
            <Select
              isClearable={false}
              isSearchable={true}
              id="cafeName"
              name="cafeName"
              options={cafeList}
              value={cafeName}
              isDisabled={editData?.cafe?.cafeName}
              onChange={(value) => setCafeName(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        {itemList?.length === 0 && (
          <>
            {items?.map((item, i) => {
              const itemOption = itemOptions?.find(
                (option) => option?.id === item?.tempId
              );

              const itemExtra = itemExtras?.find(
                (extra) => extra?.id === item?.tempId
              );
              return (
                <Fragment key={item?.tempId}>
                  {items?.length > 1 && i >= 1 && (
                    <Col sm="12 mb-3">
                      {/* //<strong className=""> - {i + 1}</strong> */}
                      <Button.Ripple
                        type="button"
                        className="btn-icon rounded float-right"
                        color="danger"
                        size="sm"
                        onClick={() => handleRemoveItems(item?.tempId)}
                      >
                        <X size={10} />
                      </Button.Ripple>
                    </Col>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "",
                      marginRight: "20px",
                      gap: "15px",
                    }}
                  >
                    <Col sm="9" style={{ padding: "0px" }}>
                      <FormGroup className="">
                        <Label className="form-label" for={item?.tempId}>
                          Select Item
                        </Label>
                        <Select
                          isClearable={false}
                          isSearchable={true}
                          id={item?.tempId}
                          name={item?.itemName}
                          classNamePrefix="select"
                          options={menu}
                          value={{
                            label: item?.itemName,
                            value: item?._id,
                          }}
                          onChange={(value) => {
                            handleSelectItem(value, item?.tempId);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="4">
                      {item?.itemName && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "25px",
                            gap: "5px",
                            marginLeft: "-18px",
                            width: "100px",
                          }}
                        >
                          <Button.Ripple
                            type="button"
                            className="btn-icon rounded"
                            color="secondary"
                            size="sm"
                            disabled={item?.quantity === 1}
                            onClick={() => {
                              handleReduceQuantity(item?.tempId);
                            }}
                          >
                            <Minus size={8} />
                          </Button.Ripple>
                          <Button.Ripple
                            type="button"
                            className="btn-icon rounded"
                            color="light"
                            size="sm"
                            style={{ fontSize: "", padding: "9px" }}
                          >
                            {item?.quantity}
                          </Button.Ripple>
                          <Button.Ripple
                            type="button"
                            className="btn-icon rounded"
                            color="secondary"
                            size="sm"
                            onClick={() => handleAddQuantity(item?.tempId)}
                          >
                            <Plus size={8} />
                          </Button.Ripple>
                        </div>
                      )}
                    </Col>
                  </div>
                  {item?.itemName && item?.itemPrice && (
                    <div style={{ marginLeft: "10px" }}>
                      <Label>
                        {"  "}
                        BasePrice: ₹
                        {item?.itemPrice + Number(item?.seasonCharge)}
                      </Label>
                    </div>
                  )}
                  {itemOption && item?.itemName && (
                    <>
                      {itemOption?.options?.length > 0 && (
                        <Label className="ml-1">Select Options</Label>
                      )}
                      {itemOption?.options?.map((option, i) => {
                        return (
                          <Col
                            sm="6"
                            key={i}
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              gap: "10px",
                            }}
                          >
                            <FormGroup
                              className="w-100"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              <div
                                className="form-check"
                                style={{ marginLeft: "-15px" }}
                              >
                                <input
                                  type="radio"
                                  id={option?.name}
                                  name={item?.tempId}
                                  value={option?.name}
                                  onChange={(e) => {
                                    handleAddOptions(
                                      e.target.value,
                                      item?.tempId
                                    );
                                  }}
                                />
                              </div>
                              <Label className="form-label">
                                {option?.name}
                              </Label>
                            </FormGroup>
                            <Label>
                              ₹{getPrice(option) + Number(item?.seasonCharge)}
                            </Label>
                          </Col>
                        );
                      })}
                    </>
                  )}
                  {itemExtra && item?.itemName && (
                    <>
                      {itemExtra?.extras?.length > 0 && (
                        <Label className="ml-1">Select Extras</Label>
                      )}
                      {itemExtra?.extras?.map((extra, i) => {
                        return (
                          <Col
                            sm="6"
                            key={i}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginLeft: "0px",
                            }}
                          >
                            <FormGroup
                              className=""
                              style={{
                                display: "flex",
                                justifyContent: "",
                              }}
                              check
                            >
                              <div
                                className="custom-control custom-checkbox"
                                style={{ marginLeft: "-40px" }}
                              >
                                <input
                                  type="checkbox"
                                  id={extra?.name}
                                  name={item?.tempId}
                                  value={extra?.name}
                                  label={extra?.name}
                                  className="custom-control-Primary"
                                  onChange={(e) =>
                                    handleAddExtras(
                                      e.target.value,
                                      item.tempId,
                                      e.target.checked
                                    )
                                  }
                                />
                              </div>
                              <Label
                                className=""
                                style={{ marginLeft: "10px" }}
                              >
                                {extra?.name}
                              </Label>
                            </FormGroup>
                            <Label>₹{getPrice(extra)}</Label>
                          </Col>
                        );
                      })}
                    </>
                  )}
                </Fragment>
              );
            })}
            <Col sm="12">
              <div>
                <Button.Ripple
                  type="button"
                  className="btn-icon rounded float-right p-1"
                  color="light"
                  size="sm"
                  onClick={handleAddItems}
                >
                  Add Item
                </Button.Ripple>
              </div>
            </Col>
          </>
        )}
        <>
          <hr style={{ marginTop: "50px" }} />
          <h5>Guest Details</h5>
          <Row>
            <Col sm="6">
              <FormGroup className="">
                <Label className="form-label" for="guestName">
                  Guest Name*
                </Label>
                <Input
                  type="text"
                  placeholder="Guest Name"
                  id="guestName"
                  name="guestName"
                  required
                  disabled={editData?.guestName}
                  value={guestName}
                  onChange={(e) => setGuestName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup className="">
                <Label className="form-label" for="mobileNo">
                  Mobile No.*
                </Label>
                <Input
                  type="text"
                  placeholder="Mobile Number"
                  id="mobileNo"
                  name="mobileNo"
                  required={true}
                  disabled={editData?.mobileNo}
                  value={mobileNo}
                  onChange={(e) => handleAddNumber(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </>

        {itemList?.length === 0 && !editData && (
          <Row>
            <Col sm="6">
              <FormGroup className="w-100">
                <Label className="form-label" for="paymentMode">
                  Payment Mode*
                </Label>
                <Select
                  isClearable={false}
                  isSearchable={false}
                  id="paymentMode"
                  name="paymentMode"
                  options={paymentModeList}
                  value={paymentMode}
                  onChange={(value) => setPaymentMode(value)}
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>
            {paymentMode?.value === "Cash" && (
              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="discountPercent">
                    Discount Percent
                  </Label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    id="discountPercent"
                    name="discountPercent"
                    options={discountList}
                    closeMenuOnSelect={true}
                    value={discountPercent}
                    onChange={(value) => setDiscountPercent(value)}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>
            )}
            {paymentMode?.value === "Ezee Meal" && (
              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="addedDiscountAmount">
                    Discount Amount*
                  </Label>
                  <Input
                    type="text"
                    placeholder="addedDiscountAmount"
                    id="addedDiscountAmount"
                    name="addedDiscountAmount"
                    required
                    disabled={editData?.discountAmount}
                    value={addedDiscountAmount}
                    onChange={(e) => handleAddDiscountAmount(e.target.value)}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        )}
        {!editData &&
          (paymentMode?.value !== "Complimentary" ? (
            <Row>
              <Col sm="6">
                <FormGroup className="">
                  <Label className="form-label" for="reservationId">
                    Reservation Id
                  </Label>
                  <Input
                    type="text"
                    placeholder="Reservation Id"
                    id="reservationId"
                    name="reservationId"
                    disabled={editData?.reservationId}
                    value={reservationId}
                    onChange={(e) => setResevationId(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="previousOrderId">
                    Previous OrderId*
                  </Label>
                  <Input
                    type="text"
                    placeholder="previousOrderId"
                    id="previousOrderId"
                    name="previousOrderId"
                    disabled={editData?.previousOrderId}
                    required
                    value={previousOrderId}
                    onChange={(e) => setPreviousOrderId(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          ))}
        {totalPrice !== 0 && !editData?.orderId && (
          <>
            <hr />
            <div
              style={{
                display: "flex",
                justifyContent: "",
                flexDirection: "column",

                gap: "15px",
              }}
            >
              <Label style={{ fontSize: "15px" }}>
                <strong>Total Price Without Tax:</strong> ₹
                {isNaN(totalPrice)
                  ? 0
                  : discountPercent?.value
                  ? (
                      totalPrice -
                      (totalPrice * discountPercent?.value) / 100
                    ).toFixed(2)
                  : paymentMode?.value === "Complimentary"
                  ? 0
                  : paymentMode?.value === "Ezee Meal"
                  ? (totalPrice - Number(addedDiscountAmount)).toFixed(2)
                  : totalPrice.toFixed(2)}
              </Label>
              <Label style={{ fontSize: "15px" }}>
                <strong>Total Tax:</strong> ₹
                {isNaN(totalTax)
                  ? 0
                  : discountPercent?.value
                  ? (
                      (totalPrice -
                        (totalPrice * discountPercent?.value) / 100) *
                      0.05
                    ).toFixed(2)
                  : paymentMode?.value === "Complimentary"
                  ? 0
                  : paymentMode?.value === "Ezee Meal"
                  ? ((totalPrice - Number(addedDiscountAmount)) * 0.05).toFixed(
                      2
                    )
                  : totalTax.toFixed(2)}
              </Label>
              <Label style={{ fontSize: "15px" }}>
                <strong>Total Price:</strong> ₹
                {isNaN(totalPrice + totalTax)
                  ? 0
                  : discountPercent?.value
                  ? (
                      (totalPrice -
                        (totalPrice * discountPercent?.value) / 100) *
                      1.05
                    ).toFixed(2)
                  : paymentMode?.value === "Complimentary"
                  ? 0
                  : paymentMode?.value === "Ezee Meal"
                  ? ((totalPrice - Number(addedDiscountAmount)) * 1.05).toFixed(
                      2
                    )
                  : (totalPrice + totalTax).toFixed(2)}
              </Label>
            </div>
          </>
        )}
        {/* )} */}

        {paymentStatus && (
          <>
            <h5>Order Details</h5>
            <Row>
              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="paymentStatus">
                    Payment Status
                  </Label>
                  <Input
                    type="text"
                    placeholder="paymentStatus"
                    id="paymentStatus"
                    name="paymentStatus"
                    required
                    disabled={editData?.paymentStatus}
                    value={paymentStatus}
                    onChange={(e) => setPaymentStatus(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="orderAmount">
                    Order Amount
                  </Label>
                  <Input
                    type="text"
                    placeholder="orderAmount"
                    id="orderAmount"
                    name="orderAmount"
                    disabled={editData?.orderAmount !== null}
                    required
                    value={Number(orderAmount).toFixed(2)}
                    onChange={(e) => orderAmount(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="orderId">
                    Order Id
                  </Label>
                  <Input
                    type="text"
                    placeholder="orderId"
                    id="orderId"
                    name="orderId"
                    disabled={editData?.orderId}
                    required
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="orderDate">
                    Order Date
                  </Label>
                  <Input
                    type="text"
                    placeholder="orderDate"
                    id="orderDate"
                    name="orderDate"
                    required
                    disabled={editData?.orderDate}
                    value={orderDate}
                    onChange={(e) => setOrderDate(e.target.value)}
                  />
                </FormGroup>
              </Col>

              {!editData?.paymentMode && (
                <>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="razorpayPaymentId">
                        Razorpay Payment Id
                      </Label>
                      <Input
                        type="text"
                        placeholder="razorpayPaymentId"
                        id="razorpayPaymentId"
                        name="razorpayPaymentId"
                        required
                        disabled={editData?.razorpayPaymentId}
                        value={razorpayPaymentId}
                        onChange={(e) => setRazorpayPaymentId(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="couponCode">
                        Coupon Applied
                      </Label>
                      <Input
                        type="text"
                        placeholder="couponCode"
                        id="couponCode"
                        name="couponCode"
                        required
                        disabled={editData?.couponCode}
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
              {editData?.paymentMode && (
                <Col sm="6">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="paymentMode">
                      Payment Mode
                    </Label>
                    <Input
                      type="text"
                      placeholder="paymentMode"
                      id="paymentMode"
                      name="paymentMode"
                      required
                      disabled={editData?.paymentMode}
                      value={editData?.paymentMode}
                      //onChange={(e) => setCouponCode(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="discountAmount">
                    Discount Amount
                  </Label>
                  <Input
                    type="text"
                    placeholder="discountAmount"
                    id="discountAmount"
                    name="discountAmount"
                    required
                    disabled={editData?.discountAmount}
                    value={couponDiscount}
                    onChange={(e) => setCouponDiscount(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr />
          </>
        )}

        {itemList?.length !== 0 && (
          <>
            <h5>Item Details</h5>
            <Row>
              <Col className="mb-2">
                {itemList?.map((el) => (
                  <div
                    key={el?._id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      //alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          margin: "2px 0",
                          display: "block",
                        }}
                      >
                        {el.itemName + "   "}
                        {el?.options?.name ? `(${el?.options?.name})` : ""}
                      </span>
                      <span style={{ fontSize: "13px" }}>
                        {el?.extras?.map((i, j) => (
                          <li key={j}>{i?.name}</li>
                        ))}
                      </span>
                    </div>
                    <div>
                      <span className="">x{el?.quantity}</span>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
            <hr />
          </>
        )}
        {orderStatus && (
          <>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="status">
                    Order Status
                  </Label>
                  <Select
                    isClearable={false}
                    isSearchable={false}
                    id="status"
                    name="status"
                    options={statusList}
                    value={orderStatus}
                    isDisabled={
                      // !authorizedPersonArray?.some(
                      //   (email) => email === getEmail
                      // )
                      !getModules?.some(
                        (module) => module === "Unbox Configuration"
                      ) || orderStatus?.value === "Cancelled"
                    }
                    onChange={(value) => setOrderStatus(value)}
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>
              {orderStatus?.value === "Cancelled" && (
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="cancellationRemark">
                      Cancellation Remark*
                    </Label>
                    <Input
                      type="textarea"
                      placeholder="cancellationRemark"
                      id="cancellationRemark"
                      name="cancellationRemark"
                      required
                      disabled={editData?.cancellationRemark}
                      value={cancellationRemark}
                      onChange={(e) => setCancellationRemark(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              )}
              {editData?.status === "Cancelled" && (
                <>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="cancellationAmount">
                        Cancellation Amount
                      </Label>
                      <Input
                        type="text"
                        placeholder="Cancellation amount"
                        id="cancellationAmount"
                        name="cancellationAmount"
                        required
                        disabled={editData?.cancellationAmount}
                        value={Number(cancellationAmount).toFixed(2)}
                        onChange={(e) => setCancellationAmount(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="cancellationDate">
                        Cancellation Date
                      </Label>
                      <Input
                        type="text"
                        placeholder="Cancellation Date"
                        id="cancellationDate"
                        name="cancellationDate"
                        required
                        disabled={editData?.cancellationDate}
                        value={cancellationDate}
                        onChange={(e) => setCancellationDate(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="refundId">
                        Refund Id
                      </Label>
                      <Input
                        type="text"
                        placeholder="Refund Id"
                        id="refundId"
                        name="refundId"
                        required
                        disabled={editData?.refundId}
                        value={refundId}
                        onChange={(e) => setRefundId(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="refundStatus">
                        Refund Status
                      </Label>
                      <Input
                        type="text"
                        placeholder="refundStatus"
                        id="refundStatus"
                        name="refundStatus"
                        required
                        disabled={editData?.refundStatus}
                        value={refundStatus}
                        onChange={(e) => setRefundStatus(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
              {orderStatus?.value !== "Cancelled" && (
                <Col sm="12 mb-3">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="cafeRemark">
                      Cafe Remark
                    </Label>
                    <Input
                      type="textarea"
                      placeholder="cafeRemark"
                      id="cafeRemark"
                      name="cafeRemark"
                      required
                      value={cafeRemark}
                      onChange={(e) => setCafeRemark(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              )}
              {editData && (
                <>
                  <Col sm="8">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="guestEmail">
                        Guest Email
                      </Label>
                      <Input
                        type="email"
                        placeholder="Guest Email"
                        id="guestEmail"
                        name="guestEmail"
                        required
                        value={guestEmail}
                        onChange={(e) => setGuestEmail(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <Button
                      style={{
                        backgroundColor: "#ff9999",
                        height: "40px",
                        marginTop: "22px",
                        //marginLeft: "40px",
                        borderRadius: "5px",
                        padding: "10px 10px",
                        outline: "none",
                        border: "0",
                      }}
                      color="danger"
                      appearance="primary"
                      type="button"
                      //disabled={sendEmailLoading ? true : false}
                      onClick={() =>
                        handleSendInvoice(
                          editData?._id,
                          guestEmail,
                          editData?.guestName,
                          editData?.orderId,
                          bccMail
                        )
                      }
                    >
                      Send Invoice
                    </Button>
                  </Col>
                  {editData?.editLogs?.length > 0 && (
                    <Col>
                      <div className="font-weight-bold text-left py-1">
                        Logs:
                      </div>
                      {[...editData.editLogs]?.reverse()?.map((el, i) => {
                        return (
                          <Label key={i}>
                            <li>{el}</li>
                          </Label>
                        );
                      })}
                    </Col>
                  )}
                </>
              )}
            </Row>
          </>
        )}
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
