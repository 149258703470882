import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_PAGINATED_GO_PURCHASE_REQUEST = gql`
  query GetPaginatedPurchaseRequest($filter: filter) {
    getPaginatedGOPurchaseRequest(filter: $filter) {
      page
      limit
      count
      aggregatedAmount {
        totalAmount
        totalAmountPaid
        totalTdsAmount
        deductionAmount
      }
      data {
        _id
        hostel {
          _id
          name
          state
        }
        urgency
        head
        subHead
        subHeadType
        items {
          name
          description
          quantity
          itemStatus
          itemRemark
          itemImage
        }
        vendor
        warranty
        deliveryTimeline
        amount
        gst
        totalAmount
        attachment {
          imageURL
        }
        bankAccountNumber
        ifscCode
        bankType
        bankDetailImage {
          imageURL
        }
        poAttachment {
          imageURL
        }
        invoiceAttachment {
          imageURL
        }

        paymentDetails {
          _id
          amountToPay
          paymentStatus
          paymentDate
          bankRefNumber
          amountPaid
          tdsAmount
          financeTeamRemark
          paymentAttachment
        }

        totalAmountPaid
        totalTdsAmount
        deductionAmount
        deductionRemark
        deductionAttachment {
          imageURL
        }
        materialDispatchedRemark
        requestCompletedRemark
        purchaseRemark

        status
        amRemark
        rmRemark
        ceoRemark
        requestId

        quotationAddedDate
        purchaseApprovedDate
        paymentDoneDate
        materialDispatchedDate
        completionDate
        completionDate
        createdAt
        logs
      }
    }
  }
`;

export const useGetPaginatedGOPurchaseRequest = (limit, page, filterFeilds) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_GO_PURCHASE_REQUEST,
    {
      variables: {
        filter: {
          limit,
          page,
          filterFeilds,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const GET_GO_PURCHASE_REQUEST_BY_ID = gql`
  query getGOPurchaseRequestById($id: ID) {
    getGOPurchaseRequestById(id: $id) {
      _id
      hostel {
        _id
        name
        state
      }
      urgency
      head
      subHead
      subHeadType
      items {
        name
        description
        quantity
        itemStatus
        itemRemark
        itemImage
      }
      vendor
      warranty
      deliveryTimeline
      amount
      gst
      totalAmount
      attachment {
        imageURL
      }

      bankAccountNumber
      ifscCode
      bankType
      bankDetailImage {
        imageURL
      }
      poAttachment {
        imageURL
      }
      invoiceAttachment {
        imageURL
      }

      paymentDetails {
        _id
        amountToPay
        paymentStatus
        paymentDate
        bankRefNumber
        amountPaid
        tdsAmount
        financeTeamRemark
        paymentAttachment
      }

      totalAmountPaid
      totalTdsAmount
      deductionAmount
      deductionRemark
      deductionAttachment {
        imageURL
      }
      materialDispatchedRemark
      requestCompletedRemark
      purchaseRemark

      status
      amRemark
      rmRemark
      ceoRemark
      requestId

      quotationAddedDate
      purchaseApprovedDate
      paymentDoneDate
      materialDispatchedDate
      completionDate
      completionDate
      createdAt
      logs
    }
  }
`;
export const useGetGOPurchaseRequestById = (id) => {
  const { loading, error, data, refetch } = useQuery(
    GET_GO_PURCHASE_REQUEST_BY_ID,
    {
      variables: { id },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const GET_VENDOR_LAST_ORDER = gql`
  query getVendorLastOrder($requestId: String, $hostelId: ID, $vendorId: ID) {
    getVendorLastOrder(
      requestId: $requestId
      hostelId: $hostelId
      vendorId: $vendorId
    ) {
      _id
      items {
        name
        description
        quantity
        itemStatus
        itemRemark
      }
      requestId
      createdAt
    }
  }
`;

export const useGetVendorLastOrder = (requestId, hostelId, vendorId) => {
  const { loading, error, data, refetch } = useQuery(GET_VENDOR_LAST_ORDER, {
    variables: { requestId, hostelId, vendorId },
    skip: !hostelId && !vendorId ? true : false,
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const GET_GO_PURCHASE_SAME_TYPE_REQUEST = gql`
  query getGOPurchaseSameTypeRequest(
    $createdAt: Date
    $hostelId: ID
    $head: String
    $subHead: String
    $subHeadType: String
  ) {
    getGOPurchaseSameTypeRequest(
      createdAt: $createdAt
      hostelId: $hostelId
      head: $head
      subHead: $subHead
      subHeadType: $subHeadType
    ) {
      _id
      requestId
      createdAt
    }
  }
`;

export const useGetGOPurchaseSameTypeRequest = (
  createdAt,
  hostelId,
  head,
  subHead,
  subHeadType
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_GO_PURCHASE_SAME_TYPE_REQUEST,
    {
      variables: { createdAt, hostelId, head, subHead, subHeadType },
      skip: !hostelId && !head && !subHead && !subHeadType ? true : false,
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const GET_GO_PURCHASE_REQUEST = gql`
  query getGOPurchaseRequest {
    getGOPurchaseRequest {
      _id
      totalAmount
      totalAmountPaid
      totalTdsAmount
    }
  }
`;

export const useGetGOPurchaseRequest = () => {
  const { loading, error, data, refetch } = useQuery(GET_GO_PURCHASE_REQUEST);

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};
