import gql from "graphql-tag";

// ! updateUserRequest added by Ashwin
export const UPDATE_WEB_APP_USER_REQUEST = gql`
  mutation updateUserRequest($id: ID!, $userRequestUpdate: UserRequestInput) {
    updateUserRequest(id: $id, userRequestUpdate: $userRequestUpdate) {
      requestStatus
    }
  }
`;

//Added by Nitish
export const GENERATE_USER_REQUEST_EXPORT_DATA = gql`
  mutation generatexlsx($filter: filter) {
    generateUserRequestExport(filter: $filter)
  }
`;
