import React, { useState } from 'react'
import { useParams } from "react-router";
import { Table } from 'reactstrap'
import { useGetEmployeeSalaryStructure } from '../../../api/employeeSalaryStructure/Queries';
// import { useUpdateEmployeeSalaryStructure } from '../../../api/employeeSalaryStructure/Mutation';
import { v4 as uuidv4 } from "uuid";
import { dateFormatterDDMMYYYY } from "../../../utility/Utils";
import SalaryStructureModal from './SalaryStructureModal';
import { Edit2 } from 'react-feather';

const tableHeaderData = ["Duration", "monthly CTC", "annual CTC", "Edit"]
const SalaryStructure = () => {
    const { id } = useParams();
    const [selectedEmployeeForUpdate, setSelectedEmployeeForUpdate] = useState(null)
    const [showUpdateEmployeeModal, setShowUpdateEmployeeModal] = useState(false);
    const { data: salaryStructures, refetch: salaryStructureRefetch } = useGetEmployeeSalaryStructure(id)
    function handleCloseUpdateProfile() {
        try {
            setShowUpdateEmployeeModal(!showUpdateEmployeeModal);

            if (showUpdateEmployeeModal) {
            }
        } catch (error) {
            console.error(error);
        }
    }
    return (

        <div>

            <Table>
                <thead>
                    <tr>
                        {tableHeaderData.map((item) => {
                            return <th key={uuidv4()}>{item}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {salaryStructures?.getEmployeeSalaryStructure?.map((item) => (
                        <tr>
                            {console.log(item?.fromDate)}
                            <td>{dateFormatterDDMMYYYY(item?.fromDate)} - {dateFormatterDDMMYYYY(item?.toDate)}</td>
                            <td>{item?.monthlyCTC}</td>
                            <td>{item?.yearlyCTC}</td>
                            <td onClick={() => {
                                handleCloseUpdateProfile();
                                setSelectedEmployeeForUpdate(item);
                            }}>
                                <Edit2 size={15} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {selectedEmployeeForUpdate && (
                <SalaryStructureModal
                    showModal={showUpdateEmployeeModal}
                    onClose={handleCloseUpdateProfile}
                    data={selectedEmployeeForUpdate}
                    refetchEmployeeSalaryStructure={salaryStructureRefetch}
                />
            )}

        </div>

    )
}

export default SalaryStructure