import gql from "graphql-tag";

export const SEND_PAYMENT_LINK = gql`
  mutation ($uniqueId: String!) {
    sendPaymentLinktoUnpaidBooking(uniqueId: $uniqueId) {
      status
      message
    }
  }
`;

export const UPDATE_EZEE_BOOKING_REMARKS = gql`
  mutation (
    $uniqueId: String
    $remarksLogUpdate: String
    $alternateEmail: String
    $alternateMobile: String
  ) {
    updateEzeeBookingRemarks(
      uniqueId: $uniqueId
      remarksLogUpdate: $remarksLogUpdate
      alternateEmail: $alternateEmail
      alternateMobile: $alternateMobile
    ) {
      status
      message
    }
  }
`;

