// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import CouponsTable from "./CouponsTable";
import AddEditModal from "./AddEditModal";
import TablePagination from "@components/common/TablePagination";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useGetAllPaginatedCoupons } from "../../api/dataSource";
import { Col, Row, FormGroup, Label, Input, Button } from "reactstrap";
import Select from "react-select";
import { dateFormatter } from "../../utility/Utils";
import Flatpickr from "react-flatpickr";

const Coupons = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [status, setStatus] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filter, setFilter] = useState(null);
  const [privateStatus, setPrivateStatus] = useState(true);
  const [subCategory, setSubCategory] = useState(null);
  const [product, setProduct] = useState(null);
  const [expirationStartDate, setExpirationStartDate] = useState(null);
  const [expirationEndDate, setExpirationEndDate] = useState(null);

  const { data, refetch } = useGetAllPaginatedCoupons(
    limit,
    page,
    // status?.value,
    // searchKeyword
    filter?.status,
    filter?.searchKeyword,
    filter?.privateStatus,
    filter?.product,
    filter?.subCategory,
    filter?.expirationStartDate,
    filter?.expirationEndDate
  ); // ! Coded by Ashwin

  const CouponStatusList = [
    { label: "paused", value: "paused" },
    { label: "live", value: "live" },
    { label: "completed", value: "completed" },
    { label: "expired", value: "expired" },
  ];

  const PrivateList = [
    { label: "Private", value: true },
    { label: "Public", value: false },
  ];

  const ProductList = [
    { label: "Hostel", value: "Hostel" },
    { label: "Membership", value: "Membership" },
    // { label: "Workation", value: "Workation" },
    // { label: "Trip", value: "Trip" },
    // { label: "User", value: "User" },
  ];

  const SubCategoryList = [
    { label: "Absolute", value: "Absolute" },
    { label: "Percentage", value: "Percentage" },
    { label: "MembershipAbsolute", value: "MembershipAbsolute" },
    { label: "MembershipPercentage", value: "MembershipPercentage" },
    { label: "SpecialBookAtOneRs", value: "SpecialBookAtOneRs" },
    { label: "SpecialOneNightOff", value: "SpecialOneNightOff" },
  ];

  const count = data?.getPaginatedCouponList?.count;

  // useEffect(() => {
  //   refetch();
  // }, [refetch]);

  // Modiefied by Dushynat to Call when Proper access
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("modules"))?.includes("Coupon Admin")) {
      refetch();
    }
  }, [status, page]);

  // useEffect(() => {
  //   refetch();
  // }, [page, refetch]);

  useEffect(() => {
    if (data?.getPaginatedCouponList?.data.length > 0) {
      setTableData(data?.getPaginatedCouponList?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedCouponList?.count / limit));
  }, [
    data?.getPaginatedCouponList?.count,
    data?.getPaginatedCouponList?.data,
    limit,
  ]);

  useEffect(() => {
    if (data?.getPaginatedCouponList?.data.length) {
      let editData = data?.getPaginatedCouponList?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedCouponList?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const handleSearch = () => {
    const data = {
      searchKeyword,
      status: status?.value,
      privateStatus: privateStatus?.value,
      product: product?.value,
      subCategory: subCategory?.value,
      expirationStartDate,
      expirationEndDate,
    };
    setFilter(data);
    setPage(1);
  };

  const handleReset = () => {
    setSearchKeyword("");
    setStatus(null);
    setPrivateStatus(true);
    setProduct(null);
    setSubCategory(null);
    setExpirationStartDate(null);
    setExpirationEndDate(null);
    setFilter(null);
    setPage(1);
  };

  return (
    JSON.parse(localStorage.getItem("modules"))?.includes("Coupon Admin") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                Promo Code
              </Label>
              <Input
                type="text"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                placeholder="Promo Code"
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="status">
                Status
              </Label>
              <Select
                isClearable={true}
                id="status"
                name="status"
                value={status}
                options={CouponStatusList}
                required
                onChange={(value) => setStatus(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="privateStatus">
                Coupon Type
              </Label>
              <Select
                isClearable={true}
                id="privateStatus"
                name="privateStatus"
                value={privateStatus}
                options={PrivateList}
                required
                onChange={(value) => setPrivateStatus(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Product
              </Label>
              <Select
                isClearable={true}
                id="product"
                name="product"
                value={product}
                options={ProductList}
                required
                onChange={(value) => setProduct(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="sub category">
                Sub Category
              </Label>
              <Select
                isClearable={true}
                id="subCategory"
                name="subCategory"
                value={subCategory}
                options={SubCategoryList}
                onChange={(value) => setSubCategory(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                Expiry Date Range
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={expirationStartDate}
                options={{
                  mode: "range",
                }}
                onChange={(date) => {
                  setExpirationStartDate(dateFormatter(date[0]));
                  setExpirationEndDate(dateFormatter(date[1]));
                }}
                id="expiryDate"
                // isClearable={true}
                placeholder="Expiry Date Range"
              />
            </FormGroup>
          </Col>

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>

            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>

        {/* {tableData.length > 0 ? (
          <> */}
        <CouponsTable
          setId={setId}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          refetch={refetch}
          count={count}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
        />
        {/* </>
        ) : (
          <h2 style={{ textAlign: "center", marginTop: "150px" }}>
            No Record Found
          </h2>
        )} */}
      </div>
    )
  );
};

export default Coupons;
