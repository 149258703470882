import React, { useState } from "react";
import { CardHeader, Col, Row, Table } from "reactstrap";
import { ChevronDown, ChevronUp } from "react-feather";
const Report = ({ tableData }) => {
  const [openIndices, setOpenIndices] = useState([]);

  const toggleRow = (index) => {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      setOpenIndices([...openIndices, index]);
    }
  };
  return (
    <div>
      <Row>
        <Col>
          <CardHeader>
            <h4 style={{}}>Soldout Report</h4>
          </CardHeader>
          <div style={{ overflowX: "auto", maxHeight: "600px" }}>
            <Table striped bordered>
              <thead style={{ position: "sticky", top: 0, zIndex: 2 }}>
                <tr>
                  <th>Cafe</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((el, i) => {
                  return (
                    el?.data?.length > 0 && (
                      <React.Fragment key={i}>
                        <tr
                          onClick={() => toggleRow(i)}
                          style={{ cursor: "pointer" }}
                        >
                          <th
                            style={{
                              paddingTop: "10px",
                              paddingBottom: "10px",
                            }}
                          >
                            {el?.cafe}
                            {!openIndices.includes(i) ? (
                              <ChevronDown size={15} />
                            ) : (
                              <ChevronUp size={15} />
                            )}
                          </th>
                        </tr>
                        {openIndices.includes(i) && (
                          <tr>
                            <td colSpan="1">
                              <table
                                style={{ width: "100%", paddingTop: "10px" }}
                              >
                                <thead>
                                  <tr>
                                    <th>Item Name</th>
                                    <th>Soldout Start Time</th>
                                    <th>Soldout End Time</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {el.data.map((soldoutData, j) => (
                                    <tr key={j}>
                                      <td>{soldoutData?.item?.itemName}</td>
                                      <td>
                                        {new Date(
                                          soldoutData?.soldOutStartTime
                                        ).toLocaleString("en-IN", {
                                          hour12: true,
                                        })}
                                      </td>
                                      <td>
                                        {new Date(
                                          soldoutData?.soldOutEndTime
                                        ).toLocaleString("en-IN", {
                                          hour12: true,
                                        })}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    )
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Report;
