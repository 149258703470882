import React, { useEffect, useState } from "react";
import { Edit, Link } from "react-feather";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeader = [
  "Action",
  "Hostel",
  "Cafe",
  "Cafe Timings",
  "Status",
  "Cafe url",
];
const CafeMasterTable = ({ openModal, data, setId }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);
  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>Cafes</CardTitle>

              <Button.Ripple
                color="light"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                }}
              >
                Add Cafe
              </Button.Ripple>
            </CardHeader>

            <Table responsive>
              <thead>
                <tr>
                  {tableHeader?.map((item) => {
                    return <th key={uuidv4()}>{item}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {tableData?.map((element, i) => (
                  <tr key={uuidv4()}>
                    <td style={{ width: "5%" }}>
                      <Button
                        className="rounded-circle btn-icon"
                        color="none"
                        onClick={(e) => {
                          e.preventDefault();
                          setId(element?._id);
                          openModal();
                        }}
                      >
                        <Edit
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                      </Button>
                    </td>
                    <td>{element?.hostel?.name}</td>
                    <td>{element?.cafeName}</td>
                    <td>
                      <ul style={{ listStyleType: "none", padding: 0 }}>
                        {element?.cafeTimings?.map((el, index) => (
                          <li key={index}>
                            {el?.fromTime || el?.toTime ? (
                              <Badge
                                pill
                                color="light-success"
                                className="mr-1"
                                key={index}
                              >
                                {el?.fromTime
                                  ? moment(el?.fromTime).format("hh:mm A")
                                  : ""}{" "}
                                -{" "}
                                {el?.toTime
                                  ? moment(el?.toTime).format("hh:mm A")
                                  : ""}
                              </Badge>
                            ) : (
                              <></>
                            )}
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      {" "}
                      <Badge
                        pill
                        color={
                          element?.active ? "light-success" : "light-danger"
                        }
                        className="mr-1"
                      >
                        {element?.active ? "Active" : "Inactive"}
                      </Badge>
                    </td>
                    <td>
                      <a
                        href={`https://www.thehosteller.com/glu/unbox?cafeId=${element?._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <Link></Link>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CafeMasterTable;
