import React from "react";
import { Card, CardHeader, CardTitle, Button, Table, Badge } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Edit } from "react-feather";
import { reactFeatherIcon } from "../../styles/reactFeatherIcon";

const headers = [
  "Action",
  "Request Date",
  "Refund ID",
  "Hostel Name",
  "Reservation ID",
  "Guest Name",
  "Refund Amount",
  "Type",
  "Reason",
  "Check out",
  "Status",
  "Initiated by",
];

const ReviewsTable = ({ openModal, setId, data, dataCount }) => {
  const userDepartment = localStorage.getItem("department");
  const userRole = localStorage.getItem("role");

  const formatDateToIST = (date) => {
    const dateObj = new Date(date);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = dateObj.getFullYear().toString().slice(-2);
    const hours = dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedTime = `${formattedHours}:${minutes}:${seconds} ${ampm}`;

    return `${day}/${month}/${year} ${formattedTime}`;
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>{dataCount} Requests</CardTitle>

          {userRole === "Ground Staff" && userDepartment === "Operations" && (
            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add Refund Request
            </Button.Ripple>
          )}
        </CardHeader>
      </Card>

      <div style={{ maxHeight: "400px", overflowY: "auto" }}>
        <Table hover>
          <thead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#fff",
              zIndex: 1,
            }}
          >
            <tr>
              {headers.map((headerItem) => (
                <th key={uuidv4()}>{headerItem}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item._id || index}>
                <td>
                  <Button
                    href="#"
                    color="none"
                    onClick={(e) => {
                      e.preventDefault();
                      setId(item._id);
                      openModal();
                    }}
                  >
                    <Edit
                      color={reactFeatherIcon.iconColor}
                      size={reactFeatherIcon.iconSize}
                    />
                  </Button>
                </td>
                <td>
                  {item.requestDate ? formatDateToIST(item.requestDate) : "N/A"}
                </td>
                <td>
                  <Badge
                    style={{
                      backgroundColor:
                        item.refundType === "Post-stay" ? "#ffc107" : "#28a745",
                      color: "#fff",
                      padding: "5px 10px",
                      borderRadius: "12px",
                    }}
                  >
                    {item.refundID}
                  </Badge>
                </td>
                <td>{item.hostel.name}</td>
                <td>{item.reservationID}</td>
                <td>{item.guestName}</td>
                <td>{item.refundAmount}</td>
                <td
                  style={{ whiteSpace: "nowrap", textTransform: "capitalize" }}
                >
                  {item.refundType.toLowerCase()}
                </td>
                <td>{item.refundReason}</td>
                <td>{item.checkOut}</td>
                <td>{item.status}</td>
                <td>{item.initiatedBy}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ReviewsTable;
