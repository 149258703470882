import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateEmployeeProfile } from "../../../api/dataSource";
import { tableDataHandler } from "@store/Cms/cms.action";

const PersonalInfo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [uan, setUan] = useState("");
  const [esic, setEsic] = useState("");
  const [aadhaar, setAadhaar] = useState("");
  const [pan, setPan] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [emergencyName, setEmergencyName] = useState("");
  const [emergencyNo, setEmergencyNo] = useState("");

  const userRole = localStorage.getItem("role");

  const [updateEmployeeProfileHandler, { data: updateEmployeeProfileData }] =
    useUpdateEmployeeProfile();

  const state = useSelector((state) => state?.cms);

  useEffect(() => {
    if (state?.tableData) {
      setUan(state?.tableData?.uan);
      setEsic(state?.tableData?.esic);
      setAadhaar(state?.tableData?.aadhaar);
      setPan(state?.tableData?.pan);
      setBankName(state?.tableData?.bankDetails?.bankName);
      setBranchName(state?.tableData?.bankDetails?.branchName);
      setAccountNumber(state?.tableData?.bankDetails?.accountNumber);
      setIfscCode(state?.tableData?.bankDetails?.ifscCode);
      setEmergencyName(state?.tableData?.emergencyName);
      setEmergencyNo(state?.tableData?.emergencyNo);
    }
  }, [state]);

  const submitDataHandler = (e) => {
    e.preventDefault();

    let data = {
      uan,
      esic,
      aadhaar,
      pan,
      bankDetails: {
        bankName,
        branchName,
        accountNumber,
        ifscCode,
      },
      emergencyName,
      emergencyNo,
    };
    if (id) {
      updateEmployeeProfileHandler(id, data);
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form className="mt-2" onSubmit={submitDataHandler}>
      <Row>
        {/*// ! uan */}
        <Col sm="6">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="uan">
                UAN
              </Label>
            </strong>
            <Input
              type="text"
              value={uan}
              id="uan"
              name="uan"
              placeholder="Add UAN"
              // required
              onChange={(e) => setUan(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/*// ! esic */}
        <Col sm="6">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="esic">
                ESIC
              </Label>
            </strong>
            <Input
              type="text"
              value={esic}
              id="esic"
              name="esic"
              placeholder="Add ESIC"
              // required
              onChange={(e) => setEsic(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/*// ! aadhaar */}
        <Col sm="6">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="aadhaar">
                Aadhaar*
              </Label>
            </strong>
            <Input
              type="text"
              value={aadhaar}
              id="aadhaar"
              name="aadhaar"
              placeholder="Add aadhaar"
              required
              maxLength="12"
              onChange={(e) => setAadhaar(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/*// ! pan */}
        <Col sm="6">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="pan">
                Pan
              </Label>
            </strong>
            <Input
              type="text"
              value={pan}
              id="pan"
              name="pan"
              placeholder="Add pan"
              // required
              onChange={(e) => setPan(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/*// ! bankDetails */}
        <Col sm="12">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="bankDetails">
                Bank details
              </Label>
            </strong>
            <Row>
              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="bankName">
                    Bank name*
                  </Label>
                  <Input
                    type="text"
                    value={bankName}
                    id="bankName"
                    name="bankName"
                    placeholder="Add bank name"
                    required
                    onChange={(e) => setBankName(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="branchName">
                    Branch name
                  </Label>
                  <Input
                    type="text"
                    value={branchName}
                    id="branchName"
                    name="branchName"
                    placeholder="Add branch name"
                    //   required
                    onChange={(e) => setBranchName(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="accountNumber">
                    Account number*
                  </Label>
                  <Input
                    type="text"
                    value={accountNumber}
                    id="accountNumber"
                    name="accountNumber"
                    placeholder="Add account number"
                    required
                    onChange={(e) => setAccountNumber(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <FormGroup className="w-100">
                  <Label className="form-label" for="ifscCode">
                    IFSC code*
                  </Label>
                  <Input
                    type="text"
                    value={ifscCode}
                    id="ifscCode"
                    name="ifscCode"
                    placeholder="Add ifsc code"
                    required
                    onChange={(e) => setIfscCode(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </FormGroup>
        </Col>

        {/*// ! emergencyName */}
        <Col sm="6">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="emergencyName">
                Emergency name*
              </Label>
            </strong>
            <Input
              type="text"
              value={emergencyName}
              id="emergencyName"
              name="emergencyName"
              placeholder="Add emergency name"
              required
              onChange={(e) => setEmergencyName(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/*// ! emergencyNo */}
        <Col sm="6">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="emergencyNo">
                Emergency number*
              </Label>
            </strong>
            <Input
              type="text"
              value={emergencyNo}
              id="emergencyNo"
              name="emergencyNo"
              placeholder="Add emergency number"
              required
              onChange={(e) => setEmergencyNo(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      {userRole !== "Area Manager" && <SaveDiscardBtnGroup />}
    </form>
  );
};

export default PersonalInfo;
