import gql from "graphql-tag";

export const GET_UNPAID_BOOKINGS = gql`
  query {
    getUnpaidBookings {
      Source
      ReservationNo
      GuestName
      ArrivalDate
      DepartureDate
      ReservationDate
      Mobile
      Address
      DueAmount
      hostelName
    }
  }
`;

export const GET_UNPAID_BOOKING_LIST = gql`
  query getUnpaidBookingsList($filter: filter) {
    getUnpaidBookingsList(filter: $filter) {
      count
      page
      limit
      data {
        _id
        Source
        ReservationNo
        GuestName
        ArrivalDate
        DepartureDate
        ReservationDate
        Mobile
        Address
        DueAmount
        Deposit
        hostelName
        VoucherNo
        Email
        Status
        hostelId
        uniqueId
        paymentLinkSent
        dueAmountPaid
        paymentId
        Phone
        logs
        alternateEmail
        alternateMobile
      }
    }
  }
`;

export const GET_ALL_UNPAID_BOOKINGS_LIST = gql`
  query getAllUnpaidBookingsList($filter: filter) {
    getAllUnpaidBookingsList(filter: $filter) {
      _id
      Source
      ReservationNo
      GuestName
      ArrivalDate
      DepartureDate
      ReservationDate
      Mobile
      Address
      DueAmount
      hostelName
      VoucherNo
      Email
      Status
      hostelId
      uniqueId
      paymentLinkSent
      dueAmountPaid
      paymentId
      Phone
      logs
    }
  }
`;

