import React, { useEffect, useState } from "react";
import SlideModal from "./slide-modal";
import { useHostelList, useUploadImage } from "../../../api/dataSource";
import { useCreateGoPurchaseRequest } from "../../../api/GOPurchaseRequest/Mutation";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import toaster from "@components/common/Toaster";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import SaveDiscardBtnGroup from "./save-discard-btn-group";
import { Trash, Upload } from "react-feather";
import confirmDelete from "@components/common/confirmDelete";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  //! AM / GS Create purchase request useState
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [urgency, setUrgency] = useState(null);
  const [items, setItems] = useState([
    { id: uuidv4(), name: "", description: "", quantity: "", itemImage: null },
  ]);
  const [head, setHead] = useState(null);
  const [subHead, setSubHead] = useState(null);
  const [subHeadType, setSubHeadType] = useState(null);

  const [index, setIndex] = useState("");

  //for fetching hostel List
  const { data: allHostelsData } = useHostelList();

  //create Handler
  const [createGOPurchaseHandler, { data: createGoPurchaseData }] =
    useCreateGoPurchaseRequest();

  const [uploadImageHandler, { data: imageData }] = useUploadImage();

  const userName = localStorage.getItem("name");

  const userRole = localStorage.getItem("role");

  const userDepartment = localStorage.getItem("department");

  const userHostel = localStorage.getItem("hostels");

  const urgencyList = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  const HeadList = [{ label: "CAPEX", value: "CAPEX" }];

  let SubHeadList;

  if (head?.value === "CAPEX") {
    SubHeadList = [
      { label: "Hostel", value: "Hostel" },
      { label: "F&B", value: "F&B" },
      { label: "Ancillary", value: "Ancillary" },
    ];
  }

  // let CapexSubHeadTypeList;
  // if (
  //   subHead?.value === "Hostel" ||
  //   subHead?.value === "F&B" ||
  //   subHead?.value === "Ancillary"
  // ) {
  //   CapexSubHeadTypeList = [
  //     { label: "Civil", value: "Civil" },
  //     { label: "Furniture", value: "Furniture" },
  //     { label: "Furnishing", value: "Furnishing" },
  //     { label: "Fabrication", value: "Fabrication" },
  //     { label: "Electrical", value: "Electrical" },
  //     { label: "Electronics/Equipments", value: "Electronics/Equipments" },
  //     { label: "Plumbing", value: "Plumbing" },
  //     { label: "Others", value: "Others" },
  //   ];
  // }

  const CapexSubHeadTypeList = [
    { label: "Civil", value: "Civil" },
    { label: "Furniture", value: "Furniture" },
    { label: "Furnishing", value: "Furnishing" },
    { label: "Fabrication", value: "Fabrication" },
    { label: "Electrical", value: "Electrical" },
    { label: "Electronics/Equipments", value: "Electronics/Equipments" },
    { label: "Plumbing", value: "Plumbing" },
    { label: "Others", value: "Others" },
  ];

  //refetching and toggle logic
  useEffect(() => {
    if (createGoPurchaseData?.createGOPurchaseRequest) {
      refetch();
      handleToggle();
    }
  }, [createGoPurchaseData, refetch]);

  useEffect(() => {
    if (imageData?.uploadImage?.url) {
      setItems(
        items?.map((item) => {
          if (item.id === index) {
            return { ...item, itemImage: imageData?.uploadImage?.url };
          } else {
            return item;
          }
        })
      );
    }
  }, [imageData?.uploadImage?.url]);

  useEffect(() => {
    const allowedHostels = JSON.parse(userHostel);
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  const fileUploader = (e, id) => {
    const file = e.target.files[0];
    if (!file) return;

    setIndex(id);

    const maxFileSize = 2097152;

    if (file.size > maxFileSize) {
      toaster("error", "File size exceeds the maximum limit of 2 MB.");
      return;
    }

    uploadImageHandler(
      file,
      `${file?.name?.toLowerCase()?.replaceAll(" ", "-")}` +
        `/Operations/Capex/Item/Image`
    );

    toaster("success", "Item image uploaded successfully");
  };

  //setting edit data value
  useEffect(() => {
    if (editData?._id) {
      setHostel(
        editData?.hostel?.name
          ? { label: editData?.hostel?.name, value: editData?.hostel?._id }
          : null
      );

      setUrgency(
        editData?.urgency !== null
          ? { label: editData?.urgency, value: editData?.urgency }
          : null
      );
      setHead(
        editData?.head !== null
          ? { label: editData?.head, value: editData?.head }
          : null
      );
      setSubHead(
        editData?.subHead !== null
          ? { label: editData?.subHead, value: editData?.subHead }
          : null
      );
      setSubHeadType(
        editData?.subHeadType !== null
          ? { label: editData?.subHeadType, value: editData?.subHeadType }
          : null
      );

      setItems(
        editData?.items?.map((item) => {
          return {
            id: uuidv4(),
            name: item?.name,
            description: item?.description,
            quantity: item?.quantity,
            itemImage: item?.itemImage,
          };
        })
      );
    } else {
      setHostel(null);
      setUrgency(null);
      setItems([
        {
          id: uuidv4(),
          name: "",
          description: "",
          quantity: "",
          itemImage: null,
        },
      ]);
    }
  }, [editData]);

  const handleTextChangeName = (val, id, key) =>
    setItems(
      items.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeDescription = (val, id, key) =>
    setItems(
      items.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleTextChangeQuantity = (val, id, key) =>
    setItems(
      items.map((item) =>
        item.id === id ? { ...item, [key]: Number(val) } : item
      )
    );

  const handleAddItems = () =>
    setItems([
      ...items,
      {
        id: uuidv4(),
        name: "",
        description: "",
        quantity: "",
        itemImage: null,
      },
    ]);

  const handleRemoveItems = (id) => {
    // eslint-disable-next-line array-callback-return
    let newItemsData = items.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    confirmDelete(() => setItems(newItemsData));
  };

  //submit handler for form
  const onSubmitHandler = () => {
    let itemsClone = JSON.parse(JSON.stringify(items));
    itemsClone.forEach((item) => {
      delete item.id;
    });
    const data = {
      hostel: hostel?.value,
      urgency: urgency?.value,
      items: itemsClone,
      head: head?.value,
      subHead: subHead?.value,
      subHeadType: subHeadType?.value,
    };

    if (
      !data?.hostel ||
      !data?.urgency ||
      !data?.items ||
      !data?.items.length === 0 ||
      !data?.head ||
      !data?.subHead ||
      !data?.subHeadType
    ) {
      toaster("error", "Please fill all the required details marked with '*'");
      return;
    }

    for (const item of data.items) {
      if (!item.name || !item.quantity) {
        toaster("error", "All items must have a name and quantity");
        return;
      }
    }

    if (data?.hostel) {
      if (
        data?.hostel &&
        data?.urgency &&
        data?.items[0]?.name &&
        data?.items[0]?.quantity
      ) {
        createGOPurchaseHandler({
          ...data,
          logs: [
            `${userName} (${userRole}) has created purchase request on ${new Date().toLocaleString(
              "en-IN",
              {
                hour12: false,
              }
            )}`,
          ],
        });
        toaster("success", "Capex request raised successfully");
      } else {
        toaster(
          "error",
          "Please fill all the required details marked with '*'"
        );
      }

      setHostel(null);
      setUrgency(null);
      setItems([
        {
          id: uuidv4(),
          name: "",
          description: "",
          quantity: "",
          itemImage: null,
        },
      ]);
    } else {
      toaster("error", "Please fill all the required details marked with '*'");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
      editData={editData}
      userRole={userRole}
      userDepartment={userDepartment}
    >
      <form onSubmit={() => console.log()}>
        {
          /* userRole === "Area Manager" ||  */
          userRole === "Ground Staff" && (
            <>
              <Row>
                {/* //! Create GO Purchase Request */}
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="hostel">
                      Hostel*
                    </Label>
                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="hostel"
                      name="hostel"
                      options={hostelsList}
                      value={hostel}
                      isDisabled={editData?._id}
                      onChange={(value) => setHostel(value)}
                      classNamePrefix="select"
                    />
                  </FormGroup>
                </Col>

                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="urgency">
                      Urgency*
                    </Label>
                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="urgency"
                      name="urgency"
                      options={urgencyList}
                      value={urgency}
                      onChange={(value) => setUrgency(value)}
                      classNamePrefix="select"
                      isDisabled={editData?._id}
                    />
                  </FormGroup>
                </Col>

                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="head">
                      Request head*
                    </Label>
                    <Select
                      isClearable={false}
                      isSearchable={true}
                      id="head"
                      name="head"
                      options={HeadList}
                      value={head}
                      classNamePrefix="select"
                      onChange={(module) => {
                        setHead(module);
                      }}
                    />
                  </FormGroup>
                </Col>

                {head && (
                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="subHead">
                        Request sub-head*
                      </Label>
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="subHead"
                        name="subHead"
                        value={subHead}
                        options={SubHeadList}
                        required
                        onChange={(value) => setSubHead(value)}
                        classNamePrefix="select"
                      />
                    </FormGroup>
                  </Col>
                )}

                {(subHead?.value === "Hostel" ||
                  subHead?.value === "F&B" ||
                  subHead?.value === "Ancillary") && (
                  <Col sm="12">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="subHeadType">
                        CAPEX type*
                      </Label>
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        id="subHeadType"
                        name="subHeadType"
                        value={subHeadType}
                        options={CapexSubHeadTypeList}
                        required
                        onChange={(value) => setSubHeadType(value)}
                        classNamePrefix="select"
                      />
                    </FormGroup>
                  </Col>
                )}
                <hr
                  style={{
                    borderTop: "2px solid #ffe700",
                    width: "100%",
                  }}
                />

                {items.map(
                  ({ id, name, description, quantity, itemImage }, index) => (
                    <React.Fragment key={id}>
                      <Col sm="12">
                        <Row>
                          <Col sm="10">
                            <FormGroup className="w-100">
                              <Label className="form-label" for="index">
                                <strong> Item {index + 1}</strong>
                              </Label>
                            </FormGroup>
                          </Col>

                          <Col sm="2">
                            {items.length > 1 ? (
                              <>
                                <Button.Ripple
                                  className="btn-icon rounded-circle mb-1 mr-1"
                                  color="light"
                                  type="button"
                                  size="sm"
                                  onClick={() => handleRemoveItems(id)}
                                  disabled={editData?._id}
                                >
                                  <Trash size={14} />
                                </Button.Ripple>
                              </>
                            ) : null}
                          </Col>

                          <Col sm="6">
                            <FormGroup className="w-100">
                              <Label className="form-label" for="name">
                                Name*
                              </Label>

                              <Input
                                type="text"
                                value={name}
                                id="cancel"
                                name="cancel"
                                placeholder={`Name`}
                                onChange={(e) =>
                                  handleTextChangeName(
                                    e.target.value,
                                    id,
                                    "name"
                                  )
                                }
                                disabled={editData?._id}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm="6">
                            <FormGroup className="w-100">
                              <Label className="form-label" for="quantity">
                                Quantity*
                              </Label>

                              <Input
                                type="number"
                                value={quantity}
                                id="quantity"
                                name="quantity"
                                placeholder="Quantity"
                                onChange={(e) =>
                                  handleTextChangeQuantity(
                                    e.target.value,
                                    id,
                                    "quantity"
                                  )
                                }
                                min="0"
                                onWheel={(e) => e.target.blur()}
                                disabled={editData?._id}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm="12">
                            <FormGroup className="w-100">
                              <Label className="form-label" for="description">
                                Description
                              </Label>

                              <Input
                                type="textarea"
                                value={description}
                                id="description"
                                name="description"
                                placeholder="Description"
                                rows="1"
                                onChange={(e) =>
                                  handleTextChangeDescription(
                                    e.target.value,
                                    id,
                                    "description"
                                  )
                                }
                                disabled={editData?._id}
                              />
                            </FormGroup>
                          </Col>

                          <Col
                            sm="12"
                            // className="mt-2 d-flex align-items-center gap-2"
                          >
                            <label htmlFor="">Image</label>

                            <div className="mt-2 d-flex align-items-center gap-2">
                              {itemImage !== null ? (
                                itemImage?.includes(".pdf") ? (
                                  <img
                                    className="imageSection__image"
                                    src={
                                      "https://static-dev.thehosteller.com/GO_PURCHASE_REQUEST/pdfImage-1720680601586.png"
                                    }
                                    alt="Attachment"
                                    style={{
                                      width: "6rem",
                                      height: "6rem",
                                      objectFit: "cover",
                                      cursor: "pointer",
                                      marginLeft: "1.7rem",
                                    }}
                                    onClick={() =>
                                      window.open(itemImage, "_blank")
                                    }
                                  />
                                ) : (
                                  <img
                                    className="imageSection__image"
                                    src={itemImage}
                                    alt="Attachment"
                                    style={{
                                      width: "6rem",
                                      height: "6rem",
                                      objectFit: "cover",
                                      cursor: "pointer",
                                      marginLeft: "1.7rem",
                                    }}
                                    onClick={() =>
                                      window.open(itemImage, "_blank")
                                    }
                                  />
                                )
                              ) : null}

                              <label htmlFor={`myFile${id}`}>
                                <p className="btn btn-sm">
                                  <Upload size={30} />
                                </p>
                                <input
                                  name={`myFile${id}`}
                                  id={`myFile${id}`}
                                  className="d-none"
                                  type="file"
                                  onChange={(e) => fileUploader(e, id)}
                                />
                              </label>
                            </div>
                          </Col>

                          <hr
                            style={{
                              borderTop: "2px solid #ffe700",
                              width: "100%",
                            }}
                          />
                        </Row>
                      </Col>
                    </React.Fragment>
                  )
                )}
              </Row>

              <SaveDiscardBtnGroup
                addAction={handleAddItems}
                userRole={editData?._id}
              />
            </>
          )
        }
      </form>
    </SlideModal>
  );
};
export default AddEditModal;
