import gql from "graphql-tag";

export const GET_BOOKING_CALENDER = gql`
  query get_booking_calendar(
    $hostelId: ID!
    $fromDate: String!
    $toDate: String!
  ) {
    get_booking_calendar(
      hostelId: $hostelId
      fromDate: $fromDate
      toDate: $toDate
    )
  }
`;

export const GET_ROOM_TYPES = gql`
  query get_room_types($hostelId: ID!) {
    get_room_types(hostelId: $hostelId)
  }
`;

export const GET_WEB_CHECKIN_DATA_BY_RESERVATION_ID = gql`
  query getWebcheckinDataByReservationId($reservationID: String!) {
    getWebcheckinDataByReservationId(reservationID: $reservationID) {
      phoneNumber
      reservationID
      residingCity
      firstName
      lastName
      email
      govtIdNumber
    }
  }
`;

export const _GET_GUEST_DATABASE = gql`
  query ($filter: filter) {
    getGuestDatabase(filter: $filter) {
      GuestName
      Email
      Mobile
      uniqueId
      hostelName
      BookingStatus
      ArrivalDate
      DepartureDate
      Source
      Adult
      Child
      Phone
      NoOfGuest
      NoOfNights
      RoomNo
      RatePlan
      ReservationDate
      TotalExclusivTax
      hostelId
      ReservationNo
    }
  }
`;
