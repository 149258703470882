import gql from "graphql-tag";
import { useLoadingHandler } from "../../dataSource";
import { useLazyQuery } from "@apollo/client";

export const GET_PAGINATED_USER_REQUESTS_LIST = gql`
  query getPaginatedUserRequestList($filter: filter) {
    getPaginatedUserRequestList(filter: $filter) {
      data {
        _id
        reservationId
        hostel {
          _id
          name
        }
        requestCategory
        request {
          requestName
          requestDescription
          requestIcon
          tat
        }
        requestStatus
        guestComment
        requestImages
        staffRemark
        staffName
        logs
        ezeebookings
        requestNumber
        isCritical
        createdAt
        updatedAt
        rating
        staffImages {
          image
        }
        roomNo
        room
        guestName
      }
      count
      limit
      page
    }
  }
`;

//added by nitish for export
export const GET_ALL_USER_REQUESTS_LIST = gql`
  query getAllUserRequestList($filter: filter) {
    getAllUserRequestList(filter: $filter) {
      data {
        _id
        reservationId
        hostel {
          _id
          name
        }
        requestCategory
        request {
          requestName
          requestDescription
          requestIcon
          tat
        }
        requestStatus
        guestComment
        requestImages
        staffRemark
        logs
        ezeebookings
        requestNumber
        isCritical
        createdAt
        updatedAt
        createdAt
        rating
        staffImages {
          image
        }
      }
    }
  }
`;




export const GET_DATA_FOR_REQUEST_REPORT = gql`
 query getRequestwiseReport($fromDate:Date,$toDate:Date,$hostel:[ID]) {
  getRequestwiseReport(fromDate: $fromDate,toDate: $toDate,hostel: $hostel) {
      _id
      requestCount
      avgTat
      tatMissed
      avgRating
  }
}
`


export const useGetDataForRequestReport = () => {

  const [getReport, { loading, error, data, refetch }] = useLazyQuery(GET_DATA_FOR_REQUEST_REPORT)


  useLoadingHandler(loading)

  const queryHandler = (fromDate, toDate, hostel) => {

    getReport({
      variables: {
        fromDate,
        toDate,
        hostel
      }
    })
  }


  return [queryHandler, { data, error, refetch }]
}