import React, { useEffect, useState } from "react";
import { Edit2, FileText } from "react-feather";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Table,
  Row,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { dateFormatHandler } from "../../../utility/Utils";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Request ID",
  "Request Date & Time",
  "Hostel",
  "Item x Quantity - Status",
  "Urgency",
  "Status",
  "Total Amount",
  "Paid Amount",
  "Deduction Amount",
  "Due Amount",
  "Payment Details",
  // "Warranty",
  // "Delivery Timeline (In days)",
  "Completion Date & Time",
  "TAT (In HH:MM)",
  // "Total TAT (In HH:MM)",
  "Total TAT (In Days:HH:MM)",
];

const GOPurchaseTable = ({
  openModal,
  setId,
  data,
  handleUpdateGoPurchase,
  count,
  consolidatedTotalAmount,
  consolidatedTotalAmountPaid,
  consolidatedTotalTdsAmount,
  consolidatedTotalDeductionAmount,
  refetch,
  loading,
}) => {
  const [tableData, setTableData] = useState(data);

  const userRole = localStorage.getItem("role");
  const userDepartment = localStorage.getItem("department");

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
    hour12: true,
    timeZone: "Asia/Kolkata",
  };
  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const requestToQuotationAdded = (createdAt, quotationAddedDate) => {
    const createdAtDateTime = new Date(createdAt);
    const quotationAddedDateTime = new Date(quotationAddedDate);

    const timeDifference = quotationAddedDateTime - createdAtDateTime;

    const totalSeconds = timeDifference / 1000;
    const hours = totalSeconds / 3600;
    const minutes = (totalSeconds % 3600) / 60;
    // const seconds = totalSeconds % 60;

    const pad = (num) => String(num).padStart(2, "0");
    // const tat = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    const tat = `${pad(Math.floor(hours))}:${pad(Math.floor(minutes))}`;

    return tat;
  };

  const quotationAddedToPurchaseApproved = (
    quotationAddedDate,
    purchaseApprovedDate
  ) => {
    const quotationAddedDateTime = new Date(quotationAddedDate);
    const purchaseApprovedDateTime = new Date(purchaseApprovedDate);

    const timeDifference = purchaseApprovedDateTime - quotationAddedDateTime;

    const totalSeconds = timeDifference / 1000;
    const hours = totalSeconds / 3600;
    const minutes = (totalSeconds % 3600) / 60;
    // const seconds = totalSeconds % 60;

    const pad = (num) => String(num).padStart(2, "0");
    // const tat = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    const tat = `${pad(Math.floor(hours))}:${pad(Math.floor(minutes))}`;

    return tat;
  };

  const purchaseApprovedToPaymentDone = (
    purchaseApprovedDate,
    paymentDoneDate
  ) => {
    const purchaseApprovedDateTime = new Date(purchaseApprovedDate);
    const paymentDoneDateTime = new Date(paymentDoneDate);

    const timeDifference = paymentDoneDateTime - purchaseApprovedDateTime;

    const totalSeconds = timeDifference / 1000;
    const hours = totalSeconds / 3600;
    const minutes = (totalSeconds % 3600) / 60;
    // const seconds = totalSeconds % 60;

    const pad = (num) => String(num).padStart(2, "0");
    // const tat = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    const tat = `${pad(Math.floor(hours))}:${pad(Math.floor(minutes))}`;

    return tat;
  };

  const purchaseApprovedToMaterialDispatched = (
    purchaseApprovedDate,
    materialDispatchedDate
  ) => {
    const purchaseApprovedDateTime = new Date(purchaseApprovedDate);
    const materialDispatchedDateTime = new Date(materialDispatchedDate);

    const timeDifference =
      materialDispatchedDateTime - purchaseApprovedDateTime;

    const totalSeconds = timeDifference / 1000;
    const hours = totalSeconds / 3600;
    const minutes = (totalSeconds % 3600) / 60;
    // const seconds = totalSeconds % 60;

    const pad = (num) => String(num).padStart(2, "0");
    // const tat = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    const tat = `${pad(Math.floor(hours))}:${pad(Math.floor(minutes))}`;

    return tat;
  };

  const paymentDoneToCompletionDate = (paymentDoneDate, completionDate) => {
    const paymentDoneDateTime = new Date(paymentDoneDate);
    const completionDateTime = new Date(completionDate);

    const timeDifference = completionDateTime - paymentDoneDateTime;

    const totalSeconds = timeDifference / 1000;
    const hours = totalSeconds / 3600;
    const minutes = (totalSeconds % 3600) / 60;
    // const seconds = totalSeconds % 60;

    const pad = (num) => String(num).padStart(2, "0");
    // const tat = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    const tat = `${pad(Math.floor(hours))}:${pad(Math.floor(minutes))}`;

    return tat;
  };

  const requestToCompletionDate = (createdAt, completionDate) => {
    const createdAtDateTime = new Date(createdAt);
    const completionDateTime = new Date(completionDate);

    const timeDifference = completionDateTime - createdAtDateTime;

    // const totalSeconds = timeDifference / 1000;
    // const hours = totalSeconds / 3600;
    // const minutes = (totalSeconds % 3600) / 60;
    // // const seconds = totalSeconds % 60;

    const totalSeconds = timeDifference / 1000;
    const days = totalSeconds / (3600 * 24);
    const hours = (totalSeconds % (3600 * 24)) / 3600;
    const minutes = (totalSeconds % 3600) / 60;
    // const seconds = totalSeconds % 60;

    const pad = (num) => String(num).padStart(2, "0");
    // const tat = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    const tat = `${pad(Math.floor(days))}:${pad(Math.floor(hours))}:${pad(
      Math.floor(minutes)
    )}`;

    return tat;
  };

  const consolidatedDueAmount =
    consolidatedTotalAmount -
    consolidatedTotalAmountPaid -
    consolidatedTotalTdsAmount -
    consolidatedTotalDeductionAmount;

  const consolidatedTotalAmountPaidWithTds =
    consolidatedTotalAmountPaid + consolidatedTotalTdsAmount;

  const tableDataStyle = {
    minWidth: "240px",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              CAPEX Request ({count > 0 ? count : 0} Requests) | Total Amount (₹
              {consolidatedTotalAmount > 0
                ? consolidatedTotalAmount?.toFixed(2)
                : 0}{" "}
              ) | Paid (₹
              {consolidatedTotalAmountPaid > 0
                ? /* consolidatedTotalAmountPaid?.toFixed(2) +
                    consolidatedTotalTdsAmount?.toFixed(2) */
                  consolidatedTotalAmountPaidWithTds?.toFixed(2)
                : 0}
              )
              {/* | Due (₹
              {consolidatedDueAmount > 0
                ? consolidatedDueAmount?.toFixed(2)
                : 0}
              ) */}
            </CardTitle>

            <CardHeader className="d-flex justify-content-between align-items-center px-3">
              {
                /* userRole === "Area Manager" ||  */
                userRole === "Ground Staff" && (
                  <Button.Ripple
                    color="light"
                    onClick={(e) => {
                      e.preventDefault();
                      openModal();
                    }}
                  >
                    Add Capex Request
                  </Button.Ripple>
                )
              }
            </CardHeader>
          </CardHeader>
          {loading ? (
            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
              Please wait...
            </h2>
          ) : tableData.length > 0 ? (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    {tableHeaderData.map((item) => {
                      return (
                        <th style={{ whiteSpace: "nowrap" }} key={uuidv4()}>
                          {item}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((element, i) => (
                    <tr key={uuidv4()}>
                      {/* <td>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(element._id);
                        openModal();
                      }}
                    >
                      <Edit2 size={15} />
                    </Button>
                  </td> */}
                      <td style={{ width: "5%" }}>
                        <Button
                          className="rounded-circle btn-icon"
                          color="none"
                          onClick={(e) => {
                            e.preventDefault();
                            userDepartment !== "Purchase"
                              ? handleUpdateGoPurchase(element?._id)
                              : window.open(
                                  `https://www.admin.thehosteller.com/operations/edit/goPurchase/${element?._id}`,
                                  "_blank"
                                );
                            // window.open(
                            //   `http://localhost:3000/operations/edit/goPurchase/${element?._id}`,
                            //   "_blank"
                            // );
                          }}
                        >
                          <FileText
                            color={reactFeatherIcon.iconColor}
                            size={reactFeatherIcon.iconSize}
                          />
                        </Button>
                      </td>
                      <td>
                        <Badge color={"light-info"}>{element?.requestId}</Badge>
                      </td>
                      <td>
                        {/* {element?.createdAt !== null
                      ? dateFormatHandler(element?.createdAt)
                      : "NA"} */}
                        {new Intl.DateTimeFormat("en-IN", options).format(
                          new Date(element?.createdAt)
                        )}
                      </td>
                      <td>{element?.hostel?.name}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {element?.items.map((item, index) => (
                          <React.Fragment key={index}>
                            {item?.name} x {item?.quantity}{" "}
                            {item?.itemStatus?.length > 0
                              ? `- ${item?.itemStatus}`
                              : null}
                            <br />{" "}
                          </React.Fragment>
                        ))}
                      </td>
                      <td>
                        <Badge
                          color={
                            element?.urgency === "Low"
                              ? "success"
                              : element?.urgency === "Medium"
                              ? "warning"
                              : "danger"
                          }
                        >
                          {element?.urgency}
                        </Badge>
                      </td>
                      <td>
                        <Badge
                          color={
                            element?.status?.includes("Approved") ||
                            element?.status?.includes("Done") ||
                            element?.status === "Request Completed"
                              ? "success"
                              : element?.status?.includes("Rejected")
                              ? "danger"
                              : "warning"
                          }
                        >
                          {element?.status}
                        </Badge>
                      </td>
                      <td>
                        {element?.totalAmount !== 0
                          ? element?.totalAmount?.toFixed(2)
                          : "-"}
                      </td>
                      <td>
                        {/* {element?.totalAmountPaid && element?.totalTdsAmount
                          ? element?.totalAmountPaid +
                            "+" +
                            element?.totalTdsAmount +
                            "(TDS)"
                          : "NA"} */}
                        {element?.totalAmountPaid
                          ? parseFloat(
                              element?.totalAmountPaid + element?.totalTdsAmount
                            )?.toFixed(2)
                          : "-"}
                      </td>

                      <td>
                        {element?.deductionAmount
                          ? element?.deductionAmount?.toFixed(2)
                          : "-"}
                      </td>

                      <td>
                        {element?.totalAmount
                          ? /* && element?.totalAmountPaid */
                            parseFloat(
                              element?.totalAmount -
                                element?.totalAmountPaid -
                                element?.totalTdsAmount -
                                element?.deductionAmount
                            ).toFixed(2)
                          : "-"}
                      </td>

                      <td style={tableDataStyle}>
                        {element?.paymentDetails?.length
                          ? element?.paymentDetails?.map((item, i) => (
                              <React.Fragment key={i}>
                                {(item?.paymentStatus === "Payment Due" ||
                                item?.paymentStatus === "Payment Rejected"
                                  ? item?.amountToPay?.toFixed(2)
                                  : parseFloat(
                                      item?.amountPaid + item?.tdsAmount
                                    ).toFixed(2)) +
                                  "-" +
                                  item?.paymentStatus}{" "}
                                <br />
                              </React.Fragment>
                            ))
                          : "-"}
                      </td>

                      {/* 
                  <td>{element?.warranty !== 0 ? element?.warranty : "NA"}</td>

                  <td>
                    {element?.deliveryTimeline !== 0
                      ? element?.deliveryTimeline
                      : "NA"}
                  </td> */}
                      <td>
                        {element?.completionDate !== null
                          ? /* dateFormatHandler(element?.completionDate) */
                            new Intl.DateTimeFormat("en-IN", options).format(
                              new Date(element?.completionDate)
                            )
                          : "-"}
                      </td>

                      {element?.status !== "Pending" ? (
                        <td style={tableDataStyle}>
                          {element?.createdAt !== null &&
                          element?.quotationAddedDate !== null
                            ? `Quotation: ${requestToQuotationAdded(
                                element?.createdAt,
                                element?.quotationAddedDate
                              )}`
                            : null}
                          <br />
                          {element?.quotationAddedDate !== null &&
                          element?.purchaseApprovedDate !== null
                            ? `Approval: ${quotationAddedToPurchaseApproved(
                                element?.quotationAddedDate,
                                element?.purchaseApprovedDate
                              )}`
                            : null}
                          <br />
                          {element?.purchaseApprovedDate !== null &&
                          element?.paymentDoneDate !== null
                            ? `Payment: ${purchaseApprovedToPaymentDone(
                                element?.purchaseApprovedDate,
                                element?.paymentDoneDate
                              )}`
                            : null}

                          <br />
                          {element?.purchaseApprovedDate !== null &&
                          element?.materialDispatchedDate !== null
                            ? `Material Dispatched: ${purchaseApprovedToMaterialDispatched(
                                element?.purchaseApprovedDate,
                                element?.materialDispatchedDate
                              )}`
                            : null}

                          <br />
                          {/* {element?.paymentDoneDate !== null &&
                          element?.completionDate !== null
                            ? `Completed: ${paymentDoneToCompletionDate(
                                element?.paymentDoneDate,
                                element?.completionDate
                              )}`
                            : null}
                          <br /> */}
                          {/* {element?.createdAt !== null &&
                          element?.completionDate !== null
                            ? `R&I: ${requestToCompletionDate(
                                element?.createdAt,
                                element?.completionDate
                              )}`
                            : null} */}
                        </td>
                      ) : (
                        <td style={tableDataStyle}> - </td>
                      )}

                      {element?.status !== "Pending" ? (
                        <td style={tableDataStyle}>
                          {element?.createdAt !== null &&
                          element?.completionDate !== null
                            ? requestToCompletionDate(
                                element.createdAt,
                                element.completionDate
                              )
                            : null}
                        </td>
                      ) : (
                        <td style={tableDataStyle}> - </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
              No request to show
            </h2>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default GOPurchaseTable;
