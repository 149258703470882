import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useQuery } from "@apollo/client";

export const GET_ALL_CONFIRM_BUS_BOOKINGS = gql`
query getAllPaginatedBusBookings{
    getAllPaginatedBusBookings{
        data{
        _id
            bookingPNR
            razorpayPaymentId
            busName
            busType
            journeyDate
            boardingAddress
            droppingAddress
            boardingLandmark
            droppingLandmark
            boardingTime
            droppingTime
            inventoryItems{
                fare
                seatName
                passenger{
                    name
                    primary
                    mobile
                    }
            }
        }
    }
}
`

export const useGetAllConfirmBusBookings = (filter) => {
    const { loading, error, data, refetch } = useQuery(GET_ALL_CONFIRM_BUS_BOOKINGS);
    useLoadingHandler(loading);
    return { loading, error, data, refetch };
};