import React, { useState, useEffect, useRef } from "react";
import {
  useGetReviewSentimentAnalysis,
  useGetOverallAverageRating,
  useGetOtaSummary,
  useGetAvgRatingOtaReviews,
} from "../../api/dataSource";
import { FormGroup, Label, Spinner } from "reactstrap";
import ScoreCard from "./ScoreCard";
import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import { useHostelList } from "../../api/dataSource";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import { dateFormatter } from "@utils";
import Flatpickr from "react-flatpickr";
import Chart from "react-apexcharts";
const ORMHomepage = () => {
  const [fromDate, setFromDate] = useState(
    dateFormatter(new Date(new Date().setDate(1)))
  );
  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  const [hostelsList, setHostelsList] = useState([]);
  const { data: allHostelsData } = useHostelList();
  const [hostel, setHostel] = useState(null);

  const [filterFields, setFilterFields] = useState({
    fromDate,
    toDate,
    hostel: null,
  });

  const { data, loading: loadingOtaSummary } = useGetOtaSummary(
    filterFields.hostel?.value
  );

  const { data: sentimentAnalysisData, loading: loadingSentimentAnalysis } =
    useGetReviewSentimentAnalysis(
      filterFields.hostel ? [filterFields.hostel.value] : [],
      filterFields.fromDate,
      filterFields.toDate
    );

  const { data: overallRatingData, loading: loadingOverallRating } =
    useGetOverallAverageRating(
      filterFields.hostel ? [filterFields.hostel.value] : [],
      filterFields.fromDate,
      filterFields.toDate
    );

  const { data: avgReviewsRangeRating, loading: loadingAvgReviewsRangeRating } =
    useGetAvgRatingOtaReviews(
      filterFields.hostel ? [filterFields.hostel.value] : [],
      filterFields.fromDate,
      filterFields.toDate
    );

  const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
  const hostelsListRef = useRef([]);
  const allowedHostelsRef = useRef([]);

  const handleSearch = () => {
    setFilterFields({
      fromDate,
      toDate,
      hostel,
    });
  };

  // useEffect(() => {
  //   let hostelsList = [];

  //   if (allHostelsData?.getHostelList?.length) {
  //     if (allowedHostels && allowedHostels.length > 0) {
  //       const filteredHostels = allowedHostels.map((hostelID) =>
  //         allHostelsData.getHostelList.find((item) => item?._id === hostelID)
  //       );

  //       hostelsList = filteredHostels.map((item) => ({
  //         label: item?.name,
  //         value: item?._id,
  //       }));
  //     } else {
  //       hostelsList = allHostelsData.getHostelList.map((item) => ({
  //         label: item?.name,
  //         value: item?._id,
  //       }));
  //     }

  //     if (
  //       JSON.stringify(hostelsList) !==
  //         JSON.stringify(hostelsListRef.current) ||
  //       JSON.stringify(allowedHostels) !==
  //         JSON.stringify(allowedHostelsRef.current)
  //     ) {
  //       hostelsListRef.current = hostelsList;
  //       allowedHostelsRef.current = allowedHostels;

  //       setHostelsList(hostelsList);

  //       if (hostelsList.length > 0) {
  //         setHostel({
  //           label: hostelsList[0]?.label,
  //           value: hostelsList[0]?.value,
  //         });
  //       }
  //     }
  //   }
  // }, [allHostelsData?.getHostelList, allowedHostels]);

  useEffect(() => {
    let hostelsList = [];

    if (allHostelsData?.getHostelList?.length) {
      if (allowedHostels && allowedHostels.length > 0) {
        const filteredHostels = allowedHostels
          .map((hostelID) =>
            allHostelsData.getHostelList.find((item) => item?._id === hostelID)
          )
          .filter(Boolean);
        hostelsList = filteredHostels.map((item) => ({
          label: item?.name,
          value: item?._id,
        }));
      } else {
        hostelsList = allHostelsData.getHostelList.map((item) => ({
          label: item?.name,
          value: item?._id,
        }));
      }

      if (
        JSON.stringify(hostelsList) !==
          JSON.stringify(hostelsListRef.current) ||
        JSON.stringify(allowedHostels) !==
          JSON.stringify(allowedHostelsRef.current)
      ) {
        hostelsListRef.current = hostelsList;
        allowedHostelsRef.current = allowedHostels;

        setHostelsList(hostelsList);

        if (
          hostelsList.length > 0 &&
          allowedHostels &&
          allowedHostels.length > 0
        ) {
          setHostel({
            label: hostelsList[0]?.label,
            value: hostelsList[0]?.value,
          });
        } else {
          setHostel(null);
        }
      }
    }
  }, [allHostelsData?.getHostelList, allowedHostels]);

  const bookingSourceImages = {
    "Booking.com":
      "https://static.thehosteller.com/Hostel/Media/bookingcom%201-1694686256522.svg",
    Goibibo:
      "https://static.thehosteller.com/Hostel/Media/go%20ibibo-1694686239581.svg",
    "Hostel World":
      "https://static.thehosteller.com/Hostel/Media/hostelworld%202%20(1)-1695295577721.svg",
    Google:
      "https://static.thehosteller.com/Hostel/Media/Layer_x0020_1-1694686214140.svg",
    MakeMyTrip:
      "https://static.thehosteller.com/Hostel/Media/Make%20mytrip]-1694686267981.svg",
    TripAdvisor:
      "https://static.thehosteller.com/Hostel/Media/Tripadvisor%20(1)-1695295571996.svg",
  };

  const chartData = {
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          barWidth: "50%",
          columnWidth: "20%",
        },
      },
      xaxis: {
        categories: ["Positive", "Neutral", "Negative"],
        labels: {
          style: {
            colors: "#7D7C7C",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      yaxis: {
        labels: {
          style: {
            colors: "#7D7C7C",
          },
        },
      },
      legend: {
        position: "top",
      },
      colors: ["#36bd92", "#3498db", "#f44336"],
    },
    series: [
      {
        name: "Sentiment Analysis",
        data: [
          sentimentAnalysisData?.getReviewSentimentAnalysis?.positiveRate?.toFixed(
            2
          ),
          sentimentAnalysisData?.getReviewSentimentAnalysis?.neutralRate?.toFixed(
            2
          ),
          sentimentAnalysisData?.getReviewSentimentAnalysis?.negativeRate?.toFixed(
            2
          ),
        ],
      },
    ],
  };

  if (!sentimentAnalysisData || !chartData.options || !chartData.series) {
    return null;
  }

  // const isLoading =
  //   loadingOtaSummary &&
  //   loadingSentimentAnalysis &&
  //   loadingOverallRating &&
  //   loadingAvgReviewsRangeRating;

  const isLoading =
    loadingOtaSummary ||
    loadingSentimentAnalysis ||
    loadingOverallRating ||
    loadingAvgReviewsRangeRating;

  // if (isLoading) {
  //   return <Spinner color="primary" />;
  // }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner color="primary" style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }

  const homepageData = [
    {
      title: "ORM SCORE",
      score: Math.round(sentimentAnalysisData?.getReviewSentimentAnalysis?.orm),
      bgColor: "#36bd92",
    },
    {
      title: "AVG REVIEW RATING",
      score: overallRatingData?.getOverallAverageRating?.toFixed(1),
      bgColor: "#d7607d",
    },
    {
      title: "NPS SCORE",
      score: Math.round(sentimentAnalysisData?.getReviewSentimentAnalysis?.nps),
      bgColor: "#36bd92",
    },
    {
      title: "REVIEWS",
      chart: true,
      score: sentimentAnalysisData?.getReviewSentimentAnalysis?.totalReviews,
      positiveReviews:
        sentimentAnalysisData?.getReviewSentimentAnalysis?.positiveReviews,
      negativeReviews:
        sentimentAnalysisData?.getReviewSentimentAnalysis?.negativeReviews,
      neutralReviews:
        sentimentAnalysisData?.getReviewSentimentAnalysis?.neutralReviews,
    },

    {
      title: "TICKETS",
      score: sentimentAnalysisData?.getReviewSentimentAnalysis?.ticketsCreated,
    },

    {
      title: "GOOGLE RATING",
      score: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ fontSize: "18px" }}>
            {data?.getRecentOtaSummary[0]?.googleAvgRating?.toFixed(1) || "N/A"}
          </div>
          <div style={{ fontSize: "14px", margin: "0 2px" }}>/</div>
          <div style={{ fontSize: "18px" }}>
            {avgReviewsRangeRating?.getAvgRatingOtaReviews[0]?.googleAvgRating?.toFixed(
              1
            ) || "N/A"}
          </div>
        </div>
      ),
      image: bookingSourceImages?.Google,
    },
    {
      title: "BOOKING.COM RATING",
      score: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ fontSize: "18px" }}>
            {data?.getRecentOtaSummary[0]?.bookingComAvgRating?.toFixed(1) ||
              "N/A"}
          </div>
          <div style={{ fontSize: "14px", margin: "0 2px" }}>/</div>
          <div style={{ fontSize: "18px" }}>
            {avgReviewsRangeRating?.getAvgRatingOtaReviews[0]?.bookingComAvgRating?.toFixed(
              1
            ) || "N/A"}
          </div>
        </div>
      ),
      image: bookingSourceImages["Booking.com"],
    },
    {
      title: "TRIPADVISOR RATING",
      score: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ fontSize: "18px" }}>
            {data?.getRecentOtaSummary[0]?.tripadvisorAvgRating?.toFixed(1) ||
              "N/A"}
          </div>
          <div style={{ fontSize: "14px", margin: "0 2px" }}>/</div>
          <div style={{ fontSize: "18px" }}>
            {avgReviewsRangeRating?.getAvgRatingOtaReviews[0]?.tripadvisorAvgRating?.toFixed(
              1
            ) || "N/A"}
          </div>
        </div>
      ),
      image: bookingSourceImages?.TripAdvisor,
    },
    {
      title: "GOIBIBO RATING",
      score: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ fontSize: "18px" }}>
            {data?.getRecentOtaSummary[0]?.goibiboAvgRating?.toFixed(1) ||
              "N/A"}
          </div>
          <div style={{ fontSize: "14px", margin: "0 2px" }}>/</div>
          <div style={{ fontSize: "18px" }}>
            {avgReviewsRangeRating?.getAvgRatingOtaReviews[0]?.goibiboAvgRating?.toFixed(
              1
            ) || "N/A"}
          </div>
        </div>
      ),
      image: bookingSourceImages?.Goibibo,
    },
    {
      title: "MAKEMYTRIP RATING",
      score: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ fontSize: "18px" }}>
            {data?.getRecentOtaSummary[0]?.makeMyTripAvgRating?.toFixed(1) ||
              "N/A"}
          </div>
          <div style={{ fontSize: "14px", margin: "0 2px" }}>/</div>
          <div style={{ fontSize: "18px" }}>
            {avgReviewsRangeRating?.getAvgRatingOtaReviews[0]?.makeMyTripAvgRating?.toFixed(
              1
            ) || "N/A"}
          </div>
        </div>
      ),
      image: bookingSourceImages?.MakeMyTrip,
    },
    {
      title: "HOSTELWORLD RATING",
      score: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ fontSize: "18px" }}>
            {data?.getRecentOtaSummary[0]?.hostelWorldAvgRating?.toFixed(1) ||
              "N/A"}
          </div>
          <div style={{ fontSize: "14px", margin: "0 2px" }}>/</div>
          <div style={{ fontSize: "18px" }}>
            {avgReviewsRangeRating?.getAvgRatingOtaReviews[0]?.hostelWorldAvgRating?.toFixed(
              1
            ) || "N/A"}
          </div>
        </div>
      ),
      image: bookingSourceImages["Hostel World"],
    },

    {
      title: "SENTIMENT",
      positiveRate: Math.round(
        sentimentAnalysisData?.getReviewSentimentAnalysis?.positiveRate
      ),
    },

    {
      title: "RESPONSIVENESS",
      positiveRate: Math.round(
        sentimentAnalysisData?.getReviewSentimentAnalysis?.responsiveness
      ),
    },
  ];

  return (
    localStorage.getItem("modules")?.includes("ORM Reports") && (
      <>
        <Row>
          <Col sm="4">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                Hostel Name
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                id="hostel"
                name="hostel"
                value={hostel}
                options={hostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                Review Date - From
              </Label>

              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
                id="fromDate"
                placeholder="From Date"
              />
            </FormGroup>
          </Col>
          <Col sm="2">
            <FormGroup className="w-100">
              <Label className="form-label" for="toDate">
                Review Date - To
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={(date) => setToDate(dateFormatter(new Date(date)))}
                id="toDate"
                placeholder="To Date"
              />
            </FormGroup>
          </Col>
          <Col sm="3">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ alignSelf: "flex-start" }}>
                  <h3>Home</h3>
                </div>
                <div style={{ textAlign: "right" }}>
                  <p>
                    <strong>Live Ratings / Range Ratings</strong>
                  </p>
                  <p style={{ fontSize: "12px" }}>
                    <strong>
                      ORM Score - [(Positive Reviews - (Negative Reviews / 3)] /
                      [Total Reviews] * 100
                    </strong>
                  </p>
                </div>
              </CardHeader>
            </Card>

            <Row>
              {homepageData?.map((item, index) => (
                <Col sm="3" key={index}>
                  <ScoreCard
                    cardData={item}
                    bgColor={item?.bgColor}
                    color={item?.bgColor ? "white" : "#7D7C7C"}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col sm="8">
            <Card>
              <CardHeader>
                <CardTitle>Review Analysis</CardTitle>
              </CardHeader>
            </Card>
            <Chart
              options={chartData?.options}
              series={chartData?.series}
              type="bar"
              width={1000}
              height={420}
            />
          </Col>
        </Row>
      </>
    )
  );
};

export default ORMHomepage;
