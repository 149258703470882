// Coded by Nitish
import React, { useEffect, useState, Fragment } from "react";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";
import {
  useUpdateService,
  useCreateService,
  // useHostelList,
  useUploadImage,
  useGetHostelsNameandId,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import SlideModal from "./slide-modal/index";
import SwitchIcon from "@components/common/switch-icon";
import Flatpickr from "react-flatpickr";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import "flatpickr/dist/themes/material_green.css";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { Plus, X } from "react-feather";
import { v4 as uuidv4 } from "uuid";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  console.log(editData);
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [totalInventoryPerDay, setTotalInventoryPerDay] = useState(0);
  const [strikeThroughPrice, setStrikeThroughPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [cutOffTime, setCutOffTime] = useState(0);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [status, setStatus] = useState(false);
  const [icon, setIcon] = useState(null);
  const [createServiceHandler, { data }] = useCreateService();
  const [updateServiceHandler, { data: updatdedServiceData }] =
    useUpdateService();
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [hsnCode, setHsnCode] = useState("");
  const [displayOrder, setDisplayOrder] = useState(0);
  const [serviceNudge, setServiceNudge] = useState("");
  const [bookingRule, setBookingRule] = useState("");
  const [variationCaption, setVariationCaption] = useState("");
  const [extraCaption, setExtraCaption] = useState("");
  const [logs, setLogs] = useState(editData?.logs ? editData?.logs : []);
  const [vendorOrg, setVendorOrg] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorMobileNumber, setVendorMobileNumber] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [serviceStartTime, setServiceStartTime] = useState(null);
  const [serviceEndTime, setServiceEndTime] = useState(null);
  const [incrementUnit, setIncrementUnit] = useState(1);
  // const { data: allHostelsData } = useHostelList();
  const { data: allHostelsData } = useGetHostelsNameandId();

  useEffect(() => {
    let HostelsList = allHostelsData?.getHostelsNameandId?.map((hostel) => {
      return { label: hostel?.name, value: hostel?._id };
    });

    HostelsList?.push({ label: "All", value: null });
    setHostelsList(HostelsList);
  }, [allHostelsData]);

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data, refetch]);

  const [
    uploadServiceIconHandler,
    // eslint-disable-next-line no-unused-vars
    { data: serviceIconData, loading: serviceIconLoad },
  ] = useUploadImage();

  const uppyServiceIcon = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  uppyServiceIcon.use(thumbnailGenerator);

  uppyServiceIcon.on("thumbnail:generated", async (file, preview) => {
    const res = await uploadServiceIconHandler(file.data);
    setIcon(res?.data?.uploadImage?.url);
    toaster("success", "Icon uploaded successfully");
  });

  uppyServiceIcon.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading icon: ${error.message}`);
  });

  const ServiceCategoryList = [
    { label: "Add-ons", value: "Add-ons" },
    { label: "Rentals", value: "Rentals" },
    { label: "Transfers", value: "Transfers" },
    { label: "Hostel Essentials", value: "Hostel Essentials" },
    { label: "Local Activities", value: "Local Activities" },
    { label: "Vehicle Rentals", value: "Vehicle Rentals" },
    { label: "Pickup", value: "Pickup" },
    { label: "Drop", value: "Drop" },
    { label: "Sightseeing", value: "Sightseeing" },
    { label: "Car Transfers", value: "Car Transfers" },
  ];

  const serviceTaxList = [
    { label: "0", value: "0" },
    { label: "5", value: "5" },
    { label: "12", value: "12" },
    { label: "18", value: "18" },
    { label: "28", value: "28" },
  ];

  const bookingRuleOptions = [
    { label: "Per Quantity", value: "perQuantity" },
    { label: "Per Night", value: "perNight" },
    { label: "Per Day", value: "perDay" },
  ];

  const bookingRuleInExtraOptions = [
    // { label: "Per Quantity", value: "perQuantity" },
    { label: "Per Booking", value: "perBooking" },
  ];

  useEffect(() => {
    if (updatdedServiceData) {
      refetch();
    }
  }, [updatdedServiceData, refetch]);

  const onSubmitHandler = () => {
    const arrExtras = extra
      ?.filter((option) => option?.extraTitle)
      ?.map((option) => ({
        option: option?.extraTitle,
        price: option?.extraPrice,
        bookingRule: option?.extraBookingRule,
        arrExtras: option?.arrExtras,
      }));

    const arrVariations = variations
      ?.filter((variation) => variation.optionTitle)
      ?.map((variation) => ({
        option: variation.optionTitle,
        description: variation.optionDesciption,
        price: variation.optionPrice,
        displayOrder: variation.optionDisplayOrder,
      }));

    const data = {
      category,
      hostel: hostel?.value,
      title,
      description,
      totalInventoryPerDay: parseFloat(totalInventoryPerDay, 0),
      strikeThroughPrice: parseFloat(strikeThroughPrice, 0),
      price: parseFloat(price, 0),
      cutOffTime: parseFloat(cutOffTime, 0),
      tax: parseFloat(tax?.value),
      fromTime,
      toTime,
      status,
      // icon: serviceIconData?.uploadImage?.url || icon || null,
      icon: icon || null,
      hsnCode,
      serviceNudge,
      displayOrder: parseFloat(displayOrder, 0),
      bookingRule: bookingRule,
      variationCaption: variationCaption,
      variationOptions: arrVariations,
      extraCaption: extraCaption,
      extraOptions: arrExtras,
      vendorEmail,
      vendorOrg,
      vendorMobileNumber,
      vendorName,
      logs: [
        ...logs,
        `${localStorage.getItem("name")} ${
          editData ? "modified" : "created"
        } this service config. on ${new Date().toLocaleString("en-IN", {
          hour12: false,
        })}`,
      ],
      serviceStartTime,
      serviceEndTime,
      incrementUnit: parseFloat(incrementUnit, 0),
    };
    if (category && title && hostel && totalInventoryPerDay && price && tax) {
      if (editData) {
        updateServiceHandler(editData._id, data);
      } else {
        createServiceHandler(data);
      }

      toaster("success", "Service added successfully");
      refetch();
      setCategory("");
      setTitle("");
      setDescription("");
      setTotalInventoryPerDay(0);
      setStrikeThroughPrice(0);
      setPrice(0);
      setTax(0);
      setCutOffTime(0);
      setFromTime(new Date());
      setToTime(new Date());
      setStatus(false);
      setIcon(null);
      setHsnCode("");
      setServiceNudge("");
      setDisplayOrder(0);
      setLogs([]);
      setVendorOrg("");
      setVendorEmail("");
      setVendorMobileNumber("");
      setVendorName("");
      setServiceStartTime(new Date());
      setServiceEndTime(new Date());
      setIncrementUnit(1);
      handleToggle();
    } else {
      toaster("error", "Please fill all required details marked with (*)");
    }
  };

  useEffect(() => {
    if (editData) {
      setHostel(
        editData.hostel
          ? {
              label: editData.hostel.name,
              value: editData.hostel._id,
            }
          : {
              label: "All",
              value: null,
            }
      );
      setCategory(editData.category || "");
      setTitle(editData.title || "");
      setDescription(editData.description || "");
      setTotalInventoryPerDay(editData.totalInventoryPerDay || 0);
      setStrikeThroughPrice(editData.strikeThroughPrice || 0);
      setPrice(editData.price || 0);
      setTax({ label: editData?.tax, value: editData?.tax } || 0);
      setCutOffTime(editData.cutOffTime || 0);
      setFromTime(editData.fromTime || null);
      setToTime(editData.toTime || null);
      setStatus(Boolean(editData.status));
      setIcon(editData.icon || "");
      setHsnCode(editData.hsnCode || "");
      setServiceNudge(editData?.serviceNudge || "");
      setDisplayOrder(editData?.displayOrder || 0);
      setBookingRule(editData?.bookingRule || "");
      setVariationCaption(editData?.variationCaption || "");
      setExtraCaption(editData?.extraCaption || "");
      setServiceStartTime(editData?.serviceStartTime || null);
      setServiceEndTime(editData?.serviceEndTime || null);
      //console.log(editData?.incrementUnit);
      setIncrementUnit(editData?.incrementUnit || 1);

      const prefetchedVariations = editData?.variationOptions?.map(
        (variation) => {
          return {
            id: uuidv4(),
            optionTitle: variation?.option,
            optionPrice: variation?.price,
            optionDesciption: variation?.description,
            optionDisplayOrder: variation?.displayOrder,
          };
        }
      );
      const prefetchedExtras = editData?.extraOptions?.map((extra) => {
        return {
          id: uuidv4(),
          extraTitle: extra?.option,
          extraPrice: extra?.price,
          extraBookingRule: extra?.bookingRule,
        };
      });
      setVariations(prefetchedVariations || []);
      setExtra(prefetchedExtras || []);
      setLogs(editData?.logs ? editData?.logs : []);
      setVendorOrg(editData?.vendorOrg);
      setVendorEmail(editData?.vendorEmail);
      setVendorMobileNumber(editData?.vendorMobileNumber);
      setVendorName(editData?.vendorName);
    } else {
      setCategory("");
      setHostel(null);
      setTitle("");
      setDescription("");
      setTotalInventoryPerDay(0);
      setStrikeThroughPrice(0);
      setPrice(0);
      setTax(0);
      setCutOffTime(0);
      setFromTime(null);
      setToTime(null);
      setStatus(false);
      setIcon("");
      setHsnCode("");
      setServiceNudge("");
      setDisplayOrder(0);
      setBookingRule("");
      setLogs([]);
      setVendorOrg("");
      setVendorEmail("");
      setVendorMobileNumber("");
      setVendorName("");
      setFromTime(null);
      setToTime(null);
      setIncrementUnit(1);
    }
  }, [editData]);

  const [variations, setVariations] = useState([
    {
      id: uuidv4(),
      optionTitle: "",
      optionPrice: "",
      optionDesciption: "",
      optionDisplayOrder: "",
    },
  ]);

  const handleAddVariations = () =>
    setVariations([
      ...variations,
      {
        id: uuidv4(),
        optionTitle: "",
        optionPrice: "",
        optionDesciption: "",
        optionDisplayOrder: "",
      },
    ]);

  const handleRemoveVariations = (id) =>
    setVariations(variations.filter((item) => item.id !== id));

  const handleChangeVariationTitle = (title, id) => {
    setVariations(
      variations.map((item) =>
        item.id === id
          ? {
              ...item,
              optionTitle: title,
            }
          : item
      )
    );
  };

  const handleChangeVariationPrice = (price, id) => {
    if (!isNaN(price)) {
      setVariations(
        variations.map((item) =>
          item.id === id
            ? {
                ...item,
                optionPrice: Number(price),
              }
            : item
        )
      );
    } else {
      alert("Please enter number only");
    }
  };

  const handleChangeVariationDescription = (description, id) => {
    setVariations(
      variations.map((item) =>
        item.id === id
          ? {
              ...item,
              optionDesciption: description,
            }
          : item
      )
    );
  };

  const handleChangeVariationDisplayOrder = (optionDisplayOrder, id) => {
    if (!isNaN(optionDisplayOrder)) {
      setVariations(
        variations.map((item) =>
          item.id === id
            ? {
                ...item,
                optionDisplayOrder: Number(optionDisplayOrder),
              }
            : item
        )
      );
    } else {
      alert("Please enter number only");
    }
  };

  const [extra, setExtra] = useState([
    {
      id: uuidv4(),
      extraTitle: "",
      extraPrice: "",
      extraBookingRule: "",
    },
  ]);

  const handleChangeExtraTitle = (title, id) => {
    setExtra(
      extra.map((item) =>
        item.id === id
          ? {
              ...item,
              extraTitle: title,
            }
          : item
      )
    );
  };

  const handleChangeExtraPrice = (price, id) => {
    if (!isNaN(price)) {
      setExtra(
        extra.map((item) =>
          item.id === id
            ? {
                ...item,
                extraPrice: Number(price),
              }
            : item
        )
      );
    } else {
      alert("Please enter number only");
    }
  };

  const handleChangeExtraBookingRule = (value, id) => {
    setExtra(
      extra.map((item) =>
        item.id === id
          ? {
              ...item,
              extraBookingRule: value,
            }
          : item
      )
    );
  };

  const handleAddExtra = () => {
    setExtra([
      ...extra,
      {
        id: uuidv4(),
        extraTitle: "",
        extraPrice: "",
        extraBookingRule: "",
      },
    ]);
  };
  const handleRemoveExtra = (id) => {
    setExtra(extra.filter((item) => item.id !== id));
  };

  // useEffect(() => {
  //   console.log(bookingRule);
  // }, [bookingRule]);
  //console.log(incrementUnit);
  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="category">
                  Category*
                </Label>
              </strong>
              <Select
                id="category"
                name="category"
                value={ServiceCategoryList.find(
                  (option) => option.value === category
                )}
                options={ServiceCategoryList}
                required
                onChange={(selectedOption) => setCategory(selectedOption.value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="hostel">
                  Hostel*
                </Label>
              </strong>
              <Select
                isClearable={false}
                isSearchable={true}
                id="hostel"
                name="hostel"
                options={HostelsList}
                required
                value={hostel}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="title">
                  Title*
                </Label>
              </strong>
              <Input
                type="text"
                id="title"
                name="title"
                placeholder="Title"
                value={title}
                options={title}
                required
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="description">
                  Description
                </Label>
              </strong>
              <Input
                type="textarea"
                id="description"
                name="description"
                placeholder="Description"
                value={description}
                required
                rows={2}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="totalInventoryPerDay">
                  Total Inventory (per day)*
                </Label>
              </strong>
              <Input
                type="text"
                value={totalInventoryPerDay}
                id="totalInventoryPerDay"
                name="totalInventoryPerDay"
                placeholder="Total Inventory per day"
                required
                onChange={(e) => setTotalInventoryPerDay(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="strikeThroughPrice">
                  Strike Through Price
                </Label>
              </strong>
              <Input
                type="text"
                value={strikeThroughPrice}
                id="strikeThroughPrice"
                name="strikeThroughPrice"
                placeholder="Strike Through Price"
                onChange={(e) => setStrikeThroughPrice(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="price">
                  Price*
                </Label>
              </strong>
              <Input
                type="text"
                value={price}
                id="price"
                name="price"
                placeholder="Price"
                required
                onChange={(e) => setPrice(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="hsnCode">
                  HSN Code
                </Label>
              </strong>
              <Input
                type="text"
                id="hsnCode"
                name="hsnCode"
                placeholder="HSN Code"
                value={hsnCode}
                options={hsnCode}
                onChange={(e) => setHsnCode(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="tax">
                  Tax (%)*
                </Label>
              </strong>
              <Select
                id="tax"
                name="tax"
                value={tax}
                options={serviceTaxList}
                onChange={(value) => setTax(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="min booking amount">
                  Booking Cutoff Time (in Hours)
                </Label>
              </strong>
              <Input
                type="text"
                value={cutOffTime}
                id="cutOffTime"
                name="cutOffTime"
                placeholder="Cutoff Time"
                required
                onChange={(e) => setCutOffTime(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="fromTime">
                  Booking Active From (24 hrs)
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromTime}
                onChange={(value) => setFromTime(value)}
                id="time"
                placeholder="Time (24 hrs format)"
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: "H:i",
                  time_24hr: true,
                }}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="toTime">
                  Booking Active To (24 hrs)
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toTime}
                onChange={(value) => setToTime(value)}
                id="time"
                placeholder="Time (24 hrs format)"
                name="toTime"
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: "H:i",
                  time_24hr: true,
                }}
              />
            </FormGroup>
          </Col>

          {/* added for  service startime and service endtime*/}

          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="serviceStarttime">
                  Service Start Time
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={serviceStartTime}
                onChange={(value) => setServiceStartTime(value)}
                id="serviceStarttime"
                placeholder="Time (24 hrs format)"
                name="serviceStarttime"
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: "H:i",
                  time_24hr: true,
                }}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="serviceEndtime">
                  Service End Time
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={serviceEndTime}
                onChange={(value) => setServiceEndTime(value)}
                id="serviceEndtime"
                placeholder="Time (24 hrs format)"
                name="serviceEndtime"
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: "H:i",
                  time_24hr: true,
                }}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="incrementUnit">
                  Increment value (For Quantity Increment)
                </Label>
              </strong>
              <Input
                type="number"
                id="incrementUnit"
                name="incrementUnit"
                placeholder="Add Increment Value"
                value={incrementUnit}
                required
                onChange={(e) => {
                  setIncrementUnit(e.target.value);
                }}
                onWheel={(e) => e.target.blur()}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="title">
                  Service Nudge
                </Label>
              </strong>
              <Input
                type="text"
                id="serviceNudge"
                name="serviceNudge"
                placeholder="Service Nudge"
                value={serviceNudge}
                required
                onChange={(e) => setServiceNudge(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/*  */}

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="title">
                  Booking Rule
                </Label>
              </strong>
              <Select
                id="bookingRule"
                name="bookingRule"
                value={bookingRuleOptions?.find(
                  (booking) => booking.value === bookingRule
                )}
                options={bookingRuleOptions}
                onChange={(booking) => setBookingRule(booking.value)}
                placeholder="Booking rule"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="title">
                  Variation Caption
                </Label>
              </strong>
              <Input
                type="text"
                id="variationCaption"
                name="variationCaption"
                placeholder="Variation Caption"
                value={variationCaption}
                required
                onChange={(e) => setVariationCaption(e.target.value)}
              />
            </FormGroup>
          </Col>
          {variations.map(
            (
              {
                optionTitle,
                optionPrice,
                optionDesciption,
                optionDisplayOrder,
                id,
              },
              index
            ) => {
              return (
                <Row className="px-1" key={index}>
                  {variations.length > 1 && index >= 1 && (
                    <Col sm="12">
                      <strong className="">Variation - {index + 1}</strong>
                      <Button.Ripple
                        className="btn-icon rounded float-right"
                        color="danger"
                        type="button"
                        size="sm"
                        onClick={() => handleRemoveVariations(id)}
                      >
                        <X size={14} />
                      </Button.Ripple>
                    </Col>
                  )}
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="title">
                          Variation Name
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        id={id}
                        name="optionName"
                        placeholder="Title"
                        value={optionTitle}
                        required
                        onChange={(e) =>
                          handleChangeVariationTitle(
                            e.target.value,
                            e.target.id
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="title">
                          Variation Price
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        id={id}
                        name="optionPrice"
                        placeholder="Price"
                        value={optionPrice}
                        required
                        onChange={(e) =>
                          handleChangeVariationPrice(
                            e.target.value,
                            e.target.id
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="title">
                          Variation Description
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        id={id}
                        name="optionDescription"
                        placeholder="Description"
                        value={optionDesciption}
                        required
                        onChange={(e) =>
                          handleChangeVariationDescription(
                            e.target.value,
                            e.target.id
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="title">
                          Variation Display Order
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        id={id}
                        name="optDisplayOrder"
                        placeholder="Display Order"
                        value={optionDisplayOrder}
                        required
                        onChange={(e) =>
                          handleChangeVariationDisplayOrder(
                            e.target.value,
                            e.target.id
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              );
            }
          )}
          <Col sm="12">
            <Button.Ripple
              type="button"
              className="btn-icon rounded float-right"
              color="light"
              size="sm"
              onClick={handleAddVariations}
            >
              <Plus size={14} className="" />
            </Button.Ripple>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="title">
                  Extra Caption
                </Label>
              </strong>
              <Input
                type="text"
                id="extraCaption"
                name="extraCaption"
                placeholder="Extra Caption"
                value={extraCaption}
                required
                onChange={(e) => setExtraCaption(e.target.value)}
              />
            </FormGroup>
          </Col>
          {extra.map(
            ({ extraTitle, extraPrice, extraBookingRule, id }, index) => {
              return (
                <Fragment key={index}>
                  {extra?.length > 1 && index >= 1 && (
                    <Col sm="12">
                      <strong className="">Extra - {index + 1}</strong>
                      <Button.Ripple
                        type="button"
                        className="btn-icon rounded float-right"
                        color="danger"
                        size="sm"
                        onClick={() => handleRemoveExtra(id)}
                      >
                        <X size={14} />
                      </Button.Ripple>
                    </Col>
                  )}
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="title">
                          Extra Name
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        id={id}
                        name="extraName"
                        placeholder="Title"
                        value={extraTitle}
                        required
                        onChange={(e) =>
                          handleChangeExtraTitle(e.target.value, e.target.id)
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="title">
                          Extra Price
                        </Label>
                      </strong>
                      <Input
                        type="text"
                        id={id}
                        name="extraPrice"
                        placeholder="Price"
                        value={extraPrice}
                        required
                        onChange={(e) =>
                          handleChangeExtraPrice(e.target.value, e.target.id)
                        }
                      />
                    </FormGroup>
                  </Col>

                  {/* added by nitish */}
                  <Col sm="6">
                    <FormGroup className="w-100">
                      <strong>
                        <Label className="form-label" for="bookingRule">
                          Extra Booking Rule
                        </Label>
                      </strong>
                      <Select
                        id={id}
                        name="extraBookingRule"
                        options={bookingRuleInExtraOptions}
                        value={bookingRuleInExtraOptions.find(
                          (option) => option.value === extraBookingRule
                        )}
                        onChange={(selectedOption) =>
                          handleChangeExtraBookingRule(selectedOption.value, id)
                        }
                      />
                    </FormGroup>
                  </Col>
                </Fragment>
              );
            }
          )}

          <Col sm="12">
            <Button.Ripple
              type="button"
              className="btn-icon rounded float-right"
              color="light"
              size="sm"
              onClick={handleAddExtra}
            >
              <Plus size={14} className="" />
            </Button.Ripple>
          </Col>

          {/*  */}
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="price">
                  Display Order
                </Label>
              </strong>
              <Input
                type="text"
                value={displayOrder}
                id="displayOrder"
                name="displayOrder"
                placeholder="Display Order"
                required
                onChange={(e) => setDisplayOrder(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <SwitchIcon
              checked={status}
              id="status"
              name="status"
              label="Status"
              handleChecked={(value) => setStatus(value)}
            />
          </Col>
          <hr />
          <Col sm="12">
            <FormGroup>
              <Label for="category">Vendor Organization</Label>
              <Input
                type="text"
                name="vendorOrg"
                id="vendorOrg"
                value={vendorOrg}
                onChange={(e) => setVendorOrg(e.target.value)}
                placeholder={"Vendor Organization"}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup>
              <Label for="category">Vendor Name</Label>
              <Input
                type="text"
                name="vendorName"
                id="vendorName"
                value={vendorName}
                onChange={(e) => setVendorName(e.target.value)}
                placeholder={"Vendor Name"}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup>
              <Label for="category">Vendor Phone number</Label>
              <Input
                type="text"
                name="vendorMobileNumber"
                id="vendorMobileNumber"
                value={vendorMobileNumber}
                onChange={(e) => setVendorMobileNumber(e.target.value)}
                placeholder={"Vendor Mobile"}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup>
              <Label for="category">Vendor Email</Label>
              <Input
                type="text"
                name="vendorEmail"
                id="vendorEmail"
                value={vendorEmail}
                onChange={(e) => setVendorEmail(e.target.value)}
                placeholder={"Vendor Email"}
              />
            </FormGroup>
          </Col>
          <Col
            sm="12"
            style={{
              marginTop: "20px",
              marginBottom: "50px",
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <div style={{ width: "50%" }}>
              <Label className="form-label">Upload Icon</Label>
              <DragDrop
                note="Image size should be less than 500KB"
                width="100%"
                uppy={uppyServiceIcon}
                locale={{
                  strings: {
                    dropHereOr: "Drop icon here or %{browse}",
                    browse: "browse",
                  },
                }}
              />
            </div>
            <div style={{ width: "50%", marginLeft: "40px" }}>
              <Label className="form-label">Service Icon Preview</Label>
              <div className="service-icon-preview" style={{ width: "160px" }}>
                {serviceIconLoad ? (
                  "Uploading image..."
                ) : icon ? (
                  <img className="w-75" src={icon} alt="service icon" />
                ) : null}
              </div>
            </div>
          </Col>

          <Col sm="12">
            {/* <hr/> */}
            <FormGroup className="w-100">
              <Label className="form-label" for="logs">
                <strong>Logs:</strong>
              </Label>
              <br />
              {logs
                ?.map((log, i) => {
                  return (
                    <Label className="form-label" for="name">
                      {/* <p >{log}.</p> */}
                      {log}.
                    </Label>
                  );
                })
                .reverse()}
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
