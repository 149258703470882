import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Col,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useGetEmployeeSalaryStructure } from "../../../api/employeeSalaryStructure/Queries";
import { useUpdateEmployeeSalaryStructure } from "../../../api/employeeSalaryStructure/Mutation";
import { dateFormatterDDMMYYYY } from "../../../utility/Utils";

const SalaryStructureModal = (props) => {
  const { showModal, onClose, data, refetchEmployeeSalaryStructure } = props;
  const { id } = useParams();

  const [fromYear, setFromYear] = useState(null);
  const [toYear, setToYear] = useState(null);
  const [toMonth, setToMonth] = useState(null);
  const [fromMonth, setFromMonth] = useState(null);
  const [yearlyCTC, setYearlyCTC] = useState(null);
  const [monthlyCTC, setMonthlyCTC] = useState(null);
  const [HRA, setHRA] = useState(null);
  const [statutoryBonus, setStatutoryBonus] = useState(null);
  const [specialAllowance, setSpecialAllowance] = useState(null);
  const [grossSalary, setGrossSalary] = useState(null);
  const [PF, setPF] = useState(null);
  const [ESIC, setESIC] = useState(null);
  const [PT, setPT] = useState(null);
  const [LWF, setLWF] = useState(null);
  const [otherDeduction, setOtherDeduction] = useState(null);
  const [advances, setAdvances] = useState(null);
  const [finesAndPenalty, setFinesAndPenalty] = useState(null);
  const [holidayWage, setHolidayWage] = useState(null);
  const [otherIncentive, setOtherIncentive] = useState(null);
  const [basicSalary, setBasicSalary] = useState(null);
  const [arrers, setArrers] = useState(null);
  const userRole = localStorage.getItem("role");

  // const { data: currentMonth } = useGetEmployeeCurrentMonthSalaryStructure(id)
  const [updateEmployeeSalaryStructureHandler] =
    useUpdateEmployeeSalaryStructure();
  const { data: salaryStructures } = useGetEmployeeSalaryStructure(id);
  console.log(salaryStructures);
  const handleChangeStructure = async (e) => {
    e.preventDefault();
    if (data?._id) {
      const salaryStructureObject = {
        fromYear: Number(fromYear),
        toYear: Number(toYear),
        fromMonth: Number(fromMonth),
        toMonth: Number(toMonth),
        yearlyCTC: Number(yearlyCTC),
        monthlyCTC: Number(monthlyCTC),
        fromDate: new Date(fromYear, fromMonth - 1, 2).setUTCHours(0, 0, 0, 0),
        toDate: new Date(toYear, toMonth).setUTCHours(0, 0, 0, 0),
        HRA: Number(HRA),
        basicSalary: Number(basicSalary),
        statutoryBonus: Number(statutoryBonus),
        specialAllowance: Number(specialAllowance),
        grossSalary: Number(grossSalary),
        PF: Number(PF),
        ESIC: Number(ESIC),
        PT: Number(PT),
        LWF: Number(LWF),
        otherDeduction: Number(otherDeduction),
        advances: Number(advances),
        finesAndPenalty: Number(finesAndPenalty),
        holidayWage: Number(holidayWage),
        otherIncentive: Number(otherIncentive),
        arrers: Number(arrers),
      };
      const response = await updateEmployeeSalaryStructureHandler(
        data?._id,
        salaryStructureObject
      );
      if (response) {
        refetchEmployeeSalaryStructure();
      }
    }
    handleClose();
  };
  useEffect(() => {
    if (data) {
      const {
        fromMonth,
        fromYear,
        toMonth,
        toYear,
        yearlyCTC,
        monthlyCTC,
        fromDate,
        toDate,
        HRA,
        statutoryBonus,
        specialAllowance,
        grossSalary,
        PF,
        ESIC,
        PT,
        LWF,
        otherDeduction,
        advances,
        finesAndPenalty,
        holidayWage,
        otherIncentive,
        basicSalary,
        arrers,
      } = data;
      setFromYear(fromYear);
      setToYear(toYear);
      setFromMonth(fromMonth);
      setToMonth(toMonth);
      setYearlyCTC(yearlyCTC);
      setMonthlyCTC(monthlyCTC);
      setHRA(HRA);
      setStatutoryBonus(statutoryBonus);
      setSpecialAllowance(specialAllowance);
      setGrossSalary(grossSalary);
      setPF(PF);
      setESIC(ESIC);
      setPT(PT);
      setLWF(LWF);
      setOtherDeduction(otherDeduction);
      setAdvances(advances);
      setFinesAndPenalty(finesAndPenalty);
      setHolidayWage(holidayWage);
      setOtherIncentive(otherIncentive);
      setBasicSalary(basicSalary);
      setArrers(arrers);
    } else {
      setFromMonth(null);
      setToMonth(null);
      setFromYear(null);
      setToYear(null);
      setYearlyCTC(null);
      setMonthlyCTC(null);
      setHRA(null);
      setStatutoryBonus(null);
      setSpecialAllowance(null);
      setGrossSalary(null);
      setPF(null);
      setESIC(null);
      setPT(null);
      setLWF(null);
      setOtherDeduction(null);
      setAdvances(null);
      setFinesAndPenalty(null);
      setHolidayWage(null);
      setOtherIncentive(null);
      setBasicSalary(null);
      setArrers(null);
    }
  }, [data]);

  const handleClose = () => {
    onClose(!showModal);
  };
  return (
    <Modal
      isOpen={showModal}
      toggle={handleClose}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader className="bg-transparent" toggle={handleClose}>
        {" "}
        {data?.empCode} {"  -  "}
        {dateFormatterDDMMYYYY(data?.fromDate)} -{" "}
        {dateFormatterDDMMYYYY(data?.toDate)}
      </ModalHeader>
      <ModalBody className="px-sm-5 mx-50 pb-5">
        <form className="mt-2" onSubmit={handleChangeStructure}>
          <Row>
            <Col sm="6">
              <Label className="form-label" for="fromYear">
                From year
              </Label>
              <Input
                type="text"
                value={fromYear}
                id="fromYear"
                name="fromYear"
                placeholder="From year"
                // required
                onChange={(e) => setFromYear(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="toYear">
                To year
              </Label>
              <Input
                type="text"
                value={toYear}
                id="toYear"
                name="toYear"
                placeholder="To year"
                // required
                onChange={(e) => setToYear(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="fromMonth">
                From month
              </Label>
              <Input
                type="text"
                value={fromMonth}
                id="fromMonth"
                name="fromMonth"
                placeholder="From month"
                // required
                onChange={(e) => setFromMonth(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="toMonth">
                To month
              </Label>
              <Input
                type="text"
                value={toMonth}
                id="toMonth"
                name="toMonth"
                placeholder="To month"
                // required
                onChange={(e) => setToMonth(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="yearlyCTC">
                Annual CTC
              </Label>
              <Input
                type="text"
                value={yearlyCTC}
                id="yearlyCTC"
                name="yearlyCTC"
                placeholder="Annual CTC"
                // required
                onChange={(e) => setYearlyCTC(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="monthlyCTC">
                Monthly CTC
              </Label>
              <Input
                type="text"
                value={monthlyCTC}
                id="monthlyCTC"
                name="monthlyCTC"
                placeholder="Monthly CTC"
                onChange={(e) => setMonthlyCTC(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="basicSalary">
                Basic salary
              </Label>
              <Input
                type="text"
                value={basicSalary}
                id="basicSalary"
                name="basicSalary"
                placeholder="Basic salary"
                onChange={(e) => setBasicSalary(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="HRA">
                HRA
              </Label>
              <Input
                type="text"
                value={HRA}
                id="HRA"
                name="HRA"
                placeholder="HRA"
                onChange={(e) => setHRA(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="statutoryBonus">
                Statutory Bonus
              </Label>
              <Input
                type="text"
                value={statutoryBonus}
                id="statutoryBonus"
                name="statutoryBonus"
                placeholder="Statutory Bonus"
                onChange={(e) => setStatutoryBonus(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="specialAllowance">
                Special allowance
              </Label>
              <Input
                type="text"
                value={specialAllowance}
                id="specialAllowance"
                name="specialAllowance"
                placeholder="Special Allowance"
                onChange={(e) => setSpecialAllowance(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="grossSalary">
                Gross salary
              </Label>
              <Input
                type="text"
                value={grossSalary}
                id="grossSalary"
                name="grossSalary"
                placeholder="Gross salary"
                onChange={(e) => setGrossSalary(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="PF">
                PF
              </Label>
              <Input
                type="text"
                value={PF}
                id="PF"
                name="PF"
                placeholder="PF"
                onChange={(e) => setPF(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="ESIC">
                ESIC
              </Label>
              <Input
                type="text"
                value={ESIC}
                id="ESIC"
                name="ESIC"
                placeholder="ESIC"
                onChange={(e) => setESIC(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="PT">
                PT
              </Label>
              <Input
                type="text"
                value={PT}
                id="PT"
                name="PT"
                placeholder="PT"
                onChange={(e) => setPT(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="LWF">
                LWF
              </Label>
              <Input
                type="text"
                value={LWF}
                id="LWF"
                name="LWF"
                placeholder="LWF"
                onChange={(e) => setLWF(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="otherDeduction">
                Other deduction
              </Label>
              <Input
                type="text"
                value={otherDeduction}
                id="otherDeduction"
                name="otherDeduction"
                placeholder="Other deduction"
                onChange={(e) => setOtherDeduction(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="advances">
                Advances
              </Label>
              <Input
                type="text"
                value={advances}
                id="advances"
                name="advances"
                placeholder="Advances"
                onChange={(e) => setAdvances(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="finesAndPenalty">
                Fines and penalty
              </Label>
              <Input
                type="text"
                value={finesAndPenalty}
                id="finesAndPenalty"
                name="finesAndPenalty"
                placeholder="Fines and penalty"
                onChange={(e) => setFinesAndPenalty(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="holidayWage">
                Holiday Wage
              </Label>
              <Input
                type="text"
                value={holidayWage}
                id="holidayWage"
                name="holidayWage"
                placeholder="Holiday Wage"
                onChange={(e) => setHolidayWage(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="otherIncentive">
                Other incentive
              </Label>
              <Input
                type="text"
                value={otherIncentive}
                id="otherIncentive"
                name="otherIncentive"
                placeholder="Other incentive"
                onChange={(e) => setOtherIncentive(e.target.value)}
              />
            </Col>
            <Col sm="6">
              <Label className="form-label" for="arrers">
                Other incentive
              </Label>
              <Input
                type="text"
                value={arrers}
                id="arrers"
                name="arrers"
                placeholder="Other incentive"
                onChange={(e) => setArrers(e.target.value)}
              />
            </Col>
            {userRole !== "Area Manager" && <SaveDiscardBtnGroup />}
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default SalaryStructureModal;
