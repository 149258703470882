// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import { Col, Row, Input, FormGroup, Label, Button } from "reactstrap";
import { XCircle } from "react-feather";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import makeAnimated from "react-select/animated";
import {
  useCreateCoupon,
  useHostelList,
  useUpdateCoupon,
} from "../../api/dataSource";
import toaster from "@components/common/Toaster";
import SlideModal from "./slide-modal/index";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import SwitchIcon from "@components/common/switch-icon";
import { dateFormatter } from "@utils";

import Flatpickr from "react-flatpickr";

const animatedComponents = makeAnimated();

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [name, setName] = useState("");
  const [status, setStatus] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [product, setProduct] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [discount, setDiscount] = useState("");
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [maxBookingDate, setMaxBookingDate] = useState(new Date());
  const [minBookingDate, setMinBookingDate] = useState(new Date());
  const [maxStayDate, setMaxStayDate] = useState(new Date());
  const [minStayDate, setMinStayDate] = useState(new Date());
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const [dateRanges, setDateRanges] = useState([]);

  const [maxDiscountValue, setMaxDiscountValue] = useState("");
  const [duration, setDuration] = useState("");
  const [quantity, setQuantity] = useState("");
  const [numberOfUsage, setNumberOfUsage] = useState("0");
  const [privateStatus, setPrivateStatus] = useState(true);

  const [rules, setRules] = useState([]);
  const [HostelsList, setHostelsList] = useState([]);
  const [minBookingAmount, setMinBookingAmount] = useState("");
  const [minimumNights, setMinimumNights] = useState("");
  const [maximumNights, setMaximumNights] = useState("");
  const [minimumAdults, setMinimumAdults] = useState("");
  const [couponsPerUser, setCouponsPerUser] = useState("");
  const [couponsPerDay, setCouponsPerDay] = useState("");
  const [newUserOnly, setNewUserOnly] = useState(false);
  const [dormsOnly, setDormsOnly] = useState(false);
  const [sameDayBooking, setSameDayBooking] = useState(false);

  const [createCouponsHandler, { data }] = useCreateCoupon();
  const { data: allHostelsData } = useHostelList();
  const [updateCouponHandler, { data: updatdedCouponData }] = useUpdateCoupon();
  const [logs, setLogs] = useState(editData?.logs ? editData?.logs : []);

  const CouponStatusList = [
    { label: "paused", value: "paused" },
    { label: "live", value: "live" },
    { label: "completed", value: "completed" },
    { label: "expired", value: "expired" },
  ];

  const ProductList = [
    { label: "Hostel", value: "Hostel" },
    { label: "Membership", value: "Membership" },
    // { label: "Workation", value: "Workation" },
    // { label: "Trip", value: "Trip" },
    // { label: "User", value: "User" },
  ];

  const SubCategoryList = [
    { label: "Absolute", value: "Absolute" },
    { label: "Percentage", value: "Percentage" },
    { label: "MembershipAbsolute", value: "MembershipAbsolute" },
    { label: "MembershipPercentage", value: "MembershipPercentage" },
    { label: "SpecialBookAtOneRs", value: "SpecialBookAtOneRs" },
    { label: "SpecialOneNightOff", value: "SpecialOneNightOff" },
  ];

  const PrivateList = [
    { label: "Private", value: true },
    { label: "Public", value: false },
  ];

  const WeekDaysList = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
  ];

  useEffect(() => {
    console.log(editData?.excludedWeekDays);
  }, [selectedWeekdays]);

  const handleStartDateChange = (index, event) => {
    const updatedRanges = [...dateRanges];
    const startDate = event.target.value ? new Date(event.target.value) : null;
    updatedRanges[index].startDate = startDate || "";
    setDateRanges(updatedRanges);
  };

  const handleEndDateChange = (index, event) => {
    const updatedRanges = [...dateRanges];
    const endDate = event.target.value ? new Date(event.target.value) : null;
    updatedRanges[index].endDate = endDate || "";
    setDateRanges(updatedRanges);
  };

  const addDateRange = () => {
    const newRange = { startDate: "", endDate: "" };
    setDateRanges([...dateRanges, newRange]);
  };

  const removeDateRange = (index) => {
    const updatedRanges = [...dateRanges];
    updatedRanges.splice(index, 1);
    setDateRanges(updatedRanges);
  };

  useEffect(() => {
    console.log(dateRanges);
  }, [dateRanges]);

  useEffect(() => {
    if (updatdedCouponData) {
      refetch();
      handleToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, updatdedCouponData]);

  useEffect(() => {
    if (allHostelsData?.getHostelList?.length) {
      // eslint-disable-next-line array-callback-return
      allHostelsData?.getHostelList?.map((item) => {
        setHostelsList((prev) => [
          ...prev,
          { ...item, label: item?.name, value: item?._id },
        ]);
      });
    }
  }, [allHostelsData?.getHostelList]);

  // const handleChange = (HostelsList) => setRules(HostelsList);
  const handleChange = function (HostelsList) {
    return setRules(HostelsList);
  };

  useEffect(() => {
    if (editData?.product) {
      const Coupon = editData;
      let altRules = [];
      // eslint-disable-next-line array-callback-return
      Coupon.rules?.hostels?.map((id) => {
        // eslint-disable-next-line array-callback-return
        allHostelsData?.getHostelList.map((hostel) => {
          if (id === hostel._id) {
            altRules.push({ label: hostel.name, value: hostel._id });
          }
        });
      });
      setRules(altRules);
      setName(editData?.name);
      setStatus({ label: editData?.status, value: editData?.status });
      setPromoCode(editData?.promoCode);
      setProduct({ label: editData?.product, value: editData?.product });
      setSubCategory({
        label: editData?.subCategory,
        value: editData?.subCategory,
      });
      setDiscount(editData?.discount);
      setExpirationDate(editData?.expirationDate);
      setMaxBookingDate(editData?.maxBookingDate);
      setMinBookingDate(editData?.minBookingDate);
      setMaxStayDate(editData?.maxStayDate);
      setMinStayDate(editData?.minStayDate);
      setMaxDiscountValue(editData?.maxDiscountValue);
      setDuration(editData?.duration);
      setQuantity(editData?.quantity);
      setNumberOfUsage(editData?.numberOfUsage);
      setPrivateStatus({
        label: editData?.private?.toString() === "true" ? "Private" : "Public",
        //label: editData.private.toString(),
        value: editData?.private,
      });
      setMinBookingAmount(editData?.minBookingAmount);
      setMinimumNights(editData?.minimumNights);
      setMaximumNights(editData?.maximumNights);
      setMinimumAdults(editData?.minimumAdults);
      setCouponsPerUser(editData?.couponsPerUser);
      setCouponsPerDay(editData?.couponsPerDay);
      setNewUserOnly(editData?.newUserOnly);
      setDormsOnly(editData?.dormsOnly);
      setSameDayBooking(editData?.sameDayBooking);
      setSelectedWeekdays(
        editData?.excludedWeekDays?.map((day) => {
          return {
            label: day,
            value: day,
          };
        })
      );
      if (editData?.blockDates?.length > 0) {
        setDateRanges(
          editData?.blockDates?.map((date) => {
            return {
              startDate: new Date(date?.startDate),
              endDate: new Date(date?.endDate),
            };
          })
        );
      } else {
        setDateRanges([]);
      }
      setLogs(editData?.logs ? editData?.logs : []);
    } else {
      setName("");
      setStatus("");
      setPromoCode("");
      setProduct("");
      setSubCategory("");
      setDiscount("");
      setExpirationDate("");
      setMaxBookingDate("");
      setMinBookingDate("");
      setMaxStayDate("");
      setMinStayDate("");
      setMaxDiscountValue("");
      setDuration("");
      setQuantity("");
      setNumberOfUsage("0");
      setRules([]);
      setPrivateStatus(true);
      setMinBookingAmount("");
      setMinimumNights("");
      setMaximumNights("");
      setMinimumAdults("");
      setCouponsPerUser("");
      setCouponsPerDay("");
      setNewUserOnly(false);
      setDormsOnly(false);
      setSameDayBooking(false);
      setSelectedWeekdays([]);
      setDateRanges([]);
      setLogs([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const onSubmitHandler = () => {
    const hostelsArray = [];
    // eslint-disable-next-line array-callback-return
    rules.map((h) => {
      hostelsArray.push(h.value);
    });
    const type = "allowedHostels";
    const hostels = JSON.parse(JSON.stringify(hostelsArray));
    const data = {
      name: name,
      status: status?.value,
      private: privateStatus?.value,
      promoCode: promoCode?.toUpperCase(),
      product: product?.value,
      subCategory: subCategory?.value,
      discount: parseFloat(discount),
      maxDiscountValue: parseInt(maxDiscountValue),
      expirationDate,
      maxBookingDate,
      minBookingDate,
      maxStayDate,
      minStayDate,
      duration: parseInt(duration),
      quantity: parseInt(quantity),
      numberOfUsage: parseInt(numberOfUsage),
      rules: { type, hostels },
      minBookingAmount: parseInt(minBookingAmount),
      minimumNights: parseInt(minimumNights),
      maximumNights: parseInt(maximumNights),
      minimumAdults: parseInt(minimumAdults),
      couponsPerUser: parseInt(couponsPerUser),
      couponsPerDay: parseInt(couponsPerDay),
      newUserOnly,
      dormsOnly,
      sameDayBooking,
      excludedWeekDays: selectedWeekdays?.map((day) => day.value),
      blockDates: dateRanges?.filter((date) => {
        return date.startdate !== "" && date.endDate !== "";
      }),
      logs: [
        ...logs,
        `${localStorage.getItem("name")} ${
          editData ? "modified" : "created"
        } this coupon on ${new Date().toLocaleString("en-IN", {
          hour12: false,
        })}`,
      ],
    };
    if (
      name &&
      status &&
      privateStatus &&
      promoCode &&
      product &&
      discount &&
      maxDiscountValue &&
      expirationDate &&
      quantity &&
      minBookingAmount &&
      subCategory
    ) {
      if (editData?.product) {
        const _id = editData._id;

        updateCouponHandler(_id, data);
      } else {
        createCouponsHandler(data);
      }

      toaster("success", "Coupon added successfully");
      // refetchCoupons();
      refetch();

      setName("");
      setStatus("");
      setPrivateStatus(true);
      setPromoCode("");
      setProduct("");
      setSubCategory("");
      setDiscount("");
      setMaxDiscountValue("");
      setExpirationDate(new Date(dateFormatter(new Date())));
      setMaxBookingDate(new Date());
      setMinBookingDate(new Date());
      setMaxStayDate(new Date());
      setMinStayDate(new Date());
      setDuration("");
      setQuantity("");
      setNumberOfUsage("0");
      setRules([]);
      setMinBookingAmount("");
      setMinimumNights("");
      setMaximumNights("");
      setMinimumAdults("");
      setCouponsPerUser("");
      setCouponsPerDay("");
      setNewUserOnly(false);
      setDormsOnly(false);
      setSameDayBooking(false);
      setSelectedWeekdays([]);
      setDateRanges([]);
      setLogs([]);
    } else {
      toaster("error", "Please fill all required details");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          {/*// ! name */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="name">
                Coupon title*
              </Label>
              <Input
                type="text"
                value={name}
                id="name"
                name="name"
                placeholder="Coupon title"
                required
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! promoCode */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="promo code">
                Promo Code*
              </Label>
              <Input
                type="text"
                value={promoCode}
                id="promoCode"
                name="promoCode"
                placeholder="Add Promo Code"
                required
                onChange={(e) => setPromoCode(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/*// ! status */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="status">
                Status*
              </Label>
              <Select
                isClearable={false}
                id="status"
                name="status"
                value={status}
                options={CouponStatusList}
                required
                onChange={(value) => setStatus(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! private */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="privateStatus">
                Coupon Type*
              </Label>
              <Select
                isClearable={false}
                id="privateStatus"
                name="privateStatus"
                value={privateStatus}
                options={PrivateList}
                required
                onChange={(value) => setPrivateStatus(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! product */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Product*
              </Label>
              <Select
                isClearable={false}
                id="product"
                name="product"
                value={product}
                options={ProductList}
                required
                onChange={(value) => setProduct(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! subCategory */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="sub category">
                Sub Category*
              </Label>
              <Select
                isClearable={false}
                id="subCategory"
                name="subCategory"
                value={subCategory}
                options={SubCategoryList}
                onChange={(value) => setSubCategory(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! discount */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="discount">
                Discount*
              </Label>
              <Input
                type="text"
                value={discount}
                id="discount"
                name="discount"
                placeholder="Add Discount"
                required
                onChange={(e) => setDiscount(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! expirationDate */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="expiry date">
                Expiry Date*
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={expirationDate}
                onChange={(date) =>
                  setExpirationDate(new Date(dateFormatter(new Date(date))))
                }
                id="expirationDate"
                placeholder="Add Expiry Date"
                required
              />
            </FormGroup>
          </Col>

          {/* // ! maxDiscountValue */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="max discount value">
                Max Discount Value*
              </Label>
              <Input
                type="text"
                value={maxDiscountValue}
                id="maxDiscountValue"
                name="maxDiscountValue"
                placeholder="Add Maximum Discount Value"
                required
                onChange={(e) => setMaxDiscountValue(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! minBookingDate */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="minBooking date">
                Min Booking Date
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={minBookingDate}
                onChange={(date) => setMinBookingDate(date)}
                id="minBookingDate"
                placeholder="Add Min Booking Date"
              />
            </FormGroup>
          </Col>

          {/* // ! maxBookingDate */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="maxBooking date">
                Max Booking Date
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={maxBookingDate}
                onChange={(date) => setMaxBookingDate(date)}
                id="maxBookingDate"
                placeholder="Add Max Booking Date"
              />
            </FormGroup>
          </Col>

          {/* // ! minStayDate */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="minStay date">
                Min Stay Date
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={minStayDate}
                onChange={(date) => setMinStayDate(date)}
                id="minStayDate"
                placeholder="Add Min Stay Date"
              />
            </FormGroup>
          </Col>

          {/* // ! maxStayDate */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="maxStay date">
                Max Stay Date
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={maxStayDate}
                onChange={(date) => setMaxStayDate(date)}
                id="maxStayDate"
                placeholder="Add Max Stay Date"
              />
            </FormGroup>
          </Col>

          {/* // ! minBookingAmount */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="min booking amount">
                Min Booking Amount*
              </Label>
              <Input
                type="text"
                value={minBookingAmount}
                id="minBookingAmount"
                name="minBookingAmount"
                placeholder="Add Minimum Amount"
                required
                onChange={(e) => setMinBookingAmount(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! minimumNights */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="minimum nights">
                Minimum Nights
              </Label>
              <Input
                type="text"
                value={minimumNights}
                id="minimumNights"
                name="minimumNights"
                placeholder="Add Minimum Nights"
                onChange={(e) => setMinimumNights(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! maximumNights */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="maximum nights">
                Maximum Nights
              </Label>
              <Input
                type="text"
                value={maximumNights}
                id="maximumNights"
                name="maximumNights"
                placeholder="Add Maximum Nights"
                onChange={(e) => setMaximumNights(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! minimumAdults */}
          {/* <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="minimum adults">
                Minimum Adults
              </Label>
              <Input
                type="text"
                value={minimumAdults}
                id="minimumAdults"
                name="minimumAdults"
                placeholder="Add Minimum Adults"
                onChange={(e) => setMinimumAdults(e.target.value)}
              />
            </FormGroup>
          </Col> */}

          {/* // ! duration */}
          {/* <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="duration">
                Duration(in days)
              </Label>
              <Input
                type="text"
                value={duration}
                id="duration"
                name="duration"
                placeholder="Add Duration"
                onChange={(e) => setDuration(e.target.value)}
              />
            </FormGroup>
          </Col> */}

          {/* // ! quantity */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="quantity">
                Quantity*
              </Label>
              <Input
                type="text"
                value={quantity}
                id="quantity"
                name="quantity"
                placeholder="Add Quantity"
                required
                onChange={(e) => setQuantity(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! numberOfUsage */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="number of usage">
                Number Of Usage
              </Label>
              <Input
                type="text"
                value={numberOfUsage}
                id="numberOfUsage"
                name="numberOfUsage"
                placeholder="Add Number of Usage"
                disabled
                onChange={(e) => setNumberOfUsage(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! couponsPerUser */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="couponsPerUser">
                Coupons per user
              </Label>
              <Input
                type="text"
                value={couponsPerUser}
                id="couponsPerUser"
                name="couponsPerUser"
                placeholder="Add Coupons per user"
                onChange={(e) => setCouponsPerUser(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! couponsPerDay */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="couponsPerDay">
                Coupons per day per hostel
              </Label>
              <Input
                type="text"
                value={couponsPerDay}
                id="couponsPerDay"
                name="couponsPerDay"
                placeholder="Add Coupons per day per hostel"
                onChange={(e) => setCouponsPerDay(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! hostels */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostels">
                Hostels
              </Label>
              <Select
                // onChange={(value) => setRules(value)}
                onChange={handleChange}
                isClearable={false}
                theme={selectThemeColors}
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={rules}
                isMulti
                options={HostelsList}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! newUserOnly */}
          <Col sm="6">
            <SwitchIcon
              checked={newUserOnly}
              id="newUserOnly"
              name="newUserOnly"
              label="New User Only"
              handleChecked={(value) => setNewUserOnly(value)}
            />
          </Col>

          {/* // ! dormsOnly */}
          <Col sm="6">
            <SwitchIcon
              checked={dormsOnly}
              id="dormsOnly"
              name="dormsOnly"
              label="Dorms Only"
              handleChecked={(value) => setDormsOnly(value)}
            />
          </Col>
          <br />
          <br />

          {/* // ! sameDayBooking */}
          <div>
            <Col sm="12">
              <SwitchIcon
                checked={sameDayBooking}
                id="sameDayBooking"
                name="sameDayBooking"
                label="Same day booking(Booking = Checkin = Today)"
                handleChecked={(value) => setSameDayBooking(value)}
              />
            </Col>
            <Col>
              <br />
              <FormGroup className="w-100">
                <Label className="form-label" for="hostels">
                  Exclude Days (Coupon not valid on days)
                </Label>
                <Select
                  onChange={(value) => setSelectedWeekdays(value)}
                  isClearable={false}
                  theme={selectThemeColors}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  value={selectedWeekdays}
                  isMulti
                  options={WeekDaysList}
                  className="react-select"
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="w-100">
                <div>
                  {dateRanges.map((range, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "0.625rem",
                      }}
                    >
                      <label style={{ marginRight: "0.625rem" }}>
                        Start Date:
                      </label>
                      <input
                        type="date"
                        value={
                          range.startDate
                            ? range.startDate.toISOString().substr(0, 10)
                            : ""
                        }
                        onChange={(event) =>
                          handleStartDateChange(index, event)
                        }
                      />
                      <label
                        style={{
                          marginRight: "0.625rem",
                          marginLeft: "0.625rem",
                        }}
                      >
                        End Date:
                      </label>
                      <input
                        style={{ marginRight: "0.625rem" }}
                        type="date"
                        value={
                          range.endDate
                            ? range.endDate.toISOString().substr(0, 10)
                            : ""
                        }
                        onChange={(event) => handleEndDateChange(index, event)}
                      />

                      <a href="#" onClick={() => removeDateRange(index)}>
                        <XCircle />
                      </a>
                    </div>
                  ))}

                  <a href="#" onClick={addDateRange}>
                    Add Block Date
                  </a>
                </div>
              </FormGroup>
            </Col>
            <Col sm="12">
              {/* <hr/> */}
              <FormGroup className="w-100">
                <Label className="form-label" for="logs">
                  <strong>Logs:</strong>
                </Label>
                <br />
                {logs
                  ?.map((log, i) => {
                    return (
                      <Label className="form-label" for="name">
                        {/* <p >{log}.</p> */}
                        {log}.
                      </Label>
                    );
                  })
                  .reverse()}
              </FormGroup>
            </Col>
          </div>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
