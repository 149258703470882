import React from "react";
import { Col, FormGroup, Label, Row, Button } from "reactstrap";
import { selectThemeColors } from "@utils";
import Select from "react-select";
import Flatpickr from "react-flatpickr";

const occupancies = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
];

function Filters({
  handleDateRangeChange,
  reviewDateFrom,
  reviewDateTo,
  uniqueCompetitors,
  selectedCompetitors,
  handleCompetitorChange,
  hostelsList,
  handleHostelChange,
  hostel,
  uniqueRoomNames,
  selectedRoomName,
  handleRoomNameChange,
  handleSearch,
  handleReset,
  selectedOccupancy,
  handleOccupancyChange,
  handleSync,
  handlePriceUpdate
}) {
  const adjusttheDateAndhandleDateRangeChange = (dates) => {
    const [startDate, endDate] = dates;

    if (!startDate || !endDate) return;
    // Adjust the date range to handle lag of 1 day
    const adjustedStartDate = new Date(startDate);
    adjustedStartDate.setDate(adjustedStartDate.getDate() + 1);

    // Adjust the date range to handle lag of 1 day
    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);

    handleDateRangeChange([adjustedStartDate, adjustedEndDate]);
  };
  return (
    <Row>
      <Col sm="3">
        <div style={{ width: "100%", position: "relative", zIndex: 201 }}>
          <FormGroup className="w-100">
            <Label className="form-label" for="hostel">
              <strong>Hostel Name</strong>
            </Label>
            <Select
              // isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              //   isMulti
              id="hostel"
              name="hostel"
              value={hostel}
              options={hostelsList}
              onChange={(value) => {
                handleHostelChange(value);
                // setHostel(value);
              }}
              //   className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </div>
      </Col>

      <Col sm="3">
        <div style={{ width: "100%", position: "relative", zIndex: 200 }}>
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Date Range</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              id="reviewDate"
              placeholder="Select Date Range"
              options={{
                mode: "range",
              }}
              value={[reviewDateFrom, reviewDateTo]}
              onChange={(date) => {
                adjusttheDateAndhandleDateRangeChange(date);
              }}
            />
          </FormGroup>
        </div>
      </Col>

      <Col sm="3">
        <div style={{ width: "100%", position: "relative", zIndex: 200 }}>
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Room</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="Room"
              name="Room"
              closeMenuOnSelect={false}
              options={uniqueRoomNames}
              value={selectedRoomName}
              onChange={handleRoomNameChange}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </div>
      </Col>

      {/* <Col sm="3">
        <div style={{ width: "100%", position: "relative", zIndex: 200 }}>
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Competitor</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="competitor"
              name="competitor"
              closeMenuOnSelect={false}
              options={uniqueCompetitors}
              value={selectedCompetitors}
              onChange={handleCompetitorChange}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </div>
      </Col> */}

      {/* <Col sm="3">
        <div style={{ width: "100%", position: "relative", zIndex: 200 }}>
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Platform</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="platform"
              name="platform"
              closeMenuOnSelect={false}
              options={platforms}
              onChange={handlePlatformChange}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </div>
      </Col> */}

      <Col sm="3">
        <div style={{ width: "100%", position: "relative", zIndex: 200 }}>
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Occupancy</strong>
            </Label>
            <Select
              isSearchable={true}
              theme={selectThemeColors}
              isClearable={true}
              id="occupancy"
              name="occupancy"
              value={selectedOccupancy}
              options={occupancies}
              onChange={handleOccupancyChange}
              className="basic-single-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </div>
      </Col>

      {/* <Col sm="3">
        <div style={{ width: "100%", position: "relative", zIndex: 200 }}>
          <FormGroup className="w-100 ">
            <Label className="form-label" for="url">
              <strong>Sort by</strong>
            </Label>
            <Select
              isSearchable={true}
              theme={selectThemeColors}
              id="sort"
              name="sort"
              value={sortOrder}
              options={sortOptions}
              onChange={handleSortChange}
              className="basic-single-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </div>
      </Col> */}

      <Col sm="12" className="d-flex justify-content-end mb-1">
      
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={handleSearch}
          >
            Search
          </Button>

          <Button
            className="pull-right"
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginLeft: "20px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={handleReset}
          >
            Reset
          </Button>

          <Button
            className="pull-right"
            style={{
              backgroundColor: "#82868b",
              height: "40px",
              marginTop: "22px",
              marginLeft: "20px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            appearance="primary"
            type="button"
            onClick={handleSync}
          >
            Sync
          </Button>

          {/* <Button
            className="pull-right"
            style={{
              backgroundColor: "#82868b",
              height: "40px",
              marginTop: "22px",
              marginLeft: "20px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            appearance="primary"
            type="button"
            onClick={handlePriceUpdate}
          >
            Update Prices
          </Button> */}
      
      </Col>

      {/* <Col sm="1">

      </Col> */}
    </Row>
  );
}

export default Filters;
