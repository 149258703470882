import { gql, useLazyQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_OTA_RATING_MONTHLY_REPORT = gql`
  query getOtaRatingMonthlyReport(
    $monthStart: Int!
    $monthEnd: Int!
    $yearStart: Int!
    $yearEnd: Int!
    $bookingSource: String!
  ) {
    getOtaRatingMonthlyReport(
      monthStart: $monthStart
      monthEnd: $monthEnd
      yearStart: $yearStart
      yearEnd: $yearEnd
      bookingSource: $bookingSource
    ) {
      _id
      name
      rating
    }
  }
`;

export const useGetOtaRatingMonthlyReport = (
  monthStart,
  monthEnd,
  yearStart,
  yearEnd,
  bookingSource
) => {
  const [fetchReport, { loading, error, data }] = useLazyQuery(
    GET_OTA_RATING_MONTHLY_REPORT,
    {
      variables: {
        monthStart,
        monthEnd,
        yearStart,
        yearEnd,
        bookingSource,
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, fetchReport };
};
