import { Grid, Circle } from "react-feather";
const modules = JSON.parse(localStorage.getItem("modules"));

export default modules?.includes("Merchandise Store") ? [

    {
        id: "busBookings",
        title: "bookings",
        icon: <Grid size={20} />,
        badge: "light-warning",
        children: [
            {
                id: "MerchandiseProduces",
                title: "Bookings",
                icon: <Circle size={12} />,
                badge: "light-warning",
                navLink: "/busBookings/bookings",
            },
        ]
    }
] : [];