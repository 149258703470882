import RoomRatesComparisonComponent from "../../views/RoomRatesComparison";


const RoomRatesComparison = [
  {
    path: "/roomRatesComparison",
    component: RoomRatesComparisonComponent,
    exact: true,
  },
//   {
//     path: "/competitorRates/messageConfiguration",
//     component: MessageConfiguration,
//     exact: true,
//   }
];

export default RoomRatesComparison;
