import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Badge,
} from "reactstrap";
import styled from "styled-components";

const SlideModal = ({
  open,
  handleToggle,
  submitDataHandler,
  children,
  large,
  disabled,
  editData,
  userRole,
  userDepartment,
}) => {
  const isSaveDisabled = () => {
    if (userRole === "Area Manager" && editData?.amountPaid) {
      return editData?.status !== "Payment Done";
    } else if (
      userRole === "Area Manager" &&
      editData?.vendorName?.length > 0
    ) {
      return editData?.status !== "Quotation Added";
    } else if (userRole === "Area Manager" || userRole === "Ground Staff") {
      return editData?.status !== undefined;
    } else if (
      userDepartment === "Purchase" &&
      editData?.vendorName?.length > 0
    ) {
      return editData?.status !== "Purchase Approved";
    } else if (userDepartment === "Purchase") {
      return editData?.status !== "Pending";
    } else if (userRole === "Regional Manager") {
      return editData?.status !== "AM Approved";
    } else if (userRole === "CEO") {
      return editData?.status !== "RM Approved";
    } else if (userDepartment === "Finance") {
      return editData?.status !== "Payment Due";
    } else {
      return true;
    }
  };

  const shouldShowSaveButton = () => {
    return (
      [
        "Area Manager",
        "Regional Manager",
        "CEO",
        "Ground Staff",
        "Customer Experience",
      ].includes(userRole) ||
      ["Purchase", "Finance", "Customer Experience"].includes(userDepartment)
    );
  };

  return (
    <StyledModalWrap
      unmountOnClose={true}
      isOpen={open}
      toggle={handleToggle}
      className={large ? "ml-auto m-0 modal-lg" : "ml-auto m-0"}
    >
      {/* <ModalHeader toggle={handleToggle}>Details</ModalHeader> */}
      <ModalHeader
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <HeaderText className="mb-0.5">Blacklisted Guest Details</HeaderText>
        <HeaderBadgeWrapper>
          {editData?.status !== undefined && (
            <StatusBadgeWrapper>
              <StatusLabel>Current Status:</StatusLabel>
              <Badge color="secondary" className="status-badge animated pulse">
                {editData?.status}
              </Badge>
            </StatusBadgeWrapper>
          )}
          {editData?.requestId && (
            <RequestIDWrapper>
              <Badge color="primary" className="request-id-badge">
                Request ID: {editData.requestId}
              </Badge>
            </RequestIDWrapper>
          )}
        </HeaderBadgeWrapper>
      </ModalHeader>

      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        {shouldShowSaveButton() && (
          <Button
            color="light"
            onClick={submitDataHandler}
            className="save-button"
            // disabled={isSaveDisabled()}
          >
            Save
          </Button>
        )}
      </ModalFooter>
    </StyledModalWrap>
  );
};
const StyledModalWrap = styled(Modal)`
  .modal-content {
    min-height: 100vh;
  }
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
    .close {
      margin: 0;
    }
  }
  .status-badge {
    background-color: #3e6c09;
    margin-left: 12px;
  }
  .status-badge.animated.pulse {
    animation: pulse 1s infinite;
  }
  .request-id-badge {
    background-color: #007bff;
    margin-left: 18px;
  }
  .modal-footer {
    position: sticky;
    bottom: 0;
    z-index: 99;
    background-color: #f8f8f8;
  }
`;

const HeaderText = styled.div`
  font-size: 18px;
`;

const StatusBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StatusLabel = styled.div`
  font-size: 14px;
  margin-right: 5px;
`;

const HeaderBadgeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const RequestIDWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export default SlideModal;
