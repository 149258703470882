import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ROOM_RATES } from "./Queries";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { startLoading, stopLoading } from "@store/loader/loader.action";
import { UPDATE_ROOM_RATES_VIA_EZEE } from "./Mutation";

export const useLoadingHandler = (loading) => {
  const dispatch = useDispatch();

  useEffect(() => {
    loading ? dispatch(startLoading()) : dispatch(stopLoading());
  }, [dispatch, loading]);
};

export const useGetRoomRates = () => {
  const [fetchRoomRates, { error, called, loading, data, refetch }] =
    useLazyQuery(GET_ROOM_RATES);
  const isLoading = called && loading;
  useLoadingHandler(isLoading);
  return { fetchRoomRates, isLoading, error, data, refetch };
};

export const useUpdateRoomRates = () => {
  const [updateRoomRates, { loading, error, data, reset }] = useMutation(
    UPDATE_ROOM_RATES_VIA_EZEE
  );
  useLoadingHandler(loading);
  return  {updateRoomRates, loading, error, data, reset };
};
