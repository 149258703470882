import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CREATE_OTA_LOGIN = gql`
  mutation CreateOtaLogin($input: OtaLoginInput!) {
    createOtaLogin(input: $input) {
      platform
      userName
    }
  }
`;

export const useCreateOtaLogin = () => {
  const [createOtaLogin, { loading, error, data, refetch }] =
    useMutation(CREATE_OTA_LOGIN);

  useLoadingHandler(loading);

  const createOtaLoginHandler = (input) => {
    createOtaLogin({
      variables: {
        input,
      },
    });
  };

  return [createOtaLoginHandler, { loading, error, data, refetch }];
};

export const UPDATE_OTA_LOGIN = gql`
  mutation UpdateOtaLogin($id: ID!, $input: OtaLoginInput!) {
    updateOtaLogin(id: $id, input: $input) {
      _id
      platform
      userName
      password
      brand
      logs
    }
  }
`;

export const useUpdateOtaLogin = () => {
  const [updateOtaLogin, { loading, error, data, refetch }] =
    useMutation(UPDATE_OTA_LOGIN);

  useLoadingHandler(loading);

  const updateOtaLoginHandler = async ({ id, input }) => {
    console.log(id, input, "ID and input");

    try {
      const response = await updateOtaLogin({
        variables: {
          id,
          input,
        },
      });

      console.log("Update successful:", response.data.updateOtaLogin);
    } catch (err) {
      console.error("Update failed:", err);
    }
  };

  return [updateOtaLoginHandler, { loading, error, data, refetch }];
};
