import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";
import SlideModal from "@components/common/slide-modal";
import {
  useCreateGluBanner,
  useUpdateGluBanner,
  useUploadImage,
  useHostelList,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import { selectThemeColors } from "@utils";
import { useParams } from "react-router-dom";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import Select from "react-select";
import SwitchIcon from "@components/common/switch-icon";

const AddEditModal = ({ open, handleToggle, refetch, editData, setEditId }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [imageType, setImageType] = useState(null);
  const [displayOrder, setDisplayOrder] = useState("");
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState([]);
  const [status, setStatus] = useState(false);

  const { id } = useParams();
  const [
    uploadImageHandler,
    { data: imageData, reset: imageReset, loading: imageLoad },
  ] = useUploadImage();

  const { data: allHostelsData } = useHostelList();

  const [createBannerHandler, { data }] = useCreateGluBanner();

  const [updateGluBannerHandler, { data: updatedGluBannerData }] =
    useUpdateGluBanner();

  const [logs, setLogs] = useState(data?.logs ? data?.logs : []);

  const bannerType = [
    { label: "Top banner", value: "Top banner" },
    { label: "Bottom banner", value: "Bottom banner" },
  ];

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 512000,
    },
  });

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setImagePreview([preview]);

    const fileName = file.name;

    const specialCharsRegex = /[!@#$%^&*(),?":{}|<>]/;

    if (specialCharsRegex.test(fileName)) {
      console.error("File name:", fileName);
      toaster(
        "error",
        "File name contains special characters. Please provide a valid file name."
      );
      uppy.removeFile(file.id);
      return;
    }

    uploadImageHandler(file.data, "images/GluBanner");
    toaster("success", "Glu banner image uploaded successfully");
  });

  uppy.on("restriction-failed", (file, error) => {
    toaster("error", `Error uploading Glu banner image: ${error.message}`);
  });

  const renderPreview = () =>
    imagePreview ? (
      <img className="rounded mr-1 mb-2 w-50" src={imagePreview} alt="avatar" />
    ) : null;

  useEffect(() => {
    setImagePreview(imageData?.uploadImage?.url);
  }, [imageData]);

  useEffect(() => {
    let HostelsList = allHostelsData?.getHostelList?.map((hostel) => {
      return { label: hostel?.name, value: hostel?._id };
    });
    setHostelsList(HostelsList);
    // }
  }, [allHostelsData?.getHostelList]);

  const submitDataHandler = () => {
    const dataToSend = {
      image: imagePreview,
      imageType: imageType,
      displayOrder: parseInt(displayOrder),
      url: url,
      title: title,
      hostel: hostel?.map((hostel) => hostel.value),
      status,
      logs: [
        ...logs,
        `${localStorage.getItem("name")} ${
          editData ? "modified" : "created"
        } this glu banner on ${new Date().toLocaleString("en-IN", {
          hour12: false,
        })}`,
      ],
    };

    if (dataToSend.title) {
      if (editData?._id) {
        updateGluBannerHandler(editData?._id, dataToSend);
        toaster("success", "Glu banner updated successfully");
      } else {
        createBannerHandler({ data: dataToSend });
        toaster("success", "Glu banner added successfully");
      }

      setImageType(null);
      setDisplayOrder("");
      setTitle("");
      setImagePreview(null);
      setUrl("");
      setStatus(false);
      setLogs([]);
      refetch();
      handleToggle();
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  useEffect(() => {
    if (editData) {
      setHostel(
        editData?.hostel?.map((hostel) => {
          return { label: hostel?.name, value: hostel?._id };
        })
      );
      setImageType(editData?.imageType);
      setDisplayOrder(editData?.displayOrder);
      setTitle(editData?.title);
      setImagePreview(editData?.image);
      setUrl(editData?.url);
      setStatus(editData?.status);
      setLogs(editData?.logs ? editData?.logs : []);
    } else {
      setHostel(null);
      setImageType(null);
      setDisplayOrder("");
      setTitle("");
      setImagePreview(null);
      setUrl("");
      setStatus(false);
      setLogs([]);
    }
  }, [editData]);

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          {/* // ! Hostel */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                Hostel
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                closeMenuOnSelect={false}
                options={hostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! Image Type */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="imageType">
                Image Type
              </Label>
              <Select
                placeholder={imageType}
                id="imageType"
                name="imageType"
                value={imageType}
                options={bannerType}
                onChange={(e) => setImageType(e.value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! Display Order */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="displayOrder">
                Display Order
              </Label>
              <Input
                type="number"
                value={displayOrder}
                id="displayOrder"
                name="displayOrder"
                placeholder="Add Display Order"
                onChange={(e) => setDisplayOrder(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Title */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="title">
                Title*
              </Label>
              <Input
                type="text"
                value={title}
                name="title"
                id="title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! URL */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                URL
              </Label>
              <Input
                type="text"
                value={url}
                id="url"
                name="url"
                placeholder="Add Url"
                onChange={(e) => setUrl(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Image */}
          <Col sm="12">
            <p>Upload Image</p>
            <DragDrop
              note="Image size should be less than 500KB"
              width="100%"
              uppy={uppy}
              locale={{
                strings: {
                  dropHereOr: "Drop image here or %{browse}",
                  browse: "browse",
                },
              }}
            />
            <Col sm="6">
              <Label className="form-label w-100">Image preview here</Label>
              {imageLoad ? "Uploading image..." : renderPreview()}
            </Col>
          </Col>

          {/* // ! Status */}
          <Col sm="12">
            <SwitchIcon
              checked={status}
              id="status1"
              name="status1"
              label="Ststus ?"
              handleChecked={(value) => setStatus(value)}
            />
          </Col>

          {/* // ! Logs */}
          {logs?.length > 0 && (
            <Col sm="12">
              {/* <hr/> */}
              <FormGroup className="w-100">
                <Label className="form-label" for="logs">
                  <strong>Logs:</strong>
                </Label>
                <br />
                {logs
                  ?.map((log, i) => {
                    return (
                      <Label className="form-label" for="name">
                        {log}.
                      </Label>
                    );
                  })
                  .reverse()}
              </FormGroup>
            </Col>
          )}
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
