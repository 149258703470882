import React from 'react'
import { useGetHostelFoodCharges } from '../../../api/dataSource';
import HostelFoodList from './HostelFoodList';

const HostelWiseFoodCharges = () => {
    const { data: hostelListData, refetch } = useGetHostelFoodCharges();
    return (
        <div>
            <HostelFoodList hostelListData={hostelListData?.getAllHostelAndFoodCharges} refetch={refetch} />
        </div>
    )
}

export default HostelWiseFoodCharges