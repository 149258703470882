import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_PAGINATED_PRE_STAY_REFUND_REQUEST_LIST = gql`
  query getPaginatedPreStayRefundRequestList($filter: filter) {
    getPaginatedPreStayRefundRequestList(filter: $filter) {
      count
      limit
      page
      data {
        _id
        refundID
        hostel {
          _id
          name
        }
        reservationID
        bookingSource
        guestName
        email
        mobileNumber
        bookingAmount
        refundAmount
        refundMode
        refundType
        refundReason
        refundRemark
        status
        priority
        initiatedBy
        logs
        requestDate

        approverRemark
        approvalTime
        markedOnEzee
        cancelOnEzee

        paymentID
        refundStatus
        refundStartTime
        razprpayRefundID
        refundSuccessTime
        refundStatusDetails
        createdAt
        updatedAt
      }
    }
  }
`;

export const useGetPaginatedPreStayRefundRequestList = (filter) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_PRE_STAY_REFUND_REQUEST_LIST,
    {
      variables: {
        filter,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const GET_PRE_STAY_REFUND_GUEST_DATA_BY_RESERVATION_ID = gql`
  query getPreStayRefundGuestDataByReservationId($reservationID: String!) {
    getPreStayRefundGuestDataByReservationId(reservationID: $reservationID) {
      reservationID
      bookingAmount
      guestName
      phoneNumber
      email
      bookingSource
      hostelId
      hostelName
    }
  }
`;

export const useGetPreStayRefundGuestDataByReservationId = (reservationID) => {
  const [fetchData, { loading, error, data }] = useLazyQuery(
    GET_PRE_STAY_REFUND_GUEST_DATA_BY_RESERVATION_ID,
    {
      variables: {
        reservationID,
      },
    }
  );

  return {
    fetchData,
    loading,
    error,
    data,
  };
};
