import gql from "graphql-tag";

export const GET_GLU_BANNER = gql`
  query getGluBanner ($filter: filter) {
    getGluBanner (filter: $filter){
      _id
      image
      url
      title
      imageType
      displayOrder
      hostel {
        _id
        name
      }   
      status
      logs
    }
  }
`;
