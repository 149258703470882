import { AlertTriangle } from "react-feather";

const modules = JSON.parse(localStorage.getItem("modules"));

export default modules?.includes("Refund Request Post-Stay")
  ? [
      {
        id: "refundRequestPostStay",
        title: "Refund Request",
        icon: <AlertTriangle />,
        navLink: "/refundRequestPostStay",
      },
    ]
  : [];
