import React, { useEffect, useState } from 'react'
import BusBookingsTable from './BusBookingsTable'
import { useGetAllConfirmBusBookings } from '../../api/BusBooking/Queries'
import AddEditModal from './AddEditModal';

const Index = () => {
    const [tableData, setTableData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [id, setId] = useState(null);
    const [editData, setEditData] = useState(null);
    console.log(id)

    const { data: busBookings, refetch: busBookingRefetch } =
        useGetAllConfirmBusBookings();

    console.log(busBookings)

    useEffect(() => {
        if (busBookings?.getAllPaginatedBusBookings?.data) {
            setTableData(busBookings?.getAllPaginatedBusBookings?.data)
        }
    }, [busBookings])

    const toggleModal = () => {
        setOpenModal(!openModal);
        setId(null);
        setEditData(null);
    };

    useEffect(() => {
        if (busBookings?.getAllPaginatedBusBookings?.data) {
            let editData = busBookings?.getAllPaginatedBusBookings?.data?.find((item) => item._id === id)
            setEditData(editData);
        } else {
            setId(null);
            setEditData(null);
        }
    }, [id])
    return (
        <>
            <BusBookingsTable
                tableData={tableData}
                openModal={() => setOpenModal(!openModal)}
                setId={setId}
            />
            <AddEditModal
                open={openModal}
                handleToggle={toggleModal}
                refetch={busBookingRefetch}
                editData={editData}
            />
        </>
    )
}

export default Index