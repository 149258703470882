import EmployeeProfile from "../../views/HR/EmployeeProfile";
import AddHRTabs from "../../views/HR/EmployeeProfile/AddHRTabs";
import EmployeeAttendance from "../../views/HR/EmployeeAttendance"
import EmployeeLeaveBalance from "../../views/HR/EmployeeLeaveBalance"
import ImportFiles from "../../views/HR/EmployeeProfile/ImportFiles";
import HostelWiseFoodCharges from "../../views/HR/HostelWiseFoodCharges";
import ProcessPaySlip from "../../views/HR/EmployeeAttendance/ProcessPaySlip";

const HRRoutes = [
  {
    path: "/hr/employeeProfile",
    component: EmployeeProfile,
    exact: true,
  },
  {
    path: "/hr/edit/employeeProfile/:id",
    component: AddHRTabs,
    exact: true,
    meta: {
      navLink: "/hr",
    },
  },
  {
    path: "/hr/employeeAttendance",
    component: EmployeeAttendance,
    exact: true,
  },
  {
    path: "/hr/employeeLeaveBalance",
    component: EmployeeLeaveBalance,
    exact: true,
  },
  {
    path: "/hr/importFiles/",
    component: ImportFiles,
    exact: true
  },
  {
    path: "/hr/hostelWiseFoodCharges/",
    component: HostelWiseFoodCharges,
    exact: true
  },
  {
    path: "/hr/ProcessPayslips/",
    component: ProcessPaySlip,
    exact: true
  },
];

export default HRRoutes;
