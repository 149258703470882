import React, { useEffect, useState } from "react";
import GluBannerTable from "./GluBannerTable";
import { useGetGluBanner } from "../../../api/dataSource";
import AddEditModal from "./AddEditModal";
import { Button, Col, FormGroup, Label, Row, Input } from "reactstrap";
import Select from "react-select";

const GluBanner = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [id, setId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({});

  const [title, setTitle] = useState("");
  const [imageType, setImageType] = useState(null);
  const [statusBool, setStatusBool] = useState(null);
  const { data, loading, refetch } = useGetGluBanner(filter);

  const statusList = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];

  const bannerType = [
    { label: "Top banner", value: "Top banner" },
    { label: "Bottom banner", value: "Bottom banner" },
  ];

  useEffect(() => {
    if (data?.getGluBanner) {
      setTableData(data?.getGluBanner);
    } else {
      setTableData([]);
    }
  }, [data]);

  useEffect(() => {
    if (data?.getGluBanner) {
      let editData = data?.getGluBanner.find((item) => item._id === id);
      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [id]);

  const handleSearch = () => {
    const filter = {
      title,
      imageType: imageType?.value,
      statusBool: statusBool?.value,
    };
    setFilter(filter);
  };

  const handleReset = () => {
    setFilter({});
    setTitle("");
    setImageType(null);
    setStatusBool(null);
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  return (
    <div>
      <Row>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="title">
              Title
            </Label>
            <Input
              type="text"
              value={title}
              name="title"
              id="title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="imageType">
              Banner Type
            </Label>
            <Select
              placeholder={imageType}
              id="imageType"
              name="imageType"
              value={imageType}
              options={bannerType}
              onChange={(value) => setImageType(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="statusBool">
              <strong>Status</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              id="statusBool"
              name="statusBool"
              options={statusList}
              value={statusBool}
              onChange={(value) => setStatusBool(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col className="mb-2">
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={handleSearch}
          >
            Search
          </Button>

          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <GluBannerTable
        setId={setId}
        openModal={() => setOpenModal(!openModal)}
        data={tableData}
        refetch={refetch}
      />

      <AddEditModal
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
      />
    </div>
  );
};

export default GluBanner;
