import React, { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";

const tableHeaderData = [
  "Hostel Name",
  "Month - Year",
  "Room Category",
  "Available Nights",
  "Sold Nights",
  "Occupancy (%)",
  "ADR (₹)",
  "Revenue (₹)",
];

const RoomwiseADRReportTable = ({ data }) => {
  const [tableData, setTableData] = useState([]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    setTableData(data?.length > 0 ? data : []);
  }, [data]);

  const calculateTotals = (reports, isDorm) => {
    return reports.reduce(
      (totals, reportData) => {
        const isCategoryDorm = reportData?.roomCategory
          ?.toLowerCase()
          .includes("dorm");
        if (isDorm === null || isDorm === isCategoryDorm) {
          totals.availableNights += reportData?.availableNights || 0;
          totals.soldNights += reportData?.soldNights || 0;
          // totals.occupancy += reportData?.occupancy || 0;
          totals.revenue += reportData?.revenue || 0;
          totals.adr += reportData?.adr || 0;
        }
        return totals;
      },
      {
        availableNights: 0,
        soldNights: 0,
        //  occupancy: 0,
        revenue: 0,
        adr: 0,
      }
    );
  };

  const renderTotalsRow = (label, totals) => (
    <tr
      className={`font-weight-bold 
      `}
    >
      <td></td>
      <td></td>
      <td>{label}</td>

      {/* //! Available Nights */}
      <td>{totals.availableNights.toLocaleString()}</td>

      {/* //! Sold Nights */}
      <td>{totals.soldNights.toLocaleString()}</td>
      {/* //! Occupancy */}
      {/* <td>{totals.occupancy.toFixed(2)}</td> */}
      {/* <td>{((totals.soldNights / totals.availableNights) * 100).toFixed(2)}</td> */}
      <td>
        {totals.availableNights > 0
          ? ((totals.soldNights / totals.availableNights) * 100).toFixed(2)
          : "0.00"}
      </td>

      {/* //! ADR */}
      {/* <td>{totals.adr.toFixed(2)}</td> */}
      {/* <td>{(totals.revenue / totals.soldNights).toFixed(2)}</td> */}
      <td>
        {totals.soldNights > 0
          ? (totals.revenue / totals.soldNights).toFixed(2)
          : "0.00"}
      </td>

      {/* //! Revenue */}
      <td>{totals.revenue.toLocaleString()}</td>
    </tr>
  );

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <h2>Room-wise ADR Report</h2>
            </CardTitle>
          </CardHeader>
          <Table responsive hover className="table-bordered">
            <thead className="bg-light">
              <tr>
                {tableHeaderData.map((item, index) => (
                  <th key={index} className="p-2">
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((item) => {
                const dormTotals = calculateTotals(item.report, true);
                const privateTotals = calculateTotals(item.report, false);
                const finalTotals = calculateTotals(item.report, null);

                return (
                  <React.Fragment key={uuidv4()}>
                    {item.report.map((reportData, idx) => (
                      <tr key={uuidv4()}>
                        {idx === 0 && (
                          <>
                            <td
                              rowSpan={item.report.length}
                              className="align-middle font-weight-bold"
                            >
                              {item.hostelName}
                            </td>
                            <td
                              rowSpan={item.report.length}
                              className="align-middle font-weight-bold"
                            >
                              {item.month >= 1 && item.month <= 12
                                ? `${months[item.month - 1]} - ${item.year}`
                                : "-"}
                            </td>
                          </>
                        )}
                      </tr>
                    ))}

                    {/* Dorm Rooms */}
                    {item?.report
                      ?.filter((reportData) =>
                        reportData?.roomCategory
                          ?.toLowerCase()
                          ?.includes("dorm")
                      )
                      ?.map((reportData) => (
                        <tr key={uuidv4()}>
                          <td></td>
                          <td></td>
                          <td>{reportData?.roomCategory || "NA"}</td>
                          <td>
                            {reportData?.availableNights?.toLocaleString() ??
                              "-"}
                          </td>
                          <td>
                            {reportData?.soldNights?.toLocaleString() ?? "-"}
                          </td>
                          {/* <td>
                             {reportData.availableNights > 0
                               ? (
                                   (reportData.soldNights /
                                     reportData.availableNights) *
                                   100
                                 ).toFixed(2)
                               : "-"}
                           </td> */}
                          <td>{reportData?.occupancy.toFixed(2)}</td>
                          <td>{reportData?.adr?.toFixed(2) ?? "-"}</td>
                          <td>
                            {reportData?.revenue?.toLocaleString() ?? "-"}
                          </td>
                        </tr>
                      ))}
                    {/* {renderTotalsRow("Total Dorms", dormTotals)} */}
                    {item?.report?.filter((reportData) =>
                      reportData?.roomCategory?.toLowerCase()?.includes("dorm")
                    )?.length > 0
                      ? renderTotalsRow(
                          "Total Dorms",

                          dormTotals
                        )
                      : null}

                    {/* Private Rooms */}
                    {item?.report
                      ?.filter(
                        (reportData) =>
                          !reportData?.roomCategory
                            ?.toLowerCase()
                            ?.includes("dorm")
                      )
                      ?.map((reportData) => (
                        <tr key={uuidv4()}>
                          <td></td>
                          <td></td>
                          <td>{reportData?.roomCategory || "NA"}</td>
                          <td>
                            {reportData?.availableNights?.toLocaleString() ??
                              "-"}
                          </td>
                          <td>
                            {reportData?.soldNights?.toLocaleString() ?? "-"}
                          </td>
                          {/* <td>
                             {reportData.availableNights > 0
                               ? (
                                   (reportData.soldNights /
                                     reportData.availableNights) *
                                   100
                                 ).toFixed(2)
                               : "-"}
                           </td> */}
                          <td>{reportData?.occupancy.toFixed(2)}</td>
                          <td>{reportData?.adr?.toFixed(2) ?? "-"}</td>
                          <td>
                            {reportData?.revenue?.toLocaleString() ?? "-"}
                          </td>
                        </tr>
                      ))}

                    {/* {renderTotalsRow("Total Private", privateTotals)} */}
                    {item?.report?.filter(
                      (reportData) =>
                        !reportData?.roomCategory
                          ?.toLowerCase()
                          ?.includes("dorm")
                    )?.length > 0
                      ? renderTotalsRow("Total Private Rooms", privateTotals)
                      : null}

                    {/* {renderTotalsRow("Ove}rall", finalTotals) */}
                    {item?.report?.filter((reportData) =>
                      reportData?.roomCategory?.toLowerCase()?.includes("dorm")
                    )?.length > 0 ||
                    item?.report?.filter(
                      (reportData) =>
                        !reportData?.roomCategory
                          ?.toLowerCase()
                          ?.includes("dorm")
                    )?.length > 0
                      ? renderTotalsRow("Overall", finalTotals)
                      : null}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default RoomwiseADRReportTable;

// import React, { useEffect, useState } from "react";
// import { Col, Card, Row, CardHeader, CardTitle, Table } from "reactstrap";
// import { v4 as uuidv4 } from "uuid";

// const tableHeaderData = [
//   "Hostel Name",
//   "Month - Year",
//   "Room Category",
//   "Available Nights",
//   "Sold Nights",
//   "Occupancy (%)",
//   "ADR (₹)",
//   "Revenue (₹)",
// ];

// const RoomwiseADRReportTable = ({ data }) => {
//   const [tableData, setTableData] = useState([]);

//   const months = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   useEffect(() => {
//     setTableData(data?.length > 0 ? data : []);
//   }, [data]);

//   const calculateTotals = (reports, isDorm) => {
//     return reports?.reduce(
//       (totals, reportData) => {
//         const isCategoryDorm = reportData?.roomCategory
//           ?.toLowerCase()
//           ?.includes("dorm");
//         if (isDorm === null || isDorm === isCategoryDorm) {
//           totals.availableNights += reportData?.availableNights || 0;
//           totals.soldNights += reportData?.soldNights || 0;
//           totals.revenue += reportData?.revenue || 0;
//         }
//         return totals;
//       },
//       {
//         availableNights: 0,
//         soldNights: 0,
//         revenue: 0,
//       }
//     );
//   };

//   const renderTotalsRow = (label, totals) => (
//     <tr
//       style={{
//         borderTop: "2px solid #ffe700",
//         borderBottom: "2px solid #ffe700",
//       }}
//       className={`font-weight-bold ${label === "Overall"}`}
//     >
//       <td
//       // colSpan={label !== "Overall" ? 1 : 3}
//       >
//         {" "}
//         <strong> {label}</strong>
//       </td>
//       <td>
//         <strong>{totals?.availableNights.toLocaleString()}</strong>
//       </td>
//       <td>
//         <strong>{totals?.soldNights.toLocaleString()}</strong>
//       </td>
//       <td>
//         <strong>
//           {totals?.availableNights > 0
//             ? ((totals?.soldNights / totals?.availableNights) * 100).toFixed(2)
//             : "0.00"}
//         </strong>
//       </td>
//       <td>
//         <strong>
//           {totals?.soldNights > 0
//             ? (totals?.revenue / totals?.soldNights).toFixed(2)
//             : "0.00"}
//         </strong>
//       </td>
//       <td>
//         <strong>{totals?.revenue.toLocaleString()}</strong>
//       </td>
//     </tr>
//   );

//   return (
//     <Row>
//       <Col>
//         <Card>
//           <CardHeader>
//             <CardTitle>
//               <h2>Room-wise ADR Report</h2>
//             </CardTitle>
//           </CardHeader>
//           <Table responsive hover className="table-bordered">
//             <thead
//               className="bg-light"
//               style={{ position: "sticky", top: 0, zIndex: 2 }}
//             >
//               <tr>
//                 {tableHeaderData?.map((item, index) => (
//                   <th key={index} className="p-2">
//                     {item}
//                   </th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {tableData?.map((item) => {
//                 const dormTotals = calculateTotals(item?.report, true);
//                 const privateTotals = calculateTotals(item?.report, false);
//                 const finalTotals = calculateTotals(item?.report, null);

//                 return (
//                   <React.Fragment key={uuidv4()}>
//                     <tr>
//                       <td
//                         rowSpan={item?.report?.length}
//                         className="align-middle font-weight-bold"
//                       >
//                         <strong>{item?.hostelName}</strong>
//                       </td>
//                       <td
//                         rowSpan={item?.report?.length}
//                         className="align-middle font-weight-bold"
//                       >
//                         <strong>
//                           {" "}
//                           {item?.month >= 1 && item?.month <= 12
//                             ? `${months[item?.month - 1]} - ${item?.year}`
//                             : "-"}
//                         </strong>
//                       </td>
//                     </tr>
//                     {/* Dorm Rooms */}
//                     {item?.report
//                       ?.filter((reportData) =>
//                         reportData?.roomCategory
//                           ?.toLowerCase()
//                           ?.includes("dorm")
//                       )
//                       ?.map((reportData) => (
//                         <tr key={uuidv4()}>
//                           <td>{reportData?.roomCategory || "NA"}</td>
//                           <td>
//                             {reportData?.availableNights?.toLocaleString() ??
//                               "-"}
//                           </td>
//                           <td>
//                             {reportData?.soldNights?.toLocaleString() ?? "-"}
//                           </td>
//                           {/* <td>
//                             {reportData.availableNights > 0
//                               ? (
//                                   (reportData.soldNights /
//                                     reportData.availableNights) *
//                                   100
//                                 ).toFixed(2)
//                               : "-"}
//                           </td> */}
//                           <td>{reportData?.occupancy.toFixed(2)}</td>
//                           <td>{reportData?.adr?.toFixed(2) ?? "-"}</td>
//                           <td>
//                             {reportData?.revenue?.toLocaleString() ?? "-"}
//                           </td>
//                         </tr>
//                       ))}
//                     {item?.report?.filter((reportData) =>
//                       reportData?.roomCategory?.toLowerCase()?.includes("dorm")
//                     )?.length > 0
//                       ? renderTotalsRow(
//                           "Total Dorms",

//                           dormTotals
//                         )
//                       : null}
//                     {/* {renderTotalsRow("Total Dorms", dormTotals)} */}
//                     {/* Private Rooms */}
//                     {item?.report
//                       ?.filter(
//                         (reportData) =>
//                           !reportData?.roomCategory
//                             ?.toLowerCase()
//                             ?.includes("dorm")
//                       )
//                       ?.map((reportData) => (
//                         <tr key={uuidv4()}>
//                           <td>{reportData?.roomCategory || "NA"}</td>
//                           <td>
//                             {reportData?.availableNights?.toLocaleString() ??
//                               "-"}
//                           </td>
//                           <td>
//                             {reportData?.soldNights?.toLocaleString() ?? "-"}
//                           </td>
//                           {/* <td>
//                             {reportData.availableNights > 0
//                               ? (
//                                   (reportData.soldNights /
//                                     reportData.availableNights) *
//                                   100
//                                 ).toFixed(2)
//                               : "-"}
//                           </td> */}
//                           <td>{reportData?.occupancy.toFixed(2)}</td>
//                           <td>{reportData?.adr?.toFixed(2) ?? "-"}</td>
//                           <td>
//                             {reportData?.revenue?.toLocaleString() ?? "-"}
//                           </td>
//                         </tr>
//                       ))}
//                     {item?.report?.filter(
//                       (reportData) =>
//                         !reportData?.roomCategory
//                           ?.toLowerCase()
//                           ?.includes("dorm")
//                     )?.length > 0
//                       ? renderTotalsRow("Total Private Rooms", privateTotals)
//                       : null}
//                     {/* {renderTotalsRow("Total Private Rooms", privateTotals)} */}
//                     {/* Overall Totals */}
//                     {renderTotalsRow("Overall", finalTotals)}{" "}
//                   </React.Fragment>
//                 );
//               })}
//             </tbody>
//           </Table>
//         </Card>
//       </Col>
//     </Row>
//   );
// };

// export default RoomwiseADRReportTable;

// import React, { useEffect, useState } from "react";
// import { Col, Card, Row, CardHeader, CardTitle, Table } from "reactstrap";
// import { v4 as uuidv4 } from "uuid";

// const tableHeaderData = [
//   "Hostel Name",
//   "Month - Year",
//   "Room Category",
//   "Available Nights",
//   "Sold Nights",
//   "Occupancy (%)",
//   "ADR (₹)",
//   "Revenue (₹)",
// ];

// const RoomwiseADRReportTable = ({ data }) => {
//   const [tableData, setTableData] = useState([]);

//   const months = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   useEffect(() => {
//     setTableData(data?.length > 0 ? data : []);
//   }, [data]);

//   const calculateTotals = (reports, isDorm) => {
//     return reports?.reduce(
//       (totals, reportData) => {
//         const isCategoryDorm = reportData?.roomCategory
//           ?.toLowerCase()
//           ?.includes("dorm");
//         if (isDorm === null || isDorm === isCategoryDorm) {
//           totals.availableNights += reportData?.availableNights || 0;
//           totals.soldNights += reportData?.soldNights || 0;
//           totals.revenue += reportData?.revenue || 0;
//         }
//         return totals;
//       },
//       {
//         availableNights: 0,
//         soldNights: 0,
//         revenue: 0,
//       }
//     );
//   };

//   const renderTotalsRow = (label, totals) => (
//     <tr
//       style={{
//         borderTop: "2px solid #ffe700",
//         borderBottom: "2px solid #ffe700",
//       }}
//       className={`font-weight-bold ${label === "Overall"}`}
//     >
//       <td
//       // colSpan={label !== "Overall" ? 1 : 3}
//       >
//         {" "}
//         <strong> {label}</strong>
//       </td>
//       <td>
//         <strong>{totals?.availableNights.toLocaleString()}</strong>
//       </td>
//       <td>
//         <strong>{totals?.soldNights.toLocaleString()}</strong>
//       </td>
//       <td>
//         <strong>
//           {totals?.availableNights > 0
//             ? ((totals?.soldNights / totals?.availableNights) * 100).toFixed(2)
//             : "0.00"}
//         </strong>
//       </td>
//       <td>
//         <strong>
//           {totals?.soldNights > 0
//             ? (totals?.revenue / totals?.soldNights).toFixed(2)
//             : "0.00"}
//         </strong>
//       </td>
//       <td>
//         <strong>{totals?.revenue.toLocaleString()}</strong>
//       </td>
//     </tr>
//   );

//   return (
//     <Row>
//       <Col>
//         <Card>
//           <CardHeader>
//             <CardTitle>
//               <h2>Room-wise ADR Report</h2>
//             </CardTitle>
//           </CardHeader>
//           <Table responsive hover className="table-bordered">
//             <thead
//               className="bg-light"
//               style={{ position: "sticky", top: 0, zIndex: 2 }}
//             >
//               <tr>
//                 {tableHeaderData?.map((item, index) => (
//                   <th key={index} className="p-2">
//                     {item}
//                   </th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {tableData?.map((item) => {
//                 const dormTotals = calculateTotals(item?.report, true);
//                 const privateTotals = calculateTotals(item?.report, false);
//                 const finalTotals = calculateTotals(item?.report, null);

//                 return (
//                   <React.Fragment key={uuidv4()}>
//                     <tr>
//                       <td
//                         rowSpan={item?.report?.length + 3}
//                         className="align-middle font-weight-bold"
//                       >
//                         <strong>{item?.hostelName}</strong>
//                       </td>
//                       <td
//                         rowSpan={item?.report?.length + 3}
//                         className="align-middle font-weight-bold"
//                       >
//                         <strong>
//                           {" "}
//                           {item?.month >= 1 && item?.month <= 12
//                             ? `${months[item?.month - 1]} - ${item?.year}`
//                             : "-"}
//                         </strong>
//                       </td>
//                     </tr>
//                     {/* Dorm Rooms */}
//                     {item?.report
//                       ?.filter((reportData) =>
//                         reportData?.roomCategory
//                           ?.toLowerCase()
//                           ?.includes("dorm")
//                       )
//                       ?.map((reportData) => (
//                         <tr key={uuidv4()}>
//                           <td>{reportData?.roomCategory || "NA"}</td>
//                           <td>
//                             {reportData?.availableNights?.toLocaleString() ??
//                               "-"}
//                           </td>
//                           <td>
//                             {reportData?.soldNights?.toLocaleString() ?? "-"}
//                           </td>
//                           {/* <td>
//                             {reportData.availableNights > 0
//                               ? (
//                                   (reportData.soldNights /
//                                     reportData.availableNights) *
//                                   100
//                                 ).toFixed(2)
//                               : "-"}
//                           </td> */}
//                           <td>{reportData?.occupancy.toFixed(2)}</td>
//                           <td>{reportData?.adr?.toFixed(2) ?? "-"}</td>
//                           <td>
//                             {reportData?.revenue?.toLocaleString() ?? "-"}
//                           </td>
//                         </tr>
//                       ))}
//                     {item?.report?.filter((reportData) =>
//                       reportData?.roomCategory?.toLowerCase()?.includes("dorm")
//                     )?.length > 0
//                       ? renderTotalsRow(
//                           "Total Dorms",

//                           dormTotals
//                         )
//                       : null}
//                     {/* {renderTotalsRow("Total Dorms", dormTotals)} */}
//                     {/* Private Rooms */}
//                     {item?.report
//                       ?.filter(
//                         (reportData) =>
//                           !reportData?.roomCategory
//                             ?.toLowerCase()
//                             ?.includes("dorm")
//                       )
//                       ?.map((reportData) => (
//                         <tr key={uuidv4()}>
//                           <td>{reportData?.roomCategory || "NA"}</td>
//                           <td>
//                             {reportData?.availableNights?.toLocaleString() ??
//                               "-"}
//                           </td>
//                           <td>
//                             {reportData?.soldNights?.toLocaleString() ?? "-"}
//                           </td>
//                           {/* <td>
//                             {reportData.availableNights > 0
//                               ? (
//                                   (reportData.soldNights /
//                                     reportData.availableNights) *
//                                   100
//                                 ).toFixed(2)
//                               : "-"}
//                           </td> */}
//                           <td>{reportData?.occupancy.toFixed(2)}</td>
//                           <td>{reportData?.adr?.toFixed(2) ?? "-"}</td>
//                           <td>
//                             {reportData?.revenue?.toLocaleString() ?? "-"}
//                           </td>
//                         </tr>
//                       ))}
//                     {item?.report?.filter(
//                       (reportData) =>
//                         !reportData?.roomCategory
//                           ?.toLowerCase()
//                           ?.includes("dorm")
//                     )?.length > 0
//                       ? renderTotalsRow("Total Private Rooms", privateTotals)
//                       : null}
//                     {/* {renderTotalsRow("Total Private Rooms", privateTotals)} */}
//                     {/* Overall Totals */}
//                     {renderTotalsRow("Overall", finalTotals)}{" "}
//                   </React.Fragment>
//                 );
//               })}
//             </tbody>
//           </Table>
//         </Card>
//       </Col>
//     </Row>
//   );
// };

// export default RoomwiseADRReportTable;

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// import React, { useEffect, useState } from "react";
// import { Col, Card, Row, CardHeader, CardTitle, Table } from "reactstrap";
// import { v4 as uuidv4 } from "uuid";

// const tableHeaderData = [
//   "Hostel Name",
//   "Month - Year",
//   "Room Category",
//   "Available Nights",
//   "Sold Nights",
//   "Occupancy (%)",
//   "ADR (₹)",
//   "Revenue (₹)",
// ];

// const RoomwiseADRReportTable = ({ data }) => {
//   const [tableData, setTableData] = useState([]);

//   const months = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   useEffect(() => {
//     setTableData(data?.length > 0 ? data : []);
//   }, [data]);

//   const calculateTotals = (reports, isDorm) => {
//     return reports.reduce(
//       (totals, reportData) => {
//         const isCategoryDorm = reportData?.roomCategory
//           ?.toLowerCase()
//           .includes("dorm");
//         if (isDorm === null || isDorm === isCategoryDorm) {
//           totals.availableNights += reportData?.availableNights || 0;
//           totals.soldNights += reportData?.soldNights || 0;
//           // totals.occupancy += reportData?.occupancy || 0;
//           totals.revenue += reportData?.revenue || 0;
//           totals.adr += reportData?.adr || 0;
//         }
//         return totals;
//       },
//       {
//         availableNights: 0,
//         soldNights: 0,
//         //  occupancy: 0,
//         revenue: 0,
//         adr: 0,
//       }
//     );
//   };

//   const renderTotalsRow = (label, totals) => (
//     <tr
//       className={`font-weight-bold ${
//         label === "Overall" ? "bg-secondary text-white" : ""
//       }`}
//     >
//       <td colSpan={3}>{label}</td>

//       {/* //! Available Nights */}
//       <td>{totals.availableNights.toLocaleString()}</td>

//       {/* //! Sold Nights */}
//       <td>{totals.soldNights.toLocaleString()}</td>
//       {/* //! Occupancy */}
//       {/* <td>{totals.occupancy.toFixed(2)}</td> */}
//       {/* <td>{((totals.soldNights / totals.availableNights) * 100).toFixed(2)}</td> */}
//       <td>
//         {totals.availableNights > 0
//           ? ((totals.soldNights / totals.availableNights) * 100).toFixed(2)
//           : "0.00"}
//       </td>

//       {/* //! ADR */}
//       {/* <td>{totals.adr.toFixed(2)}</td> */}
//       {/* <td>{(totals.revenue / totals.soldNights).toFixed(2)}</td> */}
//       <td>
//         {totals.soldNights > 0
//           ? (totals.revenue / totals.soldNights).toFixed(2)
//           : "0.00"}
//       </td>

//       {/* //! Revenue */}
//       <td>{totals.revenue.toLocaleString()}</td>
//     </tr>
//   );

//   return (
//     <Row>
//       <Col>
//         <Card>
//           <CardHeader>
//             <CardTitle>
//               <h2>Room-wise ADR Report</h2>
//             </CardTitle>
//           </CardHeader>
//           <Table responsive hover className="table-bordered">
//             <thead className="bg-light">
//               <tr>
//                 {tableHeaderData.map((item, index) => (
//                   <th key={index} className="p-2">
//                     {item}
//                   </th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               {tableData.map((item) => {
//                 const dormTotals = calculateTotals(item.report, true);
//                 const privateTotals = calculateTotals(item.report, false);
//                 const finalTotals = calculateTotals(item.report, null);

//                 return (
//                   <React.Fragment key={uuidv4()}>
//                     {item.report.map((reportData, idx) => (
//                       <tr key={uuidv4()}>
//                         {idx === 0 && (
//                           <>
//                             <td
//                               rowSpan={item.report.length}
//                               className="align-middle font-weight-bold"
//                             >
//                               {item.hostelName}
//                             </td>
//                             <td
//                               rowSpan={item.report.length}
//                               className="align-middle font-weight-bold"
//                             >
//                               {item.month >= 1 && item.month <= 12
//                                 ? `${months[item.month - 1]} - ${item.year}`
//                                 : "-"}
//                             </td>
//                           </>
//                         )}

//                         {reportData.roomCategory
//                           ?.toLowerCase()
//                           .includes("dorm") && (
//                           <>
//                             <td>{reportData.roomCategory || "NA"}</td>
//                             <td>
//                               {reportData.availableNights?.toLocaleString() ??
//                                 "-"}
//                             </td>
//                             <td>
//                               {reportData.soldNights?.toLocaleString() ?? "-"}
//                             </td>
//                             <td>
//                               {reportData.occupancy !== null
//                                 ? reportData.occupancy.toFixed(2)
//                                 : "-"}
//                             </td>
//                             <td>
//                               {reportData.adr !== null
//                                 ? reportData.adr.toFixed(2)
//                                 : "-"}
//                             </td>
//                             <td>
//                               {reportData.revenue !== null
//                                 ? reportData.revenue.toLocaleString()
//                                 : "-"}
//                             </td>
//                           </>
//                         )}
//                       </tr>
//                     ))}
//                     {renderTotalsRow("Total Dorms", dormTotals)}

//                     {item.report.map((reportData, idx) => (
//                       <tr key={uuidv4()}>
//                         {idx === 0 && (
//                           <>
//                             <td
//                               rowSpan={item.report.length}
//                               className="align-middle font-weight-bold"
//                             >
//                               {item.hostelName}
//                             </td>
//                             <td
//                               rowSpan={item.report.length}
//                               className="align-middle font-weight-bold"
//                             >
//                               {item.month >= 1 && item.month <= 12
//                                 ? `${months[item.month - 1]} - ${item.year}`
//                                 : "-"}
//                             </td>
//                           </>
//                         )}

//                         {!reportData.roomCategory
//                           ?.toLowerCase()
//                           .includes("dorm") && (
//                           <>
//                             <td>{reportData.roomCategory || "NA"}</td>
//                             <td>
//                               {reportData.availableNights?.toLocaleString() ??
//                                 "-"}
//                             </td>
//                             <td>
//                               {reportData.soldNights?.toLocaleString() ?? "-"}
//                             </td>
//                             <td>
//                               {reportData.occupancy !== null
//                                 ? reportData.occupancy.toFixed(2)
//                                 : "-"}
//                             </td>
//                             <td>
//                               {reportData.adr !== null
//                                 ? reportData.adr.toFixed(2)
//                                 : "-"}
//                             </td>
//                             <td>
//                               {reportData.revenue !== null
//                                 ? reportData.revenue.toLocaleString()
//                                 : "-"}
//                             </td>
//                           </>
//                         )}
//                       </tr>
//                     ))}
//                     {renderTotalsRow("Total Private", privateTotals)}

//                     {renderTotalsRow("Overall", finalTotals)}
//                   </React.Fragment>
//                 );
//               })}
//             </tbody>
//           </Table>
//         </Card>
//       </Col>
//     </Row>
//   );
// };

// export default RoomwiseADRReportTable;
