// ! Coded by Ashwin

import React, { useEffect, useState } from "react";
import { Col, Row, Input, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import {
  useHostelList,
  useCreateEmployeeProfile,
  useUpdateEmployeeProfile,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import SlideModal from "./slide-modal/index";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { dateFormatter } from "@utils";

import Flatpickr from "react-flatpickr";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [prefix, setPrefix] = useState(null);
  const [fullName, setFullName] = useState("");
  const [spouseFullName, setSpouseFullName] = useState("");
  const [fathersFullName, setFathersFullName] = useState("");
  const [empCode, setEmpCode] = useState("");
  const [dob, setDob] = useState(new Date());
  const [gender, setGender] = useState(null);
  const [bloodGroup, setBloodGroup] = useState(null);
  const [maritalStatus, setMaritalStatus] = useState(null);
  const [personalEmail, setPersonalEmail] = useState("");
  const [presentAddressLine1, setPresentAddressLine1] = useState("");
  const [presentAddressLine2, setPresentAddressLine2] = useState("");
  const [presentCity, setPresentCity] = useState("");
  const [presentState, setPresentState] = useState(null);
  const [presentPostalCode, setPresentPostalCode] = useState("");
  const [presentCountry, setPresentCountry] = useState(null);
  const [permanentAddressLine1, setPermanentAddressLine1] = useState("");
  const [permanentAddressLine2, setPermanentAddressLine2] = useState("");
  const [permanentCity, setPermanentCity] = useState("");
  const [permanentState, setPermanentState] = useState(null);
  const [permanentPostalCode, setPermanentPostalCode] = useState("");
  const [permanentCountry, setPermanentCountry] = useState(null);
  const [mobileNo, setMobileNo] = useState("");
  const [skilledType, setSkilledType] = useState(null);
  const [doj, setDoj] = useState(new Date());
  const [empType, setEmpType] = useState(null);
  const [empStatus, setEmpStatus] = useState(null);
  const [address, setAddress] = useState(false);
  const [department, setDepartment] = useState(null);
  const [subDepartment, setSubDepartment] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [currentCTC, setCurrentCTC] = useState(null);
  const [agencyName, setAgencyName] = useState(null);
  const [aadhaar, setAadhaar] = useState("");

  const { data: allHostelsData } = useHostelList();
  const [createEmployeeProfileHandler, { data: createEmployeeProfileData }] =
    useCreateEmployeeProfile();
  const [updateEmployeeProfileHandler, { data: updateEmployeeProfileData }] =
    useUpdateEmployeeProfile();

  const dateFormat = "d/m/Y";
  const options = {
    dateFormat: dateFormat,
  };

  const PrefixList = [
    { label: "Mr.", value: "Mr." },
    { label: "Ms.", value: "Ms." },
    { label: "Mrs.", value: "Mrs." },
  ];

  const GenderList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];

  const BloodGroupList = [
    { label: "O+", value: "O+" },
    { label: "O-", value: "O-" },
    { label: "A+", value: "A+" },
    { label: "A-", value: "A-" },
    { label: "B+", value: "B+" },
    { label: "B-", value: "B-" },
    { label: "AB+", value: "AB+" },
    { label: "AB-", value: "AB-" },
  ];

  const MaritalStatusList = [
    { label: "Single", value: "Single" },
    { label: "Married", value: "Married" },
    { label: "Divorce", value: "Divorce" },
  ];

  const SkilledTypeList = [
    { label: "Skilled", value: "Skilled" },
    { label: "Semi skilled", value: "Semi skilled" },
    { label: "Unskilled", value: "Unskilled" },
  ];

  const EmploymentTypeList = [
    { label: "Agency", value: "Agency" },
    { label: "Company", value: "Company" },
  ];

  const EmploymentStatusList = [
    { label: "Probation", value: "Probation" },
    { label: "Confirmed", value: "Confirmed" },
    { label: "Absconding", value: "Absconding" },
    { label: "Resigned", value: "Resigned" },
    { label: "Termination", value: "Termination" },
  ];

  const DepartmentList = [{ label: "Operations", value: "Operations" }];

  const SubDepartmentList = [
    { label: "Front Office", value: "Front Office" },
    { label: "Housekeeping", value: "Housekeeping" },
    { label: "Kitchen", value: "Kitchen" },
    { label: "Security", value: "Security" },
    { label: "Community", value: "Community" },
    { label: "Repair & Maintenance", value: "Repair & Maintenance" },
    { label: "Other", value: "Other" },
  ];

  const DesignationList = [
    { label: "Senior General Manager", value: "Senior General Manager" },
    { label: "General Manager", value: "General Manager" },
    {
      label: "Assistant General Manager",
      value: "Assistant General Manager",
    },
    { label: "Front Desk Manager", value: "Front Desk Manager" },
    { label: "Front Desk Executive", value: "Front Desk Executive" },
    { label: "Front Desk Associate", value: "Front Desk Associate" },
    { label: "Cafe Manager", value: "Cafe Manager" },
    { label: "Assistant Cafe Manager", value: "Assistant Cafe Manager" },
    { label: "Cafe Executive", value: "Cafe Executive" },
    { label: "Head Chef", value: "Head Chef" },
    { label: "Chef", value: "Chef" },
    { label: "Bartender", value: "Bartender" },
    { label: "Helper", value: "Helper" },
    { label: "Server", value: "Server" },
    { label: "Utility (UT)", value: "Utility (UT)" },
    { label: "HK Supervisor", value: "HK Supervisor" },
    { label: "HK Executive", value: "HK Executive" },
    { label: "HK Associate", value: "HK Associate" },
    { label: "Multi Skilled Technician", value: "Multi Skilled Technician" },
    {
      label: "Multi Skilled Technician Supervisor",
      value: "Multi Skilled Technician Supervisor",
    },
    { label: "Security Guard", value: "Security Guard" },
    { label: "Intern", value: "Intern" },
    { label: "Others", value: "Others" },
    { label: "Community Manager", value: "Community Manager" },
  ];

  const IndianStatesUTList = [
    {
      value: "Andaman and Nicobar Islands",
      label: "Andaman and Nicobar Islands",
    },
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Dadra and Nagar Haveli", label: "Dadra and Nagar Haveli" },
    { value: "Daman and Diu", label: "Daman and Diu" },
    { value: "Delhi", label: "Delhi" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Lakshadweep", label: "Lakshadweep" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "West Bengal", label: "West Bengal" },
  ];

  const CountryList = [{ label: "India", value: "India" }];

  useEffect(() => {
    let HostelsList = allHostelsData?.getHostelList?.map((hostel) => {
      return { label: hostel?.name, value: hostel?._id };
    });
    setHostelsList(HostelsList);
  }, [allHostelsData]);

  useEffect(() => {
    if (
      createEmployeeProfileData?.createEmployeeProfile?.empCode ||
      updateEmployeeProfileData
    ) {
      refetch();
      handleToggle();

      // toaster("success", "Employee profile added successfully");
    }
  }, [createEmployeeProfileData, updateEmployeeProfileData]);
  const onSubmitHandler = () => {
    const data = {
      hostel: hostel?.value,
      prefix: prefix?.value,
      fullName,
      spouseFullName,
      fathersFullName,
      empCode,
      dob,
      gender: gender?.value,
      bloodGroup: bloodGroup?.value,
      maritalStatus: maritalStatus?.value,
      personalEmail,
      presentAddress: {
        addressLine1: presentAddressLine1,
        addressLine2: presentAddressLine2,
        city: presentCity,
        state: presentState?.value,
        postalCode: presentPostalCode,
        country: presentCountry?.value,
      },
      permanentAddress: {
        addressLine1: permanentAddressLine1,
        addressLine2: permanentAddressLine2,
        city: permanentCity,
        state: permanentState?.value,
        postalCode: permanentPostalCode,
        country: permanentCountry?.value,
      },
      mobileNo,
      skilledType: skilledType?.value,
      doj,
      empType: empType?.value,
      empStatus: empStatus?.value,
      currentCTC,
      department: department?.value,
      subDepartment: subDepartment?.value,
      designation: designation?.value,
      agencyName,
      aadhaar,
    };

    if (
      data.hostel &&
      data.prefix &&
      data.fullName &&
      data.fathersFullName &&
      data.empCode &&
      data.dob &&
      data.gender &&
      data.maritalStatus &&
      data.department &&
      data.subDepartment &&
      data.designation &&
      data.mobileNo &&
      data.skilledType &&
      data.doj &&
      data.empType &&
      data.empStatus &&
      data.aadhaar
    ) {
      if (editData?._id?.length) {
        const { _id } = editData;
        updateEmployeeProfileHandler(_id, data);
        toaster("success", "Employee profile updated successfully");
      } else {
        createEmployeeProfileHandler(data);
        toaster("success", "Employee profile added successfully");
      }
      setHostel(null);
      setPrefix(null);
      setFullName("");
      setSpouseFullName("");
      setFathersFullName("");
      setEmpCode("");
      setDob(new Date());
      setGender(null);
      setBloodGroup(null);
      setMaritalStatus(null);
      setPersonalEmail("");
      setPresentAddressLine1("");
      setPresentAddressLine2("");
      setPresentCity("");
      setPresentState(null);
      setPresentPostalCode("");
      setCurrentCTC(null);
      setPresentCountry(null);
      setPermanentAddressLine1("");
      setPermanentAddressLine2("");
      setPermanentCity("");
      setPermanentState(null);
      setPermanentPostalCode("");
      setPermanentCountry(null);
      setMobileNo("");
      setSkilledType(null);
      setDoj(new Date());
      setEmpType(null);
      setEmpStatus(null);
      setDepartment(null);
      setSubDepartment(null);
      setDesignation(null);
      setAgencyName(null);
      setAadhaar("");
    } else {
      toaster("error", "Please fill all the required details marked with *");
    }
  };
  useEffect(() => {
    if (editData?.empCode) {
      setHostel(
        editData?.hostel?.name
          ? { label: editData?.hostel?.name, value: editData?.hostel?._id }
          : null
      );
      setPrefix(
        editData?.prefix
          ? { label: editData?.prefix, value: editData?.prefix }
          : null
      );
      setFullName(editData?.fullName !== null ? editData?.fullName : "");
      setSpouseFullName(
        editData?.spouseFullName !== null ? editData?.spouseFullName : ""
      );
      setFathersFullName(
        editData?.fathersFullName !== null ? editData?.fathersFullName : ""
      );
      setEmpCode(editData?.empCode !== null ? editData?.empCode : "");
      setDob(editData?.dob);
      setGender(
        editData?.gender
          ? { label: editData?.gender, value: editData?.gender }
          : null
      );
      setBloodGroup(
        editData?.bloodGroup !== null
          ? { label: editData?.bloodGroup, value: editData?.bloodGroup }
          : null
      );
      setMaritalStatus(
        editData?.maritalStatus
          ? { label: editData?.maritalStatus, value: editData?.maritalStatus }
          : null
      );
      setPersonalEmail(
        editData?.personalEmail !== null ? editData?.personalEmail : ""
      );
      setPresentAddressLine1(
        editData?.presentAddress?.addressLine1 !== null
          ? editData?.presentAddress?.addressLine1
          : ""
      );
      setPresentAddressLine2(
        editData?.presentAddress?.addressLine2 !== null
          ? editData?.presentAddress?.addressLine2
          : ""
      );
      setPresentCity(
        editData?.presentAddress?.city !== null
          ? editData?.presentAddress?.city
          : ""
      );
      setPresentState(
        editData?.presentAddress?.state !== null
          ? {
              label: editData?.presentAddress?.state,
              value: editData?.presentAddress?.state,
            }
          : null
      );
      setPresentPostalCode(
        editData?.presentAddress?.postalCode !== null
          ? editData?.presentAddress?.postalCode
          : ""
      );
      setCurrentCTC(
        editData?.currentCTC !== null ? editData?.currentCTC : null
      );
      setPresentCountry(
        editData?.presentAddress?.country !== null
          ? {
              label: editData?.presentAddress?.country,
              value: editData?.presentAddress?.country,
            }
          : ""
      );
      setPermanentAddressLine1(
        editData?.permanentAddress?.addressLine1 !== null
          ? editData?.permanentAddress?.addressLine1
          : ""
      );
      setPermanentAddressLine2(
        editData?.permanentAddress?.addressLine2 !== null
          ? editData?.permanentAddress?.addressLine2
          : ""
      );
      setPermanentCity(
        editData?.permanentAddress?.city !== null
          ? editData?.permanentAddress?.city
          : ""
      );
      setPermanentState(
        editData?.permanentAddress?.state !== null
          ? {
              label: editData?.permanentAddress?.state,
              value: editData?.permanentAddress?.state,
            }
          : null
      );
      setPermanentPostalCode(
        editData?.permanentAddress?.postalCode !== null
          ? editData?.permanentAddress?.postalCode
          : ""
      );
      setPermanentCountry(
        editData?.permanentAddress?.country !== null
          ? {
              label: editData?.permanentAddress?.country,
              value: editData?.permanentAddress?.country,
            }
          : ""
      );
      setMobileNo(editData?.mobileNo !== null ? editData?.mobileNo : "");
      setSkilledType(
        editData?.skilledType
          ? { label: editData?.skilledType, value: editData?.skilledType }
          : null
      );
      setDoj(editData?.doj);
      setEmpType(
        editData?.empType
          ? { label: editData?.empType, value: editData?.empType }
          : null
      );
      setEmpStatus(
        editData?.empStatus
          ? { label: editData?.empStatus, value: editData?.empStatus }
          : null
      );
      setDepartment(
        editData?.department
          ? { label: editData?.department, value: editData?.department }
          : null
      );
      setSubDepartment(
        editData?.subDepartment
          ? { label: editData?.subDepartment, value: editData?.subDepartment }
          : null
      );
      setDesignation(
        editData?.designation
          ? { label: editData?.designation, value: editData?.designation }
          : null
      );
      setAgencyName(editData?.agencyName !== null ? editData?.agencyName : "");
      setAadhaar(editData?.aadhaar !== null ? editData?.aadhaar : "");
    } else {
      setHostel(null);
      setPrefix(null);
      setFullName("");
      setSpouseFullName("");
      setFathersFullName("");
      setEmpCode("");
      setDob(new Date());
      setGender(null);
      setBloodGroup(null);
      setPersonalEmail("");
      setPresentAddressLine1("");
      setPresentAddressLine2("");
      setPresentCity("");
      setPresentState(null);
      setPresentPostalCode("");
      setCurrentCTC(null);
      setPresentCountry(null);
      setPermanentAddressLine1("");
      setPermanentAddressLine2("");
      setPermanentCity("");
      setPermanentState(null);
      setPermanentPostalCode("");
      setPermanentCountry(null);
      setMaritalStatus(null);
      setMobileNo("");
      setSkilledType(null);
      setDoj(new Date());
      setEmpType(null);
      setEmpStatus(null);
      setDepartment(null);
      setSubDepartment(null);
      setDesignation(null);
      setAgencyName(null);
      setAadhaar("");
    }
  }, [editData]);

  const userRole = localStorage.getItem("role");

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
      userRole={userRole}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          {/* // ! Hostel */}
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="hostel">
                  Hostel*
                </Label>
              </strong>
              <Select
                isClearable={false}
                isSearchable={true}
                id="hostel"
                name="hostel"
                options={HostelsList}
                value={hostel}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! prefix */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="prefix">
                  Prefix*
                </Label>
              </strong>
              <Select
                isClearable={false}
                id="prefix"
                name="prefix"
                value={prefix}
                options={PrefixList}
                onChange={(value) => setPrefix(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! fullName */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="fullName">
                  Full Name*
                </Label>
              </strong>
              <Input
                type="text"
                value={fullName}
                id="fullName"
                name="fullName"
                placeholder="Add full name"
                onChange={(e) => setFullName(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/*// ! spouseFullName */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="spouseFullName">
                  Spouse Full Name
                </Label>
              </strong>
              <Input
                type="text"
                value={spouseFullName}
                id="spouseFullName"
                name="spouseFullName"
                placeholder="Add spouse full name"
                onChange={(e) => setSpouseFullName(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/*// ! fathersFullName */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="fathersFullName">
                  Father's Full Name*
                </Label>
              </strong>
              <Input
                type="text"
                value={fathersFullName}
                id="fathersFullName"
                name="fathersFullName"
                placeholder="Add fathers full name"
                onChange={(e) => setFathersFullName(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/*// ! empCode */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="empCode">
                  Employee Code*
                </Label>
              </strong>
              <Input
                type="text"
                value={empCode}
                id="empCode"
                name="empCode"
                placeholder="Add emp. code"
                onChange={(e) => setEmpCode(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! dob */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="dob">
                  Date of birth*
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={dob}
                onChange={(date) =>
                  setDob(new Date(dateFormatter(new Date(date))))
                }
                options={{
                  ...options,
                }}
                id="dob"
                placeholder="select date of birth"
              />
            </FormGroup>
          </Col>

          {/*// ! gender */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="gender">
                  Gender*
                </Label>
              </strong>
              <Select
                isClearable={false}
                id="gender"
                name="gender"
                value={gender}
                options={GenderList}
                onChange={(value) => setGender(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! bloodGroup */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="bloodGroup">
                  Blood Group
                </Label>
              </strong>
              <Select
                isClearable={false}
                id="bloodGroup"
                name="bloodGroup"
                value={bloodGroup}
                options={BloodGroupList}
                onChange={(value) => setBloodGroup(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! maritalStatus */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="maritalStatus">
                  Marital status*
                </Label>
              </strong>
              <Select
                isClearable={false}
                id="maritalStatus"
                name="maritalStatus"
                value={maritalStatus}
                options={MaritalStatusList}
                onChange={(value) => setMaritalStatus(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! personalEmail */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="personalEmail">
                  Personal Email
                </Label>
              </strong>
              <Input
                type="email"
                value={personalEmail}
                id="personalEmail"
                name="personalEmail"
                placeholder="Add personal email"
                onChange={(e) => setPersonalEmail(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/*// ! department */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="department">
                  Department*
                </Label>
              </strong>
              <Select
                isClearable={false}
                id="department"
                name="department"
                value={department}
                options={DepartmentList}
                onChange={(value) => setDepartment(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! subDepartment */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="subDepartment">
                  Sub department*
                </Label>
              </strong>
              <Select
                isClearable={false}
                id="subDepartment"
                name="subDepartment"
                value={subDepartment}
                options={SubDepartmentList}
                onChange={(value) => setSubDepartment(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! designation */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="designation">
                  Designation*
                </Label>
              </strong>
              <Select
                isClearable={false}
                id="designation"
                name="designation"
                value={designation}
                options={DesignationList}
                onChange={(value) => setDesignation(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="aadhaar">
                  Aadhaar*
                </Label>
              </strong>
              <Input
                type="text"
                value={aadhaar}
                id="aadhaar"
                name="aadhaar"
                placeholder="Add aadhaar"
                maxLength="12"
                required
                onChange={(e) => setAadhaar(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/*// ! presentAddress */}
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="presentAddress">
                  Present Address
                </Label>
              </strong>
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="presentAddressLine1">
                    Address Line 1
                  </Label>
                  <Input
                    type="text"
                    value={presentAddressLine1}
                    id="presentAddressLine1"
                    name="presentAddressLine1"
                    placeholder="Add address line 1"
                    onChange={(e) => setPresentAddressLine1(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="presentAddressLine2">
                    Address Line 2
                  </Label>
                  <Input
                    type="text"
                    value={presentAddressLine2}
                    id="presentAddressLine2"
                    name="presentAddressLine2"
                    placeholder="Add address line 2"
                    onChange={(e) => setPresentAddressLine2(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="presentCity">
                  City
                </Label>
              </strong>

              <Input
                type="text"
                value={presentCity}
                id="presentCity"
                name="presentCity"
                placeholder="Add city"
                onChange={(e) => setPresentCity(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="presentState">
                  State
                </Label>
              </strong>

              <Select
                isClearable={false}
                id="presentState"
                name="presentState"
                value={presentState}
                options={IndianStatesUTList}
                onChange={(value) => setPresentState(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                {" "}
                <Label className="form-label" for="presentPostalCode">
                  Postal code
                </Label>
              </strong>

              <Input
                type="text"
                value={presentPostalCode}
                id="presentPostalCode"
                name="presentPostalCode"
                placeholder="Add postal code"
                onChange={(e) => setPresentPostalCode(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="presentCountry">
                Country
              </Label>
              <Select
                isClearable={false}
                id="presentCountry"
                name="presentCountry"
                value={presentCountry}
                options={CountryList}
                onChange={(value) => setPresentCountry(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! address*/}
          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="address">
                  Same as present address
                </Label>
              </strong>
              <Input
                style={{ marginLeft: "20px" }}
                type="checkbox"
                value={address}
                id="address"
                name="address"
                placeholder="Same as present address"
                onChange={(e) => {
                  setPermanentAddressLine1(presentAddressLine1);
                  setPermanentAddressLine2(presentAddressLine2);
                  setPermanentCity(presentCity);
                  setPermanentState(presentState);
                  setPermanentPostalCode(presentPostalCode);
                  setPermanentCountry(presentCountry);
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="permanentAddress">
                  Permanent Address
                </Label>
              </strong>
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="permanentAddressLine1">
                    Address Line 1
                  </Label>
                  <Input
                    type="text"
                    value={permanentAddressLine1}
                    id="permanentAddressLine1"
                    name="permanentAddressLine1"
                    placeholder="Add address line 1"
                    onChange={(e) => setPermanentAddressLine1(e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="permanentAddressLine2">
                    Address Line 2
                  </Label>
                  <Input
                    type="text"
                    value={permanentAddressLine2}
                    id="permanentAddressLine2"
                    name="permanentAddressLine2"
                    placeholder="Add address line 2"
                    onChange={(e) => setPermanentAddressLine2(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="permanentCity">
                  City
                </Label>
              </strong>

              <Input
                type="text"
                value={permanentCity}
                id="permanentCity"
                name="permanentCity"
                placeholder="Add city"
                onChange={(e) => setPermanentCity(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                {" "}
                <Label className="form-label" for="permanentState">
                  State
                </Label>
              </strong>

              <Select
                isClearable={false}
                id="permanentState"
                name="permanentState"
                value={permanentState}
                options={IndianStatesUTList}
                onChange={(value) => setPermanentState(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="permanentPostalCode">
                  Postal code
                </Label>
              </strong>

              <Input
                type="text"
                value={permanentPostalCode}
                id="permanentPostalCode"
                name="permanentPostalCode"
                placeholder="Add postal code"
                onChange={(e) => setPermanentPostalCode(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="permanentCountry">
                Country
              </Label>
              <Select
                isClearable={false}
                id="permanentCountry"
                name="permanentCountry"
                value={permanentCountry}
                options={CountryList}
                onChange={(value) => setPermanentCountry(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! mobileNo */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="mobileNo">
                  Mobile number*
                </Label>
              </strong>
              <Input
                type="text"
                value={mobileNo}
                id="mobileNo"
                name="mobileNo"
                placeholder="Add mobile number"
                onChange={(e) => setMobileNo(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/*// ! skilledType */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="skilledType">
                  Skilled Type*
                </Label>
              </strong>
              <Select
                isClearable={false}
                id="skilledType"
                name="skilledType"
                value={skilledType}
                options={SkilledTypeList}
                onChange={(value) => setSkilledType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! doj */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="expiry date">
                  Date of joining*
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={doj}
                onChange={(date) =>
                  setDoj(new Date(dateFormatter(new Date(date))))
                }
                options={{
                  ...options,
                }}
                id="doj"
                placeholder="Select date of joining"
              />
            </FormGroup>
          </Col>

          {/*// ! empType */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="empType">
                  Employment Type*
                </Label>
              </strong>
              <Select
                isClearable={false}
                id="empType"
                name="empType"
                value={empType}
                options={EmploymentTypeList}
                onChange={(value) => setEmpType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          {/* <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="mobileNo">
                  Current CTC*
                </Label>
              </strong>
              <Input
                type="text"
                value={currentCTC}
                id="presentPostalCode"
                name="presentPostalCode"
                placeholder="Current CTC"
                onChange={(e) => {
                  if (!isNaN(Number(e.target.value))) {
                    setCurrentCTC(Number(e.target.value));
                  } else {
                    console.error("Please enter number only.");
                  }
                }}
              />
            </FormGroup>
          </Col>
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="mobileNo">
                  Next Appraisal Date*
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={doj}
                disabled
                options={{
                  ...options,
                }}
                id="doj"
                placeholder="Next appraisal date"
              />
            </FormGroup>
          </Col> */}
          <Col sm="6">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="empStatus">
                  Employment Status*
                </Label>
              </strong>
              <Select
                isClearable={false}
                id="empStatus"
                name="empStatus"
                value={empStatus}
                options={EmploymentStatusList}
                onChange={(value) => setEmpStatus(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {empType?.value === "Agency" && (
            <Col sm="6">
              <FormGroup className="w-100">
                <strong>
                  <Label className="form-label" for="agencyName">
                    Agency Name
                  </Label>
                </strong>
                <Input
                  type="text"
                  value={agencyName}
                  id="agencyName"
                  name="agencyName"
                  placeholder="Add agency name"
                  onChange={(e) => setAgencyName(e.target.value)}
                />
              </FormGroup>
            </Col>
          )}
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
