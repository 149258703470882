import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useMutation } from "@apollo/client";

// ! createEmployeeProfile added by Ashwin
export const CREATE_EMPLOYEE_PROFILE = gql`
  mutation createEmployeeProfile($employeeProfileInput: EmployeeProfileInput) {
    createEmployeeProfile(employeeProfileInput: $employeeProfileInput) {
      empCode
    }
  }
`;

// ! updateEmployeeProfile added by Ashwin
export const UPDATE_EMPLOYEE_PROFILE = gql`
  mutation updateEmployeeProfile(
    $id: ID!
    $employeeProfileUpdate: EmployeeProfileInput
  ) {
    updateEmployeeProfile(
      id: $id
      employeeProfileUpdate: $employeeProfileUpdate
    ) {
      empCode
    }
  }
`;

export const UPDATE_EMPLOYEE_TRANSFER_DETAILS = gql`
  mutation (
    $id: ID
    $employeeTransferUpdate: EmployeeTransferInput
    $fromAppraisal: Boolean
    $fromTransfer: Boolean
  ) {
    updateEmployeeTransferDetails(
      id: $id
      employeeTransferUpdate: $employeeTransferUpdate
      fromAppraisal: $fromAppraisal
      fromTransfer: $fromTransfer
    ) {
      _id
      dateOfExit
      empStatus
      exitReason
      hostel {
        _id
        name
      }
      resignedStatus
      transferDate
    }
  }
`;

export const CREATE_EMPLOYEE_ATTENDANCE = gql`
  mutation ($employeeAttendanceInput: [EmployeeAttendanceInput]) {
    createEmployeeAttendance(
      employeeAttendanceInput: $employeeAttendanceInput
    ) {
      status
    }
  }
`;

export const CREATE_EMPLOYEE_LEAVE_BALANCE = gql`
  mutation createEmployeeLeaveBalance(
    $employeeLeaveBalanceInput: EmployeeLeaveBalanceInput
  ) {
    createEmployeeLeaveBalance(
      employeeLeaveBalanceInput: $employeeLeaveBalanceInput
    ) {
      balance
    }
  }
`;

// ! updateEmployeeLeaveBalance added by Ashwin
export const UPDATE_EMPLOYEE_LEAVE_BALANCE = gql`
  mutation updateEmployeeLeaveBalance(
    $id: ID!
    $employeeLeaveBalanceUpdate: EmployeeLeaveBalanceInput
  ) {
    updateEmployeeLeaveBalance(
      id: $id
      employeeLeaveBalanceUpdate: $employeeLeaveBalanceUpdate
    ) {
      balance
    }
  }
`;

export const SAVE_EMPLOYEE_PAYSLIP = gql`
  mutation ($paySlipInput: paySlipInput) {
    saveEmployeePaySlip(paySlipInput: $paySlipInput) {
      empId
      payslip
    }
  }
`;

export const SAVE_MULTIPLE_PAYSLIP = gql`
  mutation ($multipleFileInput: multipleFileInput) {
    saveBulkEmployeePaySlip(multipleFileInput: $multipleFileInput)
  }
`;

export const UPLOAD_XLSX_FILE_EMPLOYEE_PROFILE = gql`
  mutation ($file: Upload) {
    uploadXlsxFileForEmployeeProfile(file: $file) {
      status
      message
    }
  }
`;

export const UPLOAD_XLSX_FILE_EMPLOYEE_TRAINING_SCORE = gql`
  mutation ($file: Upload) {
    uploadXlsxFileForEmployeeTrainingScore(file: $file) {
      status
      message
    }
  }
`;
export const UPLOAD_XLSX_FILE_EMPLOYEE_PERFORMANCE_EVAL = gql`
  mutation ($file: Upload) {
    uploadXlsxFileForEmployeePerformanceEval(file: $file) {
      status
      message
    }
  }
`;
export const GET_XLSX_FORMAT_FOR_EMPLOYEE = gql`
  mutation getXlsxFormatforEmployOperations($operationName: String) {
    getXlsxFormatforEmployOperations(operationName: $operationName)
  }
`;

export const UPLOAD_XLSX_FILE_EMPLOYEE_SALARY_STRUCTURE = gql`
  mutation ($file: Upload) {
    uploadXlsxFileForEmployeeSalaryStructure(file: $file) {
      status
      message
    }
  }
`;

export const GET_ATTENDANCE_DATA_EXPORT = gql`
  mutation ($month: Int, $year: Int) {
    getAttendanceXlsxExport(month: $month, year: $year)
  }
`;

export const UPLOAD_XLSX_EMPLOYEE_MONTHLY_VALUES = gql`
  mutation ($file: Upload) {
    uploadMonthlyValuesAndCalculateMonthlySalary(file: $file)
  }
`;

export const GET_PROCESSED_SALARIES_EXPORT = gql`
  mutation ($month: Int, $year: Int) {
    getProcessedPaySheet(month: $month, year: $year)
  }
`;
export const UPLOAD_BULK_EMPLOYEE_DOCUMENTS = gql`
  mutation ($file: Upload) {
    uploadBulkEmployeeDocuments(file: $file)
  }
`;

export const GET_EMPLOYEE_PROFILE_REPORT = gql`
  mutation getEmployeeProfileReport($filter: filter) {
    getEmployeeProfileReport(filter: $filter)
  }
`;


export const useGetEmployeeProfileReport = () => {
  const [getEmployeeProfileReportData, { loading, error, data, refetch }] =
    useMutation(GET_EMPLOYEE_PROFILE_REPORT);
  useLoadingHandler(loading);
  const getEmployeeProfileReportDataHandler = (filter) => {
    getEmployeeProfileReportData({
      variables: {
        filter: filter?.filteredData,
      },
    });
  };
  return [
    getEmployeeProfileReportDataHandler,
    { data, refetch, loading, error },
  ];
};

export const PUBLISH_EMPLOYEE_PAYSLIP = gql`
mutation publishMonthlyPayslip($month:Int, $year:Int, $publish:Boolean){
    publishMonthlyPayslip(month:$month, year:$year, publish:$publish)
}
`

export const usePublishMonthlyPayslip = () => {
  const [publishMonthlyPayslip, { loading, error, data, refetch }] =
    useMutation(PUBLISH_EMPLOYEE_PAYSLIP);
  useLoadingHandler(loading);
  const publishMonthlyPayslipHandler = (month, year, publish) => {
    publishMonthlyPayslip({
      variables: {
        month, year, publish
      },
    });
  };
  return [
    publishMonthlyPayslipHandler,
    { data, refetch, loading, error },
  ];
}


export const UPDATE_PUBLISHED_PAYSHEET = gql`
mutation updatePublishedPayslip($month:Int, $year:Int, $publish:Boolean){
    updatePublishedPayslip(month:$month, year:$year, publish:$publish)
}
`

export const useUpdateMonthlyPaysheet = () => {
  const [updatePublishMonthlyPayslip, { loading, error, data, refetch }] =
    useMutation(UPDATE_PUBLISHED_PAYSHEET);
  useLoadingHandler(loading);
  const updatePublishMonthlyPayslipHandler = (month, year, publish) => {
    updatePublishMonthlyPayslip({
      variables: {
        month, year, publish
      },
    });
  };
  return [
    updatePublishMonthlyPayslipHandler,
    { data, refetch, loading, error }
  ];
}