import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_OTA_LOGIN = gql`
  query getOtaLogin {
    getOtaLogin {
      _id
      platform
      userName
      password
      brand
      createdAt
      updatedAt
      logs
    }
  }
`;

export const useGetOtaLogin = () => {
  const { loading, error, data, refetch } = useQuery(GET_OTA_LOGIN);

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
