import React, { useEffect, useState } from "react";
import BlacklistedGuestsTable from "./BlacklistedGuestsTable";
import TablePagination from "@components/common/TablePagination";
import { useHistory } from "react-router";
import AddEditModal from "./AddEditModal";
import { Button, Col, FormGroup, Label, Row, Input } from "reactstrap";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import { useGetPaginatedBlacklistedGuests } from "../../../api/WebApp/BlacklistedGuests/Queries";
import { useHostelList } from "../../../api/dataSource";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";

const BlacklistedGuests = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState([]);
  const [type, setType] = useState(null);
  const [severity, setSeverity] = useState(null);
  const [blacklistedStatus, setBlacklistedStatus] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [reservationID, setReservationID] = useState(null);
  const [documentID, setDocumentID] = useState(null);
  const [blacklistedGuestsStartDateRange, setBlacklistedGuestsStartDateRange] =
    useState(null);
  const [blacklistedGuestsEndDateRange, setBlacklistedGuestsEndDateRange] =
    useState(null);
  const [filterFields, setFilterFields] = useState({
    hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
  });

  const [openModal, setOpenModal] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [id, setId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState(null);
  const { data: allHostelsData } = useHostelList();
  const { data, loading, refetch } = useGetPaginatedBlacklistedGuests(
    limit,
    page,
    filterFields
  );

  const history = useHistory();

  const [blacklistID, setBlacklistID] = useState(null);

  const userRole = localStorage.getItem("role");

  const userDepartment = localStorage.getItem("department");

  const severityList = [
    { label: "Low", value: "Low" },
    // { label: "Moderate", value: "Moderate" },
    { label: "High", value: "High" },
  ];

  const statusList = [
    { label: "Pending", value: "Pending" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
    { label: "Reversed", value: "Reversed" },
  ];

  const typeList = [
    { label: "Property damage", value: "Property damage" },
    { label: "Misconduct", value: "Misconduct" },
    { label: "Illegal activities", value: "Illegal activities" },
    { label: "Non-payment", value: "Non-payment" },
    { label: "Fraudulent activity", value: "Fraudulent activity" },
    { label: "Policy violation", value: "Policy violation" },
    { label: "Abusive behavior", value: "Abusive behavior" },
    { label: "Disturbance", value: "Disturbance" },
    { label: "Safety concern", value: "Safety concern" },
    { label: "Repeated complaints", value: "Repeated complaints" },
    { label: "Defamation", value: "Defamation" },
  ];

  const count = data?.getPaginatedBlacklistedGuests?.count;

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  useEffect(() => {
    if (data?.getPaginatedBlacklistedGuests?.data?.length > 0) {
      setTableData(data?.getPaginatedBlacklistedGuests?.data);
    } else {
      setTableData([]);
    }

    setTotalPage(Math.ceil(data?.getPaginatedBlacklistedGuests?.count / limit));
  }, [
    data?.getPaginatedBlacklistedGuests?.data,
    data?.getPaginatedBlacklistedGuests?.count,
    limit,
  ]);

  // useEffect(() => {
  //   const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
  //   if (allHostelsData?.getHostelList?.length) {
  //     const filteredHostels =
  //       allowedHostels !== null && allowedHostels?.length !== 0
  //         ? allowedHostels?.map((hostelID) => {
  //             return allHostelsData?.getHostelList?.find(
  //               (item) => item?._id === hostelID
  //             );
  //           })
  //         : allHostelsData?.getHostelList;
  //     let HostelsList = filteredHostels?.map((item) => {
  //       return { label: item?.name, value: item?._id };
  //     });
  //     setHostelsList(HostelsList);
  //   }
  // }, [allHostelsData?.getHostelList, limit, page]);

  useEffect(() => {
    if (allHostelsData?.getHostelList?.length) {
      let HostelsList = allHostelsData?.getHostelList?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList, limit, page]);

  useEffect(() => {
    if (id && data?.getPaginatedBlacklistedGuests?.data?.length > 0) {
      let findData = data?.getPaginatedBlacklistedGuests?.data?.find(
        (item) => item._id === id
      );
      setEditData(findData);
      setId(id);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedBlacklistedGuests?.data, id]);

  console.log(filterFields, "Filter fields");

  const handleSearch = () => {
    const filterFields = {
      hostelIdArray:
        hostel.length > 0
          ? hostel?.map((hostel) => hostel?.value)
          : JSON.parse(localStorage.getItem("hostels")),

      type: type?.map((item) => item?.value),
      severity: severity?.map((item) => item?.value),
      blacklistedStatus: blacklistedStatus?.map((item) => item?.value),
      name,
      email,
      mobile,
      documentID,
      reservationID,
      blacklistedGuestsStartDateRange,
      blacklistedGuestsEndDateRange,
      blacklistID,
    };
    setPage(1);
    setFilterFields(filterFields);
  };

  const handleReset = () => {
    setFilterFields({
      hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
    });

    setHostel([]);
    setType(null);
    setSeverity(null);
    setBlacklistedStatus(null);
    setName("");
    setEmail("");
    setMobile("");
    setReservationID("");
    setDocumentID("");
    setBlacklistedGuestsStartDateRange(null);
    setBlacklistedGuestsEndDateRange(null);
    setBlacklistID("");
  };

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/webApp/edit/blacklistedGuests/${id}`);
    } else {
      setId(id);
    }
  };

  const handleUpdateBlacklistedGuests = (id) => {
    history.push(`/webApp/edit/blacklistedGuests/${id}`);
  };

  return (
    localStorage.getItem("modules")?.includes("Glu Reports") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Hostel</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                closeMenuOnSelect={false}
                options={hostelsList}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="severity">
                <strong>Severity</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="severity"
                name="severity"
                options={severityList}
                value={severity}
                onChange={(value) => setSeverity(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="blacklistedStatus">
                <strong>Status</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="blacklistedStatus"
                name="blacklistedStatus"
                options={statusList}
                value={blacklistedStatus}
                onChange={(value) => setBlacklistedStatus(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="type">
                <strong>Reason</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="type"
                name="type"
                options={typeList}
                value={type}
                onChange={(value) => setType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="name">
                <strong>Guest Name</strong>
              </Label>

              <Input
                type="text"
                value={name}
                id="name"
                name="name"
                // placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="blacklistID">
                <strong> Blacklist ID</strong>
              </Label>
              <Input
                type="text"
                value={blacklistID}
                id="blacklistID"
                name="blacklistID"
                // placeholder="Blacklist ID"
                onChange={(e) => setBlacklistID(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="email">
                <strong>Email ID</strong>
              </Label>

              <Input
                type="text"
                value={email}
                id="email"
                name="email"
                // placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="mobile">
                <strong>Phone number</strong>
              </Label>

              <Input
                type="text"
                value={mobile}
                id="mobile"
                name="mobile"
                // placeholder="Mobile"
                onChange={(e) => setMobile(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="documentID">
                Document ID
              </Label>
            </strong>

            <Input
              type="text"
              value={documentID}
              id="documentID"
              name="documentID"
              // placeholder="Document ID"
              onChange={(e) => setDocumentID(e.target.value)}
            />
          </FormGroup>
        </Col> */}

          <Col sm="3">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="reservationID">
                  Reservation ID
                </Label>
              </strong>

              <Input
                type="text"
                value={reservationID}
                id="reservationID"
                name="reservationID"
                // placeholder="Reservation ID"
                onChange={(e) => setReservationID(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Date range</strong>
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={blacklistedGuestsStartDateRange}
                options={{
                  mode: "range",
                }}
                onChange={(date) => {
                  setBlacklistedGuestsStartDateRange(dateFormatter(date[0]));
                  setBlacklistedGuestsEndDateRange(dateFormatter(date[1]));
                }}
                id="checkinDate"
                isClearable={true}
              />
            </FormGroup>
          </Col>

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={handleSearch}
            >
              Search
            </Button>

            <Button
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>

        <BlacklistedGuestsTable
          setId={setId}
          id={id}
          openModal={() => setOpenModal(!openModal)}
          data={tableData}
          handleEdit={handleEdit}
          handleUpdateBlacklistedGuests={handleUpdateBlacklistedGuests}
          refetch={refetch}
          loading={loading}
          count={count}
        />
        <TablePagination
          setPage={setPage}
          page={page}
          totalPage={totalPage}
          loading={loading}
        />

        <AddEditModal
          setId={setId}
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
        />
      </div>
    )
  );
};

export default BlacklistedGuests;
