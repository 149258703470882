import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useQuery } from "@apollo/client";

export const GET_MONTHLY_REVENUE_SALES_REPORT = gql`
  query getMonthlyRevenueSalesReport(
    $fromDate: String!
    $toDate: String!
    $hostelId: [ID]!
  ) {
    getMonthlyRevenueSalesReport(
      fromDate: $fromDate
      toDate: $toDate
      hostelId: $hostelId
    ) {
      _id
      hostelName
      salesData
    }
  }
`;

export const useGetMonthlyRevenueSalesReport = (fromDate, toDate, hostelId) => {
  const { loading, error, data, refetch } = useQuery(
    GET_MONTHLY_REVENUE_SALES_REPORT,
    {
      variables: {
        fromDate,
        toDate,
        hostelId,
      },
      skip: !fromDate && !toDate && !hostelId ? true : false,
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const GET_MONTHLY_SALES_REPORT = gql`
  query getMonthlySalesReport($month: Int, $year: Int, $hostels: [ID]) {
    getMonthlySalesReport(month: $month, year: $year, hostels: $hostels) {
      hostelId
      hostelName
      month
      year
      report {
        roomCategory
        availableNights
        soldNights
        occupancy
        revenue
        adr
      }
      totalAvailableNights
      totalSoldNights
      totalRevenue
      totalOccupancy
      totalAdr
    }
  }
`;

export const useGetMonthlySalesReport = (month, year, hostels) => {
  const { loading, error, data, refetch } = useQuery(GET_MONTHLY_SALES_REPORT, {
    variables: {
      month,
      year,
      hostels,
    },
    skip: !month && !year && !hostels ? true : false,
  });

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
