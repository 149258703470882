import AuthRoutes from "./Auth";
import CmsRoutes from "./Cms";
import PackagesRoutes from "./Package";
import AmenitiesRoutes from "./Amenities";
// import SeoRoutes from "./Seo";
import RulesRoutes from "./Rules";
import OffersRoutes from "./Offers";
import FeedbackRoutes from "./Feedback";
import FeedbackFormRoutes from "./FeedbackForm"; //for feedback form
import FeedbackResponseRoutes from "./FeedbackResponse"; //for feedback Response

import CouponsRoutes from "./Coupon";
import StaffsRoutes from "./Staffs";
import UnpaidReservation from "./UnpaidReservation";
import PartnerNewRoutes from "./PartnerNew";
// import ChatbotBooking from "./ChatbotBooking";
// import WhatsappMessages from "./WhatsappMessages";
import ReportsRoutes from "./Report";
import AdminUsersRoutes from "./AdminUsers";
import CouponsGeneratorRoutes from "./CouponsGenerator";
import BlogRoutes from "./Blogs";
import WebCheckinRoutes from "./WebCheckin";
import CommunicationRoutes from "./Communications";
import WebAppRoutes from "./WebApp";
import HRRotes from "./HR";
import FileUploadRoutes from "./FileUpload";
import ORMRoutes from "./ORM";
import StepOutRoutes from "./StepOut";
// import ORMReviewsRoutes from "./ORMReviews";
// import GuestDatabaseRoutes from "./GuestDatabase";
import LoyaltyRoutes from "./Loyalty";
import UserRoutes from "./User";
import WalletRoutes from "./Wallet";
import OperationRoutes from "./Operations";
import ConnectRoutes from "./Connect";
import UnboxRoutes from "./Unbox";
import EzeeRoutes from "./Ezee";
import HostelComparisonsRoutes from "./HostelComparisons";
import MerchandiseRoutes from "./MerchandiseStore";
import CompetitorRatesRoutes from "./competitorRates";
import BookingDashboard from "./BookingDashboard";
import OTAHourlyReport from "./otaHourlyReport";
import RoomRatesComparison from "./RoomRatesComparsion";
import RefundRequestPostStayRoutes from "./RefundRequestPostStay";
import BusbookingRoutes from "./busBookings";

const TemplateTitle = "Hosteller Admin";

// const DefaultRoute = "/package/hostels";
const DefaultRoute = "/userProfile";

const Routes = [
  ...CmsRoutes,
  ...AuthRoutes,
  ...PackagesRoutes,
  // ...SeoRoutes,
  ...AmenitiesRoutes,
  ...RulesRoutes,
  ...OffersRoutes,
  ...FeedbackRoutes,

  // ! Coded by Ashwin
  ...CouponsRoutes,
  ...StaffsRoutes,
  ...FeedbackFormRoutes, //for feedback form
  ...FeedbackResponseRoutes, //for feedback Response
  ...UnpaidReservation, // ! Added by Ashwin
  ...PartnerNewRoutes, // ! Added by Ashwin
  // ...ChatbotBooking,  //?Added By Gaurav
  // ...WhatsappMessages, //?Added By Gaurav
  ...ReportsRoutes,
  ...AdminUsersRoutes, // ! Added by Ashwin
  ...CouponsGeneratorRoutes, // ! Added by Ashwin
  ...BlogRoutes,
  ...WebCheckinRoutes, // ! Added by Ashwin
  ...CommunicationRoutes, // ! Added by Ashwin
  // ...WebCheckinRoutes, // ! Added by Ashwin
  // ...GuestDatabaseRoutes, //Added by Nitish
  ...WebAppRoutes, // ! Added by Ashwin
  ...HRRotes,
  ...AdminUsersRoutes,
  ...FileUploadRoutes,
  ...ORMRoutes,
  ...StepOutRoutes,
  // ...ORMReviewsRoutes, //Added by Nitish,
  ...LoyaltyRoutes,
  ...UserRoutes,
  ...WalletRoutes,
  ...OperationRoutes,
  ...ConnectRoutes,
  ...UnboxRoutes,
  ...EzeeRoutes,
  ...HostelComparisonsRoutes,
  ...MerchandiseRoutes,
  ...CompetitorRatesRoutes, //added by Ashish
  ...BookingDashboard, //added by Ashish
  ...OTAHourlyReport, //added by Ashish
  ...RoomRatesComparison, //added by Ashish
  ...RefundRequestPostStayRoutes, //Added By Nitish
  ...BusbookingRoutes,
];

export { DefaultRoute, TemplateTitle, Routes };
