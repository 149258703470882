import React, { useEffect, useMemo, useState } from "react";
import Table from "./table";
import { generateDateRange, generateFormattedDates } from "./helpers";
import {
  useGetRoomRates,
  useUpdateRoomRates,
} from "../../api/CompetitorRates/CompetitorRatesDataSource";
import Filters from "./filter";
import {
  useGetAllCompetitorsName,
  useGetDatewiseOccupancy,
  useGetHostelsNameandId,
  useGetLatestLastUpdatedAt,
  useGetmaxDateForWhichDataisPresent,
  useGetRoomNames,
} from "../../api/dataSource";
import AppLoader from "./loader";
import toaster from "../../@core/components/common/Toaster";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import axios from "axios";

const RoomRatesComparison = () => {
  // Dates for the table header and date ranges for mapping
  const [datesToShowToTableHeader, setDatesToShowToTableHeader] = useState(
    generateFormattedDates(new Date(), 8)
  );
  const [dateRangesForMap, setDateRangesForMap] = useState(
    generateDateRange(new Date(), null, 8)
  );

  // Temporary state for date range selection
  const [tempDateRanges, setTempDateRanges] = useState(dateRangesForMap);

  // Filters
  const [selectedOccupancy, setSelectedOccupancy] = useState({});
  const [hostel, setHostel] = useState({}); // not a multiselect
  const [selectedCompetitors, setSelectedCompetitors] = useState([]);
  const [selectedRoomNames, setSelectedRoomNames] = useState([]);
  const [isLoadingDataFetcing, setIsLoadingDataFetcing] = useState(false);
  const { data: uniqueCompetitors } = useGetAllCompetitorsName();
  const getAllCompetitors = useMemo(() => {
    return uniqueCompetitors?.getUniqueCompetitors?.map((competitor) => ({
      value: competitor,
      label: competitor,
    }));
  }, [uniqueCompetitors]);

  const { getRoomNames, data: roomNamesData } = useGetRoomNames();
  // console.log(roomNamesData);
  const getRoomNamesOptions = useMemo(() => {
    return roomNamesData?.getUniqueRoomsByFilters?.map((roomName) => ({
      value: roomName,
      label: roomName,
    }));
  }, [roomNamesData]);

  // console.log(getRoomNamesOptions);
  const {
    getMaxDateForWhichTheDataIsPresent,
    data: _MaxDateForWhichTheDataIsPresent,
    refetch: refetchMaxDateForWhichTheDataIsPresent,
  } = useGetmaxDateForWhichDataisPresent();

  const {
    getLatestLastUpdatedAt,
    data: _lastUpdatedAt,
    refetch: refetchLastUpdatedAt,
  } = useGetLatestLastUpdatedAt();
  const lastUpdatedAt = _lastUpdatedAt?.getLatestLastUpdatedAt;
  // console.log(lastUpdatedAt);

  //not a requirment as of now
  // const isDataScrappedForToday = () =>{
  //   //compare the last updated at with the current date if the data is updated today then return true else false
  //   const today = new Date();

  // }

  const MaxDateForWhichTheDataIsPresent =
    _MaxDateForWhichTheDataIsPresent?.getMaxDateForWhichTheDataIsPresent;
  // console.log(lastUpdatedAt)

  const {
    getDateWiseOccupancy,
    data: _dateWiseOccupancy,
    refetch: refetchDateWiseOccupancy,
    loading: dateWiseOccupancyLoading,
  } = useGetDatewiseOccupancy();
  const dateWiseOccupancy = _dateWiseOccupancy?.getDateWiseOccupancy;

  const { updateRoomRates, loading } = useUpdateRoomRates();

  // useEffect(() => {
  //   if (!hostel?.value) return;
  //   getRoomNames({
  //     variables: {
  //       hostelId: hostel?.value,
  //     },
  //   });

  //   getMaxDateForWhichTheDataIsPresent({
  //     variables: {
  //       hostel: hostel?.value,
  //     },
  //   });
  //   getLatestLastUpdatedAt({
  //     variables: {
  //       hostel: hostel?.value,
  //     },
  //   });
  // }, [hostel]);

  // Variables for API call

  const {
    fetchRoomRates,
    isLoading,
    data,
    refetch: refetchRoomRates,
  } = useGetRoomRates();
  const roomData = data?.getCompetitorByRoomNamesByFilters?.data;
  // console.log(isLoading);
  // console.log(roomData);
  const { data: allHostelsData } = useGetHostelsNameandId();

  //test purpose
  // const handleFetch = () => {
  //   fetchRoomRates({
  //     variables: {
  //       filterRoomRates: {
  //         from: dateRangesForMap[0],
  //         to: dateRangesForMap.at(-1),
  //         hostels: "62725e2d5a1c65e71f472201",
  //         occupency: selectedOccupancy?.value ? selectedOccupancy?.value : 1,
  //         competitor: selectedCompetitors?.length
  //           ? selectedCompetitors.map((item) => item.value)
  //           : [],
  //         roomName: selectedRoomNames?.length
  //           ? selectedRoomNames.map((item) => item.value)
  //           : [],
  //       },
  //     },
  //   });
  // };

  const handleRefetch = async () => {
    if (!hostel?.value) return;
    await refetchRoomRates({
      variables: {
        filterRoomRates: {
          from: dateRangesForMap[0],
          to: dateRangesForMap.at(-1),
          hostels: hostel?.value,
          occupency: selectedOccupancy?.value ? selectedOccupancy?.value : null,
          roomName: selectedRoomNames?.length
            ? selectedRoomNames.map((item) => item.value)
            : [],
        },
      },
    });

    await refetchLastUpdatedAt({
      variables: {
        hostels: hostel?.value,
      },
    });

    await refetchMaxDateForWhichTheDataIsPresent({
      variables: {
        hostels: hostel?.value,
      },
    });

    await refetchDateWiseOccupancy({
      variables: {
        fromDate: dateRangesForMap[0],
        toDate: dateRangesForMap.at(-1),
        hostelId: hostel?.value,
      },
    });
  };

  // useEffect(() => {
  //   handleFetch();
  // }, []);

  const HostelsList = useMemo(() => {
    return allHostelsData?.getHostelsNameandId?.map((item) => ({
      value: item?._id,
      label: item?.name,
    }));
  }, [allHostelsData]);

  const handleDateRangeChange = (selectedDates) => {
    const dateRanges = generateDateRange(selectedDates[0], selectedDates[1]);
    setTempDateRanges(dateRanges); // Set temporary date range
  };

  const handleOccupancyChange = (selectedOption) => {
    setSelectedOccupancy(selectedOption);
  };

  const handleHostelChange = (selectedOption) => {
    setHostel(selectedOption);
  };

  const handleCompetitorChange = (selectedOptions) => {
    setSelectedCompetitors(selectedOptions);
  };

  const handleRoomChange = (selectedOptions) => {
    setSelectedRoomNames(selectedOptions);
  };

  const handleSync = () => {
    const checkinDate = dateRangesForMap[0];
    const checkoutDate = dateRangesForMap.at(-1);
    if (!hostel?.value) {
      return toaster("error", "Please select Hostel");
    }
    setIsLoadingDataFetcing(true);
    axios
      .get("https://api.thehosteller.com/rest/v1/jober/syncprices", {
        params: {
          checkinDate,
          checkoutDate,
          hostel: hostel?.value,
        },
      })
      .then((res) => {
        setIsLoadingDataFetcing(false);
        handleRefetch().then(() => {
          toaster("success", "Sync Successfull");
        });
      })
      .catch((err) => {
        setIsLoadingDataFetcing(false);
        toaster("error", "Sync Failed");
      });
  };

  const handleSearch = () => {
    if (!hostel?.value) {
      return toaster("error", "Please select Hostel");
    }
    // Update the date ranges and table header dates using tempDateRanges
    setDateRangesForMap((prevDateRanges) => {
      const newDateRanges = [...tempDateRanges]; // Use the latest tempDateRanges
      setDatesToShowToTableHeader(
        generateFormattedDates(newDateRanges[0], newDateRanges.length)
      );

      // Trigger the API call with updated filters
      fetchRoomRates({
        variables: {
          filterRoomRates: {
            hostel: hostel?.value,
            occupency: selectedOccupancy?.value
              ? selectedOccupancy?.value
              : null,
            from: newDateRanges[0],
            to: newDateRanges.at(-1),
            competitor: selectedCompetitors?.length
              ? selectedCompetitors.map((item) => item.value)
              : [],
            roomName: selectedRoomNames?.length
              ? selectedRoomNames.map((item) => item.value)
              : [],
          },
        },
      });

      //fetch the date wise occpancy
      getDateWiseOccupancy({
        variables: {
          fromDate: newDateRanges[0],
          toDate: newDateRanges.at(-1),
          hostelId: hostel?.value,
        },
      });

      return newDateRanges; // Update the state
    });

    getRoomNames({
      variables: {
        hostelId: hostel?.value,
      },
    });

    getMaxDateForWhichTheDataIsPresent({
      variables: {
        hostel: hostel?.value,
      },
    });
    getLatestLastUpdatedAt({
      variables: {
        hostel: hostel?.value,
      },
    });
  };

  const handleReset = () => {
    // Reset all filters and dates in one click
    setSelectedOccupancy({});
    setHostel({});

    const newDateRangesForMap = generateDateRange(new Date(), null, 8);
    setTempDateRanges(newDateRangesForMap); // Reset temp date range
    setDateRangesForMap(newDateRangesForMap); // Update the final date ranges
    setDatesToShowToTableHeader(generateFormattedDates(new Date(), 8));
    setSelectedCompetitors([]);
    setSelectedRoomNames([]);
    setSelectedOccupancy({});

    // Trigger the API with reset values :  ** optional **
    // fetchRoomRates({
    //   variables: {
    //     filterRoomRates: {
    //       from: newDateRangesForMap[0],
    //       to: newDateRangesForMap.at(-1),
    //       hostel: hostel?.value,
    //       occupency: null,
    //       competitor: [],
    //       roomName: [],
    //     },
    //   },
    // });
  };

  //   const handleReset = () => {
  //     setDatesToShowToTableHeader(generateFormattedDates(new Date(), 8));
  //     setDateRangesForMap(generateDateRange(new Date(), null, 8));
  //     setTempDateRanges(dateRangesForMap);
  //     setSelectedCompetitors([]);
  //     setSelectedPlatform(["All"]);
  //     setSelectedOccupancy({ value: 1, label: "1" });
  //     setHostel({});
  //   };

  const handlePriceUpdate = () => {
    if (!hostel?.value) {
      return toaster("error", "Please select Hostel");
    }
    // Update the date ranges and table header dates using tempDateRanges
    setDateRangesForMap((prevDateRanges) => {
      const newDateRanges = [...tempDateRanges]; // Use the latest tempDateRanges
      setDatesToShowToTableHeader(
        generateFormattedDates(newDateRanges[0], newDateRanges.length)
      );

      // Trigger the API call with updated filters
      updateRoomRates({
        variables: {
          filterRoomRates: {
            hostel: hostel?.value,
            occupency: selectedOccupancy?.value
              ? selectedOccupancy?.value
              : null,
            from: newDateRanges[0],
            to: newDateRanges.at(-1),
            competitor: selectedCompetitors?.length
              ? selectedCompetitors.map((item) => item.value)
              : [],
            roomName: selectedRoomNames?.length
              ? selectedRoomNames.map((item) => item.value)
              : [],
          },
        },
      });
    });
  };

  if (isLoadingDataFetcing) {
    return <AppLoader />;
  }

  return (
    <>
      <Row>
        <Col>
          <Filters
            handleDateRangeChange={handleDateRangeChange}
            reviewDateFrom={tempDateRanges[0]}
            reviewDateTo={tempDateRanges.at(-1)}
            hostelsList={HostelsList}
            handleHostelChange={handleHostelChange}
            hostel={hostel}
            handleSearch={handleSearch}
            handleReset={handleReset}
            uniqueCompetitors={getAllCompetitors}
            selectedCompetitors={selectedCompetitors}
            handleCompetitorChange={handleCompetitorChange}
            uniqueRoomNames={getRoomNamesOptions}
            selectedRoomName={selectedRoomNames}
            handleRoomNameChange={handleRoomChange}
            selectedOccupancy={selectedOccupancy}
            handleOccupancyChange={handleOccupancyChange}
            handleSync={handleSync}
            handlePriceUpdate={handlePriceUpdate}
          />
        </Col>
      </Row>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Room Rates Comparison</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <main style={{ maxWidth: "100%", overflow: "auto" }}>
                <Table
                  dateRanges={dateRangesForMap}
                  datesToShowToTableHeader={datesToShowToTableHeader}
                  data={roomData}
                  isHostelSelected={!!hostel?.value}
                  MaxDateForWhichTheDataIsPresent={
                    MaxDateForWhichTheDataIsPresent
                  }
                  lastUpdatedAt={lastUpdatedAt}
                  dailyOcupancy={dateWiseOccupancy}
                  isOccupancyLoading={dateWiseOccupancyLoading}
                />
              </main>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default RoomRatesComparison;
