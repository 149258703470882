// import React, { useState } from "react";
// import {
//   Button,
//   Container,
//   Row,
//   Col,
//   Form,
//   FormGroup,
//   Label,
//   Input,
// } from "reactstrap";
// import Select from "react-select";
// import {
//   useCreateOtaLogin,
//   useUpdateOtaLogin,
// } from "../../api/OTALogin/Mutation";
// import { useGetOtaLogin } from "../../api/OTALogin/Queries";
// import toaster from "@components/common/Toaster"; // Import the toaster

// const OTALogin = () => {
//   const [selectedPlatform, setSelectedPlatform] = useState(null);
//   const [selectedId, setSelectedId] = useState(null);
//   const [formState, setFormState] = useState({
//     userName: "",
//     password: "",
//     brand: "",
//   });
//   const [logs, setLogs] = useState([]);
//   const [isCreatingNewLogin, setIsCreatingNewLogin] = useState(false); // New state to manage create form visibility

//   const {
//     loading: loadingOtaLogins,
//     data: otaLoginData,
//     refetch: refetchOtaLogins,
//   } = useGetOtaLogin();

//   const [updateOtaLoginHandler, { loading: updating, error: updateError }] =
//     useUpdateOtaLogin();
//   const [createOtaLoginHandler, { loading: creating, error: createError }] =
//     useCreateOtaLogin();

//   const handlePlatformClick = (platform, brand) => {
//     const selectedLogin = otaLoginData.getOtaLogin.find(
//       (login) => login.platform === platform && login.brand === brand
//     );
//     if (selectedLogin) {
//       setSelectedPlatform(`${platform} (${brand})`);
//       setSelectedId(selectedLogin._id);
//       setFormState({
//         userName: selectedLogin.userName || "",
//         password: selectedLogin.password || "",
//         brand: selectedLogin.brand || "",
//       });
//       setLogs(selectedLogin.logs || []);
//       setIsCreatingNewLogin(false); // Switch to viewing mode
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormState((prevState) => ({ ...prevState, [name]: value }));
//   };

//   const handleBrandChange = (selectedOption) => {
//     setFormState((prevState) => ({
//       ...prevState,
//       brand: selectedOption.value,
//     }));
//   };

//   const handleCreateOtaLogin = async () => {
//     const input = {
//       userName: formState.userName,
//       password: formState.password,
//       brand: formState.brand,
//     };

//     try {
//       await createOtaLoginHandler({
//         variables: {
//           platform: selectedPlatform.split(" (")[0],
//           input,
//         },
//       });

//       refetchOtaLogins(); // Refresh the list to show the new entry
//       toaster("success", "OTA login created successfully!");
//       setFormState({ userName: "", password: "", brand: "" }); // Clear the form fields after creation
//       setIsCreatingNewLogin(false); // Optionally switch back to viewing mode
//     } catch (error) {
//       console.error("Error creating OTA login:", error);
//       toaster("error", error.message || "Failed to create OTA login");
//     }
//   };

//   const handleUpdateOtaLogin = async () => {
//     const input = {
//       userName: formState.userName,
//       password: formState.password,
//       brand: formState.brand,
//     };

//     try {
//       await updateOtaLoginHandler({
//         id: selectedId,
//         input,
//       });

//       refetchOtaLogins();
//       toaster("success", "OTA login updated successfully!");
//     } catch (error) {
//       console.error("Error updating OTA login:", error);
//       toaster("error", error.message || "Failed to update OTA login");
//     }
//   };

//   return (
//     <Container fluid>
//       <Row className="mb-4">
//         <Col>
//           <h2>OTA Login Management</h2>
//           {/* <Button
//             color="primary"
//             onClick={() => {
//               setSelectedPlatform(null);
//               setSelectedId(null);
//               setFormState({
//                 userName: "",
//                 password: "",
//                 brand: "",
//               });
//               setIsCreatingNewLogin(true);
//             }}
//           >
//             Create New Login
//           </Button> */}
//           {createError && <p className="text-danger">{createError.message}</p>}
//         </Col>
//       </Row>

//       <Row>
//         <Col sm="3">
//           <div className="platform-list">
//             <h4>Platforms</h4>
//             <ul style={{ listStyle: "none", padding: 0 }}>
//               {otaLoginData?.getOtaLogin
//                 ?.filter(
//                   (login) => login.brand && login.userName && login.password
//                 )
//                 .map((login, index) => (
//                   <li
//                     key={index}
//                     onClick={() =>
//                       handlePlatformClick(login.platform, login.brand)
//                     }
//                     style={{
//                       cursor: "pointer",
//                       border: "1px solid #ccc",
//                       borderRadius: "5px",
//                       padding: "10px",
//                       margin: "5px 0",
//                       backgroundColor: "#f9f9f9",
//                     }}
//                   >
//                     {login.platform}{" "}
//                     <span style={{ fontStyle: "italic" }}>({login.brand})</span>
//                   </li>
//                 ))}
//             </ul>
//           </div>
//         </Col>

//         <Col sm="9">
//           {isCreatingNewLogin ? (
//             <div>
//               <h4>Create New Login</h4>
//               <Form>
//                 <FormGroup>
//                   <Label for="userName">Username</Label>
//                   <Input
//                     type="text"
//                     name="userName"
//                     id="userName"
//                     value={formState.userName}
//                     onChange={handleInputChange}
//                   />
//                 </FormGroup>

//                 <FormGroup>
//                   <Label for="password">Password</Label>
//                   <Input
//                     type="password"
//                     name="password"
//                     id="password"
//                     value={formState.password}
//                     onChange={handleInputChange}
//                   />
//                 </FormGroup>

//                 <div
//                   className="brand-selection"
//                   style={{
//                     border: "1px solid #ccc",
//                     borderRadius: "5px",
//                     padding: "10px",
//                     marginTop: "20px",
//                   }}
//                 >
//                   <Label for="brand">Brand</Label>
//                   <Select
//                     name="brand"
//                     value={{ label: formState.brand, value: formState.brand }}
//                     options={otaLoginData?.getOtaLogin
//                       .filter((login) => login.brand)
//                       .map((login) => ({
//                         label: login.brand,
//                         value: login.brand,
//                       }))}
//                     onChange={handleBrandChange}
//                   />
//                 </div>

//                 <Button
//                   color="primary"
//                   onClick={handleCreateOtaLogin}
//                   disabled={creating || updating || loadingOtaLogins}
//                   style={{ marginTop: "20px" }}
//                 >
//                   {creating ? "Creating..." : "Create Login"}
//                 </Button>
//               </Form>
//               {createError && (
//                 <p className="text-danger">{createError.message}</p>
//               )}
//             </div>
//           ) : selectedPlatform ? (
//             <div className="login-details">
//               <h4>{`Login Details for ${selectedPlatform}`}</h4>
//               <Form>
//                 <FormGroup>
//                   <Label for="userName">Username</Label>
//                   <Input
//                     type="text"
//                     name="userName"
//                     id="userName"
//                     value={formState.userName}
//                     onChange={handleInputChange}
//                   />
//                 </FormGroup>

//                 <FormGroup>
//                   <Label for="password">Password</Label>
//                   <Input
//                     type="password"
//                     name="password"
//                     id="password"
//                     value={formState.password}
//                     onChange={handleInputChange}
//                   />
//                 </FormGroup>

//                 <div
//                   className="brand-selection"
//                   style={{
//                     border: "1px solid #ccc",
//                     borderRadius: "5px",
//                     padding: "10px",
//                     marginTop: "20px",
//                   }}
//                 >
//                   <Label for="brand">Brand</Label>
//                   <Select
//                     name="brand"
//                     value={{ label: formState.brand, value: formState.brand }}
//                     options={otaLoginData?.getOtaLogin
//                       .filter((login) => login.brand)
//                       .map((login) => ({
//                         label: login.brand,
//                         value: login.brand,
//                       }))}
//                     onChange={handleBrandChange}
//                   />
//                 </div>

//                 <Button
//                   color="success"
//                   onClick={handleUpdateOtaLogin}
//                   disabled={updating || creating || loadingOtaLogins}
//                   style={{ marginTop: "20px" }}
//                 >
//                   {updating ? "Updating..." : "Update Login"}
//                 </Button>
//               </Form>
//               {updateError && (
//                 <p className="text-danger">{updateError.message}</p>
//               )}

//               <div style={{ marginTop: "20px" }}>
//                 <h5>Logs</h5>
//                 <ul>
//                   {logs.map((log, index) => (
//                     <li key={index}>{log}</li>
//                   ))}
//                 </ul>
//               </div>
//             </div>
//           ) : (
//             <div>Select a platform to view login details.</div>
//           )}
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default OTALogin;

// import React, { useState } from "react";
// import {
//   Button,
//   Container,
//   Row,
//   Col,
//   Form,
//   FormGroup,
//   Label,
//   Input,
// } from "reactstrap";
// import Select from "react-select";
// import {
//   useCreateOtaLogin,
//   useUpdateOtaLogin,
// } from "../../api/OTALogin/Mutation";
// import { useGetOtaLogin } from "../../api/OTALogin/Queries";
// import toaster from "@components/common/Toaster"; // Import the toaster

// const OTALogin = () => {
//   const [selectedPlatform, setSelectedPlatform] = useState(null);
//   const [selectedId, setSelectedId] = useState(null);
//   const [formState, setFormState] = useState({
//     userName: "",
//     password: "",
//     brand: "",
//   });
//   const [logs, setLogs] = useState([]);
//   const [isCreatingNewLogin, setIsCreatingNewLogin] = useState(false);
//   const [showPassword, setShowPassword] = useState(false); // New state for showing password

//   const {
//     loading: loadingOtaLogins,
//     data: otaLoginData,
//     refetch: refetchOtaLogins,
//   } = useGetOtaLogin();

//   const [updateOtaLoginHandler, { loading: updating, error: updateError }] =
//     useUpdateOtaLogin();
//   const [createOtaLoginHandler, { loading: creating, error: createError }] =
//     useCreateOtaLogin();

//   const handlePlatformClick = (platform, brand) => {
//     const selectedLogin = otaLoginData.getOtaLogin.find(
//       (login) => login.platform === platform && login.brand === brand
//     );
//     if (selectedLogin) {
//       setSelectedPlatform(`${platform} (${brand})`);
//       setSelectedId(selectedLogin._id);
//       setFormState({
//         userName: selectedLogin.userName || "",
//         password: selectedLogin.password || "",
//         brand: selectedLogin.brand || "",
//       });
//       setLogs(selectedLogin.logs || []);
//       setIsCreatingNewLogin(false);
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormState((prevState) => ({ ...prevState, [name]: value }));
//   };

//   const handleBrandChange = (selectedOption) => {
//     setFormState((prevState) => ({
//       ...prevState,
//       brand: selectedOption.value,
//     }));
//   };

//   const handleCreateOtaLogin = async () => {
//     const input = {
//       userName: formState.userName,
//       password: formState.password,
//       brand: formState.brand,
//     };

//     try {
//       await createOtaLoginHandler({
//         variables: {
//           platform: selectedPlatform.split(" (")[0],
//           input,
//         },
//       });

//       refetchOtaLogins();
//       toaster("success", "OTA login created successfully!");
//       setFormState({ userName: "", password: "", brand: "" });
//       setIsCreatingNewLogin(false);
//     } catch (error) {
//       console.error("Error creating OTA login:", error);
//       toaster("error", error.message || "Failed to create OTA login");
//     }
//   };

//   const handleUpdateOtaLogin = async () => {
//     const input = {
//       userName: formState.userName,
//       password: formState.password,
//       brand: formState.brand,
//     };

//     try {
//       await updateOtaLoginHandler({
//         id: selectedId,
//         input,
//       });

//       refetchOtaLogins();
//       toaster("success", "OTA login updated successfully!");
//     } catch (error) {
//       console.error("Error updating OTA login:", error);
//       toaster("error", error.message || "Failed to update OTA login");
//     }
//   };

//   return (
//     <Container fluid>
//       <Row className="mb-4">
//         <Col>
//           <h2>OTA Login Management</h2>
//           {createError && <p className="text-danger">{createError.message}</p>}
//         </Col>
//       </Row>

//       <Row>
//         <Col sm="3">
//           <div className="platform-list">
//             <h4>Platforms</h4>
//             <ul style={{ listStyle: "none", padding: 0 }}>
//               {otaLoginData?.getOtaLogin
//                 ?.filter(
//                   (login) => login.brand && login.userName && login.password
//                 )
//                 .map((login, index) => (
//                   <li
//                     key={index}
//                     onClick={() =>
//                       handlePlatformClick(login.platform, login.brand)
//                     }
//                     style={{
//                       cursor: "pointer",
//                       border: "1px solid #ccc",
//                       borderRadius: "5px",
//                       padding: "10px",
//                       margin: "5px 0",
//                       backgroundColor: "#f9f9f9",
//                     }}
//                   >
//                     {login.platform}{" "}
//                     <span style={{ fontStyle: "italic" }}>({login.brand})</span>
//                   </li>
//                 ))}
//             </ul>
//           </div>
//         </Col>

//         <Col sm="9">
//           {isCreatingNewLogin ? (
//             <div>
//               <h4>Create New Login</h4>
//               <Form>
//                 <FormGroup>
//                   <Label for="userName">Username</Label>
//                   <Input
//                     type="text"
//                     name="userName"
//                     id="userName"
//                     value={formState.userName}
//                     onChange={handleInputChange}
//                   />
//                 </FormGroup>

//                 <FormGroup>
//                   <Label for="password">Password</Label>
//                   <Input
//                     type={showPassword ? "text" : "password"} // Toggle input type based on showPassword state
//                     name="password"
//                     id="password"
//                     value={formState.password}
//                     onChange={handleInputChange}
//                   />
//                   <div>
//                     <Input
//                       type="checkbox"
//                       id="showPassword"
//                       checked={showPassword}
//                       onChange={() => setShowPassword(!showPassword)} // Toggle password visibility
//                     />
//                     <Label for="showPassword" style={{ marginLeft: "5px" }}>
//                       Show Password
//                     </Label>
//                   </div>
//                 </FormGroup>

//                 <div
//                   className="brand-selection"
//                   style={{
//                     border: "1px solid #ccc",
//                     borderRadius: "5px",
//                     padding: "10px",
//                     marginTop: "20px",
//                   }}
//                 >
//                   <Label for="brand">Brand</Label>
//                   <Select
//                     name="brand"
//                     value={{ label: formState.brand, value: formState.brand }}
//                     options={otaLoginData?.getOtaLogin
//                       .filter((login) => login.brand)
//                       .map((login) => ({
//                         label: login.brand,
//                         value: login.brand,
//                       }))}
//                     onChange={handleBrandChange}
//                   />
//                 </div>

//                 <Button
//                   color="primary"
//                   onClick={handleCreateOtaLogin}
//                   disabled={creating || updating || loadingOtaLogins}
//                   style={{ marginTop: "20px" }}
//                 >
//                   {creating ? "Creating..." : "Create Login"}
//                 </Button>
//               </Form>
//               {createError && (
//                 <p className="text-danger">{createError.message}</p>
//               )}
//             </div>
//           ) : selectedPlatform ? (
//             <div className="login-details">
//               <h4>{`Login Details for ${selectedPlatform}`}</h4>
//               <Form>
//                 <FormGroup>
//                   <Label for="userName">Username</Label>
//                   <Input
//                     type="text"
//                     name="userName"
//                     id="userName"
//                     value={formState.userName}
//                     onChange={handleInputChange}
//                   />
//                 </FormGroup>

//                 <FormGroup>
//                   <Label for="password">Password</Label>
//                   <Input
//                     type={showPassword ? "text" : "password"} // Toggle input type based on showPassword state
//                     name="password"
//                     id="password"
//                     value={formState.password}
//                     onChange={handleInputChange}
//                   />
//                   <div>
//                     <Input
//                       type="checkbox"
//                       id="showPassword"
//                       checked={showPassword}
//                       onChange={() => setShowPassword(!showPassword)} // Toggle password visibility
//                     />
//                     <Label for="showPassword" style={{ marginLeft: "5px" }}>
//                       Show Password
//                     </Label>
//                   </div>
//                 </FormGroup>

//                 <div
//                   className="brand-selection"
//                   style={{
//                     border: "1px solid #ccc",
//                     borderRadius: "5px",
//                     padding: "10px",
//                     marginTop: "20px",
//                   }}
//                 >
//                   <Label for="brand">Brand</Label>
//                   <Select
//                     name="brand"
//                     value={{ label: formState.brand, value: formState.brand }}
//                     options={otaLoginData?.getOtaLogin
//                       .filter((login) => login.brand)
//                       .map((login) => ({
//                         label: login.brand,
//                         value: login.brand,
//                       }))}
//                     onChange={handleBrandChange}
//                   />
//                 </div>

//                 <Button
//                   color="primary"
//                   onClick={handleUpdateOtaLogin}
//                   disabled={creating || updating || loadingOtaLogins}
//                   style={{ marginTop: "20px" }}
//                 >
//                   {updating ? "Updating..." : "Update Login"}
//                 </Button>
//               </Form>
//               {updateError && (
//                 <p className="text-danger">{updateError.message}</p>
//               )}
//             </div>
//           ) : (
//             <div>
//               <h4>Select a platform to view or edit login details</h4>
//               <Button
//                 color="secondary"
//                 onClick={() => setIsCreatingNewLogin(true)}
//               >
//                 Create New Login
//               </Button>
//             </div>
//           )}
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default OTALogin;

import React, { useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import {
  useCreateOtaLogin,
  useUpdateOtaLogin,
} from "../../api/OTALogin/Mutation";
import { useGetOtaLogin } from "../../api/OTALogin/Queries";
import toaster from "@components/common/Toaster"; // Import the toaster

const OTALogin = () => {
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [formState, setFormState] = useState({
    userName: "",
    password: "",
    brand: "",
  });
  const [logs, setLogs] = useState([]);
  const [isCreatingNewLogin, setIsCreatingNewLogin] = useState(false); // New state to manage create form visibility
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

  const {
    loading: loadingOtaLogins,
    data: otaLoginData,
    refetch: refetchOtaLogins,
  } = useGetOtaLogin();

  const [updateOtaLoginHandler, { loading: updating, error: updateError }] =
    useUpdateOtaLogin();
  const [createOtaLoginHandler, { loading: creating, error: createError }] =
    useCreateOtaLogin();

  const handlePlatformClick = (platform, brand) => {
    const selectedLogin = otaLoginData.getOtaLogin.find(
      (login) => login.platform === platform && login.brand === brand
    );
    if (selectedLogin) {
      setSelectedPlatform(`${platform} (${brand})`);
      setSelectedId(selectedLogin._id);
      setFormState({
        userName: selectedLogin.userName || "",
        password: selectedLogin.password || "",
        brand: selectedLogin.brand || "",
      });
      setLogs(selectedLogin.logs || []);
      setIsCreatingNewLogin(false); // Switch to viewing mode
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleBrandChange = (selectedOption) => {
    setFormState((prevState) => ({
      ...prevState,
      brand: selectedOption.value,
    }));
  };

  const handlePasswordToggle = () => {
    setShowPassword((prev) => !prev); // Toggle password visibility
  };

  const handleCreateOtaLogin = async () => {
    const input = {
      userName: formState.userName,
      password: formState.password,
      brand: formState.brand,
    };

    try {
      await createOtaLoginHandler({
        variables: {
          platform: selectedPlatform.split(" (")[0],
          input,
        },
      });

      refetchOtaLogins(); // Refresh the list to show the new entry
      toaster("success", "OTA login created successfully!");
      setFormState({ userName: "", password: "", brand: "" }); // Clear the form fields after creation
      setIsCreatingNewLogin(false); // Optionally switch back to viewing mode
    } catch (error) {
      console.error("Error creating OTA login:", error);
      toaster("error", error.message || "Failed to create OTA login");
    }
  };

  const handleUpdateOtaLogin = async () => {
    const input = {
      userName: formState.userName,
      password: formState.password,
      brand: formState.brand,
    };

    try {
      await updateOtaLoginHandler({
        id: selectedId,
        input,
      });

      refetchOtaLogins();
      toaster("success", "OTA login updated successfully!");
    } catch (error) {
      console.error("Error updating OTA login:", error);
      toaster("error", error.message || "Failed to update OTA login");
    }
  };

  return (
    <Container fluid>
      <Row className="mb-4">
        <Col>
          <h2>OTA Login Management</h2>
          {/* <Button
            color="primary"
            onClick={() => {
              setSelectedPlatform(null);
              setSelectedId(null);
              setFormState({
                userName: "",
                password: "",
                brand: "",
              });
              setIsCreatingNewLogin(true);
            }}
          >
            Create New Login
          </Button> */}
          {createError && <p className="text-danger">{createError.message}</p>}
        </Col>
      </Row>

      <Row>
        <Col sm="3">
          <div className="platform-list">
            <h4>Platforms</h4>
            <ul style={{ listStyle: "none", padding: 0 }}>
              {otaLoginData?.getOtaLogin
                ?.filter(
                  (login) => login.brand && login.userName && login.password
                )
                .map((login, index) => (
                  <li
                    key={index}
                    onClick={() =>
                      handlePlatformClick(login.platform, login.brand)
                    }
                    style={{
                      cursor: "pointer",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      padding: "10px",
                      margin: "5px 0",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    {login.platform}{" "}
                    <span style={{ fontStyle: "italic" }}>({login.brand})</span>
                  </li>
                ))}
            </ul>
          </div>
        </Col>

        <Col sm="9">
          {isCreatingNewLogin ? (
            <div>
              <h4>Create New Login</h4>
              <Form>
                <FormGroup>
                  <Label for="userName">Username</Label>
                  <Input
                    type="text"
                    name="userName"
                    id="userName"
                    value={formState.userName}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    value={formState.password}
                    onChange={handleInputChange}
                  />
                  <Button
                    onClick={handlePasswordToggle}
                    type="button"
                    size="sm"
                    style={{ marginTop: "5px" }}
                  >
                    {showPassword ? "Hide" : "Show"} Password
                  </Button>
                </FormGroup>

                <div
                  className="brand-selection"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "10px",
                    marginTop: "20px",
                  }}
                >
                  <Label for="brand">Brand</Label>
                  <Select
                    name="brand"
                    value={{ label: formState.brand, value: formState.brand }}
                    options={otaLoginData?.getOtaLogin
                      .filter((login) => login.brand)
                      .map((login) => ({
                        label: login.brand,
                        value: login.brand,
                      }))}
                    onChange={handleBrandChange}
                  />
                </div>

                <Button
                  color="primary"
                  onClick={handleCreateOtaLogin}
                  disabled={creating || updating || loadingOtaLogins}
                  style={{ marginTop: "20px" }}
                >
                  {creating ? "Creating..." : "Create Login"}
                </Button>
              </Form>
              {createError && (
                <p className="text-danger">{createError.message}</p>
              )}
            </div>
          ) : selectedPlatform ? (
            <div className="login-details">
              <h4>{`Login Details for ${selectedPlatform}`}</h4>
              <Form>
                <FormGroup>
                  <Label for="userName">Username</Label>
                  <Input
                    type="text"
                    name="userName"
                    id="userName"
                    value={formState.userName}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    value={formState.password}
                    onChange={handleInputChange}
                  />
                  <Button
                    onClick={handlePasswordToggle}
                    type="button"
                    size="sm"
                    style={{ marginTop: "10px" }}
                  >
                    {showPassword ? "Hide" : "Show"} Password
                  </Button>
                </FormGroup>

                <div
                  className="brand-selection"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    padding: "10px",
                    marginTop: "20px",
                  }}
                >
                  <Label for="brand">Brand</Label>
                  <Select
                    name="brand"
                    value={{ label: formState.brand, value: formState.brand }}
                    options={otaLoginData?.getOtaLogin
                      .filter((login) => login.brand)
                      .map((login) => ({
                        label: login.brand,
                        value: login.brand,
                      }))}
                    onChange={handleBrandChange}
                  />
                </div>

                <Button
                  color="primary"
                  onClick={handleUpdateOtaLogin}
                  disabled={creating || updating || loadingOtaLogins}
                  style={{ marginTop: "20px" }}
                >
                  {updating ? "Updating..." : "Update Login"}
                </Button>
              </Form>
              {updateError && (
                <p className="text-danger">{updateError.message}</p>
              )}

              {/* Logs display */}
              <div className="logs-display" style={{ marginTop: "20px" }}>
                <h5>Logs</h5>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  {logs.map((log, index) => (
                    <li key={index} style={{ margin: "5px 0" }}>
                      {log}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <div>Select a platform to view login details.</div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default OTALogin;
