//Added By Gaurav
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_SALES_DATA_HOSTEL_WISE = gql`
  query getSalesDataHostelWise($filter: filter) {
    getSalesDataHostelWise(filter: $filter) {
      data {
        _id
        totalBookings
        totalSaleAmount
        totalSaleAmountWithTax
      }
      limit
      count
      page
    }
  }
`;

export const RETRY_UNCONFIRMED_BOOKING = gql`
  mutation retryUnconfirmedBooking(
    $bookingId: ID!
    $providerOrderRefId: String!
    $providerPaymentRefId: String!
  ) {
    retryUnconfirmedBooking(
      bookingId: $bookingId
      providerOrderRefId: $providerOrderRefId
      providerPaymentRefId: $providerPaymentRefId
    ) {
      status
      message
    }
  }
`;

export const SYNC_WEBSITE_BOOKINGS = gql`
  mutation syncWebsiteBookings {
    syncWebsiteBookings {
      message
      status
    }
  }
`;

export const useRetryUnconfirmedBooking = () => {
  const [retryUncofirmedBooking, { loading, error, data, refetch }] =
    useMutation(RETRY_UNCONFIRMED_BOOKING);

  const retryUncofirmedBookinghandler = (
    bookingId,
    providerOrderRefId,
    providerPaymentRefId
  ) => {
    console.log(bookingId, providerOrderRefId, providerPaymentRefId);
    retryUncofirmedBooking({
      variables: { bookingId, providerOrderRefId, providerPaymentRefId },
    });
  };

  return [retryUncofirmedBookinghandler, { loading, error, data, refetch }];
};

export const useSyncWebsiteBokings = () => {
  const [syncWebsiteBookings, { loading, error, data, refetch }] = useMutation(
    SYNC_WEBSITE_BOOKINGS
  );

  const syncWebsiteBookingsHandler = () => {
    syncWebsiteBookings({
      variables: {},
    });
  };

  return [syncWebsiteBookingsHandler, { loading, error, data, refetch }];
};

//Added by Nitish - refund
export const CANCEL_WEBSITE_BOOKING = gql`
  mutation cancelWebsiteBooking($bookingId: ID!, $refundType: String!) {
    cancelWebsiteBooking(bookingId: $bookingId, refundType: $refundType) {
      status
      message
      refundId
    }
  }
`;

export const useCancelWebsiteBooking = () => {
  const [cancelWebsiteBooking, { loading, error, data, reset }] = useMutation(
    CANCEL_WEBSITE_BOOKING
  );

  const cancelWebsiteBookingHandler = (bookingId, refundType) => {
    if (!bookingId || !refundType) {
      throw new Error("Both bookingId and refundType are required");
    }

    cancelWebsiteBooking({
      variables: { bookingId, refundType },
    });
  };

  return [cancelWebsiteBookingHandler, { loading, error, data, reset }];
};
