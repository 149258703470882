import React, { Fragment, useState } from "react";
import { Tooltip, Table as ReactTable, Spinner } from "reactstrap";
import { useSkin } from "@hooks/useSkin";
import "./style.css";
import { getISTDateAndTime } from "./helpers";
import ThreeDotsLoader from "./ThreeDotLoader";
import { Info } from "react-feather";

const Table = ({
  datesToShowToTableHeader,
  dateRanges,
  data,
  isOccupancyLoading,
  isHostelSelected,
  MaxDateForWhichTheDataIsPresent,
  lastUpdatedAt,
  dailyOcupancy,
}) => {
  const [skin] = useSkin();
  const backgroundColor = skin === "light" ? "#F3F2F7" : "#343D55";
  const [expandedRows, setExpandedRows] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const toggleAccordion = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const renderTooltip = (id, roomName, updatedAt, originalPrice) => (
    <Tooltip
      placement="top"
      isOpen={tooltipOpen[id] || false}
      target={id}
      toggle={() => toggleTooltip(id)}
    >
      {roomName || "-"}
      <br />
      {updatedAt ? "UpdatedAt :" + getISTDateAndTime(updatedAt).date : "-"}
      <br />
      {updatedAt ? "Time :" + getISTDateAndTime(updatedAt).time : "-"}
      <br />
      {originalPrice ? "Original Price :" + originalPrice : null}
    </Tooltip>
  );

  const renderTooltipTheHosteller = (id, updatedAt) => (
    <Tooltip
      placement="top"
      isOpen={tooltipOpen[id] || false}
      target={id}
      toggle={() => toggleTooltip(id)}
    >
      {updatedAt ? "UpdatedAt :" + getISTDateAndTime(updatedAt).date : "-"}
      <br />
      {updatedAt ? "Time :" + getISTDateAndTime(updatedAt).time : "-"}
    </Tooltip>
  );

  // if (isloading) return <div>Loading...</div>; // Replace with a spinner if needed

  const getLableOfTheTbody = () => {
    if (isHostelSelected && data?.length === 0) {
      return "No Data Found";
    } else {
      return "Kindly Search Hostel";
    }
  };

  const knowWeatherthePropertyIsSoldOut = (currentDateTime) => {
    // Parse the input dates
    currentDateTime = new Date(currentDateTime);
    const lastUpdated = new Date(MaxDateForWhichTheDataIsPresent);

    // Log the dates for debugging
    // console.log("Last Updated At:", lastUpdated);
    // console.log("Current Date Time:", currentDateTime);

    // Compare the dates
    if (currentDateTime > lastUpdated) {
      return "NA"; // Property is not sold out
    }
    // return "Sold Out"; // Property is sold out
    return "--"; // Property is sold out
  };

  function normalizeString(input) {
    // Remove special characters (dashes and spaces) and remove "Room" (case insensitive)
    let normalized = input
      .replace(/\bRoom\b/i, "") // Remove the word "Room" (case insensitive) as there is mis-match in the ezee data and booking.com data
      .replace(/[-\s]+/g, ""); // Remove spaces and dashes

    return normalized.toLowerCase(); // Convert the string to lowercase
  }

  function GetBackgrounAndTextColour(deltaPrice , staticPrice, updatedAt) {
    const result = {
      backgroundColor: null,
      color: null,
    };
    if (deltaPrice) {
      result.backgroundColor = "yellow";
      result.color = "black";
    }
    if(staticPrice){
      result.backgroundColor = "lightgreen";
      result.color = "black";
    }
    // else if (updatedAt && new Date(updatedAt) < new Date()) {
    //   result.backgroundColor = "red";
    //   result.color = "black";
    // }
    return result;
  }

  const FindTheOccupancy = (roomName, date) => {
    if (isOccupancyLoading) {
      return (
        <div
          style={{
            width: "90%",
            height: "20%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <ThreeDotsLoader />
        </div>
      );
    }
    const occupancy = dailyOcupancy?.[date]?.data?.find((_room) => {
      // If roomName matches and the occupancy property exists, return occupancy, otherwise "-"
      if (normalizeString(_room.roomCategory) === normalizeString(roomName)) {
        return _room?.occupancy;
      }
    });

    return occupancy?.occupancy > 0
      ? `${Math.round(occupancy?.occupancy, 2)} % `
      : "0 % ";
  };

  const FindTheDailyTotalOccupancy = (date) => {
    if (isOccupancyLoading) {
      return (
        <div
          style={{
            width: "90%",
            height: "20%",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <ThreeDotsLoader />
        </div>
      );
    }
    const totalOccupancy = dailyOcupancy?.[date]?.totalOccupancy;
    return totalOccupancy > 0 ? `${Math.round(totalOccupancy, 2)} %` : "0 % ";
  };

  return (
    <section style={{ minWidth: "max-content", overflowX: "auto" }}>
      <div style={{ textAlign: "left" }}>
        {data?.length && (
          <div>
            Last Updated At :{" "}
            {getISTDateAndTime(lastUpdatedAt)?.date +
              "," +
              getISTDateAndTime(lastUpdatedAt)?.time}
          </div>
        )}
      </div>
      <div>
        <strong>
          <div style={{ fontSize: "12px", marginTop: "10px" }}>
            <Info size={16} /> "Sold Out" - Property is sold out for that
            particular date , "NA" - Data isn`t fetched yet , "-" - No data
            available
          </div>
        </strong>
      </div>
      <ReactTable bordered>
        <thead style={{ position: "sticky", top: 0 }}>
          <tr>
            <th>Room Name</th>
            <th> </th>
            {datesToShowToTableHeader.map((day, index) => (
              <th key={index}>{day}</th>
            ))}
          </tr>
          {/* if we have data?.length that signifies that we have interacted with
          the hostel filter and we need to show the total occupancy */}
          {data?.length ? (
            <tr>
              <th>Total Occupancy</th>
              <th></th>
              {dateRanges.map((day, index) => (
                <th key={index}>{FindTheDailyTotalOccupancy(day)}</th>
              ))}
            </tr>
          ) : null}
        </thead>
        {data?.length ? (
          <tbody>
            {data.map((hostel, hIdx) =>
              Object.keys(hostel?.pricebyRoomName).map((roomName, roomIdx) => (
                // Doing React Fragment to render multiple rows with the appropriate index to resolve the issue of missing id
                <React.Fragment key={`hostel-${hIdx}-room-${roomIdx}`}>
                  <tr>
                    <td
                      style={{ verticalAlign: "top" }}
                      rowSpan={
                        hostel?.pricebyRoomName[roomName]?.competitors
                          ? hostel?.pricebyRoomName[roomName]?.competitors
                              .length + 3
                          : 3
                      }
                    >
                      {/* since the roomNames are with the occuoency followed by _  */}
                      {roomName.split("_")[0]}
                      {/* {roomName} */}
                    </td>

                    <td style={{ background: backgroundColor }}>Occupancy</td>
                    {dateRanges.map((day, dayIdx) => (
                      <td
                        style={{ background: backgroundColor }}
                        key={`avg-${dayIdx}`}
                      >
                        {FindTheOccupancy(roomName.split("_")[0], day)}
                      </td>
                    ))}
                  </tr>

                  <tr>
                    <td style={{ background: backgroundColor }}>
                      Current Price
                    </td>
                    {dateRanges.map((day, dayIdx) => {
                      const tooltipId = `Tooltip-${hIdx}-${roomIdx}-${dayIdx}`;
                      return (
                        <td
                          style={{ background: backgroundColor }}
                          id={tooltipId}
                          key={`th-${dayIdx}`}
                          onMouseEnter={() =>
                            setTooltipOpen({
                              ...tooltipOpen,
                              [tooltipId]: true,
                            })
                          }
                          onMouseLeave={() =>
                            setTooltipOpen({
                              ...tooltipOpen,
                              [tooltipId]: false,
                            })
                          }
                        >
                          {hostel.pricebyRoomName[roomName]?.prices?.[day]?.[0]
                            ?.rate || knowWeatherthePropertyIsSoldOut(day)}

                          {renderTooltipTheHosteller(
                            tooltipId,
                            hostel.pricebyRoomName[roomName]?.prices?.[day]?.[0]
                              ?.updatedAt
                          )}
                        </td>
                      );
                    })}
                  </tr>

                  <tr>
                    <td style={{ background: backgroundColor }}>
                      Suggested Price
                    </td>
                    {dateRanges.map((day, dayIdx) => (
                      <td
                        style={{ background: backgroundColor }}
                        key={`avg-${dayIdx}`}
                      >
                        {hostel.pricebyRoomName[roomName]?.expectedPrice?.[
                          day
                        ] || "-"}
                      </td>
                    ))}
                  </tr>

                  {hostel.pricebyRoomName[roomName]?.competitors?.map(
                    (competitor, compIdx) => (
                      <tr key={`comp-${compIdx}`}>
                        <td>{competitor.competitor}</td>
                        {dateRanges.map((day, dayIdx) => {
                          const tooltipId = `Tooltip-${hIdx}-${roomIdx}-${compIdx}-${dayIdx}`;
                          const { backgroundColor, color } =
                            GetBackgrounAndTextColour(
                              competitor?.prices[day]?.[0]?.deltaPrice,
                              competitor?.prices[day]?.[0]?.staticPricing,
                              competitor?.prices[day]?.[0]?.updatedAt
                            );
                          return (
                            <td
                              style={{
                                background: backgroundColor,
                                color: color,
                              }}
                              id={tooltipId} // Assign the ID here
                              key={`comp-price-${compIdx}-${dayIdx}`}
                              onMouseEnter={() =>
                                setTooltipOpen({
                                  ...tooltipOpen,
                                  [tooltipId]: true,
                                })
                              }
                              onMouseLeave={() =>
                                setTooltipOpen({
                                  ...tooltipOpen,
                                  [tooltipId]: false,
                                })
                              }
                            >
                              {competitor?.prices[day]?.[0]?.rate ||
                                knowWeatherthePropertyIsSoldOut(day)}
                              {renderTooltip(
                                tooltipId,
                                competitor?.prices[day]?.[0]?.roomName,
                                competitor?.prices[day]?.[0]?.updatedAt,
                                competitor?.prices[day]?.[0]?.originalPrice
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    )
                  )}
                </React.Fragment>
              ))
            )}
          </tbody>
        ) : // : (
        //   <tbody>
        //     <tr>
        //       <td
        //         colSpan={datesToShowToTableHeader.length}
        //         style={{ padding: "20px" }}
        //       >
        //         <h2>{getLableOfTheTbody()}</h2>
        //       </td>
        //     </tr>
        //   </tbody>
        // )
        null}
      </ReactTable>
    </section>
  );
};

export default Table;
