import React from 'react';

const ThreeDotsLoader = () => {
  const loaderStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
  };

  const dotStyle = {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: 'yellow',
    animation: 'oscillate 1.5s infinite ease-in-out',
  };

  const dot1Style = { ...dotStyle, animationDelay: '0s' };
  const dot2Style = { ...dotStyle, animationDelay: '0.2s' };
  const dot3Style = { ...dotStyle, animationDelay: '0.4s' };

  return (
    <>
      <style>
        {`
          @keyframes oscillate {
            0%, 100% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.5);
            }
          }
        `}
      </style>
      <div style={loaderStyle}>
        <div style={dot1Style}></div>
        <div style={dot2Style}></div>
        <div style={dot3Style}></div>
      </div>
    </>
  );
};

export default ThreeDotsLoader;
