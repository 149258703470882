// ! Added by Ashwin

import React, { useEffect, useState } from "react";
import { Col, Row, FormGroup, Label, Input, Button } from "reactstrap";
import { dateFormatHandler } from "@utils";
import SlideModal from "./slide-modal";
import { useUpdateEzeeBookingRemarks } from "../../../api/dataSource";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import toaster from "@components/common/Toaster";
import { useCancelPaidOtaBookings } from "../../../api/OTACancellation/Mutation";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [source, setSource] = useState("");
  const [reservationNo, setReservationNo] = useState("");
  const [guestName, setGuestName] = useState("");
  const [dueAmount, setDueAmount] = useState("");
  const [hostelName, setHostelName] = useState("");
  const [arrivalDate, setArrivalDate] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [reservationDate, setReservationDate] = useState("");
  const [voucherNo, setVoucherNo] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [alternateEmail, setAlternateEmail] = useState("");
  const [alternateMobile, setAlternateMobile] = useState("");

  const [refundId, setRefundId] = useState("");
  const [refundStatus, setRefundStatus] = useState("");
  const [refundAmount, setRefundAmount] = useState("");
  const [cancelDate, setCancelDate] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [updateEzeeBookingRemarksHandler, data] = useUpdateEzeeBookingRemarks();
  const [cancelPaidOtaBookingsHandler, { data: cancelledOTABookingData }] =
    useCancelPaidOtaBookings(uniqueId, paymentId);

  // useEffect(() => {
  //   if (data) {
  //     refetch();
  //     handleToggle();

  //     toaster("success", "Remark added successfully");
  //   }
  // }, [data]);

  useEffect(() => {
    if (editData?._id) {
      setSource(editData.Source);
      setGuestName(editData.GuestName);
      setReservationNo(editData.uniqueId);
      setDueAmount(editData.DueAmount);
      setHostelName(editData.hostelName);
      setArrivalDate(editData.ArrivalDate);
      setDepartureDate(editData.DepartureDate);
      setReservationDate(editData.ReservationDate);
      setVoucherNo(editData.VoucherNo);
      setStatus(editData.Status);
      setMobile(editData.Mobile);
      setEmail(editData.Email);
      setAlternateEmail(editData?.alternateEmail);
      setAlternateMobile(editData?.alternateMobile);
      setRefundId(editData?.refundId);
      setRefundStatus(editData?.refundStatus);
      setRefundAmount(editData?.refundAmount);
      setCancelDate(editData?.cancelDate);
      setUniqueId(editData?.uniqueId);
      setPaymentId(editData?.paymentId);
    } else {
      setSource("");
      setGuestName("");
      setReservationNo("");
      setDueAmount("");
      setHostelName("");
      setArrivalDate("");
      setDepartureDate("");
      setReservationDate("");
      setVoucherNo("");
      setStatus("");
      setMobile("");
      setEmail("");
      setAlternateEmail("");
      setAlternateMobile("");
      setRefundId("");
      setRefundStatus("");
      setRefundAmount("");
      setCancelDate("");
      setUniqueId("");
      setPaymentId("");
    }
  }, [editData]);

  useEffect(() => {
    const message = cancelledOTABookingData?.cancelPaidOtaBookings?.message;
    if (message) {
      if (cancelledOTABookingData?.cancelPaidOtaBookings?.status === true) {
        toaster("success", message);
      } else {
        toaster("error", message);
      }
    }
  }, [cancelledOTABookingData]);

  // const submitDataHandler = () => {
  //   const data = {
  //     uniqueId: editData?.uniqueId,
  //     remarksLogUpdate: remarks,
  //     alternateEmail,
  //     alternateMobile,
  //   };
  //   if (data?.uniqueId && data?.remarksLogUpdate) {
  //     updateEzeeBookingRemarksHandler(
  //       data?.uniqueId,
  //       data?.remarksLogUpdate,
  //       data?.alternateEmail,
  //       data?.alternateMobile
  //     );
  //     toaster("success", "Remark added successfully");
  //   } else {
  //     toaster("error", "Please fill all the required details");
  //   }
  //   setRemarks("");
  //   refetch();
  //   handleToggle();
  // };

  const handleCancelBooking = (uniqueId, paymentId) => {
    // console.log(uniqueId, paymentId, "ID");
    cancelPaidOtaBookingsHandler(uniqueId, paymentId);
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      // submitDataHandler={() => submitDataHandler()}
    >
      <form>
        <Row>
          {/* // ! Due Amount */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="dueAmount">
                <strong>Due Amount*</strong>
              </Label>
              <br />
              <Label className="form-label" for="dueAmount">
                {dueAmount}
              </Label>

              <hr />
            </FormGroup>
          </Col>

          {/*// ! Booking Details*/}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="source">
                <strong>Booking Details*</strong>
              </Label>
              <br />
              <Label className="form-label" for="hostelName">
                {hostelName}
              </Label>
              <br />
              <Label className="form-label" for="reservationNo">
                {reservationNo}
              </Label>
              <br />
              <Label className="form-label" for="source">
                {source}
              </Label>
              <br />
              <Label className="form-label" for="arrivalDate - departureDate">
                {dateFormatHandler(arrivalDate)} -{" "}
                {dateFormatHandler(departureDate)}
              </Label>
              <br />
              <Label className="form-label" for="reservationDate">
                {dateFormatHandler(reservationDate)}
              </Label>
              <br />
              <Label className="form-label" for="voucherNo">
                {voucherNo}
              </Label>
              <br />
              <Label className="form-label" for="status">
                {status}
              </Label>
              <hr />
            </FormGroup>
          </Col>

          {/* // ! Guest Details */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="source">
                <strong>Guest Details*</strong>
              </Label>
              <br />
              <Label className="form-label" for="guestName">
                {guestName}
              </Label>
              <br />
              <Label className="form-label" for="mobile">
                {mobile}
              </Label>
              <br />
              <Label className="form-label" for="email">
                {email}
              </Label>
              <hr />
            </FormGroup>
          </Col>

          {/* // ! Cancellation Details */}
          {cancelDate && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="source">
                  <strong>Cancellation Date</strong>
                </Label>
                {cancelDate && (
                  <>
                    {" "}
                    <br />
                    <Label className="form-label" for="cancelDate">
                      {dateFormatHandler(cancelDate)}
                    </Label>
                  </>
                )}

                <hr />
              </FormGroup>
            </Col>
          )}

          {/* // ! Refund Details */}
          {refundAmount && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="source">
                  <strong>Refund Details</strong>
                </Label>
                {refundId && (
                  <>
                    <br />
                    <Label className="form-label" for="guestName">
                      {refundId}
                    </Label>
                  </>
                )}
                {refundStatus && (
                  <>
                    <br />
                    <Label className="form-label" for="mobile">
                      {refundStatus}
                    </Label>
                  </>
                )}
                {refundAmount && (
                  <>
                    {" "}
                    <br />
                    <Label className="form-label" for="email">
                      {refundAmount}
                    </Label>
                  </>
                )}
                <hr />
              </FormGroup>
            </Col>
          )}

          {status !== "Cancelled" && (
            <Col sm="12" className="text-right">
              <Button
                color="danger"
                type="button"
                onClick={() => {
                  handleCancelBooking(uniqueId, paymentId);
                }}
              >
                Cancel Booking
              </Button>
            </Col>
          )}
          {/* 
          <Col>
            <Label for="alternateEmail">Alternate Email</Label>
            <Input
              type="text"
              value={alternateEmail}
              id="alternateEmail"
              name="alternateEmail"
              placeholder="Alternate Email"
              onChange={(e) => setAlternateEmail(e.target.value)}
            />

            <Label for="alternateEmail">Alternate Phone Number</Label>
            <Input
              type="text"
              value={alternateMobile}
              id="alternatePhone"
              name="alternatePhone"
              placeholder="Alternate Phone Number"
              onChange={(e) => setAlternateMobile(e.target.value)}
            />
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="remarks">
                  Remarks*
                </Label>
              </strong>
              <Input
                type="textarea"
                value={remarks}
                id="remarks"
                name="remarks"
                onChange={(e) => setRemarks(e.target.value)}
              />
            </FormGroup>
          </Col> */}

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="source">
                <strong>Logs</strong>
              </Label>
              <br />

              {editData?.logs !== null
                ? editData?.logs?.map((log) => {
                    return (
                      <Label className="form-label" for="logs">
                        {log}.
                      </Label>
                    );
                  })
                : []}
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
