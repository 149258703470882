import React, { useEffect, useMemo, useState } from "react";
import "./styles.css";
import { Button, Col, Row } from "reactstrap";
import {
  useGetAllCompetitorsName,
  useGetCompetitorsByFilters,
  useGetHostelsNameandId,
} from "../../api/dataSource";
import FilterSection from "./Components/FilterSection";
import Table from "./Components/Table";
import { generateDateRange, generateFormattedDates } from "./helpers";
import AppLoader from "./Components/Loader";

const CompetitorRates = () => {
  // Dates for the table header and date ranges for mapping
  const [datesToShowToTableHeader, setDatesToShowToTableHeader] = useState(
    generateFormattedDates(new Date(), 8)
  );
  const [dateRangesForMap, setDateRangesForMap] = useState(
    generateDateRange(new Date(), null, 8)
  );

  // Temporary state for date range selection
  const [tempDateRanges, setTempDateRanges] = useState(dateRangesForMap);

  // Filters
  const [selectedCompetitors, setSelectedCompetitors] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(["All"]);
  const [selectedOccupancy, setSelectedOccupancy] = useState({
    value: 1,
    label: "1",
  });
  const [hostel, setHostel] = useState([]);

  const [filtersExpanded, setFiltersExpanded] = useState(true);

  // Variables for API call
  const variables = useMemo(() => ({
    filter: {
      from: dateRangesForMap[0],
      to: dateRangesForMap.at(-1),
      platform: selectedPlatform.length ? selectedPlatform : ["All"],
      competitor: selectedCompetitors?.length
        ? selectedCompetitors.map((item) => item.value)
        : [],
      hostels: hostel?.length ? hostel.map((item) => item.value) : ["All"],
      occupency: selectedOccupancy?.value ? selectedOccupancy?.value : 1,
    },
  }), [dateRangesForMap, selectedCompetitors, selectedPlatform, hostel, selectedOccupancy]);

  // API calls
  const { data: uniqueCompetitors } = useGetAllCompetitorsName();
  const { data: allHostelsData } = useGetHostelsNameandId();
  const { getCompetitorsByFilters, data, loading, error } =
    useGetCompetitorsByFilters();

  const _competitorData = data?.getCompetitorsByFilters?.data || [];

  // Data for filters
  const getAllCompetitors = useMemo(() => {
    return uniqueCompetitors?.getUniqueCompetitors?.map((competitor) => ({
      value: competitor,
      label: competitor,
    }));
  }, [uniqueCompetitors]);

  const HostelsList = useMemo(() => {
    return allHostelsData?.getHostelsNameandId?.map((item) => ({
      value: item?._id,
      label: item?.name,
    }));
  }, [allHostelsData]);

  // Handlers
  const handleCompetitorChange = (selectedOptions) => {
    setSelectedCompetitors(selectedOptions);
  };

  const handleSearch = () => {
    // Update the date ranges and table header dates using tempDateRanges
    setDateRangesForMap((prevDateRanges) => {
      const newDateRanges = [...tempDateRanges]; // Use the latest tempDateRanges
      setDatesToShowToTableHeader(
        generateFormattedDates(newDateRanges[0], newDateRanges.length)
      );
      
      // Trigger the API call with updated filters
      getCompetitorsByFilters({
        variables: {
          filter: {
            ...variables.filter,
            from: newDateRanges[0],
            to: newDateRanges.at(-1),
          },
        },
      });
  
      return newDateRanges; // Update the state
    });
  };

  const handleReset = () => {
    // Reset all filters and dates in one click
    setSelectedCompetitors([]);
    setSelectedPlatform(["All"]);
    setSelectedOccupancy({ value: 1, label: "1" });
    setHostel([]);

    const newDateRangesForMap = generateDateRange(new Date(), null, 8);
    setTempDateRanges(newDateRangesForMap); // Reset temp date range
    setDateRangesForMap(newDateRangesForMap); // Update the final date ranges
    setDatesToShowToTableHeader(generateFormattedDates(new Date(), 8));

    // Trigger the API with reset values
    getCompetitorsByFilters({
      variables: {
        filter: {
          from: newDateRangesForMap[0],
          to: newDateRangesForMap.at(-1),
          platform: ["All"],
          competitor: [],
          hostels: ["All"],
          occupency: 1,
        },
      },
    });
  };

  const handlePlatformChange = (selectedOptions) => {
    setSelectedPlatform(selectedOptions);
  };

  const handleOccupancyChange = (selectedOption) => {
    setSelectedOccupancy(selectedOption);
  };

  const handleDateRangeChange = (selectedDates) => {
    const dateRanges = generateDateRange(selectedDates[0], selectedDates[1]);
    setTempDateRanges(dateRanges); // Set temporary date range
  };

  const handleHostelChange = (selectedOption) => {
    setHostel(selectedOption);
  };

  useEffect(() => {
    getCompetitorsByFilters({
      variables,
    });
  }, []); 

  if (loading) return <AppLoader />;

  return (
    <>
      <Row>
        <Col>
          <Button
            style={{
              fontSize: "12px",
              padding: "6px 12px",
              marginBottom: "20px",
              cursor: "pointer",
              outline: "none",
              backgroundImage:
                "linear-gradient(to right, #ff6ec4 0%, #7873f5 100%)",
              color: "white",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={() => setFiltersExpanded(!filtersExpanded)}
          >
            {filtersExpanded ? "Collapse Filters" : "Expand Filters"}
          </Button>
          {filtersExpanded && (
            <FilterSection
              uniqueCompetitors={getAllCompetitors}
              handleDateRangeChange={handleDateRangeChange}
              reviewDateFrom={tempDateRanges[0]}
              reviewDateTo={tempDateRanges.at(-1)}
              handleCompetitorChange={handleCompetitorChange}
              selectedCompetitors={selectedCompetitors}
              handlePlatformChange={handlePlatformChange}
              selectedOccupancy={selectedOccupancy}
              handleOccupancyChange={handleOccupancyChange}
              hostelsList={HostelsList}
              handleHostelChange={handleHostelChange}
              hostel={hostel}
              handleSearch={handleSearch}
              handleReset={handleReset}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            dateRanges={dateRangesForMap}
            datesToShowToTableHeader={datesToShowToTableHeader}
            selectedCompetitors={selectedCompetitors}
            data={_competitorData}
            showMinimumPriceForPropertiesIrrespectiveOfRoomType
            isloading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

export default CompetitorRates;
