import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const ConfirmDeleteForTheHosteller = (callback) => {
  return MySwal.fire({
    title: "Are you sure?",
    text: "This action will delete all the competitors related to the this Property .",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete",
    customClass: {
      confirmButton: "btn btn-danger mr-1",
      cancelButton: "btn btn-outline-danger",
    },
    buttonsStyling: false,
    
  }).then((result) => {
    if (result.isConfirmed) {
      callback && callback();

      MySwal.fire({
        icon: "success",
        title: "Deleted",
        text: "Deleted successfully!",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    }
  });
};

export default ConfirmDeleteForTheHosteller;
