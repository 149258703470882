import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Badge,
} from "reactstrap";
import styled from "styled-components";

const GoPaymentsSlideModal = ({
  open,
  handleToggle,
  submitDataHandler,
  children,
  large,
  disabled,
  editData,
  userRole,
  userDepartment,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSaveDisabled = () => {
    if (editData?.status.includes("Rejected")) {
      return true;
    }

    if (userRole === "Area Manager") {
      return editData?.status !== "Pending";
    } else if (userRole === "Regional Manager") {
      return editData?.status !== "AM Approved";
    } else if (userRole === "CEO") {
      return editData?.status !== "RM Approved";
    }

    // Additional validation for the Finance department
    // if (userDepartment === "Finance") {
    //   let totalPaidAmount = 0;

    //   const allPaymentsDone = editData?.paymentDetails?.every((detail) => {
    //     if (detail.paymentStatus === "Payment Done") {
    //       const amountPaid = parseInt(detail.amountPaid, 10) || 0;
    //       const tdsAmount = parseInt(detail.tdsAmount, 10) || 0;
    //       const sumOfPaidAndTds = amountPaid + tdsAmount;
    //       totalPaidAmount += sumOfPaidAndTds;
    //     }
    //     return detail.paymentStatus === "Payment Done";
    //   });

    //   if (
    //     allPaymentsDone &&
    //     totalPaidAmount === parseInt(editData?.totalAmount, 10)
    //   ) {
    //     return true;
    //   }
    // }

    return false;
  };

  const shouldShowSaveButton = () => {
    return (
      ["Ground Staff", "Area Manager", "Regional Manager", "CEO"].includes(
        userRole
      ) || userDepartment === "Finance"
    );
  };

  return (
    <StyledModalWrap
      unmountOnClose={true}
      isOpen={open}
      toggle={handleToggle}
      className={large ? "ml-auto m-0 modal-lg" : "ml-auto m-0"}
    >
      {isMobile ? (
        <ModalHeader toggle={handleToggle}>Payment Request Details</ModalHeader>
      ) : (
        <ModalHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          toggle={handleToggle}
        >
          <HeaderText className="mb-0.5">Payment Request Details</HeaderText>
          <HeaderBadgeWrapper>
            {editData?.status !== undefined && (
              <StatusBadgeWrapper>
                <StatusLabel>Current Status:</StatusLabel>
                <Badge
                  color="secondary"
                  className="status-badge animated pulse"
                >
                  {editData?.status}
                </Badge>
              </StatusBadgeWrapper>
            )}
            {editData?.requestID && (
              <RequestIDWrapper>
                <Badge color="primary" className="request-id-badge">
                  Request ID: {editData.requestID}
                </Badge>
              </RequestIDWrapper>
            )}
          </HeaderBadgeWrapper>
        </ModalHeader>
      )}

      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        {shouldShowSaveButton() && (
          <Button
            color="light"
            onClick={submitDataHandler}
            className="save-button"
            disabled={isSaveDisabled()}
          >
            Save
          </Button>
        )}
      </ModalFooter>
    </StyledModalWrap>
  );
};

const StyledModalWrap = styled(Modal)`
  .modal-content {
    min-height: 100vh;
  }
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
    .close {
      margin: 0;
    }
  }
  .status-badge {
    background-color: #3e6c09;
    margin-left: 12px;
  }
  .status-badge.animated.pulse {
    animation: pulse 1s infinite;
  }
  .request-id-badge {
    background-color: #007bff;
    margin-left: 18px;
  }
  .modal-footer {
    position: sticky;
    bottom: 0;
    z-index: 99;
    background-color: #f8f8f8;
  }
`;

const HeaderText = styled.div`
  font-size: 18px;
`;

const HeaderBadgeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StatusBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StatusLabel = styled.div`
  font-size: 14px;
  margin-right: 5px;
`;

const RequestIDWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default GoPaymentsSlideModal;
