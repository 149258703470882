import React from "react";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Table,
  Badge,
  Button,
} from "reactstrap";
import { CSVLink } from "react-csv";
import { Edit } from "react-feather";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

export default function EventBookingsTable({
  openModal,
  setId,
  tableData,
  dataCount,
  totalRevenue,
}) {
  const tableHeaderData = [
    { label: "Reservation ID", key: "reservationId" },
    { label: "Guest Name", key: "guestDetails.GuestName" },
    { label: "Hostel Name", key: "hostelDetails.name" },
    { label: "Event Name", key: "eventDetails.title" },
    { label: "Event Date", key: "eventDate" },
    { label: "Mobile No", key: "guestDetails.Mobile" },
    { label: "No. of pax", key: "quantity" },
    { label: "Price", key: "totalAmount" },
    { label: "Booking ID", key: "razorpayOrderId" },
    // { label: "Payment ID", key: "razorpayPaymentId" },
  ];

  const role = localStorage.getItem("role");

  const headerData = [
    "View",
    "Booking ID",
    "Res ID",
    "Guest Name",
    "Hostel Name",
    "Category",
    "Title",
    "Quantity",
    "Amount (inc. GST)",
    "Booking Date/Time",
    "Delivery Date/Time",
    "Status",
    "Rating",
  ];

  const tableHeadStyle = {
    minWidth: "140px",
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader
            style={{
              padding: "0px",
              alignItems: "center",
            }}
            className="px-2 mb-2"
          >
            <CardTitle className="mt-2">
              <div>
                <h2>
                  Events & Activities CMS ({dataCount} Events) | Total Revenue (
                  {totalRevenue?.toFixed(2)})
                </h2>
              </div>
            </CardTitle>

            {/* Have added the condition because we have to disable the export button for Ground Operation */}
            {/* {role !== "Ground Staff" && (
              <CSVLink
                headers={headers}
                data={exportData}
                filename={`events_list.csv`}
              >
                <Button.Ripple
                  style={{
                    height: "40px",
                    marginTop: "22px",
                    marginLeft: "40px",
                    borderRadius: "5px",
                    padding: "10px 40px",
                    outline: "none",
                    border: "0",
                  }}
                  color="success"
                  appearance="success"
                  type="button"
                >
                  Export
                </Button.Ripple>
              </CSVLink>
            )} */}
          </CardHeader>
          <Table responsive size="sm" hover>
            <thead>
              <tr>
                {headerData?.map((item) => {
                  return (
                    <th key={item} style={tableHeadStyle}>
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item, index) => (
                <tr key={index}>
                  <td style={{ width: "5%" }}>
                    <Button
                      href="/"
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td>{item?.bookingId}</td>
                  <td>
                    <Badge color="light-secondary">{item?.reservationId}</Badge>
                  </td>
                  <td>{item?.guestDetails?.GuestName}</td>
                  <td>{item?.hostel?.name}</td>
                  <td>{item?.eventDetails?.category}</td>
                  <td>{item?.eventDetails?.title}</td>
                  <td>{item?.quantity}</td>
                  <td>{item?.totalAmount}</td>
                  <td>
                    {new Date(item?.createdAt).toLocaleDateString("en-IN", {
                      timeZone: "Asia/Kolkata",
                    })}
                    {" - "}
                    {new Date(item?.createdAt).toLocaleTimeString()}
                  </td>
                  <td>
                    {new Date(item?.eventDate).toLocaleDateString("en-IN", {
                      timeZone: "Asia/Kolkata",
                    })}
                    {" - "}
                    {new Date(item?.eventDetails?.time).toLocaleTimeString()}
                  </td>
                  <td>{item?.status}</td>
                  <td>{item?.rating}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
}
