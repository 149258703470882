import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CANCEL_PAID_OTA = gql`
  mutation cancelPaidOtaBookings($uniqueId: String!, $paymentId: String!) {
    cancelPaidOtaBookings(uniqueId: $uniqueId, paymentId: $paymentId) {
      status
      message
    }
  }
`;

export const useCancelPaidOtaBookings = () => {
  const [cancelPaidOtaBookings, { loading, error, data }] =
    useMutation(CANCEL_PAID_OTA);

  useLoadingHandler(loading);

  const cancelPaidOtaBookingsHandler = (uniqueId, paymentId) => {
    cancelPaidOtaBookings({
      variables: {
        uniqueId,
        paymentId,
      },
    });
  };

  return [cancelPaidOtaBookingsHandler, { loading, error, data }];
};
