import gql from "graphql-tag";

export const UPDATE_LANDINGPAGE_DETAILS = gql`
  mutation updateLandingPage(
    $slug: SlugEnum!
    $landingPageUpdate: LandingPageInput
  ) {
    updateLandingPage(slug: $slug, landingPageUpdate: $landingPageUpdate) {
      _id
    }
  }
`;

export const POST_ADD_WORKATION = gql`
  mutation createWorkation($workationInput: WorkationInput) {
    createWorkation(workationInput: $workationInput) {
      success
      message
    }
  }
`;

export const POST_ADD_HOSTEL = gql`
  mutation createHostel($hostelInput: HostelInput) {
    createHostel(hostelInput: $hostelInput) {
      name
    }
  }
`;

export const POST_ADD_TRIP = gql`
  mutation createTrip($tripInput: TripInput) {
    createTrip(tripInput: $tripInput) {
      name
    }
  }
`;

export const UPDATE_HOSTEL = gql`
  mutation updateHostel($id: ID!, $hostelUpdate: HostelInput) {
    updateHostel(id: $id, hostelUpdate: $hostelUpdate) {
      name
    }
  }
`;

export const UPDATE_TRIP = gql`
  mutation updateTrip($id: ID!, $tripUpdate: TripInput) {
    updateTrip(id: $id, tripUpdate: $tripUpdate) {
      name
    }
  }
`;

export const UPDATE_WORKATION = gql`
  mutation updateWorkation($id: ID!, $workationUpdate: WorkationInput) {
    updateWorkation(id: $id, workationUpdate: $workationUpdate) {
      _id
    }
  }
`;

export const UPLOAD_TRIP_IMAGE = gql`
  mutation uploadTripPhotos($files: [Upload]!) {
    uploadTripPhotos(files: $files) {
      filename
    }
  }
`;

export const UPLOAD_HOSTEL_IMAGE = gql`
  mutation uploadHostelPhotos($files: [Upload]!, $type: String, $hostelId: ID) {
    uploadHostelPhotos(files: $files, type: $type, hostelId: $hostelId) {
      filename
    }
  }
`;

export const ADD_RECOMMENDATION = gql`
  mutation createRecommendation($recommendationInput: RecommendationInput) {
    createRecommendation(recommendationInput: $recommendationInput) {
      _id
      name
      shortDesc
      duration
      paxNumber
      price
      type
      hostelId
      tripId
      productType
      tag
      image {
        url
        key
      }
    }
  }
`;

export const UPDATE_RECOMMENDATION = gql`
  mutation updateRecommendationByProductId(
    $id: ID!
    $siteType: siteTypeEnum
    $productType: productTypeEnum
    $recommendationUpdate: RecommendationInput
  ) {
    updateRecommendationByProductId(
      id: $id
      productType: $productType
      siteType: $siteType
      recommendationUpdate: $recommendationUpdate
    ) {
      _id
      name
      shortDesc
      duration
      paxNumber
      price
      type
      hostelId
      tripId
      productType
      tag
      image {
        url
        key
      }
    }
  }
`;

export const UPDATE_AMENITIES = gql`
  mutation updateAminity($id: ID!, $aminityUpdate: InputAminity) {
    updateAminity(id: $id, aminityUpdate: $aminityUpdate) {
      name
      icon
      key
    }
  }
`;

export const DELETE_AMENITY = gql`
  mutation deleteAminity($id: ID!) {
    deleteAminity(id: $id) {
      status
      message
    }
  }
`;

export const DELETE_HOSTEL = gql`
  mutation deleteHostelById($id: ID!) {
    deleteHostelById(id: $id) {
      _id
    }
  }
`;

export const DELETE_TRIP = gql`
  mutation deleteTripById($id: ID!) {
    deleteTripById(id: $id) {
      _id
    }
  }
`;

export const DELETE_WORKATION = gql`
  mutation deleteWorkationById($id: ID!) {
    deleteWorkationById(id: $id) {
      _id
    }
  }
`;

export const CREATE_AMENITIES = gql`
  mutation createAminity($body: InputAminity) {
    createAminity(body: $body) {
      name
      icon
      key
    }
  }
`;

export const SIGNED_URL = gql`
  mutation getSignedUrlHostelObj($fileName: String) {
    getSignedUrlHostelObj(fileName: $fileName) {
      url
    }
  }
`;

export const IMAGE_UPLOAD = gql`
  mutation uploadImage($file: Upload!, $keyname: String) {
    uploadImage(file: $file, keyname: $keyname) {
      url
      key
    }
  }
`;

export const HOSTEL_IMAGE = gql`
  mutation addImage($id: ID!, $imageData: AddImageInput) {
    addImage(id: $id, imageData: $imageData) {
      _id
    }
  }
`;

// ! createDestination added by Ashwin
export const POST_ADD_DESTINATION = gql`
  mutation createDestination($destinationInput: DestinationInput) {
    createDestination(destinationInput: $destinationInput) {
      destination
    }
  }
`;

// ! updateDestination added by Ashwin
export const UPDATE_DESTINATION = gql`
  mutation updateDestination($id: ID!, $destinationUpdate: DestinationInput) {
    updateDestination(id: $id, destinationUpdate: $destinationUpdate) {
      destination
    }
  }
`;

// ! updateHomePage added by Ashwin
export const UPDATE_HOMEPAGE_DETAILS = gql`
  mutation updateHomePage($id: ID, $homePageUpdate: HomePageInput) {
    updateHomePage(id: $id, homePageUpdate: $homePageUpdate) {
      _id
    }
  }
`;

// ! updateWorkationLandingPage added by Ashwin
export const UPDATE_WORKATION_LANDINGPAGE_DETAILS = gql`
  mutation updateWorkationLandingPage(
    $slug: String
    $workationLandingPageUpdate: WorkationLandingPageInput
  ) {
    updateWorkationLandingPage(
      slug: $slug
      workationLandingPageUpdate: $workationLandingPageUpdate
    ) {
      _id
    }
  }
`;

// mutation {
//   addImage(id: "62501122661b63ec4a9993c3", imageData: {
//     image: "String"
//     key: "String"
//     type: general
//   }) {
//    _id
//   }
// }

export const CREATE_TRAVEL_PACKAGES = gql`
  mutation createTravelPackage($travelPackageInput: TravelPackageInput) {
    createTravelPackage(travelPackageInput: $travelPackageInput) {
      hostelId
      memberShipType
      roomId
      roomName
      checkInDate
      checkOutDate
      meal
      isActive
    }
  }
`;

export const UPDATE_TRAVEL_PACKAGES = gql`
  mutation updateTravelPackage(
    $id: ID
    $travelPackageInput: TravelPackageInput
  ) {
    updateTravelPackage(id: $id, travelPackageInput: $travelPackageInput) {
      _id
    }
  }
`;

export const CREATE_HOMEPAGE = gql`
  mutation createHomePage($homePageInput: HomePageInput) {
    createHomePage(homePageInput: $homePageInput) {
      slug
      status
      message
    }
  }
`;

export const UPDATE_HOSTEL_FOOD_CHARGE = gql`
mutation ($hostelFoodChargeList:JSON){
    updateHostelFoodCharge(hostelFoodChargeList:$hostelFoodChargeList){
        name
    }
}
`
