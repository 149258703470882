import React, { useState, useEffect } from 'react'
import { Button, Col, FormGroup, Label, Row, Table } from 'reactstrap'
import Select from "react-select";
import { useGetXlsxFormatforEmployee, useUploadMonthlyValuesEmployeeMonthlySalary, useUploadXlsxFileforEmployeePerformanceEval, useUploadXlsxFileforEmployeeProfile, useUploadXlsxFileforEmployeeSalaryStructure, useUploadXlsxFileforEmployeeTrainingScore } from '../../../api/dataSource';
import { v4 as uuidv4 } from "uuid";
import toaster from "@components/common/Toaster";
import UploadFileProgress from './UploadFileProgress';


const ImportFiles = () => {
    const [importType, setImportType] = useState(null);
    const [tableHeaderData, setTableHeaderData] = useState(null);
    const [fileList, setFileList] = useState([])

    const [generateXlsxForEmployeeHandler, { data: XlsxFormat }] = useGetXlsxFormatforEmployee()
    const [uploadXlsxFileHandler, { data: uploadXlsxData }] =
        useUploadXlsxFileforEmployeeProfile();
    const [uploadXlsxFileHandlerTrainingScore, { data: uploadXlsxDataTrainigScore }] =
        useUploadXlsxFileforEmployeeTrainingScore();
    const [uploadXlsxFileHandlerPerformanceEvaluation, { data: uploadXlsxDataPerformanceEval }] =
        useUploadXlsxFileforEmployeePerformanceEval();
    const [uploadXlsxFileHandlerSalaryStructure, { data: uploadSalaryStructureData }] =
        useUploadXlsxFileforEmployeeSalaryStructure()
    const [uploadMonthlyValuesHandler, { data: uploadMonthlyValuesData }] =
        useUploadMonthlyValuesEmployeeMonthlySalary()

    const importTypesList = [
        { label: "Create Employee", value: "createEmployee" },
        { label: "Update Employee", value: "updateEmployee" },
        { label: "Training Score", value: "trainingScore" },
        { label: "Employee Performance Eval", value: "performanceEval" },
        { label: "Employee salary structure", value: "salaryStructure" },
        { label: "Monthly salary imports", value: "employeeMonthlySalaryImports" },
        { label: "Employee Documents", value: "employeeDocuments" },

    ];
    const createEmployeeTableHeaders = ["Hostel", "Prefix", "FullName", "SpouceFullName", "FathersFullName", "EmployeeCode", "DateOfBirth", "Gender", "BloodGroup", "MaritalStatus", "PersonalEmail", "Department", "SubDepartment", "Designation", "Aadhaar", "PresentAddressLine1", "PresentAddressLine2", "PresentCity", "PresentState", "PresentPostalCode", "PresentCountry", "PermanentAddressLine1", "PermanentAddressLine2", "PermanentCity", "PermanentState", "PermanentPostalCode", "PermanentCountry", "MobileNumber", "SkillType", "DateOfJoining", "EmploymentType", "EmploymentStatus", "UAN", "ESIC", "PAN", "BankName", "BranchName", "AccountNumber", "ifscCode", "EmergencyName", "EmergencyNumber", "CurrentCTC", "NextAppraisalDate"];


    const updateEmployeeTableHeaders = ["Hostel", "EmployeeCode", "UAN", "PresentCity", "PermanentCountry", "AccountNumber", "ifscCode", "DateOfBirth"];


    const trainingScoreTableHeaders = [
        "EmployeeCode",
        "Result"
    ];

    const performanceEvaluationTableHeaders = [
        "EmployeeCode", "EvaluationTitle", "Month", "Year", "Score", "Remark"

    ]

    const salaryStructureTableHeaders = [
        "EmployeeCode",
        "FromDate",
        "ToDate",
        "YearlyCTC",
        "MonthlyCTC",
        "BasicSalary",
        "HRA",
        "StatutoryBonus",
        "SpecialAllowance",
        "GrossSalary",
        "PF",
        "ESIC",
        "PT",
        "LWF",
        "StayDeduction",
        "OtherDeduction",
        "MedicalInsurance",
        "TotalDeduction",
        "NetSalary",
        "EmployerPF",
        "EmployerLWF",
        "EmployerESIC",
        "EmployerOthers",
        "FixedComponentCTC",
        "Variable",
        "TotalCTC",
        "Arrers",
        "Advance",
        "ShortCashBalance",
        "ProductivityIncentive",
        "NPRecovery",
        "Recovery",
        "NoticePeriodShort"
    ];

    const monthlySalaryImportsHeaders = [
        "EmployeeCode",
        "Year",
        "Month",
        "Arrers",
        "ESIC",
        "PT",
        "EMPLWF",
        "TDS",
        "MedicalInsurance",
        "NPRecovery",
        "ShortCashCollection",
        "Recovery",
        "Advance",
        "LeaveEncashment",
        "EmployerESIC",
        "EmployerEMPLWF",
        "EmployerOthers"
    ]


    const employeeXlsxFile = async (e) => {
        if (importType?.value === "createEmployee" || importType?.value === "updateEmployee") { uploadXlsxFileHandler(e[0]); }
        else if (importType?.value === "trainingScore") {
            const response = await uploadXlsxFileHandlerTrainingScore(e[0]);
            if (response?.data?.uploadXlsxFileForEmployeeTrainingScore?.status) {
                toaster(
                    "success",
                    `${response?.data?.uploadXlsxFileForEmployeeTrainingScore?.message}`
                );
            }
        } else if (importType?.value === "performanceEval") {
            const response = await uploadXlsxFileHandlerPerformanceEvaluation(e[0]);
            console.log(response)
            if (response?.data?.uploadXlsxFileForEmployeePerformanceEval?.status) {
                toaster(
                    "success",
                    `${response?.data?.uploadXlsxFileForEmployeePerformanceEval?.message}`
                );
            }
        } else if (importType?.value === "salaryStructure") {
            const response = await uploadXlsxFileHandlerSalaryStructure(e[0]);
            console.log(response)
            if (response?.data?.uploadXlsxFileForEmployeeSalaryStructure?.status) {
                toaster(
                    "success",
                    `${response?.data?.uploadXlsxFileForEmployeeSalaryStructure?.message}`
                );
            }
        } else if (importType?.value === "employeeMonthlySalaryImports") {
            const response = await uploadMonthlyValuesHandler(e[0]);
            if (response?.data?.uploadXlsxFileForEmployeeSalaryStructure?.status) {
                toaster(
                    "success",
                    `${response?.data?.uploadXlsxFileForEmployeeSalaryStructure?.message}`
                );
            }
        }

    };

    useEffect(() => {
        if (importType?.value === "createEmployee") { setTableHeaderData(createEmployeeTableHeaders) }
        else if (importType?.value === "updateEmployee") { setTableHeaderData(updateEmployeeTableHeaders) }
        else if (importType?.value === "trainingScore") { setTableHeaderData(trainingScoreTableHeaders) }
        else if (importType?.value === "performanceEval") { setTableHeaderData(performanceEvaluationTableHeaders) }
        else if (importType?.value === "salaryStructure") { setTableHeaderData(salaryStructureTableHeaders) }
        else if (importType?.value === "employeeMonthlySalaryImports") { setTableHeaderData(monthlySalaryImportsHeaders) }
    }, [importType])

    useEffect(() => {
        if (uploadXlsxData) {
            // refetch();
            if (uploadXlsxData?.uploadXlsxFileForEmployeeProfile?.status) {
                toaster(
                    "success",
                    `${uploadXlsxData?.uploadXlsxFileForEmployeeProfile?.message}`
                );
            }
        }
    }, [uploadXlsxData]);

    useEffect(() => {
        // console.log(checkinDataForExport?.generateXlsxWebCheckinList?.data)
        if (XlsxFormat?.getXlsxFormatforEmployOperations?.data) {
            if (XlsxFormat?.getXlsxFormatforEmployOperations?.data.length > 0) {
                const byteArray = new Uint8Array(
                    XlsxFormat?.getXlsxFormatforEmployOperations?.data
                );
                const blob = new Blob([byteArray], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${importType?.value}.xlsx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [XlsxFormat]);

    useEffect(() => {
        console.log(uploadMonthlyValuesData?.uploadMonthlyValuesAndCalculateMonthlySalary)
        if (uploadMonthlyValuesData?.uploadMonthlyValuesAndCalculateMonthlySalary) {
            toaster("success", uploadMonthlyValuesData?.uploadMonthlyValuesAndCalculateMonthlySalary)
        }
    }, [uploadMonthlyValuesData])

    const createDocumentData = async (list) => {
        setFileList(list)
    };

    return (
        <div>

            <Row>
                <Col sm="3">
                    <FormGroup className="w-100">
                        <strong>
                            <Label className="form-label" for="importType">
                                Import type
                            </Label>
                        </strong>
                        <Select
                            isSearchable={true}
                            isClearable={true}
                            id="importType"
                            name="importType"
                            value={importType}
                            options={importTypesList}
                            onChange={(value) => setImportType(value)}
                            classNamePrefix="select"
                        />
                    </FormGroup>
                </Col>
                {importType && importType?.value !== "employeeDocuments" && <Col sm="1">
                    <>
                        <label
                            htmlFor={`addEmployee`}
                            className="border border-2 rounded w-100 text-center"
                            style={{
                                fontSize: "15px",
                                minWidth: "100px",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                fontWeight: "bold",
                                marginTop: "21px",
                                color: "#fff",
                                cursor: "pointer",
                                backgroundColor: "#0099cc",
                            }}
                        >
                            Upload
                        </label>
                        <input
                            type="file"
                            className="d-none"
                            id={`addEmployee`}
                            name="addEmployee"
                            onChange={async (e) => {
                                employeeXlsxFile(e.target.files);
                            }}
                        />
                    </>
                </Col>}

                {
                    importType?.value === "employeeDocuments" && <Col sm="1">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <label
                                htmlFor={`documents`}
                                className="border border-2 rounded w-100 text-center"
                                style={{
                                    fontSize: "15px",
                                    minWidth: "100px",
                                    paddingTop: "8px",
                                    paddingBottom: "8px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    fontWeight: "bold",
                                    marginTop: "21px",
                                    color: "#fff",
                                    cursor: "pointer",
                                    backgroundColor: "#0099cc",
                                }}
                            >
                                Upload
                            </label>
                            <input
                                type="file"
                                className="d-none"
                                multiple
                                id={`documents`}
                                name="documents"
                                onChange={async (e) => {
                                    createDocumentData(e.target.files);
                                }}
                            />
                        </div>
                    </Col>
                }


                {importType && importType?.value !== "employeeDocuments" && <Col sm="3">
                    <Button.Ripple
                        color="light"
                        type="submit"
                        style={{ marginTop: "21px", marginLeft: "30px" }}
                        onClick={() => generateXlsxForEmployeeHandler(importType.value)}
                    >
                        Get Xlsx format
                    </Button.Ripple>
                </Col>}



            </Row>
            {
                fileList.length > 0 && <Row>
                    <Col sm="6">
                        <UploadFileProgress files={fileList} />
                    </Col>
                </Row>
            }


            <Table responsive bordered>
                {/* <thead>
                    <tr>
                        {tableHeaderData?.map((item) => {
                            return <th key={uuidv4()}></th>;
                        })}
                    </tr>
                </thead> */}
                <tbody >
                    {tableHeaderData?.map((item) => (
                        <td>{item}</td>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default ImportFiles