
import React, { useEffect, useState } from 'react'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import Select from "react-select";
import { selectThemeColors } from "@utils";
import toaster from "@components/common/Toaster";
import Flatpickr from "react-flatpickr";
import { useGetDataForRequestReport } from '../../../api/WebApp/UserRequest/Queries';
import { useGetHostelsNameandId } from '../../../api/dataSource';
import RequestReportTable from './RequestReportTable';

export const RequestReport = () => {
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [hostel, setHostel] = useState(null)
    const [hostelslist, setHostelslist] = useState([])
    const [tableData, setTableData] = useState(null)

    const { data: HostelsData } = useGetHostelsNameandId();

    const [queryHandler, { data }] = useGetDataForRequestReport()

    const handleDateRange = (data, key) => {
        if (key === "fromDate") {
            setFromDate(data)
        }
        else {
            setToDate(data)
        }
    }

    const handleSearch = () => {

        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);

        const fromDatePlus12Months = new Date(fromDateObj);
        fromDatePlus12Months.setMonth(fromDatePlus12Months.getMonth() + 11);

        if (fromDate !== null && toDate !== null && toDateObj <= fromDatePlus12Months &&
            toDateObj >= fromDateObj) {

            queryHandler(fromDateObj, toDateObj, hostel?.map((id) => id?.value))
        }
        else {
            toaster(
                "error",
                "Please select Dates with end date, should be less than or equal to 12 months from the start date"
            );
        }

    }


    const handleReset = () => {
        setFromDate(null)
        setToDate(null)
        setHostel(null)
        setTableData(null)
    }



    useEffect(() => {
        if (data?.getRequestwiseReport?.length > 0) {
            setTableData(data?.getRequestwiseReport);
        } else if (data?.getRequestwiseReport?.length === 0) {
            setTableData([]);
        }

    }, [data?.getRequestwiseReport]);

    useEffect(() => {
        const allowedHostel = JSON.parse(localStorage.getItem("hostels"));
        if (HostelsData?.getHostelsNameandId?.length) {
            const filteredHostels =
                allowedHostel !== null && allowedHostel?.length !== 0
                    ? allowedHostel?.map((hostelID) => {
                        return HostelsData?.getHostelsNameandId?.find(
                            (item) => item._id === hostelID
                        );
                    })
                    : HostelsData?.getHostelsNameandId;

            let hostelList = filteredHostels?.map((item) => {
                return { label: item?.name, value: item?._id };
            });
            setHostelslist(hostelList);
        }
    }, [HostelsData]);


    return (
        <div>
            <Row>
                <Col sm="2">
                    <FormGroup className="w-100">
                        <Label className="form-label" for="fromDate">
                            <strong>Start Date</strong>
                        </Label>
                        <Flatpickr
                            className="form-control bg-white border-dark text-dark"
                            value={fromDate}
                            onChange={(date) => {
                                handleDateRange(date, "fromDate");
                                //setFromDate(dateFormatter(new Date(date)));
                            }}
                            id="fromDate"
                            placeholder="From Date"
                        />
                    </FormGroup>
                </Col>
                <Col sm="2">
                    <FormGroup className="w-100">
                        <Label className="form-label" for="toDate">
                            <strong>End Date</strong>
                        </Label>
                        <Flatpickr
                            className="form-control bg-white border-dark text-dark"
                            value={toDate}
                            onChange={(date) => {
                                //setToDate(dateFormatter(new Date(date)));
                                handleDateRange(date, "toDate");
                            }}
                            id="toDate"
                            placeholder="To Date"
                        />
                    </FormGroup>
                </Col>
                {hostelslist?.length !== 1 && (
                    <Col sm="2" style={{ zIndex: 3 }}>
                        <FormGroup className="w-100">
                            <Label className="form-label" for="cafe">
                                <strong>Hostel</strong>
                            </Label>
                            <Select
                                isClearable={true}
                                isSearchable={true}
                                theme={selectThemeColors}
                                isMulti
                                id="hostel"
                                name="hostel"
                                value={hostel}
                                closeMenuOnSelect={false}
                                options={hostelslist}
                                onChange={(value) => {
                                    setHostel(value);
                                }}
                                classNamePrefix="select"
                            />
                        </FormGroup>
                    </Col>
                )}
                <Col className="" sm="3" style={{ display: "flex", gap: "30px" }}>
                    <Button
                        style={{
                            backgroundColor: "#ffe700",
                            height: "40px",
                            marginTop: "22px",
                            borderRadius: "5px",
                            padding: "10px 20px",
                            outline: "none",
                            border: "0",
                        }}
                        color="white"
                        appearance="primary"
                        type="button"
                        onClick={handleSearch}
                    >
                        Search
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "#ff9999",
                            height: "40px",
                            marginTop: "22px",
                            //marginLeft: "40px",
                            borderRadius: "5px",
                            padding: "10px 20px",
                            outline: "none",
                            border: "0",
                        }}
                        color="danger"
                        appearance="primary"
                        type="button"
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                </Col>
            </Row>
            {tableData?.length > 0 ? (
                <div style={{ overflowY: "auto" }}>
                    <RequestReportTable
                        tableData={tableData}
                    />
                </div>
            ) : tableData?.length === 0 ? (
                <h5 style={{ textAlign: "center" }}>No requests</h5>
            ) : (
                <h5 style={{ textAlign: "center" }}>Please select Date Range</h5>
            )}
        </div>
    )
}
