import { useState, useEffect } from "react";
import { FormGroup, Label, Row, Col, Input } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import Flatpickr from "react-flatpickr";
import { dateFormatter } from "@utils";
import Select from "react-select";
import toaster from "@components/common/Toaster";
import {
  useGetEmployeeProfileById,
  useUpdateEmployeeProfile,
} from "../../../api/dataSource";
import { useParams } from "react-router";
import AppraisalTabel from "../EmployeeAttendance/AppraisalTabel";
import { DesignationList } from "../../utils";

const dateFormat = "d/m/Y";
const options = {
  dateFormat: dateFormat,
};

export default function Appraisal() {
  const { id } = useParams();
  const [currentCTC, setCurrentCTC] = useState(0);
  const [designation, setDesignation] = useState();
  const [nextAppraisalDate, setNextAppraisalDate] = useState();
  const userRole = localStorage.getItem("role");

  const { data: empData, refetch } = useGetEmployeeProfileById(id);
  const [updateEmployeeProfileHandler] = useUpdateEmployeeProfile();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let updateParams = {
        nextAppraisalDate,
        currentCTC,
        designation: designation,
      };
      const updateData = await updateEmployeeProfileHandler(id, updateParams);
      if (updateData?.data?.updateEmployeeProfile) {
        toaster("success", "Employee profile added successfully");
        refetch();
      } else {
        toaster("error", `seems like network issue`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (empData?.getEmployeeProfileById) {
      const data = empData?.getEmployeeProfileById;
      setDesignation(data?.designation);
      setCurrentCTC(data?.currentCTC);
      setNextAppraisalDate(data?.nextAppraisalDate);
    }
  }, [empData, refetch]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col sm="4">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="mobileNo">
                  Current CTC*
                </Label>
              </strong>
              <Input
                type="text"
                value={currentCTC}
                placeholder="Current CTC"
                onChange={(e) => {
                  if (!isNaN(Number(e.target.value))) {
                    setCurrentCTC(Number(e.target.value));
                  } else {
                    console.error("Please enter number only.");
                  }
                }}
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="mobileNo">
                  Designation*
                </Label>
              </strong>
              <Select
                isClearable={false}
                id="designation"
                name="designation"
                value={
                  DesignationList.find((item) => item?.value === designation) ||
                  null
                }
                options={DesignationList}
                onChange={(selectedOption) => {
                  setDesignation(selectedOption?.value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <FormGroup className="w-100">
              <strong>
                <Label className="form-label" for="mobileNo">
                  Next Appraisal Date*
                </Label>
              </strong>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={nextAppraisalDate}
                onChange={(date) =>
                  setNextAppraisalDate(new Date(dateFormatter(new Date(date))))
                }
                options={{
                  ...options,
                }}
                id="nextAppraisalDate"
                placeholder="Next Appraisal Date"
              />
            </FormGroup>
          </Col>
        </Row>

        {userRole !== "Area Manager" && <SaveDiscardBtnGroup />}
      </form>
      {empData?.getEmployeeProfileById?.appraisalLogs?.length > 0 && (
        <AppraisalTabel
          appraisalData={empData?.getEmployeeProfileById?.appraisalLogs}
          nextAppraisalDate={empData?.getEmployeeProfileById?.nextAppraisalDate}
        />
      )}
    </>
  );
}
