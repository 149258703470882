import { useEffect, useState } from "react";
import axios from "axios";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  DropdownItem,
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import { Edit } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { CSVLink } from "react-csv";
import { dateFormatHandler } from "@utils";
import { Badge } from "reactstrap";
import { reactFeatherIcon } from "../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Check In - Out Date",
  "Submission Time",
  "Hostel Name",
  "First Name",
  "Last Name",
  "Reservation Id",
  "Blacklisted",
  "Membership Type",
  "Room NO",
  "Occupancy",
  "Time of Arrival",
  "Gender",
  "Age",
  // "Country Code",
  // "Phone Number",
  // "Email",
  "How did you book The Hosteller",
  "Group Size",
  "Residing Country",
  "C FORM No.",
  "Residing State",
  "Have you stayed with The Hosteller before",
  "What would best describe you?",
  "Mode Of Transport",
  "Purpose of Travel",
  "Stayed in Last 12 Months",
  // "Gov. ID Proof",
  // "Gov. ID Num",
];

const WebCheckinTable = ({ openEditModal, setId, data, dataCount }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const headerStyle = {
    fontSize: "12px",
    whiteSpace: "nowrap",
    textAlign: "center",
  };

  // const getBadgeColor = (membershipType) => {
  //   switch (membershipType) {
  //     case "WANDERER":
  //       return "info";
  //     case "EXPLORER":
  //       return "primary";
  //     case "FREE_EXPLORER":
  //       return "danger";
  //     default:
  //       return "warning";
  //   }
  // };

  const getBadgeColor = (membershipType) => {
    membershipType = membershipType?.replace("FREE_EXPLORER", "EXPLORER");

    switch (membershipType) {
      case "WANDERER":
        return "info";
      case "EXPLORER":
        return "primary";
      default:
        return "warning";
    }
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader
            style={{
              padding: "0px",
              alignItems: "right",
            }}
            className="px-2 mb-2"
          >
            <CardTitle className="mt-2">
              <div>
                <h2>
                  Web Check In CMS ({dataCount ? dataCount : 0} Web Checkins)
                </h2>
              </div>
            </CardTitle>
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
                float: "right",
                marginLeft: "auto",
              }}
              //alignItems="right"
              color="white"
              appearance="primary"
              type="button"
              float="right"
              onClick={() => {
                axios.post(
                  "https://api.thehosteller.com/rest/v1/start-cron-from-admin"
                );
                alert("eZee Data Sync Started, Please check after 5 Minutes");
              }}
            >
              Sync eZee Data
            </Button>
          </CardHeader>
          <Table size="sm" responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return (
                    <th key={uuidv4()} style={headerStyle}>
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr
                  key={uuidv4()}
                  style={{
                    backgroundColor: item?.isBlackListed ? "black" : "",
                    color: item?.isBlackListed ? "#ffe700" : "",
                  }}
                >
                  <td style={headerStyle}>
                    <UncontrolledDropdown>
                      <DropdownItem
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          setId(item._id);
                          openEditModal();
                        }}
                      >
                        <Edit
                          className="mr-50"
                          color={reactFeatherIcon.iconColor}
                          size={reactFeatherIcon.iconSize}
                        />
                      </DropdownItem>
                    </UncontrolledDropdown>
                  </td>
                  <td style={headerStyle}>
                    {item?.checkInDate
                      ? dateFormatHandler(item?.checkInDate)
                      : ""}
                    -
                    {item?.checkOutDate
                      ? dateFormatHandler(item?.checkOutDate)
                      : ""}
                  </td>

                  <td style={headerStyle}>
                    {new Date(item?.createdAt).toLocaleString("en-IN", {
                      timeZone: "Asia/Kolkata",
                    })}
                  </td>

                  <td style={headerStyle}>{item?.hostelName}</td>
                  <td style={headerStyle}>{item?.firstName}</td>
                  <td style={headerStyle}>{item?.lastName}</td>
                  <td style={headerStyle}>{item?.reservationID}</td>
                  {/* <td style={headerStyle}>{item?.membershipType}</td> */}
                  <td style={headerStyle}>
                    {item?.isBlackListed ? (
                      item?.severity ? (
                        <a
                          href={`https://www.admin.thehosteller.com/webApp/edit/blacklistedGuests/${item?.blacklistId?._id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <>
                            {item?.blacklistId?.blacklistID
                              ? `${item?.blacklistId?.blacklistID} - `
                              : ""}{" "}
                            Yes -{" "}
                            <Badge
                              color={
                                item?.severity === "Low" ? "success" : "danger"
                              }
                            >
                              {item?.severity}
                            </Badge>
                          </>
                        </a>
                      ) : (
                        "Yes"
                      )
                    ) : (
                      "No"
                    )}
                  </td>
                  <td>
                    <Badge
                      color={getBadgeColor(
                        item?.membershipType === "FREE_EXPLORER"
                          ? "EXPLORER"
                          : item?.membershipType
                      )}
                    >
                      {item?.membershipType === "FREE_EXPLORER"
                        ? "EXPLORER"
                        : item?.membershipType}
                    </Badge>
                  </td>

                  <td style={headerStyle}>{item?.RoomNo}</td>
                  <td style={headerStyle}>{item?.NoOfGuest}</td>
                  <td style={headerStyle}>{item?.timeOfArrival}</td>
                  <td style={headerStyle}>{item?.gender}</td>
                  <td style={headerStyle}>{item?.age}</td>
                  {/* <td style={headerStyle}>{item?.countryCode}</td>
                  <td style={headerStyle}>{item?.phoneNumber}</td>
                  <td style={headerStyle}>{item?.email}</td> */}
                  <td style={headerStyle}>{item?.howDidYouBookTheHosteller}</td>
                  <td style={headerStyle}>{item?.groupSize}</td>
                  <td style={headerStyle}>{item?.residingCountry}</td>
                  <td style={headerStyle}>{item?.cFormAppNo}</td>
                  <td style={headerStyle}>{item?.residingState}</td>
                  <td style={headerStyle}>
                    {item?.haveYouStayedWithTheHostellerBefore}
                  </td>
                  <td style={headerStyle}>{item?.whatWouldBestDescribeYou}</td>
                  <td style={headerStyle}>{item?.modeOfTransport}</td>
                  <td style={headerStyle}>{item?.primaryPurpose}</td>
                  <td style={headerStyle}>{item?.stayedWithUsIn12Months}</td>
                  {/* <td style={headerStyle}>{item?.govtIdProof}</td>
                  <td style={headerStyle}>{item?.govtIdNumber}</td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default WebCheckinTable;
