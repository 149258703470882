import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CREATE_PRE_STAY_REFUND_REQUEST = gql`
  mutation createPreStayRefundRequest(
    $preStayRefundInput: RefundRequestPreStayInput
  ) {
    createPreStayRefundRequest(preStayRefundInput: $preStayRefundInput) {
      hostel {
        name
        _id
      }
    }
  }
`;

export const useCreatePreStayRefundRequest = () => {
  const [createPreStayRefundRequest, { loading, error, data, refetch }] =
    useMutation(CREATE_PRE_STAY_REFUND_REQUEST);

  useLoadingHandler(loading);

  const createPreStayRefundRequestHandler = (preStayRefundInput) => {
    createPreStayRefundRequest({
      variables: { preStayRefundInput },
    });
  };

  return [createPreStayRefundRequestHandler, { loading, error, data, refetch }];
};

export const UPDATE_PRE_STAY_REFUND_REQUEST = gql`
  mutation updatePreStayRefundRequest(
    $id: ID
    $updatePreStayRefund: RefundRequestPreStayInput
  ) {
    updatePreStayRefundRequest(
      id: $id
      updatePreStayRefund: $updatePreStayRefund
    ) {
      hostel {
        name
        _id
      }
    }
  }
`;

export const useUpdatePreStayRefundRequest = () => {
  const [updatePreStayRefundRequest, { loading, error, data, refetch }] =
    useMutation(UPDATE_PRE_STAY_REFUND_REQUEST);

  useLoadingHandler(loading);

  const updatePreStayRefundRequestHandler = (id, updatePreStayRefund) => {
    updatePreStayRefundRequest({
      variables: { id, updatePreStayRefund },
    });
  };

  return [updatePreStayRefundRequestHandler, { loading, error, data, refetch }];
};
