import React, { useEffect, useState } from 'react'
import { useGetAllCafewiseMenuForSoldOut } from '../../../api/Unbox/UnboxCafewise/Queries'
import Report from './Report'


const SoldoutReport = () => {

    const [tableData, setTableData] = useState([])


    const { data } = useGetAllCafewiseMenuForSoldOut()



    useEffect(() => {
        if (data?.getCafewiseMenuForSoldoutReport?.length > 0) {
            setTableData(data?.getCafewiseMenuForSoldoutReport)
        } else {
            setTableData([])
        }
    }, [data?.getCafewiseMenuForSoldoutReport])

    return (
        <div>
            {tableData?.length > 0 ? <Report tableData={tableData} />
                : <h5 style={{ textAlign: "center" }}>No Soldout items</h5>
            }
        </div>
    )
}

export default SoldoutReport