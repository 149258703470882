import React, { useState, useEffect } from "react";
import { Col, Row, FormGroup, Label, Button, Input } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import RefundRequestPostStayTable from "./RefundRequestPostStayTable";
import AddEditModal from "./AddEditModal";
import TablePagination from "../../@core/components/common/TablePagination";
import { useGetPaginatedPostStayRefundRequestList } from "../../api/RefundRequestPostStay/Queries";
import { useHostelList } from "../../api/dataSource";
import { selectThemeColors, dateFormatter } from "@utils";

const RefundRequestPostStay = () => {
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(1);
  const [id, setId] = useState("");
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [hostelIdArray, setHostelIdArray] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [status, setStatus] = useState(null);
  const [reservationID, setReservationID] = useState("");
  const [refundID, setRefundID] = useState("");
  const [guestName, setGuestName] = useState("");
  const [reasons, setReasons] = useState(null);
  const [checkout, setCheckout] = useState(null);
  const [reviewDateTo, setReviewDateTo] = useState(null);
  const [reviewDateFrom, setReviewDateFrom] = useState(null);
  const [hostel, setHostel] = useState([]);
  const [filterFields, setFilterFields] = useState({
    hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
  });
  const limit = 10;
  const [dataCount, setDataCount] = useState(0);
  const [refundType, setRefundType] = useState(null);

  const { data: allHostelsData } = useHostelList();
  const { data, refetch } = useGetPaginatedPostStayRefundRequestList({
    limit,
    page,
    filterFields,
  });

  const handleSearch = () => {
    const filterFields = {
      hostelIdArray:
        hostel.length > 0
          ? hostel?.map((hostel) => hostel.value)
          : JSON.parse(localStorage.getItem("hostels")),
      status: status?.value,
      refundReason: reasons?.value,
      checkOut: checkout?.value,
      reviewDateFrom,
      reviewDateTo,
      guestName,
      reservationID,
      refundID,
      refundType: refundType?.value,
    };
    setPage(1);
    setFilterFields(filterFields);
  };

  const handleReset = () => {
    setFilterFields({
      hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
    });
    setHostelIdArray(null);
    setReviewDateFrom(null);
    setReviewDateTo(null);
    setGuestName("");
    setReservationID("");
    setHostel([]);
    setStatus(null);
    setCheckout(null);
    setReasons(null);
    setRefundID("");
    setRefundType(null);
  };

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList, limit, page]);

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  useEffect(() => {
    if (data?.getPaginatedPostStayRefundRequestList?.data?.length > 0) {
      setTableData(data.getPaginatedPostStayRefundRequestList.data);
      setTotalPage(
        Math.ceil(data.getPaginatedPostStayRefundRequestList.count / limit)
      );
    } else {
      setTableData([]);
      setTotalPage(1);
    }
  }, [data]);

  useEffect(() => {
    setDataCount(data?.getPaginatedPostStayRefundRequestList?.count);
  }, [data?.getPaginatedPostStayRefundRequestList?.count]);

  useEffect(() => {
    if (id && data?.getPaginatedPostStayRefundRequestList?.data?.length > 0) {
      let findData = data?.getPaginatedPostStayRefundRequestList?.data?.find(
        (item) => item._id === id
      );
      setEditData(findData);
      setId(id);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedPostStayRefundRequestList?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const statusOptions = [
    { label: "Pending", value: "Pending" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
  ];

  const refundTypeOptions = [
    { label: "In-stay", value: "In-stay" },
    { label: "Post-stay", value: "Post-stay" },
  ];

  const reasonsOptions = [
    { label: "R&M", value: "R&M" },
    { label: "Cleanliness", value: "Cleanliness" },
    { label: "Safety", value: "Safety" },
    { label: "Staff misconduct", value: "Staff misconduct" },
    { label: "Co-traveler misconduct", value: "Co-traveler misconduct" },
    { label: "Non-serviceability", value: "Non-serviceability" },
    { label: "Blacklist", value: "Blacklist" },
    { label: "Others", value: "Others" },
  ];

  const checkoutOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  return (
    <div>
      <Row>
        <Col sm="3">
          <FormGroup>
            <Label for="hostel">
              <strong>Hostel Name</strong>
            </Label>
            <Select
              isClearable
              isSearchable
              theme={selectThemeColors}
              isMulti
              id="hostel"
              value={hostelIdArray}
              options={HostelsList}
              onChange={setHostelIdArray}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Date Range</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              id="reviewDate"
              placeholder="Date Range"
              options={{
                mode: "range",
              }}
              value={[reviewDateFrom, reviewDateTo]}
              onChange={(date) => {
                setReviewDateFrom(dateFormatter(date[0]));
                setReviewDateTo(dateFormatter(date[1]));
              }}
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="reasons">
              <strong>Reason</strong>
            </Label>
            <Select
              isClearable
              id="reasons"
              value={reasons}
              options={reasonsOptions}
              onChange={setReasons}
              classNamePrefix="select"
              // menuPlacement="top"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="guestName">
              <strong>Guest Name</strong>
            </Label>
            <Input
              id="guestName"
              placeholder="Guest Name"
              value={guestName}
              onChange={(e) => setGuestName(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="reservationID">
              <strong>Reservation ID</strong>
            </Label>
            <Input
              id="reservationID"
              placeholder="Reservation ID"
              value={reservationID}
              onChange={(e) => setReservationID(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="refundID">
              <strong>Refund ID</strong>
            </Label>
            <Input
              id="refundID"
              placeholder="Refund ID"
              value={refundID}
              onChange={(e) => setRefundID(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="status">
              <strong>Status</strong>
            </Label>
            <Select
              isClearable
              id="status"
              value={status}
              options={statusOptions}
              onChange={setStatus}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="status">
              <strong>Refund type</strong>
            </Label>
            <Select
              isClearable
              id="status"
              value={refundType}
              options={refundTypeOptions}
              onChange={setRefundType}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="status">
              <strong>Check out</strong>
            </Label>
            <Select
              isClearable
              id="checkout"
              value={checkout}
              options={checkoutOptions}
              onChange={setCheckout}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col className="mb-2">
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 40px",
            }}
            color="white"
            onClick={() => {
              handleSearch();
            }}
          >
            Search
          </Button>

          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 40px",
            }}
            color="danger"
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>

      <RefundRequestPostStayTable
        openModal={() => setOpenModal(!openModal)}
        setId={setId}
        data={tableData}
        dataCount={dataCount}
        editData={editData}
        refetch={refetch}
      />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

      <AddEditModal
        setId={setId}
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
      />
    </div>
  );
};

export default RefundRequestPostStay;
