import React, { useEffect, useState } from "react";
import RoomwiseADRReportTable from "./RoomwiseADRReportTable";
import {
  useGetMonthlyRevenueSalesReport,
  useGetMonthlySalesReport,
} from "../../../api/RoomwiseADRReport/Queries";
import { useHostelList } from "../../../api/dataSource";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import { selectThemeColors } from "@utils";
import Select from "react-select";

const monthList = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

const yearList = [
  { label: 2024, value: 2024 },
  { label: 2025, value: 2025 },
];

const RoomwiseADRReport = () => {
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState([]);
  const currentMonth = monthList?.filter(
    (item) => item?.value === new Date().getMonth() + 1 && item
  );
  const currentYear = yearList?.filter(
    (item) => item?.value === new Date().getFullYear() && item
  );
  const [month, setMonth] = useState(currentMonth[0]);
  const [year, setYear] = useState(currentYear[0]);
  const [tableData, setTableData] = useState([]);
  const [filterFields, setFilterFields] = useState({});
  const { data: allHostelsData } = useHostelList();

  useEffect(() => {
    if (allHostelsData?.getHostelList?.length) {
      let HostelsList = allHostelsData?.getHostelList?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  let { data, refetch } = useGetMonthlySalesReport(
    filterFields?.month,
    filterFields?.year,
    filterFields?.hostels
  );

  useEffect(() => {
    if (data?.getMonthlySalesReport?.length > 0) {
      setTableData(data?.getMonthlySalesReport);
    } else {
      setTableData([]);
    }
  }, [data]);

  const handleSearch = () => {
    const data = {
      hostels: hostel?.map((item) => item?.value),
      month: Number(month?.value),
      year: Number(year?.value),
    };
    setFilterFields(data);
  };

  const handleResetData = () => {
    setFilterFields({});
    setHostel([]);
    setMonth(currentMonth[0]);
    setYear(currentYear[0]);
    setTableData([]);
  };

  return (
    localStorage.getItem("modules")?.includes("Reports") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                Hostel
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                closeMenuOnSelect={false}
                options={hostelsList}
                onChange={(value) => setHostel(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="month">
                Month
              </Label>
              <Select
                isClearable={true}
                id="month"
                name="month"
                value={month}
                options={monthList}
                onChange={(value) => setMonth(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="year">
                Year
              </Label>
              <Select
                isClearable={true}
                id="year"
                name="year"
                value={year}
                options={yearList}
                onChange={(value) => setYear(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col style={{ marginTop: "22px", display: "flex" }}>
            <Button.Ripple
              onClick={handleSearch}
              style={{
                height: "40px",
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="primary"
              appearance="danger"
              type="button"
            >
              Search
            </Button.Ripple>
            <Button.Ripple
              onClick={handleResetData}
              style={{
                height: "40px",
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="danger"
              type="button"
            >
              Reset
            </Button.Ripple>
          </Col>
        </Row>
        {tableData.length > 0 ? (
          <RoomwiseADRReportTable data={tableData} refetch={refetch} />
        ) : (
          <h2 style={{ textAlign: "center", marginTop: "150px" }}>
            No Record Found
          </h2>
        )}
      </div>
    )
  );
};

export default RoomwiseADRReport;
