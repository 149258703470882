import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
} from "reactstrap";
import { useGetRefundGuestDataByReservationId } from "../../api/RefundRequestPostStay/Queries";
import RefudRequestAttachmentComponent from "../RefundRequestPostStay/RefudRequestAttachmentComponent";
import toaster from "../../@core/components/common/Toaster";

const RefundModal = ({ isOpen, toggle, editData, userRole, onSave }) => {
  const [hostel, setHostel] = useState(null);
  const [reservationID, setReservationID] = useState("");
  const [guestData, setGuestData] = useState({
    guestName: "",
    phoneNumber: "",
    email: "",
    bookingSource: "",
    bookingAmount: 0,
  });
  const [reason, setReason] = useState(null);
  const [initiatedBy, setInitiatedBy] = useState(null);
  const [comment, setComment] = useState("");
  const [refundAmount, setRefundAmount] = useState(null);
  const [attachmentList, setAttachmentList] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [dummyStateCaseMedia] = useState(null);
  const uploadAttachmentMedia = [{ label: "imageURL", value: "photo" }];

  const { data, fetchData, refetch } =
    useGetRefundGuestDataByReservationId(reservationID);

  const initiatedByOptions = [
    { label: "Dhriti", value: "Dhriti" },
    { label: "Joanne", value: "Joanne" },
    { label: "Aryan", value: "Aryan" },
  ];
  const requestReasonOptions = [
    { label: "R&M", value: "R&M" },
    { label: "Cleanliness", value: "Cleanliness" },
    { label: "Safety", value: "Safety" },
    { label: "Staff misconduct", value: "Staff misconduct" },
    { label: "Co-traveler misconduct", value: "Co-traveler misconduct" },
    { label: "Non-serviceability", value: "Non-serviceability" },
    { label: "Blacklist", value: "Blacklist" },
    { label: "Others", value: "Others" },
  ];

  useEffect(() => {
    if (isOpen && reservationID) {
      fetchData();
      refetch();
    }
  }, [isOpen, reservationID, fetchData, refetch]);

  const handleRefundAmountChange = (e) => {
    const value = e.target.value;

    if (!/^\d+$/.test(value)) {
      toaster("error", "Only whole numbers are allowed for the refund amount.");
      return;
    }

    if (value > guestData.bookingAmount) {
      toaster("error", "Refund amount cannot exceed the booking amount.");
    } else {
      setRefundAmount(value);
    }
  };

  // useEffect(() => {
  //   if (data?.getRefundGuestDataByReservationId) {
  //     const fetchedData = data?.getRefundGuestDataByReservationId;
  //     setGuestData({
  //       reservationID: fetchedData.reservationID,
  //       bookingAmount: fetchedData.bookingAmount,
  //       guestName: fetchedData.guestName,
  //       phoneNumber: fetchedData.phoneNumber,
  //       email: fetchedData.email,
  //       bookingSource: fetchedData.bookingSource,
  //     });
  //   }
  // }, [data]);

  useEffect(() => {
    if (data?.getRefundGuestDataByReservationId) {
      const fetchedData = data.getRefundGuestDataByReservationId;
      setGuestData({
        reservationID: fetchedData.reservationID,
        bookingAmount: fetchedData.bookingAmount,
        guestName: fetchedData.guestName,
        phoneNumber: fetchedData.phoneNumber,
        email: fetchedData.email,
        bookingSource: fetchedData.bookingSource,
      });
    } else if (data?.getRefundGuestDataByReservationId === null) {
      toaster(
        "error",
        "No data found. This Reservation ID may already exist, be invalid, or have no associated records."
      );
    }
  }, [data]);

  useEffect(() => {
    if (editData) {
      setHostel({
        label: editData?.hostel?.name,
        value: editData?.hostel?._id,
      });
      setReservationID(editData?.reservationId || "");
      setRefundAmount(editData?.refundAmount || 0);
      setReason({
        label: editData?.reason,
        value: editData?.reason,
      });
      setInitiatedBy({
        label: editData?.initiatedBy,
        value: editData?.initiatedBy,
      });
      setComment(editData?.comment || "");

      setAttachmentList(editData?.photo ? editData?.photo : []);

      setGuestData({
        guestName: editData?.guestData?.guestName || "",
        phoneNumber: editData?.guestData?.phoneNumber || "",
        email: editData?.guestData?.email || "",
        bookingSource: editData?.guestData?.bookingSource || "",
        bookingAmount: editData?.guestData?.bookingAmount || 0,
      });
    }
  }, [editData]);

  const validateFields = () => {
    console.log("Starting validation...");

    // Log the current state values
    console.log("State values during validation:");
    console.log("Hostel:", hostel);
    console.log("Reservation ID:", reservationID);
    console.log("Refund Amount:", refundAmount);
    console.log("Reason:", reason);
    console.log("Initiated By:", initiatedBy);
    console.log("Comment:", comment);
    console.log("Attachment List:", attachmentList);

    const errors = {};

    // Validate hostel
    if (!hostel) {
      errors.hostel = "Hostel name is required.";
      console.log("Validation failed: Hostel is missing.");
    } else {
      console.log("Hostel validation passed.");
    }

    // Validate reservation ID
    if (!reservationID) {
      errors.reservationID = "Reservation ID is required.";
      console.log("Validation failed: Reservation ID is missing.");
    } else {
      console.log("Reservation ID validation passed.");
    }

    // Validate refund amount
    if (!refundAmount || refundAmount <= 0) {
      errors.refundAmount = "Refund amount must be greater than zero.";
      console.log("Validation failed: Refund amount is missing or invalid.");
    } else if (refundAmount > guestData.bookingAmount) {
      errors.refundAmount = "Refund amount cannot exceed booking amount.";
      console.log("Validation failed: Refund amount exceeds booking amount.");
    } else {
      console.log("Refund amount validation passed.");
    }

    // Validate reason
    if (!reason || !reason.value) {
      errors.reason = "Reason is required.";
      console.log("Validation failed: Reason is missing.");
    } else {
      console.log("Reason validation passed.");
    }

    // Validate initiated by
    if (!initiatedBy || !initiatedBy.value) {
      errors.initiatedBy = "Initiated by is required.";
      console.log("Validation failed: Initiated by is missing.");
    } else {
      console.log("Initiated by validation passed.");
    }

    // Validate comment
    if (!comment || comment.trim() === "") {
      errors.comment = "Comment is required.";
      console.log("Validation failed: Comment is missing.");
    } else {
      console.log("Comment validation passed.");
    }

    // Set validation errors
    setValidationErrors(errors);

    console.log("Validation errors:", errors);
    const isValid = Object.keys(errors).length === 0;
    console.log("Validation status:", isValid ? "Valid" : "Invalid");

    return isValid;
  };

  const handleSubmit = () => {
    if (!validateFields()) return;

    const hostelId = hostel?.value;
    const hostelName = hostel?.label;

    // Prepare request data
    const requestData = {
      hostel: hostelId,
      hostelName: hostelName,
      reservationID,
      guestData,
      refundAmount: parseFloat(refundAmount),
      initiatedBy: initiatedBy?.value,
      reason: reason?.value,
      comment,
      photo: attachmentList,
    };

    if (onSave) {
      onSave(requestData);
    }

    toaster("success", "Refund details updated successfully!");
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>In-stay Refund Details</ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label for="hostel">Hostel Name*</Label>
                      <Select
                        isClearable={false}
                        isSearchable
                        id="hostel"
                        value={hostel}
                        onChange={(value) => setHostel(value)}
                        classNamePrefix="select"
                        isDisabled
                      />
                      {validationErrors.hostel && (
                        <p className="text-danger">{validationErrors.hostel}</p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col sm="12">
                    <FormGroup>
                      <Label for="reservationID">Reservation ID*</Label>
                      <Input
                        type="text"
                        value={reservationID}
                        id="reservationID"
                        placeholder="Reservation ID"
                        onChange={(e) => setReservationID(e.target.value)}
                        readOnly
                      />
                      {validationErrors.reservationID && (
                        <p className="text-danger">
                          {validationErrors.reservationID}
                        </p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col sm="6">
                    <FormGroup>
                      <Label>Guest Name</Label>
                      <Input
                        type="text"
                        value={guestData.guestName || ""}
                        readOnly
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="6">
                    <FormGroup>
                      <Label>Phone Number</Label>
                      <Input
                        type="text"
                        value={guestData.phoneNumber || ""}
                        readOnly
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="6">
                    <FormGroup>
                      <Label>Email</Label>
                      <Input
                        type="email"
                        value={guestData.email || ""}
                        readOnly
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="6">
                    <FormGroup>
                      <Label>Booking Source</Label>
                      <Input
                        type="text"
                        value={guestData.bookingSource || ""}
                        readOnly
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="12">
                    <FormGroup>
                      <Label>Booking Amount</Label>
                      <Input
                        type="number"
                        value={guestData.bookingAmount || 0}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col sm="12">
            <FormGroup>
              <Label for="refundAmount">Refund Amount*</Label>
              <Input
                type="number"
                id="refundAmount"
                value={refundAmount || ""}
                placeholder="Enter refund amount"
                onChange={handleRefundAmountChange}
                onWheel={(e) => e.target.blur()}
                step="1"
              />
              {validationErrors.refundAmount && (
                <p className="text-danger">{validationErrors.refundAmount}</p>
              )}
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup>
              <Label for="reason">Reason*</Label>
              <Select
                isClearable={false}
                isSearchable
                id="reason"
                options={requestReasonOptions}
                value={reason}
                // onChange={(selected) => setReason(selected)}
                onChange={(selected) => {
                  setReason(selected);
                  // setComment(selected ? selected.label : "");
                }}
                classNamePrefix="select"
              />
              {validationErrors.reason && (
                <p className="text-danger">{validationErrors.reason}</p>
              )}
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup>
              <Label for="initiatedBy">Initiated By*</Label>
              <Select
                isClearable={false}
                isSearchable
                id="initiatedBy"
                options={initiatedByOptions}
                value={initiatedBy}
                onChange={(selected) => setInitiatedBy(selected)}
                classNamePrefix="select"
              />
              {validationErrors.initiatedBy && (
                <p className="text-danger">{validationErrors.initiatedBy}</p>
              )}
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup>
              <Label for="comment">Comment*</Label>
              <Input
                type="textarea"
                value={comment}
                id="comment"
                rows="3"
                placeholder="Add comment"
                onChange={(e) => setComment(e.target.value)}
              />
              {validationErrors.comment && (
                <p className="text-danger">{validationErrors.comment}</p>
              )}
            </FormGroup>
          </Col>

          <Col sm="12">
            <Label className="form-label" for="attachmentList">
              Upload Image*
            </Label>
            <RefudRequestAttachmentComponent
              imageList={attachmentList}
              setImageList={setAttachmentList}
              editData={dummyStateCaseMedia}
              uploadType={uploadAttachmentMedia}
              userRole={userRole}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Submit
        </Button>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RefundModal;
