import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import ImageSection from "./ImageSection";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useParams } from "react-router";
import { useUpdateEmployeeProfile } from "../../../api/dataSource";

const UploadType = [
  { value: "aadhaar", label: "aadhaar card" },
  { value: "pan", label: "pan card" },
  { value: "bank", label: "bank cheque / passbook" },
  { value: "educational", label: "educational certificates" },
  {
    value: "PrevOrganisationAppointmentOfferLetter",
    label: "previous organisation Appointment or Offer letter",
  },
  { value: "payslipsPrevEmp", label: "payslips for previous employee" },

  { value: "complianceDocuments", label: "compliance documents" },
  { value: "resume", label: "Resume" },
];

const Documents = () => {
  const { id } = useParams();
  const state = useSelector((state) => state?.cms);
  const [imageList, setImageList] = useState([]);

  const userRole = localStorage.getItem("role");

  const [updateEmployeeProfileHandler, { data: updateEmployeeProfileData }] =
    useUpdateEmployeeProfile();
  const dispatch = useDispatch();

  useEffect(() => {
    state?.tableData &&
      state.tableData.images?.length &&
      setImageList(state.tableData.images);
  }, [state?.tableData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const images = [];

    imageList.map((image) => {
      image.image &&
        images.push({
          type: image.type,
          image: image.image,
          key: image.key,
        });
    });

    updateEmployeeProfileHandler(id, { images: images }); // "hosteller" is used to get landing page doc
    toaster("success", "Submitted successfully");
    dispatch(
      tableDataHandler({
        ...state?.tableData,
        images,
      })
    );
  };

  return (
    <div className="mt-2">
      <form onSubmit={handleSubmit}>
        <Row>
          <ImageSection
            imageList={imageList}
            setImageList={setImageList}
            uploadType={UploadType}
          />
        </Row>
        {userRole !== "Area Manager" && <SaveDiscardBtnGroup />}
      </form>
    </div>
  );
};

export default Documents;
