import Router from "./router/Router";
import AppLoader from "@components/common/AppLoader";
import { useSelector } from "react-redux";
import { getFirebaseToken, onMessageListener } from "./firebase";
import { useEffect, useState } from "react";
import { useUpdateFirebaseToken, useCheckAccess } from "./api/dataSource";
import { toast } from "react-hot-toast";
import NotificationContainer from "./views/Connect/NotificationContainer";

const App = () => {
  // const audioUrl = process.env.PUBLIC_URL + '/sounds/notificationSound.mp3';
  const state = useSelector((state) => state.loader);
  const [updateFirebaseTokenHandler] = useUpdateFirebaseToken();
  const { data } = useCheckAccess();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationPayload, setNotificationPayload] = useState(null);

  useEffect(() => {
    if (data?.checkAccess) {
      localStorage.setItem("role", data?.checkAccess?.role);
      localStorage.setItem(
        "modules",
        JSON.stringify(data?.checkAccess?.modules)
      );
      localStorage.setItem("userId", data?.checkAccess?._id);
      localStorage.setItem(
        "hostels",
        JSON.stringify(data?.checkAccess?.hostels)
      );
      localStorage.setItem("department", data?.checkAccess?.department);
      localStorage.setItem("name", data?.checkAccess?.name);
      localStorage.setItem("email", data?.checkAccess?.email);
    }
  }, [data]);

  // useEffect(() => {
  //   if (data?.checkAccess) {
  //     localStorage.setItem("role", data?.checkAccess?.role);
  //     localStorage.setItem(
  //       "modules",
  //       JSON.stringify(data?.checkAccess?.modules)
  //     );
  //     localStorage.setItem("userId", data?.checkAccess?._id);
  //     localStorage.setItem(
  //       "hostels",
  //       JSON.stringify(data?.checkAccess?.hostels)
  //     );
  //     localStorage.setItem("department", data?.checkAccess?.department);
  //   } else localStorage.clear();
  //   // window.location.href = "https://www.admin.thehosteller.com/login";
  // }, [data]);

  useEffect(() => {
    async function saveFirebaseToken() {
      try {
        // const token = await getFirebaseToken();
        // const email = localStorage.getItem("email");

        //Coupon Reset
        const tokenReset = localStorage.getItem("tokenReset");
        if (tokenReset !== "1709") {
          localStorage.clear();
        }

        // if (email && token) {
        //   updateFirebaseTokenHandler(email, token);
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    saveFirebaseToken();
  }, []);

  // useEffect(() => {
  //   async function saveFirebaseToken() {
  //     try {
  //       const storedToken = localStorage.getItem("firebaseToken");
  //       const email = localStorage.getItem("email");

  //       // Coupon Reset
  //       const tokenReset = localStorage.getItem("tokenReset");
  //       if (tokenReset !== "1214") {
  //         localStorage.clear();
  //       }

  //       if (!storedToken && email) {
  //         const newToken = await getFirebaseToken();
  //         if (newToken) {
  //           localStorage.setItem("firebaseToken", newToken);
  //           updateFirebaseTokenHandler(email, newToken);
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   }

  //   saveFirebaseToken();
  // }, []);

  onMessageListener()
    .then((payload) => {
      const disablePopup = localStorage.getItem("disablePopup") === "true";
      if (!disablePopup) {
        setShowNotification(true);
        setNotificationPayload(payload);
      }
      //   toast.success(`${payload?.data?.title}: ${payload?.data?.body}`, {
      //     duration: 10000,
      //     position: 'top-right',
      //   });
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "auto",
      }}
    >
      {state.loading && <AppLoader />}

      <Router />

      {showNotification && (
        <NotificationContainer
          setShowNotification={setShowNotification}
          notificationData={notificationPayload}
          setNotificationData={setNotificationPayload}
          showNotification={showNotification}
        />
      )}
    </div>
  );
};

export default App;
