import React, { useState, useEffect } from "react";
import { Col, Row, FormGroup, Label, Button, Input } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";

import RefundRequestPreStayTable from "./RefundRequestPreStayTable";
import AddEditModal from "./AddEditModal";
import TablePagination from "../../@core/components/common/TablePagination";
import toaster from "../../@core/components/common/Toaster";

import { useGetPaginatedPreStayRefundRequestList } from "../../api/RefundRequestPreStay/Queries";
import { useHostelList } from "../../api/dataSource";
import { selectThemeColors, dateFormatter } from "@utils";

const RefundRequestPreStay = () => {
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(1);
  const [id, setId] = useState("");
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [hostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [filterFields, setFilterFields] = useState({
    hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
  });
  const [status, setStatus] = useState(null);
  const [reservationID, setReservationID] = useState("");
  // const [guestName, setGuestName] = useState("");
  const [refundReason, setRefundReason] = useState(null);
  const [priority, setPriority] = useState(null);
  const [bookingSource, setBookingSource] = useState(null);
  const [refundInitiatedStartDate, setRefundInitiatedStartDate] =
    useState(null);
  const [refundInitiatedEndDate, setRefundInitiatedEndDate] = useState(null);
  const [refundProcessedStartDate, setRefundProcessedStartDate] =
    useState(null);
  const [refundProcessedEndDate, setRefundProcessedEndDate] = useState(null);
  const [markedOnEzee, setMarkedOnEzee] = useState(null);
  const [cancelOnEzee, setCancelOnEzee] = useState(null);
  const [refundType, setRefundType] = useState(null);

  const limit = 10;
  const [dataCount, setDataCount] = useState(0);

  const { data: allHostelsData } = useHostelList();
  const { data, loading, refetch } = useGetPaginatedPreStayRefundRequestList({
    limit,
    page,
    filterFields,
  });

  const handleSearch = () => {
    const filterFields = {
      hostelIdArray:
        hostel?.length > 0
          ? hostel?.map((hostel) => hostel?.value)
          : JSON.parse(localStorage.getItem("hostels")),

      status: status?.value,
      refundReason: refundReason?.value,
      priority: priority?.value,
      bookingSource: bookingSource?.value,
      refundInitiatedStartDate,
      refundInitiatedEndDate,
      refundProcessedStartDate,
      refundProcessedEndDate,
      // guestName,
      reservationID,
      markedOnEzee: markedOnEzee?.value,
      cancelOnEzee: cancelOnEzee?.value,
      refundType: refundType?.value,
    };
    setPage(1);
    setFilterFields(filterFields);
  };

  const handleReset = () => {
    setFilterFields({
      hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
    });
    setHostel([]);
    setRefundInitiatedStartDate(null);
    setRefundInitiatedEndDate(null);
    setRefundProcessedStartDate(null);
    setRefundProcessedEndDate(null);
    // setGuestName("");
    setReservationID("");
    setPriority(null);
    setStatus(null);
    setRefundReason(null);
    setBookingSource(null);
    setMarkedOnEzee(null);
    setCancelOnEzee(null);
    setRefundType(null);
    setPage(1);
  };

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList, limit, page]);

  useEffect(() => {
    refetch();
  }, [page, refetch]);

  useEffect(() => {
    if (data?.getPaginatedPreStayRefundRequestList?.data?.length > 0) {
      setTableData(data.getPaginatedPreStayRefundRequestList.data);
      setTotalPage(
        Math.ceil(data.getPaginatedPreStayRefundRequestList.count / limit)
      );
    } else {
      setTableData([]);
      setTotalPage(1);
    }
  }, [data]);

  useEffect(() => {
    setDataCount(data?.getPaginatedPreStayRefundRequestList?.count);
  }, [data?.getPaginatedPreStayRefundRequestList?.count]);

  useEffect(() => {
    if (id && data?.getPaginatedPreStayRefundRequestList?.data?.length > 0) {
      let findData = data?.getPaginatedPreStayRefundRequestList?.data?.find(
        (item) => item._id === id
      );
      setEditData(findData);
      setId(id);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedPreStayRefundRequestList?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const statusOptions = [
    { label: "Pending", value: "Pending" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
  ];

  const refundReasonOptions = [
    { label: "Over booking", value: "Over booking" },
    {
      label: "Natural calamities & unforeseen events",
      value: "Natural calamities & unforeseen events",
    },
    {
      label: "Normal refunds within policies",
      value: "Normal refunds within policies",
    },
    { label: "Non-serviceability", value: "Non-serviceability" },
    { label: "Others", value: "Others" },
  ];

  const priorityOptions = [
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
  ];

  const bookingSourceOptions = [
    { label: "MakeMytrip", value: "MakeMytrip" },
    { label: "OGP", value: "OGP" },
    { label: "Walk In", value: "Walk In" },
    { label: "TD", value: "TD" },
    { label: "TT", value: "TT" },
    { label: "makemytrip", value: "makemytrip" },
    { label: "TH HO", value: "TH HO" },
    { label: "Makemytrip", value: "Makemytrip" },
    { label: "Travel Desk", value: "Travel Desk" },
    { label: "Makemytrip.com", value: "Makemytrip.com" },
    { label: "Agoda.com", value: "Agoda.com" },
    { label: "AIESEC DIIT", value: "AIESEC DIIT" },
    { label: "Go-MMT", value: "Go-MMT" },
    { label: "Business to Business", value: "Business to Business" },
    { label: "FOCO Partner", value: "FOCO Partner" },
    {
      label: "BAAZI NETWORKS PRIVATE LIMITED",
      value: "BAAZI NETWORKS PRIVATE LIMITED",
    },
    { label: "Hostlesr", value: "Hostlesr" },
    { label: "go-mmt", value: "go-mmt" },
    { label: "On Ground Partner", value: "On Ground Partner" },
    { label: "WK", value: "WK" },
    { label: "Website", value: "Website" },
    { label: "THD", value: "THD" },
    { label: "On ground partners", value: "On ground partners" },
    { label: "Goibibo.com", value: "Goibibo.com" },
    { label: "Walkin", value: "Walkin" },
    { label: "Hostelworld.com", value: "Hostelworld.com" },
    { label: "Marketing", value: "Marketing" },
    { label: "TrippyTrips", value: "TrippyTrips" },
    { label: "Agoda", value: "Agoda" },
    { label: "Ranjith Singh", value: "Ranjith Singh" },
    {
      label: "ShootUp Technologies Pvt Ltd",
      value: "ShootUp Technologies Pvt Ltd",
    },
    { label: "Jai Test", value: "Jai Test" },
    { label: "Go ibibo", value: "Go ibibo" },
    { label: "Airbnb", value: "Airbnb" },
    { label: "OYO", value: "OYO" },
    { label: "Staff Accommodation", value: "Staff Accommodation" },
    { label: "Walk-In", value: "Walk-In" },
    { label: "TH-DL", value: "TH-DL" },
    { label: "Wanderon", value: "Wanderon" },
    {
      label: "Repco home finance limited",
      value: "Repco home finance limited",
    },
    {
      label: "Schoolsindia Services Pvt Ltd",
      value: "Schoolsindia Services Pvt Ltd",
    },
    { label: "SA", value: "SA" },
    { label: "HO", value: "HO" },
    { label: "Walk-in", value: "Walk-in" },
    { label: "The Hosteller", value: "The Hosteller" },
    { label: "Banbanjara", value: "Banbanjara" },
    { label: "HyperGuest", value: "HyperGuest" },
    { label: "B2B", value: "B2B" },
    {
      label: "Baazi Games Private Limited",
      value: "Baazi Games Private Limited",
    },
    { label: "Internet Booking Engine", value: "Internet Booking Engine" },
    {
      label: "HOYA LENS INDIA PRIVATE LIMITED",
      value: "HOYA LENS INDIA PRIVATE LIMITED",
    },
    {
      label: "The Hosteller Musoorie by the stream side",
      value: "The Hosteller Musoorie by the stream side",
    },
    { label: "Goibibo", value: "Goibibo" },
    { label: "PayTM", value: "PayTM" },
    { label: "Booking.com", value: "Booking.com" },
    { label: "Staff Accomodation", value: "Staff Accomodation" },
    { label: "Expedia", value: "Expedia" },
    { label: "BOG", value: "BOG" },
    { label: "Dorms.com", value: "Dorms.com" },
    { label: "HostelWorld XML", value: "HostelWorld XML" },
    { label: "HW", value: "HW" },
    { label: "Cleartrip", value: "Cleartrip" },
    { label: "PMS", value: "PMS" },
    { label: "THHO", value: "THHO" },
    { label: "HostelWorld", value: "HostelWorld" },
    { label: "On Ground Partners", value: "On Ground Partners" },
    { label: "MakeMyTrip", value: "MakeMyTrip" },
    { label: "Walk in", value: "Walk in" },
    {
      label: "invansys Technologies Pvt Ltd",
      value: "invansys Technologies Pvt Ltd",
    },
    { label: "Extension", value: "Extension" },
    { label: "On Ground partners", value: "On Ground partners" },
    { label: "TH Staff Complimentary", value: "TH Staff Complimentary" },
    { label: "Clear trip", value: "Clear trip" },
    { label: "MakemytripXml", value: "MakemytripXml" },
    { label: "Hostelworld", value: "Hostelworld" },
    { label: "TH-ML", value: "TH-ML" },
    { label: "clear trip", value: "clear trip" },
    { label: "Book on Google", value: "Book on Google" },
    { label: "AIESEC", value: "AIESEC" },
    { label: "EaseMyTrip", value: "EaseMyTrip" },
    { label: "Ex", value: "Ex" },
    { label: "THJ", value: "THJ" },
    { label: "Baazi Networks Pvt Ltd", value: "Baazi Networks Pvt Ltd" },
    { label: "Book On Google", value: "Book On Google" },
  ];

  const markedOnEzeeOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const cancelOnEzeeOptions = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const refundOptions = [
    { label: "Full", value: "Full" },
    { label: "Partial", value: "Partial" },
  ];

  return (
    <div>
      <Row>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Hostel</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="hostel"
              name="hostel"
              value={hostel}
              closeMenuOnSelect={false}
              options={hostelsList}
              onChange={(value) => setHostel(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Refund initiated date range</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              id="reviewDate"
              placeholder="Refund initiated date range"
              options={{
                mode: "range",
              }}
              value={[refundInitiatedStartDate, refundInitiatedEndDate]}
              onChange={(date) => {
                setRefundInitiatedStartDate(dateFormatter(date[0]));
                setRefundInitiatedEndDate(dateFormatter(date[1]));
              }}
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="url">
              <strong>Refund processed date range</strong>
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              id="reviewDate"
              placeholder="Refund processed date range"
              options={{
                mode: "range",
              }}
              value={[refundProcessedStartDate, refundProcessedEndDate]}
              onChange={(date) => {
                setRefundProcessedStartDate(dateFormatter(date[0]));
                setRefundProcessedEndDate(dateFormatter(date[1]));
              }}
            />
          </FormGroup>
        </Col>

        {/* <Col sm="3">
          <FormGroup>
            <Label for="guestName">
              <strong>Guest Name</strong>
            </Label>
            <Input
              id="guestName"
              placeholder="Guest Name"
              value={guestName}
              onChange={(e) => setGuestName(e.target.value)}
            />
          </FormGroup>
        </Col> */}

        <Col sm="3">
          <FormGroup>
            <Label for="reservationID">
              <strong>Reservation ID</strong>
            </Label>
            <Input
              id="reservationID"
              placeholder="Reservation ID"
              value={reservationID}
              onChange={(e) => setReservationID(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="status">
              <strong>Status</strong>
            </Label>
            <Select
              isClearable
              id="status"
              value={status}
              options={statusOptions}
              onChange={setStatus}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="refundReason">
              <strong>Reason</strong>
            </Label>
            <Select
              isClearable
              id="refundReason"
              value={refundReason}
              options={refundReasonOptions}
              onChange={setRefundReason}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="priority">
                Priority
              </Label>
            </strong>
            <Select
              isClearable={false}
              isSearchable={true}
              id="priority"
              name="priority"
              options={priorityOptions}
              value={priority}
              onChange={(value) => {
                setPriority(value);
              }}
              classNamePrefix="select"
              isDisabled={editData?._id}
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="bookingSource">
                Booking source
              </Label>
            </strong>
            <Select
              isClearable={false}
              isSearchable={true}
              id="bookingSource"
              name="bookingSource"
              options={bookingSourceOptions}
              value={bookingSource}
              onChange={(value) => {
                setBookingSource(value);
              }}
              classNamePrefix="select"
              isDisabled={editData?._id}
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="markedOnEzee">
              <strong>Settled on Ezee</strong>
            </Label>
            <Select
              isClearable={true}
              id="markedOnEzee"
              name="markedOnEzee"
              value={markedOnEzee}
              options={markedOnEzeeOptions}
              onChange={(value) => {
                setMarkedOnEzee(value);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="cancelOnEzee">
              <strong>Cancel on Ezee</strong>
            </Label>
            <Select
              isClearable={true}
              id="cancelOnEzee"
              name="cancelOnEzee"
              value={cancelOnEzee}
              options={cancelOnEzeeOptions}
              onChange={(value) => {
                setCancelOnEzee(value);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="refundType">
              <strong>Refund type</strong>
            </Label>
            <Select
              isClearable
              id="refundType"
              value={refundType}
              options={refundOptions}
              onChange={setRefundType}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col>
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 40px",
            }}
            color="white"
            onClick={handleSearch}
          >
            Search
          </Button>

          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 40px",
            }}
            color="danger"
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>

      <RefundRequestPreStayTable
        openModal={() => setOpenModal(!openModal)}
        setId={setId}
        data={tableData}
        dataCount={dataCount}
        editData={editData}
        refetch={refetch}
        loading={loading}
      />
      <TablePagination setPage={setPage} page={page} totalPage={totalPage} />

      <AddEditModal
        setId={setId}
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        refetch={refetch}
      />
    </div>
  );
};

export default RefundRequestPreStay;
