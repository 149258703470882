import React from "react";
import { Edit, Trash } from "react-feather";
import { Badge, Button } from "reactstrap";
import { dateFormatHandler } from "@utils";
import { reactFeatherIcon } from "../../../../../../styles/reactFeatherIcon";

const HostelTableBody = ({
  tableRowData,
  openModal,
  handleEdit,
  handleDelete,
}) => {
  return (
    <>
      {tableRowData.map(
        ({
          name,
          shortName,
          ezeeConfig,
          createdAT,
          status,
          id,
          isWorkation,
          coLiving,
          businessType,
          activateGlu,
        }) => (
          <tr key={id}>
            <td style={{ width: "5%" }}>
              <Button
                className="rounded-circle btn-icon"
                color="none"
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                  handleEdit(id);
                }}
              >
                <Edit
                  color={reactFeatherIcon.iconColor}
                  size={reactFeatherIcon.iconSize}
                />
              </Button>

              {handleDelete && (
                <Button
                  className="rounded-circle btn-icon"
                  color="none"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(id);
                  }}
                >
                  <Trash size={15} />
                </Button>
              )}
            </td>
            <td>
              <span className="align-middle font-weight-bold">{name}</span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">{shortName}</span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">
                {businessType}
              </span>
            </td>
            <td>
              <span className="align-middle font-weight-bold">
                {ezeeConfig?.hotelCode}
              </span>
            </td>
            <td>{dateFormatHandler(createdAT)}</td>
            <td>
              <Badge
                pill
                color={status ? "light-success" : "light-danger"}
                className="mr-1"
              >
                {status ? "Active" : "Inactive"}
              </Badge>
            </td>
            <td>
              <Badge
                pill
                color={isWorkation ? "light-success" : "light-danger"}
                className="mr-1"
              >
                {isWorkation ? "Active" : "Inactive"}
              </Badge>
            </td>
            <td>
              <Badge
                pill
                color={coLiving ? "light-success" : "light-danger"}
                className="mr-1"
              >
                {coLiving ? "Active" : "Inactive"}
              </Badge>
            </td>
            <td>
              <Badge
                pill
                color={activateGlu ? "light-success" : "light-danger"}
                className="mr-1"
              >
                {activateGlu ? "Active" : "Inactive"}
              </Badge>
            </td>
          </tr>
        )
      )}
    </>
  );
};

export default HostelTableBody;
