import gql from "graphql-tag";

export const CREATE_OPS_FINANCE = gql`
  mutation createOpsFinance($opsFinanceInput: OpsFinanceInput) {
    createOpsFinance(opsFinanceInput: $opsFinanceInput) {
      entryType
    }
  }
`;

export const UPDATE_OPS_FINANCE = gql`
  mutation updateOpsFinance($id: ID, $opsFinanceUpdate: OpsFinanceInput) {
    updateOpsFinance(id: $id, opsFinanceUpdate: $opsFinanceUpdate) {
      entryType
    }
  }
`;

//Added by Nitish
export const UPDATE_INVOICE_PENDING_CLARIFICATION = gql`
  mutation updateInvoicePendingClarification(
    $id: ID!
    $pendingInvoiceInput: pendingInvoiceInput
  ) {
    updateInvoicePendingClarification(
      id: $id
      pendingInvoiceInput: $pendingInvoiceInput
    ) {
      _id
      invoicePendingStatusCheck
      pendingInvoiceCorrectionRemark
    }
  }
`;

export const GENERATE_DAY_BOOK_EXPORT_DATA = gql`
  mutation generatexlsx($filter: filter) {
    generateDayBookExport(filter: $filter)
  }
`;
