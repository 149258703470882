import React from "react";
import { Card, CardHeader, Table } from "reactstrap";

const RequestReportTable = ({ tableData }) => {
  return (
    <div>
      <div style={{ overflowX: "auto", overflowY: "auto", marginTop: "20px" }}>
        <Card>
          <CardHeader>
            <h4 style={{}}>Request Report</h4>
          </CardHeader>
          <div style={{ overflowX: "auto", maxHeight: "500px" }}>
            <Table bordered>
              <thead style={{ position: "sticky", top: 0, zIndex: 2 }}>
                <tr>
                  <th style={{ position: "sticky", left: 0, zIndex: 1 }}>
                    Request Name
                  </th>
                  <th>Request Count</th>
                  <th>Avg Tat (minutes)</th>
                  <th>Tat Missed</th>
                  <th>Avg Rating</th>
                  <th>Percent Tat Missed(%)</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item?._id}</td>
                      <td>{item?.requestCount || "--"}</td>
                      <td>{item?.avgTat?.toFixed(2) || "--"}</td>
                      <td>{item?.tatMissed || "--"}</td>
                      <td>{item?.percentageTatMissed?.toFixed(2) || "--"}</td>
                      <td>{item?.avgRating?.toFixed(2) || "--"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default RequestReportTable;
