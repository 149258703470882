import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import toaster from "@components/common/Toaster";
import { FormGroup, Label, Row, Col, Input } from "reactstrap";
import Select from "react-select";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { useUpdateEmployeeProfile } from "../../../api/dataSource";
import { tableDataHandler } from "@store/Cms/cms.action";
import { dateFormatter } from "@utils";
import Flatpickr from "react-flatpickr";
import ConnectDocumentSection from "../../Connect/ConnectDocumentSection";

const Exit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [dateOfExit, setDateOfExit] = useState(new Date());
  const [exitReason, setExitReason] = useState(null);
  const [resignedStatus, setResignedStatus] = useState(null);
  const [empStatus, setEmpStatus] = useState(null);
  const [dateOfResignation, setDateOfResignation] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [dummyState, setDummyState] = useState(null);
  const [exitReasonCategory, setExitReasonCategory] = useState(null);
  const userRole = localStorage.getItem("role");
  const UploadType = [{ label: "documents", value: "documentsImages" }];

  const [updateEmployeeProfileHandler, { data: updateEmployeeProfileData }] =
    useUpdateEmployeeProfile();

  const state = useSelector((state) => state?.cms);

  const resignedStatusList = [
    {
      label: "Resigned (Notice Period Served)",
      value: "Resigned (Notice Period Served)",
    },
    {
      label: "Resigned (Notice Period Didn't Serve)",
      value: "Resigned (Notice Period Didn't Serve)",
    },
    {
      label: "Resigned (Notice Period Waived Off)",
      value: "Resigned (Notice Period Waived Off)",
    },
  ];

  const EmploymentStatusList = [
    { label: "Probation", value: "Probation" },
    { label: "Confirmed", value: "Confirmed" },
    { label: "Absconding", value: "Absconding" },
    { label: "Resigned", value: "Resigned" },
    { label: "Termination", value: "Termination" },
  ];

  const exitReasonCategoryList = [
    { label: "Personal reasons", value: "personalReasons" },
    { label: "Better Opportunities", value: "betterOpportunities" },
    { label: "Family issues", value: "familyIssues" },
    { label: "Salary & Benefits", value: "Salary&Benefits" },
    { label: "Work environment", value: "eorkEnvironment" },
    { label: "Retirement", value: "retirement" },
    { label: "Relocation", value: "relocation" },
    { label: "Higher studies", value: "higherStudies" },
    { label: "Termination", value: "termination" },
    { label: "Absconding", value: "Absconding" },
    { label: "Job Dissatisfaction", value: "jobDissatisfaction" },
    { label: "Closure of contracts", value: "closureOfContracts" },
  ];

  useEffect(() => {
    if (state?.tableData) {
      setDateOfExit(state?.tableData?.dateOfExit);
      setResignedStatus(
        state?.tableData?.resignedStatus
          ? {
              label: state?.tableData?.resignedStatus,
              value: state?.tableData?.resignedStatus,
            }
          : null
      );
      setExitReason(state?.tableData?.exitReason);
      setEmpStatus(
        state?.tableData?.empStatus
          ? {
              label: state?.tableData?.empStatus,
              value: state?.tableData?.empStatus,
            }
          : null
      );
      setDateOfResignation(state?.tableData?.dateOfResignation);
      const newImages = state?.tableData?.attachments.map((url) => ({
        image: url,
      }));
      setImageList([...newImages]);
      setExitReasonCategory(
        state?.tableData?.exitReasonCategory
          ? {
              label: state?.tableData?.exitReasonCategory,
              value: state?.tableData?.exitReasonCategory,
            }
          : null
      );
    }
  }, [state]);
  const submitDataHandler = (e) => {
    e.preventDefault();
    const attachmentList = imageList.map((image) => image?.image);
    let data = {
      dateOfExit,
      resignedStatus: resignedStatus?.value,
      exitReason,
      empStatus: empStatus?.value,
      dateOfResignation,
      attachments: attachmentList,
      exitReasonCategory: exitReasonCategory?.value,
    };
    if (data.empStatus === "Absconding") {
      if (
        id &&
        data.dateOfExit &&
        data?.exitReason &&
        data?.exitReasonCategory
      ) {
        updateEmployeeProfileHandler(id, data);
        toaster("success", "Submitted successfully");

        dispatch(
          tableDataHandler({
            ...state?.tableData,
            ...data,
          })
        );
      } else {
        toaster("error", "please fill absconding details");
      }
    } else if (data.empStatus === "Resigned") {
      if (
        id &&
        data.dateOfExit &&
        data.exitReason &&
        imageList.length > 0 &&
        resignedStatus &&
        data.exitReasonCategory
      ) {
        if (data.dateOfExit >= data.dateOfResignation) {
          updateEmployeeProfileHandler(id, data);
          toaster("success", "Submitted successfully");

          dispatch(
            tableDataHandler({
              ...state?.tableData,
              ...data,
            })
          );
        } else {
          toaster(
            "error",
            "date of exit cannot be less that date of resignation"
          );
        }
      } else {
        toaster("error", "please fill resignation details");
      }
    } else if (data.empStatus === "Termination") {
      if (
        id &&
        data.dateOfExit &&
        data.exitReason &&
        imageList.length > 0 &&
        data?.exitReasonCategory
      ) {
        updateEmployeeProfileHandler(id, data);
        toaster("success", "Submitted successfully");

        dispatch(
          tableDataHandler({
            ...state?.tableData,
            ...data,
          })
        );
      } else {
        toaster("error", "please fill termanation details");
      }
    } else if (data.empStatus === "Confirmed") {
      updateEmployeeProfileHandler(id, data);
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    } else if (data.empStatus === "Probation") {
      updateEmployeeProfileHandler(id, data);
      toaster("success", "Submitted successfully");

      dispatch(
        tableDataHandler({
          ...state?.tableData,
          ...data,
        })
      );
    }

    // if (id && data.dateOfExit && data.exitReason && data.empStatus && (data.empStatus == "Resigned" ? imageList.length > 0 : true) && (data.dateOfExit > data.dateOfResignation)) {
    //   // updateEmployeeProfileHandler(id, data);
    //   toaster("success", "Submitted successfully");

    //   // dispatch(
    //   //   tableDataHandler({
    //   //     ...state?.tableData,
    //   //     ...data,
    //   //   }),
    //   // );
    // } else {
    //   toaster("error", "Please fill all the required details marked with (*)");
    // }
  };

  return (
    <form className="mt-2" onSubmit={submitDataHandler}>
      <Row>
        {empStatus?.value === "Resigned" && (
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="dataOfResignation">
                Date of Resignation*
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={dateOfResignation}
                onChange={(date) =>
                  setDateOfResignation(new Date(dateFormatter(new Date(date))))
                }
                id="dateOfResignation"
                placeholder="Select date of resignation"
              />
            </FormGroup>
          </Col>
        )}
        {/* // ! dateOfExit */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="dateOfExit">
              Date of exit*
            </Label>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={dateOfExit}
              onChange={(date) =>
                setDateOfExit(new Date(dateFormatter(new Date(date))))
              }
              id="dateOfExit"
              placeholder="Select date of exit"
            />
          </FormGroup>
        </Col>

        {/*// ! exitReason */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="exitReason">
              Exit Reason*
            </Label>
            <Input
              type="text"
              value={exitReason}
              id="exitReason"
              name="exitReason"
              placeholder="Add exit reason"
              onChange={(e) => setExitReason(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="exitReasonCategory">
              Exit reason Category*
            </Label>
            <Select
              isClearable={true}
              id="exitReasonCategory"
              name="exitReasonCategory"
              value={exitReasonCategory}
              options={exitReasonCategoryList}
              onChange={(value) => setExitReasonCategory(value)}
              classNamePrefix="select"
              isRequired
            />
          </FormGroup>
        </Col>

        {/*// ! empStatus */}
        <Col sm="6">
          <FormGroup className="w-100">
            <Label className="form-label" for="empStatus">
              Employment Status*
            </Label>
            <Select
              isClearable={false}
              id="empStatus"
              name="empStatus"
              value={empStatus}
              options={EmploymentStatusList}
              onChange={(value) => {
                setEmpStatus(value);
                setResignedStatus(null);
                setImageList([]);
                setExitReason("");
                setExitReasonCategory(null);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        {/*// ! resignedStatus */}
        {empStatus?.value === "Resigned" ? (
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="resignedStatus">
                Resigned Status*
              </Label>
              <Select
                isClearable={false}
                id="resignedStatus"
                name="resignedStatus"
                value={resignedStatus}
                options={resignedStatusList}
                onChange={(value) => setResignedStatus(value)}
                classNamePrefix="select"
                isRequired
              />
            </FormGroup>
          </Col>
        ) : null}
      </Row>
      {["Termination", "Absconding", "Resigned"].includes(empStatus?.value) && (
        <Row>
          <Col>
            <Label>
              Upload Documents{empStatus.value == "Resigned" && "*"}
            </Label>
            <ConnectDocumentSection
              imageList={imageList}
              setImageList={setImageList}
              editData={dummyState}
              uploadType={UploadType}
            />
          </Col>
        </Row>
      )}
      {userRole !== "Area Manager" && <SaveDiscardBtnGroup />}
    </form>
  );
};

export default Exit;
