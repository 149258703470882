import React, { useEffect, useState, useRef, Fragment } from "react";
import { Col, Row, Badge, Input, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";
import { useUpdateAddOnsBooking, useGetServiceInvoiceOnSuccess } from "@api";
import toaster from "@components/common/Toaster";
import SlideModal from "@components/common/slide-modal";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ServicesInvoice from "./ServicesInvoice";
import { PDFExport } from "@progress/kendo-react-pdf";
import { Download } from "react-feather";

const MySwal = withReactContent(Swal);

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [reservationId, setReservationId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [guestName, setGuestName] = useState("");
  const [hostelName, setHostelName] = useState("");
  const [guestComment, setGuestComment] = useState("");
  const [guestMobile, setGuestMobile] = useState("");
  const [guestEmail, setGuestEmail] = useState("");
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [addonPrice, setAddonPrice] = useState(0);
  const [tax, setTax] = useState(0);
  const [bookingId, setBookingId] = useState("");
  const [bookingDate, setBookingDate] = useState(null);
  const [razorpayPaymentId, setRazorpayPaymentId] = useState("");
  const [cancellationDate, setCancellationDate] = useState(0);
  const [refundStatus, setRefundStatus] = useState("");
  const [cancellationAmount, setCancellationAmount] = useState(0);
  const [guestCancellationReason, setGuestCancellationReason] = useState("");
  const [cancelledBy, setCancelledBy] = useState("");
  const [refundDate, setRefundDate] = useState("");
  const [refundID, setRefundID] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [status, setStatus] = useState(null);
  const [remark, setRemark] = useState("");
  const [ticket, setTicket] = useState("");
  const [logs, setLogs] = useState(editData ? editData[0]?.logs : []);
  const [rating, setRating] = useState(0);
  const [variationOption, setVariationOption] = useState("");
  const [dropDate, setDropDate] = useState(null);
  const [extra, setExtra] = useState([]);

  const [updateAddOnsBookingHandler, { data: updatedAddonData }] =
    useUpdateAddOnsBooking();

  useEffect(() => {
    if (updatedAddonData) {
      refetch();
      handleToggle();
    }
  }, [updatedAddonData]);

  const AddonStatusList = [
    { label: "Pending", value: "Pending" },
    { label: "Confirmed", value: "Confirmed" },
    { label: "Completed", value: "Completed" },
    { label: "Cancelled", value: "Cancelled" },
  ];

  useEffect(() => {
    if (editData) {
      setPaymentStatus(editData.paymentStatus || "");
      setReservationId(editData.reservationId || "");
      setPrice(editData.price || 0);
      setQuantity(editData.quantity || 0);
      setTotalPrice(editData.totalPrice || 0);
      setBookingId(editData.bookingId || "");
      setBookingDate(editData.createdAt || null);
      setGuestName(editData.guestDetails?.GuestName || "");
      setRazorpayPaymentId(editData.razorpayPaymentId || "");
      setHostelName(editData?.hostel?.name || "");
      setGuestMobile(editData.guestDetails?.Mobile || "");
      setGuestEmail(editData.guestDetails?.Email || "");
      setGuestComment(editData.comment || "");
      setRefundStatus(editData.refundStatus || "");
      setCategory(editData?.category || "");
      setTitle(editData?.title || "");
      //setAddonPrice(editData.serviceDetails?.price || 0);
      setAddonPrice(
        editData?.serviceData?.variation?.price
          ? editData?.serviceData?.variation?.price
          : editData.serviceData?.price
      );
      setTax(editData.serviceData?.tax || 0);
      setInvoiceNo(editData.invoiceNo || "");
      setStatus(
        editData.status
          ? {
              label: editData.status,
              value: editData.status,
            }
          : ""
      );

      setRemark(editData?.remark || "");
      setLogs(editData?.logs || "");
      setRefundID(editData?.refundId || "");
      setRefundDate(editData?.refundDate || "");
      setGuestCancellationReason(editData?.cancellationRemark || "");
      setCancelledBy(editData?.cancelledBy || "");
      setCancellationAmount(editData?.cancellationAmount || "");
      setCancellationDate(editData?.cancellationDate || null);
      setTicket(editData?.ticket || "");
      setRating(editData?.rating || 0);
      setVariationOption(editData?.serviceData?.variation?.option || "");
      setDropDate(editData?.dropDate || null);
      setExtra(editData?.serviceData?.extra || []);
    }
  }, [editData]);

  const handleSaveButtonClick = () => {
    if (
      editData?.status === "Cancelled"
      // || editData?.status === "Completed"
    ) {
      handleToggle();
      return;
    }
    onSubmitHandler();
  };

  const onSubmitHandler = () => {
    status?.value === "Cancelled"
      ? MySwal.fire({
          title: "Confirm Cancellation?",
          text: "Are you sure you want to proceed with cancellation?",
          icon: "warning",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonColor: "#FFE700",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          customClass: {
            confirmButton: "mr-1",
            cancelButton: "ml-1",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            updateStatus();
          }
        })
      : updateStatus();
  };

  const updateStatus = () => {
    const data = {
      status: status?.value,
      remark: remark,
      logs: [
        ...logs,
        `${localStorage.getItem(
          "name"
        )} modified this request on ${new Date().toLocaleString("en-IN", {
          hour12: false,
        })}, status: ${status.value} ${
          remark === null ? "" : `& Remark: ${remark}`
        }`,
      ],
    };
    if (status && remark) {
      if (editData?._id) {
        const _id = editData._id;
        updateAddOnsBookingHandler(_id, data);
      }

      toaster("success", "Status Updated successfully");
      refetch();
    } else {
      toaster("error", "Please fill all required details");
    }
  };

  const { data } = useGetServiceInvoiceOnSuccess(bookingId);

  const pdfExportComponent = useRef(null);

  const handleExportWithComponent = () => {
    try {
      pdfExportComponent?.current?.save();
    } catch (error) {
      console.error(error);
    }
  };

  const pdfDownloadFunction = () => {
    if (status?.value === "Completed") {
      setBookingId(bookingId);
      handleExportWithComponent();
      toaster("success", "PDF downloaded successfully!");
    } else {
      toaster(
        "error",
        "PDF download is only available for completed bookings."
      );
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={handleSaveButtonClick}
    >
      {/* Addon Details Section */}
      {category && (
        <>
          <h5>Service Details</h5>
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label for="category">Category</Label>
                <Input
                  type="text"
                  name="category"
                  id="category"
                  value={category}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label for="title">Title</Label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  value={title}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label for="addonPrice">Price (per unit)</Label>
                <Input
                  type="text"
                  name="addonPrice"
                  id="addonPrice"
                  value={addonPrice}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label for="quantity">Quantity</Label>
                <Input
                  type="text"
                  name="quantity"
                  id="quantity"
                  value={quantity}
                  readOnly
                />
              </FormGroup>
            </Col>
            {/* invoice */}
            {editData?.status === "Completed" && (
              <Col sm="6">
                <FormGroup>
                  <Label for="invoiceNo">Invoice No</Label>
                  <Input
                    type="text"
                    name="invoiceNo"
                    id="invoiceNo"
                    value={invoiceNo}
                    readOnly
                  />
                </FormGroup>
              </Col>
            )}

            {editData?.serviceData?.variation?.option && (
              <Col sm="6">
                <FormGroup>
                  <Label for="variationOption">Option</Label>
                  <Input
                    type="text"
                    name="variationOption"
                    id="variationOption"
                    value={variationOption}
                    readOnly
                  />
                </FormGroup>
              </Col>
            )}
            {editData?.dropDate && (
              <Col sm="6">
                <FormGroup>
                  <Label for="dropDate">Drop Date</Label>
                  <Input
                    type="text"
                    name="dropDate"
                    id="dropDate"
                    value={new Date(dropDate).toLocaleDateString("en-IN", {
                      timeZone: "Asia/Kolkata",
                    })}
                    readOnly
                  />
                </FormGroup>
              </Col>
            )}

            {/* <Col sm="6">
              <FormGroup>
                <Label for="dropTime">Drop Time</Label>
                <Input
                  type="text"
                  name="dropDate"
                  id="dropDate"
                  value={new Date(dropDate).toLocaleTimeString()}
                  readOnly
                />
              </FormGroup>
            </Col> */}

            {/* extra */}

            {editData?.serviceData?.extra?.length ? (
              <Col sm="6">
                <FormGroup>
                  <Label for="extra">Extra</Label>
                  <Input
                    type=""
                    name="extra"
                    id="extra"
                    value={extra
                      .map((item) => `${item.option} x ${item.quantity}`)
                      .join(", ")}
                    readOnly
                  />
                </FormGroup>
              </Col>
            ) : null}
          </Row>
          <hr />
        </>
      )}

      {/* Guest Details Section */}

      <>
        <h5>Guest Details</h5>
        <Row>
          <Col sm="6">
            <FormGroup>
              <Label for="guestName">Guest Name</Label>
              <Input
                type="text"
                name="guestName"
                id="guestName"
                value={guestName}
                readOnly
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup>
              <Label for="reservationId">Reservation ID</Label>
              <Input
                type="text"
                name="reservationId"
                id="reservationId"
                value={reservationId}
                readOnly
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup>
              <Label for="guestMobile">Guest Number</Label>
              <Input
                type="text"
                name="guestMobile"
                id="guestMobile"
                value={guestMobile}
                readOnly
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup>
              <Label for="hostelName">Hostel Name</Label>
              <Input
                type="text"
                name="hostelName"
                id="hostelName"
                value={hostelName}
                readOnly
              />
            </FormGroup>
          </Col>

          {guestComment && (
            <Col sm="6">
              <FormGroup>
                <Label for="hostelName">Guest Comment</Label>
                <Input
                  type="text"
                  name="guestComment"
                  id="guestComment"
                  value={guestComment}
                  readOnly
                />
              </FormGroup>
            </Col>
          )}
          <br />

          {/* {ticket && (
              <img src={ticket} height={100} width={150} alt="ticketImg" />
            )} */}

          {ticket && (
            <a href={ticket} target="_blank" rel="noopener noreferrer">
              <img
                src={ticket}
                style={{
                  maxHeight: 80,
                  maxWidth: 200,
                  width: "auto",
                  height: "auto",
                  display: "block",
                  padding: "15px",
                }}
                alt="ticketImg"
              />
            </a>
          )}
        </Row>

        <hr />
      </>

      <>
        <h5>Service Booking Details</h5>
        <Row>
          <Col sm="6">
            <FormGroup>
              <Label for="paymentStatus">Payment Status</Label>
              <Input
                type="text"
                name="paymentStatus"
                id="paymentStatus"
                value={paymentStatus}
                readOnly
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup>
              <Label for="totalPrice">Amount (inc .GST)</Label>
              <Input
                type="number"
                name="totalPrice"
                id="totalPrice"
                value={totalPrice}
                readOnly
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup>
              <Label for="tax">Tax (%)</Label>
              <Input type="text" name="tax" id="tax" value={tax} readOnly />
            </FormGroup>
          </Col>

          {/* rating */}

          <Col sm="6">
            <FormGroup>
              <Label for="rating">Rating</Label>
              <Input
                type="rating"
                name="rating"
                id="rating"
                value={rating}
                readOnly
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup>
              <Label for="bookingId">Booking ID</Label>
              <Input
                type="text"
                name="bookingId"
                id="bookingId"
                value={bookingId}
                readOnly
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup>
              <Label for="bookingDate">Booking Date</Label>
              <Input
                type="text"
                name="bookingDate"
                id="bookingDate"
                // value={bookingDate}
                value={new Date(bookingDate).toLocaleDateString("en-IN", {
                  timeZone: "Asia/Kolkata",
                })}
                readOnly
              />
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup>
              <Label for="razorpayPaymentId">Razorpay Payment ID</Label>
              <Input
                type="text"
                name="razorpayPaymentId"
                id="razorpayPaymentId"
                value={razorpayPaymentId}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <hr />
      </>

      {editData?.status === "Cancelled" && (
        <>
          <h5>Cancellation & Refund Details</h5>
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label for="cancellationAmount">Cancellation Amount</Label>
                <Input
                  type="text"
                  name="cancellationAmount"
                  id="cancellationAmount"
                  value={cancellationAmount}
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="cancelledBy">Cancelled By</Label>
                <Input
                  type="text"
                  name="cancelledBy"
                  id="cancelledBy"
                  value={cancelledBy}
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="cancellationDate">Cancellation Date</Label>
                <Input
                  type="text"
                  name="cancellationDate"
                  id="cancellationDate"
                  // value={cancellationDate}
                  value={new Date(cancellationDate).toLocaleDateString(
                    "en-IN",
                    {
                      timeZone: "Asia/Kolkata",
                    }
                  )}
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="cancellationDate">Cancellation Time</Label>
                <Input
                  type="text"
                  name="cancellationDate"
                  id="cancellationDate"
                  value={new Date(cancellationDate).toLocaleTimeString()}
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="refundDate">Refund Date</Label>
                <Input
                  type="text"
                  name="refundDate"
                  id="refundDate"
                  value={
                    refundDate
                      ? new Date(refundDate).toLocaleDateString("en-IN", {
                          timeZone: "Asia/Kolkata",
                        })
                      : null
                  }
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="refundDate">Refund Time</Label>
                <Input
                  type="text"
                  name="refundDate"
                  id="refundDate"
                  value={
                    refundDate
                      ? new Date(refundDate).toLocaleTimeString()
                      : null
                  }
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="refundID">Refund ID</Label>
                <Input
                  type="text"
                  name="refundID"
                  id="refundID"
                  value={refundID}
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="refundStatus">Refund Status</Label>
                <Input
                  type="text"
                  name="refundStatus"
                  id="refundStatus"
                  value={refundStatus}
                  readOnly
                />
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label for="guestCancellationReason">Cancellation Remark</Label>
                <Input
                  type="text"
                  name="guestCancellationReason"
                  id="guestCancellationReason"
                  value={guestCancellationReason}
                  readOnly
                />
              </FormGroup>
            </Col>
          </Row>
          <hr />
        </>
      )}

      {/*// Status */}
      <Row>
        <Col sm="12">
          <FormGroup className="w-100">
            <Label for="Status">Status</Label>
            <Select
              id="status"
              name="status"
              value={status}
              options={AddonStatusList}
              isDisabled={editData?.status === "Cancelled"}
              // isDisabled={
              //   // !(
              //   //   editData?.status === "Pending" ||
              //   //   (JSON.parse(localStorage.getItem("modules"))?.includes(
              //   //     "Glu Configuration"
              //   //   ) &&
              //   //     editData?.status === "Completed")
              //   // )
              //   editData?.status === "Completed" ||
              //editData?.status === "Cancelled"
              // }
              required
              onChange={(selectedOption) => setStatus(selectedOption)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
      </Row>

      {/* Remark */}
      <Row>
        <Col sm="12">
          <FormGroup className="w-100">
            <Label for="Remark">Comment</Label>

            <Input
              type="textarea"
              id="remark"
              name="remark"
              placeholder="Type your remark..."
              disabled={
                // !(
                //   editData?.status === "Pending" ||
                //   (JSON.parse(localStorage.getItem("modules"))?.includes(
                //     "Glu Configuration"
                //   ) &&
                //     editData?.status === "Completed")

                // )
                // editData?.status === "Completed" ||
                editData?.status === "Cancelled"
              }
              value={remark}
              required
              rows={4}
              onChange={(e) => setRemark(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* logs */}

        <Col sm="12">
          <FormGroup className="w-100">
            <Label className="form-label" for="logs">
              <strong>Logs</strong>
            </Label>
            <br />

            <ul>
              {editData?.logs?.map((i, index) => {
                return (
                  <Fragment key={index}>
                    <li>{i}</li>
                  </Fragment>
                );
              })}
            </ul>
          </FormGroup>
        </Col>

        <Col>
          {status?.value === "Completed" && (
            <div>
              <button
                onClick={pdfDownloadFunction}
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  marginLeft: "auto",
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    fontFamily: "poppins",
                    fontSize: "medium",
                    color: "#497BDD",
                    cursor: "pointer",
                    margin: 0,
                    textDecoration: "underline",
                  }}
                >
                  Invoice
                </p>
                <Download size={20} color="#497BDD" />
              </button>
            </div>
          )}
          {data?.getServiceInvoiceOnSuccess && (
            <div
              style={{
                position: "relative",
                right: "-2000px",
                height: "0px",
                display: `${status?.value === "Completed" ? `block` : `none`}`,
              }}
            >
              <PDFExport
                ref={pdfExportComponent}
                paperSize={"A4"}
                fileName={`invoice_${data?.getServiceInvoiceOnSuccess?.invoiceNo}`}
                author="The Hosteller"
                date={new Date()}
                imageResolution={100}
              >
                <ServicesInvoice data={data?.getServiceInvoiceOnSuccess} />
              </PDFExport>
            </div>
          )}
        </Col>
      </Row>
    </SlideModal>
  );
};

export default AddEditModal;
