import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_UNPAID_BOOKINGS_FOR_CANCELLATION = gql`
  query getUnpaidBookingsForCancellation($filter: filter) {
    getUnpaidBookingsForCancellation(filter: $filter) {
      count
      page
      limit
      data {
        _id
        DueAmount
        Deposit
        GuestName
        ArrivalDate
        DepartureDate
        Email
        Mobile
        Phone
        ReservationDate
        ReservationNo
        Source
        Status
        BookingStatus
        VoucherNo
        dueAmountPaid
        hostelId
        hostelName
        paymentId
        paymentLinkSent
        uniqueId
        logs
        alternateEmail
        alternateMobile
        cancelDate
        refundStatus
        refundId
        refundAmount
        Address
      }
    }
  }
`;

export const useGetUnpaidBookingsForCancellationList = (
  limit,
  page,
  hostelIds,
  paymentStatus,
  checkinStartDate,
  checkinEndDate,
  uniqueId
) => {
  const { loading, error, data, refetch } = useQuery(
    GET_UNPAID_BOOKINGS_FOR_CANCELLATION,
    {
      variables: {
        filter: {
          limit,
          page,
          hostelIds,
          paymentStatus,
          checkinStartDate,
          checkinEndDate,
          uniqueId,
        },
      },
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};
