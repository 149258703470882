import UserInfo from "../../views/User/UserInfo";

const User = [
  {
    path: "/user/userInfo",
    component: UserInfo,
    exact: true,
  },
];

export default User;
