// ! Coded by Ashwin
import gql from "graphql-tag";

export const GET_PAGINATED_WEB_CHECKIN_LIST = gql`
  query getPaginatedWebCheckinList($inputFilter: inputFilter) {
    getPaginatedWebCheckinList(inputFilter: $inputFilter) {
      data {
        _id
        timeOfArrival
        hostelID
        hostelName
        reservationID
        firstName
        lastName
        gender
        dateOfBirth
        countryCode
        phoneNumber
        email
        howDidYouBookTheHosteller
        groupSize
        residingCountry
        residingState
        residingCity
        comingFromCity
        goingToCity
        modeOfTransport
        govtIdProof
        govtIdNumber
        uploadGovId
        uploadGovIdBackImage
        uploadAadharCardFrontImage
        uploadAadhaarCardBackImage
        uploadVisa
        uploadPassportBackImage
        haveYouStayedWithTheHostellerBefore
        whatWouldBestDescribeYou
        termsAndConditions
        createdAt
        checkInDate
        checkOutDate
        cFormAppNo
        NoOfGuest
        RoomNo
        age
        membershipType
        severity
        blacklistId {
          _id
          blacklistID
        }
        isBlackListed
        unmaskNumber
        unmaskEditLogs
        primaryPurpose
        stayedWithUsIn12Months
      }
      count
      limit
      page
      message
    }
  }
`;

export const GET_ALL_WEB_CHECKIN_LIST = gql`
  query getAllWebCheckinList($inputFilter: inputFilter) {
    getAllWebCheckinList(inputFilter: $inputFilter) {
      data {
        _id
        timeOfArrival
        hostelID
        hostelName
        reservationID
        firstName
        lastName
        gender
        dateOfBirth
        countryCode
        phoneNumber
        email
        howDidYouBookTheHosteller
        groupSize
        residingCountry
        residingState
        residingCity
        comingFromCity
        goingToCity
        modeOfTransport
        govtIdProof
        govtIdNumber
        uploadGovId
        uploadAadharCardFrontImage
        uploadAadhaarCardBackImage
        uploadVisa
        uploadPassportBackImage
        haveYouStayedWithTheHostellerBefore
        whatWouldBestDescribeYou
        termsAndConditions
        createdAt
        cFormAppNo
      }
      message
    }
  }
`;

export const GET_WEBCHECKIN_DATA_BY_RESERVATION_ID = gql`
  query get($reservationID: String) {
    getWebcheckinDataByReservationId(reservationID: $reservationID) {
      phoneNumber
      reservationID
      firstName
      lastName
      email
      RoomNo
      checkInDate
      checkOutDate
    }
  }
`;
