import { useState, useEffect } from "react";
import { Col, Row, FormGroup, Label, Input, Button } from "reactstrap";
import {
  usePaginatedEventBookings,
  useGetAllEventBookingsForExport,
  useHostelList,
  useGenerateEventsExport,
} from "../../../api/dataSource";
import AddEditModal from "./AddEditModal";
import EventBookingsTable from "./EventBookingsTable";
import TablePagination from "@components/common/TablePagination";
import Flatpickr from "react-flatpickr";
import Select from "react-select";

import { selectThemeColors, dateFormatter } from "@utils";

export default function Index() {
  const role = localStorage.getItem("role");
  // const [limit, setLimit] = useState(10);
  const limit = 10;
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [id, setId] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [hostelsList, setHostelsList] = useState([]);
  const [reservationId, setReservationId] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [title, setTitle] = useState("");
  const [hostel, setHostel] = useState([]);
  const [exportTableData, setExportTableData] = useState([]);
  const [eventDateTo, setEventDateTo] = useState(null);
  const [eventDateFrom, setEventDateFrom] = useState(null);
  const [bookingDateTo, setBookingDateTo] = useState(null);
  const [bookingDateFrom, setBookingDateFrom] = useState(null);
  const [status, setStatus] = useState(null);
  const [cancelledBy, setCancelledBy] = useState(null);
  const [category, setCategory] = useState(null);
  const [amount, setAmount] = useState(null);
  const [filterFields, setFilterFields] = useState({
    hostelIdArray: JSON.parse(localStorage.getItem("hostels")),
  });

  const { data, refetch } = usePaginatedEventBookings({
    limit,
    page,
    filterFields,
  });

  // const { data: exportData } = useGetAllEventBookingsForExport({
  //   filterFields,
  // });

  const { data: allHostelsData } = useHostelList();

  const [dataCount, setDataCount] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);

  useEffect(() => {
    setDataCount(data?.getPaginatedEventBookings?.count);
  }, [data?.getPaginatedEventBookings?.count]);

  useEffect(() => {
    setTotalRevenue(data?.getPaginatedEventBookings?.totalRevenue);
  }, [data?.getPaginatedEventBookings?.totalRevenue]);

  const [generateEventsExportHandler, { data: eventsDataForExport }] =
    useGenerateEventsExport();

  console.log(eventsDataForExport, "eventsDataForExport");
  useEffect(() => {
    if (eventsDataForExport?.generateEventsExport?.data) {
      console.log(eventsDataForExport, "eventsDataForExport");
      if (eventsDataForExport?.generateEventsExport?.data.length > 0) {
        const byteArray = new Uint8Array(
          eventsDataForExport?.generateEventsExport?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `GLU_Events_Export.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [eventsDataForExport]);

  const exportInputFilter = {
    filterFields,
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  // const exportHeaders = [
  //   { label: "Reservation ID", key: "reservationId" },
  //   { label: "Guest Name", key: "guestDetails.GuestName" },
  //   { label: "Hostel Name", key: "hostelDetails.name" },
  //   { label: "Category", key: "category" },
  //   { label: "Title", key: "title" },
  //   { label: "Quantity", key: "quantity" },
  //   { label: "Amount (inc. GST)", key: "totalAmount" },
  //   { label: "Booking ID", key: "bookingId" },
  //   { label: "Booking Date/Time", key: "bookingDate" },
  //   { label: "Event Date/Time", key: "eventDate" },
  //   { label: "Status", key: "status" },
  //   { label: "Comment", key: "remark" },
  //   { label: "Amount (exc GST)", key: "amountExcGST" },
  //   { label: "Invoice Number", key: "invoiceNo" },
  //   { label: "Payment Status", key: "paymentStatus" },
  //   { label: "Tax", key: "tax" },
  //   { label: "Razorpay Payment ID", key: "razorpayPaymentId" },
  //   { label: "Cancellation Amount", key: "cancellationAmount" },
  //   { label: "Cancelled By", key: "cancelledBy" },
  //   { label: "Cancellation Date/Time", key: "cancellationDate" },
  //   { label: "Refund Date", key: "refundDate" },
  //   { label: "Refund ID", key: "refundId" },
  //   { label: "Refund Status", key: "refundStatus" },
  //   { label: "Cancellation Remark", key: "cancellationRemark" },
  // ];

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
            return allHostelsData?.getHostelList?.find(
              (item) => item?._id === hostelID
            );
          })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList, limit, page]);

  useEffect(() => {
    if (data?.getPaginatedEventBookings?.data?.length > 0) {
      setTableData(data?.getPaginatedEventBookings?.data);
    } else {
      setTableData([]);
    }
    setTotalPage(Math.ceil(data?.getPaginatedEventBookings?.count / limit));
  }, [
    data?.getPaginatedEventBookings?.count,
    data?.getPaginatedEventBookings?.data,
    limit,
    filterFields,
  ]);

  //for export
  // useEffect(() => {
  //   if (exportData?.getAllEventBookingsForExport?.data?.length > 0) {
  //     setExportTableData(exportData?.getAllEventBookingsForExport?.data);
  //   } else {
  //     setExportTableData([]);
  //   }
  // }, [exportData?.getAllEventBookingsForExport?.data]);

  // useEffect(() => {
  //   if (exportData?.getAllEventBookingsForExport?.data?.length > 0) {
  //     const modifiedExportData =
  //       exportData?.getAllEventBookingsForExport?.data.map((item) => {
  //         return {
  //           ...item,
  //           amountExcGST: item.amount * item.quantity,
  //         };
  //       });
  //     setExportTableData(modifiedExportData);
  //   } else {
  //     setExportTableData([]);
  //   }
  // }, [exportData?.getAllEventBookingsForExport?.data]);

  useEffect(() => {
    if (
      data?.getPaginatedEventBookings?.data &&
      data?.getPaginatedEventBookings?.data?.length > 0
    ) {
      let editData = data.getPaginatedEventBookings.data.find(
        (item) => item._id === id
      );

      if (editData) {
        setEditData(editData);
      } else {
        setId(null);
        setEditData(null);
      }
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPaginatedEventBookings?.data, id]);

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  //for event status filter
  const eventStatusDropdownList = [
    { label: "Pending", value: "Pending" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Completed", value: "Completed" },
    { label: "Confirmed", value: "Confirmed" }
  ];

  //for event category filter
  const eventCategoryDropdownList = [
    { label: "Event", value: "Event" },
    { label: "Activity", value: "Activity" },
  ];

  //for event cancelld by filter
  const eventCancelledByDropdownList = [
    { label: "Hostel", value: "hostel" },
    { label: "Guest", value: "guest" },
  ];

  //for event amount filter
  const eventPriceDropdownList = [
    { label: "Free", value: 0 },
    { label: "Paid", value: 1 },
  ];

  const handleSearch = () => {
    const filterFields = {
      reservationId,
      bookingId,
      title,
      hostelIdArray: hostel?.map((hostel) => hostel.value),
      bookingDateFrom,
      bookingDateTo,
      eventDateFrom,
      eventDateTo,
      status: status?.value,
      cancelledBy: cancelledBy?.value,
      amount: amount?.value,
      category: category?.value,
    };
    setFilterFields(filterFields);
  };

  const handleReset = () => {
    setFilterFields(null);
    setHostel([]);
    setEventDateFrom(null);
    setEventDateTo(null);
    setBookingDateFrom(null);
    setBookingDateTo(null);
    setReservationId("");
    setBookingId("");
    setTitle("");
    setCancelledBy(null);
    setStatus(null);
    setAmount(null);
    setCategory(null);
  };

  return (
    localStorage.getItem("modules")?.includes("Glu Reports") && (
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Hostel Name</strong>
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                options={hostelsList}
                onChange={(value) => {
                  setHostel(value);
                }}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="amount">
                <strong>Category</strong>
              </Label>
              <Select
                isClearable={true}
                id="category"
                name="category"
                value={category}
                options={eventCategoryDropdownList}
                onChange={(value) => {
                  setCategory(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="amount">
                <strong>Pricing Type</strong>
              </Label>
              <Select
                isClearable={true}
                id="amount"
                name="amount"
                value={amount}
                options={eventPriceDropdownList}
                onChange={(value) => {
                  setAmount(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="Title">
                <strong>Title</strong>
              </Label>
              <Input
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                <strong>Status</strong>
              </Label>
              <Select
                isClearable={true}
                id="Status"
                name="Status"
                value={status}
                options={eventStatusDropdownList}
                onChange={(value) => {
                  setStatus(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Delivery Date Range</strong>
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                id="checkinDate"
                placeholder="Delivery Date Range"
                options={{
                  mode: "range",
                }}
                value={[eventDateFrom, eventDateTo]}
                onChange={(date) => {
                  setEventDateFrom(dateFormatter(date[0]));
                  setEventDateTo(dateFormatter(date[1]));
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Booking Date Range</strong>
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                id="checkinDate"
                placeholder="Booking Date Range"
                options={{
                  mode: "range",
                }}
                value={[bookingDateFrom, bookingDateTo]}
                onChange={(date) => {
                  setBookingDateFrom(dateFormatter(date[0]));
                  setBookingDateTo(dateFormatter(date[1]));
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Booking ID</strong>
              </Label>
              <Input
                type="text"
                placeholder="Booking ID"
                value={bookingId}
                onChange={(e) => setBookingId(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="url">
                <strong>Reservation ID</strong>
              </Label>
              <Input
                type="text"
                placeholder="Reservation ID"
                value={reservationId}
                onChange={(e) => setReservationId(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="cancelledBy">
                <strong>Cancelled By</strong>
              </Label>
              <Select
                isClearable={true}
                id="cancelledBy"
                name="cancelledBy"
                value={cancelledBy}
                options={eventCancelledByDropdownList}
                onChange={(value) => {
                  setCancelledBy(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col className="mb-2">
            <Button
              style={{
                backgroundColor: "#ffe700",
                height: "40px",
                marginTop: "22px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="white"
              appearance="primary"
              type="button"
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>
            <Button
              className="pull-right"
              style={{
                backgroundColor: "#ff9999",
                height: "40px",
                marginTop: "22px",
                marginLeft: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="danger"
              appearance="primary"
              type="button"
              onClick={() => {
                handleReset();
              }}
            >
              Reset
            </Button>

            {role !== "Ground Staff" && (
              <Button
                style={{
                  backgroundColor: "#ff9999",
                  height: "40px",
                  marginTop: "22px",
                  marginLeft: "40px",
                  borderRadius: "5px",
                  padding: "10px 40px",
                  outline: "none",
                  border: "0",
                }}
                color="success"
                appearance="success"
                type="button"
                onClick={() => generateEventsExportHandler(exportInputFilter)}
              >
                Export
              </Button>
            )}
          </Col>
        </Row>
        <EventBookingsTable
          setId={setId}
          openModal={() => setOpenModal(!openModal)}
          refetch={refetch}
          tableData={tableData}
          // headers={exportHeaders}
          // exportData={exportTableData}
          dataCount={dataCount}
          totalRevenue={totalRevenue}
        />
        <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
        <AddEditModal
          open={openModal}
          editData={editData}
          handleToggle={toggleModal}
          refetch={refetch}
        />
      </div>
    )
  );
}
