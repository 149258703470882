import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";

export const GET_PAGINATED_BLACKLISTED_GUESTS = gql`
  query getPaginatedBlacklistedGuests($filter: filter) {
    getPaginatedBlacklistedGuests(filter: $filter) {
      page
      limit
      count
      data {
        _id
        hostel {
          _id
          name
        }
        reservationID
        name
        email
        mobile
        type
        severity
        documentID
        reason
        status
        supportingEvidence {
          mediaURL
        }
        videoUrl
        approverRemark
        approvedBy
        CXRemark
        blacklistID
        logs
      }
    }
  }
`;

export const useGetPaginatedBlacklistedGuests = (limit, page, filterFields) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_BLACKLISTED_GUESTS,
    {
      variables: {
        filter: {
          limit,
          page,
          filterFields,
        },
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const GET_BLACKLISTED_GUESTS_BY_ID = gql`
  query getBlacklistedGuestsById($id: ID) {
    getBlacklistedGuestsById(id: $id) {
      _id
      hostel {
        _id
        name
      }
      reservationID
      name
      email
      mobile
      type
      severity
      documentID
      reason
      status
      supportingEvidence {
        mediaURL
      }
      videoUrl
      approverRemark
      approvedBy
      CXRemark
      blacklistID
      logs
    }
  }
`;
export const useGetBlacklistedGuestsById = (id) => {
  const { loading, error, data, refetch } = useQuery(
    GET_BLACKLISTED_GUESTS_BY_ID,
    {
      variables: { id },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
