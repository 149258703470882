import React, { Fragment, useEffect, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";
import SlideModal from "@components/common/slide-modal";
import {
  useUpdateWebAppUserRequest,
  useUploadImage,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Select from "react-select";
import StaffImagesSection from "./StaffImagesSection";

const AddEditModal = ({ open, handleToggle, refetch, editData, setEditId }) => {
  const [requestNumber, setRequestNumber] = useState("");
  const [requestDate, setRequestDate] = useState("");
  const [requestTime, setRequestTime] = useState("");
  const [requestCategory, setRequestCategory] = useState(null);
  const [requestName, setRequestName] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [guestName, setGuestName] = useState("");
  const [guestRoom, setGuestRoom] = useState("");
  const [guestRoomNo, setGuestRoomNo] = useState("");
  const [hostelName, setHostelName] = useState("");
  const [requestStatus, setRequestStatus] = useState("");
  const [staffRemark, setStaffRemark] = useState("");
  const [staffName, setStaffName] = useState("");
  const [guestComment, setGuestComment] = useState("");
  const [requestImages, setRequestImages] = useState("");
  const [data, setData] = useState("");
  const [logs, setLogs] = useState(editData ? editData[0]?.logs : []);

  const [requestDescription, setRequestDescription] = useState("");
  const [tat, setTat] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [dummyState, setDummyState] = useState(null);
  const [rating, setRating] = useState(0);

  const [imageList, setImageList] = useState([]);
  const [
    uploadImageHandler,
    { data: imageData, reset: imageReset, loading: imageLoad },
  ] = useUploadImage();
  const [
    updateWebAppUserRequestHandler,
    { data: updatedWebAppUserRequestData },
  ] = useUpdateWebAppUserRequest();

  // let RequestStatusList = [];
  // if (
  //   requestCategory === "Housekeeping" ||
  //   requestCategory === "Maintenance" ||
  //   requestCategory === "Other requests"
  // ) {
  //   RequestStatusList = [
  //     { label: "Open", value: "Open" },
  //     { label: "Work in progress", value: "Work in progress" },
  //     { label: "Close", value: "Close" },
  //     { label: "Reject", value: "Reject" },
  //   ];
  // } else if (requestCategory === "Booking") {
  //   RequestStatusList = [
  //     { label: "Accept", value: "Accept" },
  //     { label: "Reject", value: "Reject" },
  //   ];
  // }

  let RequestStatusList = [];

  if (requestCategory === "Housekeeping" || requestCategory === "Maintenance") {
    RequestStatusList = [
      { label: "Open", value: "Open" },
      { label: "Work in progress", value: "Work in progress" },
      { label: "Close", value: "Close" },
    ];
  } else if (requestCategory === "Other requests") {
    RequestStatusList = [
      { label: "Open", value: "Open" },
      { label: "Work in progress", value: "Work in progress" },
      { label: "Close", value: "Close" },
      { label: "Reject", value: "Reject" },
    ];
  } else if (requestCategory === "Booking") {
    RequestStatusList = [
      { label: "Accept", value: "Accept" },
      { label: "Reject", value: "Reject" },
    ];
  }

  const UploadType = [{ label: "images", value: "staffImages" }];

  useEffect(() => {
    if (updatedWebAppUserRequestData) {
      refetch();
      handleToggle();

      toaster("success", "User request added successfully");
    }
  }, [updatedWebAppUserRequestData]);

  const renderPreview = () =>
    imagePreview?.map((item, index) => (
      <img
        className="rounded mr-1 mb-2 w-50"
        src={item}
        alt="avatar"
        key={index}
        onClick={() => {
          window.open(item, "_blank");
        }}
      />
    ));

  const submitDataHandler = () => {
    let data = {
      requestStatus: requestStatus?.value,
      staffRemark,
      staffName,
      logs: [
        ...logs,
        `${localStorage.getItem(
          "name"
        )} modified this request on ${new Date().toLocaleString("en-IN", {
          hour12: false,
        })}, status: ${requestStatus.value} & ${
          staffRemark === null ? "" : `Remark: ${staffRemark}`
        }`,
      ],
      staffImages: imageList,
    };

    if (data.requestStatus && data?.staffName) {
      // if (
      //   (requestCategory === "Housekeeping" ||
      //     requestCategory === "Maintenance") &&
      //   imageList.length === 0
      // ) {
      //   toaster(
      //     "error",
      //     "Please upload images for Housekeeping or Maintenance requests"
      //   );
      //   return;
      // }

      const requireStatus =
        requestStatus?.value === "Reject" || requestStatus?.value === "Close";

      const requireCategory =
        requestCategory === "Housekeeping" || requestCategory === "Maintenance";

      if (requireCategory && requireStatus && imageList.length === 0) {
        toaster(
          "error",
          "Please upload images for Housekeeping or Maintenance requests with open or close status"
        );
        return;
      }

      if (editData?.length) {
        const { _id } = editData[0];
        updateWebAppUserRequestHandler(_id, data);
      } else {
      }
      setRequestCategory(null);
      setRequestDescription("");
      setRequestName("");
      setImagePreview(null);
      setTat("");
      setImageList([]);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  useEffect(() => {
    if (editData?.length) {
      const {
        requestCategory,
        requestNumber,
        request,
        reservationId,
        ezeebookings,
        requestStatus,
        staffRemark,
        staffName,
        guestComment,
        requestImages,
        logs,
        rating,
        createdAt,
        staffImages,
        room,
        roomNo,
        hostel,
        guestName,
      } = editData[0];
      setData(editData[0]);

      setRequestNumber(requestNumber);
      setRequestDate(new Date(createdAt).toLocaleDateString());
      setRequestTime(new Date(createdAt).toLocaleTimeString());
      setRequestCategory(requestCategory);
      setRequestName(request?.requestName);
      setReservationId(reservationId);
      // setGuestName(ezeebookings?.GuestName);
      // setGuestRoom(ezeebookings?.Room);
      // setGuestRoomNo(ezeebookings?.RoomNo);
      // setHostelName(ezeebookings?.hostelName);
      setGuestName(guestName);
      setGuestRoom(room);
      setGuestRoomNo(roomNo);
      setHostelName(hostel?.name);
      setRequestStatus(
        requestStatus !== null
          ? { label: requestStatus, value: requestStatus }
          : ""
      );
      setStaffRemark(staffRemark !== null ? staffRemark : "");
      setStaffName(staffName !== null ? staffName : "");
      setGuestComment(guestComment);
      setImagePreview(requestImages);
      setLogs(logs);
      setRating(rating);
      setImageList(staffImages ? staffImages : []);

      if (
        requestStatus === "Close" ||
        requestStatus === "Reject" ||
        requestStatus === "Accept"
      ) {
        const editableElements = document.querySelectorAll(".save-button");
        editableElements.forEach((element) => {
          element.setAttribute("disabled", true);
        });
      }
    } else {
      setRequestNumber("");
      setRequestDate("");
      setRequestTime("");
      setRequestCategory(null);
      setRequestName("");
      setReservationId("");
      setGuestName("");
      setGuestRoom("");
      setGuestRoomNo("");
      setHostelName("");
      setRequestStatus("");
      setStaffRemark("");
      setStaffName("");
      setGuestComment("");
      setRequestImages("");
      setImagePreview(null);
      setImageList([]);
      setRating([]);
    }
  }, [editData]);

  useEffect(() => {
    if (editData?.length > 0) {
      setDummyState(editData[0]);
    }
  }, [editData]);

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          {/* // ! Request Number*/}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="requestNumber">
                <strong>Request ID</strong>
              </Label>
              <br />
              <Label className="form-label" for="requestNumber">
                {requestNumber}
              </Label>
            </FormGroup>
          </Col>

          {/* // ! Request Date*/}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="requestDate">
                <strong>Request Date</strong>
              </Label>
              <br />
              <Label className="form-label" for="requestDate">
                {requestDate}
              </Label>
            </FormGroup>
          </Col>

          {/* // ! Request Time*/}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="requestTime">
                <strong>Request Time</strong>
              </Label>
              <br />
              <Label className="form-label" for="requestTime">
                {requestTime}
              </Label>
            </FormGroup>
          </Col>

          {/* // ! Hostel Name*/}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostelName">
                <strong>Hostel Name</strong>
              </Label>
              <br />
              <Label className="form-label" for="hostelName">
                {hostelName}
              </Label>
            </FormGroup>
          </Col>

          {/* // ! Request Category */}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="requestCategory">
                <strong>Request Category</strong>
              </Label>
              <br />
              <Label className="form-label" for="requestCategory">
                {requestCategory}
              </Label>
            </FormGroup>
          </Col>

          {/* // ! Request Name */}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="requestName">
                <strong>Request Name</strong>
              </Label>
              <br />
              <Label className="form-label" for="requestCategory">
                {requestName}
              </Label>
            </FormGroup>
          </Col>

          {/* // ! Request Id */}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="reservationId">
                <strong>Reservation Id</strong>
              </Label>
              <br />
              <Label className="form-label" for="reservationId">
                {reservationId}
              </Label>
            </FormGroup>
          </Col>

          {/* // ! Guest Name */}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="guestName">
                <strong>Guest Name</strong>
              </Label>
              <br />
              <Label className="form-label" for="guestName">
                {guestName}
              </Label>
            </FormGroup>
          </Col>

          {/* // ! Guest Room */}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="guestRoom">
                <strong>Guest Room</strong>
              </Label>
              <br />
              <Label className="form-label" for="guestRoom">
                {guestRoom}
              </Label>
            </FormGroup>
          </Col>

          {/* // ! Guest Room No*/}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="guestRoomNo">
                <strong>Guest Room No</strong>
              </Label>
              <br />
              <Label className="form-label" for="guestRoomNo">
                {guestRoomNo}
              </Label>
            </FormGroup>
          </Col>

          {/* // ! Rating*/}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="rating">
                <strong>Rating</strong>
              </Label>
              <br />
              <Label className="form-label" for="rating">
                {rating}
              </Label>
            </FormGroup>
          </Col>

          {/* // ! Guest Remark*/}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="guestComment">
                <strong>Guest Remark</strong>
              </Label>
              <br />
              <Label className="form-label" for="guestComment">
                {guestComment}
              </Label>
            </FormGroup>
          </Col>

          {/* // ! Request Images */}
          <Col sm="12" style={{ cursor: "pointer" }}>
            <FormGroup className="w-100">
              <Label className="form-label" for="requestImages">
                <strong>Request Images</strong>
              </Label>
              <br />
              <Label className="form-label" for="requestImages">
                {requestImages}
              </Label>
              <Col sm="6">
                {imageLoad ? "Uploading image..." : renderPreview()}
              </Col>
            </FormGroup>
          </Col>

          {/* // ! Request Status */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="requestStatus">
                <strong>Request Status*</strong>
              </Label>
              <Select
                isClearable={false}
                id="requestStatus"
                name="requestStatus"
                value={requestStatus}
                options={RequestStatusList}
                required
                isDisabled={
                  data?.requestStatus === "Close" ||
                  data?.requestStatus === "Reject" ||
                  data?.requestStatus === "Accept"
                    ? true
                    : false
                }
                onChange={(value) => setRequestStatus(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          {/* // ! Staff Remark */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="staffRemark">
                <strong>Staff Remark</strong>
              </Label>

              <Input
                type="textarea"
                value={staffRemark}
                id="staffRemark"
                name="staffRemark"
                placeholder="Add Staff Remark"
                disabled={
                  data?.requestStatus === "Close" ||
                  data?.requestStatus === "Reject" ||
                  data?.requestStatus === "Accept"
                    ? true
                    : false
                }
                onChange={(e) => setStaffRemark(e.target.value)}
              />
            </FormGroup>
          </Col>

          {/* // ! Staff Name */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="staffName">
                <strong>Staff Name</strong>
              </Label>

              <Input
                type="textarea"
                value={staffName}
                id="staffName"
                name="staffName"
                placeholder="Add Staff Name"
                onChange={(e) => setStaffName(e.target.value)}
                disabled={
                  data?.requestStatus === "Close" ||
                  data?.requestStatus === "Reject" ||
                  data?.requestStatus === "Accept"
                    ? true
                    : false
                }
              />
            </FormGroup>
          </Col>

          {/* // ! Logs */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="logs">
                <strong>Logs</strong>
              </Label>
              <br />

              <ul>
                {data?.logs?.map((i, index) => {
                  return (
                    <Fragment key={index}>
                      <li>{i}</li>
                    </Fragment>
                  );
                })}
              </ul>
            </FormGroup>
          </Col>

          <Col sm="12">
            <Label className="form-label" for="logs">
              <strong>Upload images</strong>
            </Label>
            <StaffImagesSection
              imageList={imageList}
              setImageList={setImageList}
              editData={dummyState}
              uploadType={UploadType}
            />
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
