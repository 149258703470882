import React, { useEffect, useState } from 'react';
import { Button, Input, Table } from 'reactstrap';
import { Col, Card, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { useUpdateHostelFoodCharge } from '../../../api/dataSource';
import toaster from "@components/common/Toaster";

const tableHeaderData = ["Hostels", "Charges"];

const HostelFoodList = ({ hostelListData, refetch }) => {
    const [tableData, setTableData] = useState([]);
    const [hostelFoodChargeHandler, { data: hostelFoodcharge }] = useUpdateHostelFoodCharge();


    useEffect(() => {
        if (hostelListData?.length) {
            const filteredHostel = hostelListData.map(item => ({
                name: item.name,
                _id: item._id,
                value: item?.dailyFoodCharge
            }));

            setTableData(filteredHostel);
        }
    }, [hostelListData]);


    const handleChangeFoodCharge = (id, value) => {
        setTableData(prevTableData =>
            prevTableData.map(item => {
                if (item._id === id) {
                    console.log('Updating value for:', item);
                    return { ...item, value };
                }
                return item;
            })
        );
    };

    const handleSubmitData = () => {
        const validCharges = tableData
            .map(({ _id, value }) => ({
                id: _id,
                dailyFoodCharge: value !== null ? Number(value) : null
            }))
            .filter(({ dailyFoodCharge }) => dailyFoodCharge !== null);


        hostelFoodChargeHandler(validCharges)
        refetch();
        toaster("success", "food rates updated successfully")
    };


    return (
        <Row>
            <Col sm="6">
                <Card>
                    <Button onClick={handleSubmitData}>Save</Button>
                    <Table responsive>
                        <thead>
                            <tr>
                                {tableHeaderData.map((item) => (
                                    <th key={uuidv4()}>{item}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.map((item) => (
                                <tr key={item._id}>
                                    <td>{item?.name}</td>
                                    <td>
                                        <Input
                                            type="text"
                                            value={item?.value}
                                            id="charges"
                                            name="charges"
                                            placeholder="Food Charge"
                                            onChange={(e) => {
                                                const newValue = e.target.value.replace(/[^0-9]/g, '');
                                                handleChangeFoodCharge(item._id, newValue);
                                            }}
                                        />

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card>
            </Col>
        </Row>
    );
};

export default HostelFoodList;
