import Coupon from "../../views/Report/Coupon/index";
import ChatbotBoooking from "../../views/Report/Chatbot/index";
import WhatsappMessages from "../../views/Report/WhatsappMessages/index";
import BookingReport from "../../views/Report/BookingReport/index";
import DailyBookings from "../../views/Report/DailyBookings/index";
import UnpaidReservation from "../../views/UnpaidReservation/index";
import StayDateBooking from "../../views/Report/StayDateBooking/index";
import FeedbackReport from "../../views/Report/FeedbackReport/index"; //added by Nitish
import SourceReport from "../../views/Report/SourceReport/index";
import CarReport from "../../views/Report/CarReport/index";
import CarOTAwiseReport from "../../views/Report/CarOTAwiseReport/index";
import HotelCenter from "../../views/Report/HotelCenter/index";
import HourlySales from "../../views/Report/HourlySales/index";
import ExtraChargesReport from "../../views/Report/ExtraChargesReport/index";
import GuestDatabase from "../../views/Report/GuestDatabase";
import OccupancyTracker from "../../views/Report/OccupancyTracker/index";
import Memberships from "../../views/Report/Memberships/index";
import AddOn from "../../views/Report/AddOn/index";
import WebsiteBooking from "../../views/Report/WebsiteBooking/index";
import LoyaltyReport from "../../views/Report/LoyaltyReport/index";
import SearchReport from "../../views/Report/SearchReport";
import PropertyWiseNoShowCancellation from "../../views/Report/PropertyWiseNoShowCancellation";
import OccupancyADRReport from "../../views/Report/OccupancyADRReport/index";
import WebcheckinAnalysis from "../../views/Report/webCheckinAnalysis";
import RoomwiseADRReport from "../../views/Report/RoomwiseADRReport";
import OTACancellation from "../../views/Report/OTACancellation";
import RefundRequestPreStay from "../../views/RefundRequestPreStay";

const ReportsRoutes = [
  {
    path: "/report/coupon",
    component: Coupon,
    exact: true,
  },
  {
    path: "/report/chatbotBooking",
    exact: true,
    component: ChatbotBoooking,
  },
  {
    path: "/report/whatsappMessages",
    exact: true,
    component: WhatsappMessages,
  },
  {
    path: "/report/bookingReport",
    exact: true,
    component: BookingReport,
  },
  {
    path: "/report/dailyBookings",
    exact: true,
    component: DailyBookings,
  },
  {
    path: "/report/unpaidReservation",
    exact: true,
    component: UnpaidReservation,
  },
  {
    path: "/report/OTACancellation",
    exact: true,
    component: OTACancellation,
  },
  {
    path: "/report/staydateBookings",
    exact: true,
    component: StayDateBooking,
  },
  {
    path: "/report/sourceReport",
    exact: true,
    component: SourceReport,
  },
  {
    path: "/report/carReport",
    exact: true,
    component: CarReport,
  },
  {
    path: "/report/carOTAwiseReport",
    exact: true,
    component: CarOTAwiseReport,
  },
  {
    path: "/report/monthlyRevenueSalesReport",
    exact: true,
    component: RoomwiseADRReport,
  },
  {
    path: "/report/FeedbackReport",
    exact: true,
    component: FeedbackReport,
  },
  {
    path: "/report/HourlySales",
    exact: true,
    component: HourlySales,
  },
  {
    path: "/report/extraChargesReport",
    exact: true,
    component: ExtraChargesReport,
  },
  {
    path: "/report/HotelCenter",
    exact: true,
    component: HotelCenter,
  },
  {
    path: "/report/GuestDatabase",
    exact: true,
    component: GuestDatabase,
  },
  {
    path: "/report/occupancyTracker",
    exact: true,
    component: OccupancyTracker,
  },
  {
    path: "/report/memberships",
    exact: true,
    component: Memberships,
  },
  {
    path: "/report/websiteBooking",
    exact: true,
    component: WebsiteBooking,
  },
  {
    path: "/report/loyaltyReport",
    exact: true,
    component: LoyaltyReport,
  },
  // {
  //   path: "/report/addOn",
  //   exact: true,
  //   component: AddOn
  // }
  // {
  //   path: "/report/contactlesscheckout",
  //   exact: true,
  //   component: ContactlessCheckout,
  // },
  {
    path: "/report/searchReport",
    exact: true,
    component: SearchReport,
  },
  {
    path: "/report/propertyWise/noShow&Cancellation",
    exact: true,
    component: PropertyWiseNoShowCancellation,
  },
  {
    path: "/report/occupancyADRReport",
    exact: true,
    component: OccupancyADRReport,
  },
  {
    path: "/report/webCheckinAnalysis",
    exact: true,
    component: WebcheckinAnalysis,
  },
  {
    path: "/report/refundRequestPreStay",
    component: RefundRequestPreStay,
    exact: true,
  },
];

export default ReportsRoutes;
