import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_PAGINATED_POST_STAY_REFUND_REQUEST_LIST = gql`
  query getPaginatedPostStayRefundRequestList($filter: filter) {
    getPaginatedPostStayRefundRequestList(filter: $filter) {
      count
      limit
      page
      data {
        _id
        refundType
        createdAt
        updatedAt
        reservationID
        refundID
        hostel {
          name
          _id
        }
        status
        hostelName
        refundAmount
        refundReason
        refundRemark
        initiatedBy
        requestDate
        checkOut
        photo {
          imageURL
        }
        videoUrl
        guestName
        email
        mobileNumber
        bookingAmount
        bookingSource

        payoutLink
        payoutStatus
        payoutLinkCreationTime
        payoutID
        rejectionReason
        approverRemark
        logs
      }
    }
  }
`;

export const useGetPaginatedPostStayRefundRequestList = (filter) => {
  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_POST_STAY_REFUND_REQUEST_LIST,
    {
      variables: {
        filter,
      },
    }
  );

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};

export const GET_REFUND_GUEST_DATA_BY_RESERVATIONID = gql`
  query getRefundGuestDataByReservationId($reservationID: String!) {
    getRefundGuestDataByReservationId(reservationID: $reservationID) {
      reservationID
      bookingAmount
      guestName
      phoneNumber
      email
      bookingSource
    }
  }
`;

// Custom Hook with Skip Logic
// export const useGetRefundGuestDataByReservationId = (reservationID) => {
//   const shouldSkip = !reservationID?.trim();

//   const { loading, error, data } = useQuery(
//     GET_REFUND_GUEST_DATA_BY_RESERVATIONID,
//     {
//       variables: { reservationID },
//       skip: shouldSkip,
//     }
//   );

//   useLoadingHandler(loading);

//   return { loading, error, data };
// };

export const useGetRefundGuestDataByReservationId = (reservationID) => {
  const [fetchData, { loading, error, data, refetch }] = useLazyQuery(
    GET_REFUND_GUEST_DATA_BY_RESERVATIONID,
    {
      variables: {
        reservationID,
      },
    }
  );

  return {
    fetchData,
    loading,
    error,
    data,
    refetch,
  };
};

//Added by Nitish

export const GET_EMPLOYEE_DETAILS_BY_HOSTEL_ID = gql`
  query getEmployeeProfileListByHostelId($hostelId: ID) {
    getEmployeeProfileListByHostelId(hostelId: $hostelId) {
      _id
      hostel {
        _id
        name
      }
      prefix
      fullName
      empCode
      empType
      empStatus
      department
      subDepartment
      designation
    }
  }
`;

export const useGetEmployeeProfileByHostelId = (hostelId) => {
  const { loading, error, data, refetch } = useQuery(
    GET_EMPLOYEE_DETAILS_BY_HOSTEL_ID,
    {
      variables: { hostelId },
      skip: !hostelId,
    }
  );
  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};
