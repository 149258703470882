import BusBookings from "../../views/BusBooking";

const BusbookingRoutes = [
    {
        path: "/busBookings/bookings",
        component: BusBookings,
        exact: true,
    },
    // {
    //     path: "/merchandise/edit/merchandiseProduct/:id",
    //     component: MerchandiseProductTabs,
    //     exact: true,
    //     meta: {
    //         navLink: "/merchandise",
    //     },
    // },
]

export default BusbookingRoutes;