import React, { useEffect, useState } from "react";
import { Edit, Plus, Trash2, Link } from "react-feather";
import { Card, CardHeader, CardTitle, Button, Table, Badge } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { useDeleteHostelComparison } from "../../api/dataSource";
import { reactFeatherIcon } from "../../styles/reactFeatherIcon";
import confirmDelete from "@components/common/confirmDelete";
import ConfirmDeleteForTheHosteller from "./ConfirmDeleteForTheHosteller";
import toast from "react-hot-toast";

const tableHeaderData = [
  "Action",
  "",
  "Hostel Name",
  "Competitor",
  "Weightage",
  "Platform",
  "Link",
];

const HostelComparisonTable = ({ openModal, setId, data, refetch }) => {
  const [tableData, setTableData] = useState(data);

  const { deleteHostelComparisonHandler } = useDeleteHostelComparison();

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  const bookingSourceImages = {
    "Booking.com":
      "https://static.thehosteller.com/Hostel/Media/bookingcom%201-1694686256522.svg",
    Goibibo:
      "https://static.thehosteller.com/Hostel/Media/go%20ibibo-1694686239581.svg",
    HostelWorld:
      "https://static.thehosteller.com/Hostel/Media/hostelworld%202%20(1)-1695295577721.svg",
    Google:
      "https://static.thehosteller.com/Hostel/Media/Layer_x0020_1-1694686214140.svg",
    MakeMyTrip:
      "https://static.thehosteller.com/Hostel/Media/Make%20mytrip]-1694686267981.svg",
    Tripadvisor:
      "https://static.thehosteller.com/Hostel/Media/Tripadvisor%20(1)-1695295571996.svg",
  };

  const deleteHandler = (id) => {
    deleteHostelComparisonHandler(id).then(() => {
      refetch();
    });
  };

  const handleDeleteconfirm = (id, competitor) => {
    if (!id) {
      refetch();
      return;
    }
    if (competitor === "The Hosteller") {
      return ConfirmDeleteForTheHosteller(() => deleteHandler(id));
    }
    confirmDelete(() => deleteHandler(id));
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Competitor Data</CardTitle>
        <Button
          style={{
            backgroundColor: "#ffe700",
            height: "40px",
            marginTop: "22px",
            borderRadius: "5px",
            padding: "10px 40px",
            outline: "none",
            border: "0",
          }}
          color="white"
          appearance="primary"
          type="button"
          onClick={() => {
            setId(null);
            openModal();
          }}
        >
          <Plus size={15} className="mr-50" />
          Add Competitor
        </Button>
      </CardHeader>
      {tableData?.length ? (
        <Table responsive>
          <thead>
            <tr>
              {tableHeaderData.map((item) => (
                <th key={uuidv4()}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((item) => (
              <tr key={item._id}>
                <td style={{ width: "10px" }}>
                  <Button
                    href="/"
                    className="rounded-circle btn-icon"
                    color="none"
                    onClick={(e) => {
                      e.preventDefault();
                      setId(item._id);
                      openModal();
                    }}
                    title="Edit"
                  >
                    <Edit
                      color={reactFeatherIcon.iconColor}
                      size={reactFeatherIcon.iconSize}
                    />
                  </Button>
                </td>
                <td style={{ width: "10px" }}>
                  <Button
                    className="ml-2"
                    onClick={() => {
                      handleDeleteconfirm(item?._id, item.competitor);
                    }}
                    color="none"
                  >
                    <Trash2
                      color={reactFeatherIcon.iconColor}
                      size={reactFeatherIcon.iconSize}
                    />
                  </Button>
                </td>
                <td>{item.hostel.name}</td>
                <td>{item.competitor}</td>
                <td>{item.weightage}</td>
                <td>
                  {item?.platform && bookingSourceImages[item?.platform] ? (
                    <img
                      src={bookingSourceImages[item?.platform]}
                      alt={item?.platform}
                      width={
                        ["Tripadvisor", "HostelWorld"].includes(item?.platform)
                          ? "90"
                          : "75"
                      }
                      height={
                        ["Tripadvisor", "HostelWorld"].includes(item?.platform)
                          ? "45"
                          : "25"
                      }
                    />
                  ) : (
                    <span>{item?.platform}</span>
                  )}
                </td>
                <td>
                  <a
                    href={`${item?.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <Link></Link>
                  </a>
                </td>

                {/* <td>
                <Button
                  onClick={() => {
                    deleteHandler(item?._id);
                  }}
                  color="none"
                >
                  <Trash2 size={17} />
                </Button>
              </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <h2 style={{ paddingLeft: "20px" }}>No Data Found</h2>
      )}
    </Card>
  );
};

export default HostelComparisonTable;
