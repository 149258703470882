import gql from "graphql-tag";

export const GET_TRIP_LIST = gql`
  query getTripList {
    getTripList {
      name
      _id
    }
  }
`;

export const GET_HOSTEL_LIST = gql`
  query getHostelList {
    getHostelList {
      name
      _id
    }
  }
`;

// ! getDestinationList added by Ashwin
export const GET_DESTINATION_LIST = gql`
  query getDestinationList {
    getDestinationList {
      destination
      _id
    }
  }
`;

export const GET_SOURCE_LIST = gql`
  query getSourcesList {
    getSourcesList {
      Source
    }
  }
`;

export const GET_BLOG_LIST = gql`
  query getBlogsList {
    getBlogsList {
      _id
      blogTitle
    }
  }
`;

export const GET_MESSAGETYPE_LIST = gql`
  query getMessageType {
    getMessageType {
      MessageType
    }
  }
`;

//added by nitish to get only name and id of hostel
export const GET_HOSTELS_NAME_AND_ID = gql`
  query getHostelsNameandId {
    getHostelsNameandId {
      name
      _id
    }
  }
`;

// added by arun
export const GET_ALL_HOSTEL_FOOD_CHARGES = gql`
query {
    getAllHostelAndFoodCharges{
        name
        _id
        dailyFoodCharge
    }
}
`