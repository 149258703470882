import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CREATE_POST_STAY_REFUND_REQUEST = gql`
  mutation createPostStayRefundRequest(
    $refundRequestInput: refundRequestInput
  ) {
    createPostStayRefundRequest(refundRequestInput: $refundRequestInput) {
      hostel {
        name
        _id
      }
    }
  }
`;

export const useCreatePostStayRefundRequest = () => {
  const [createPostStayRefundRequest, { loading, error, data, refetch }] =
    useMutation(CREATE_POST_STAY_REFUND_REQUEST);

  useLoadingHandler(loading);

  const createPostStayRefundRequestHandler = (refundRequestInput) => {
    createPostStayRefundRequest({
      variables: { refundRequestInput },
    });
  };

  return [
    createPostStayRefundRequestHandler,
    { loading, error, data, refetch },
  ];
};

// export const UPDATE_POST_STAY_REFUND_REQUEST = gql`
//   mutation updatePostStayRefundRequestData(
//     $refundRequestInput: refundRequestInput
//   ) {
//     updatePostStayRefundRequestData(refundRequestInput: $refundRequestInput) {
//       hostel {
//         name
//         _id
//       }
//     }
//   }
// `;

export const UPDATE_POST_STAY_REFUND_REQUEST = gql`
  mutation updatePostStayRefundRequestData(
    $id: ID!
    $refundRequestInput: refundRequestInput!
  ) {
    updatePostStayRefundRequestData(
      id: $id
      refundRequestInput: $refundRequestInput
    ) {
      hostel {
        name
        _id
      }
    }
  }
`;

// export const useUpdatePostStayRefundRequest = () => {
//   const [updatePostStayRefundRequestData, { loading, error, data, refetch }] =
//     useMutation(UPDATE_POST_STAY_REFUND_REQUEST);

//   useLoadingHandler(loading);

//   const updatePostStayRefundRequestHandler = (refundRequestInput) => {
//     updatePostStayRefundRequestData({
//       variables: { refundRequestInput },
//     });
//   };

//   return [
//     updatePostStayRefundRequestHandler,
//     { loading, error, data, refetch },
//   ];
// };

export const useUpdatePostStayRefundRequest = () => {
  const [updatePostStayRefundRequestData, { loading, error, data, refetch }] =
    useMutation(UPDATE_POST_STAY_REFUND_REQUEST);

  useLoadingHandler(loading);

  const updatePostStayRefundRequestHandler = (id, refundRequestInput) => {
    updatePostStayRefundRequestData({
      variables: { id, refundRequestInput },
    });
  };

  return [
    updatePostStayRefundRequestHandler,
    { loading, error, data, refetch },
  ];
};
