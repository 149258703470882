import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Badge,
} from "reactstrap";
import styled from "styled-components";

const RefundRequestSlideModal = ({
  open,
  handleToggle,
  submitDataHandler,
  children,
  large,
  disabled,
  editData,
  userRole,
  userDepartment,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSaveButtonVisible =
    (userRole === "Ground Staff" ||
      userRole === "Area Manager" ||
      userRole === "Regional Manager") &&
    userDepartment === "Operations";

  const isSaveButtonDisabled = (() => {
    if (
      (userRole === "Area Manager" || userRole === "Regional Manager") &&
      (editData?.status === "Approved" || editData?.status === "Rejected")
    ) {
      return true;
    }

    if (
      userRole === "Ground Staff" &&
      (editData?.status === "Approved" || editData?.status === "Rejected")
    ) {
      return true;
    }
    return false;
  })();

  return (
    <StyledModalWrap
      unmountOnClose={true}
      isOpen={open}
      toggle={handleToggle}
      className={large ? "ml-auto m-0 modal-lg" : "ml-auto m-0"}
    >
      {isMobile ? (
        <ModalHeader toggle={handleToggle}>Details</ModalHeader>
      ) : (
        <ModalHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          toggle={handleToggle}
        >
          <HeaderText className="mb-0.5">
            Post-stay Refund Request Details
          </HeaderText>
          <HeaderBadgeWrapper>
            {editData?.status !== undefined && (
              <StatusBadgeWrapper>
                <StatusLabel>Current Status:</StatusLabel>
                <Badge
                  color="secondary"
                  className="status-badge animated pulse"
                >
                  {editData?.status}
                </Badge>
              </StatusBadgeWrapper>
            )}
            {editData?.requestID && (
              <RequestIDWrapper>
                <Badge color="primary" className="request-id-badge">
                  Request ID: {editData.requestID}
                </Badge>
              </RequestIDWrapper>
            )}
          </HeaderBadgeWrapper>
        </ModalHeader>
      )}

      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        {isSaveButtonVisible && (
          <Button
            color="light"
            onClick={submitDataHandler}
            className="save-button"
            disabled={isSaveButtonDisabled}
          >
            Save
          </Button>
        )}
      </ModalFooter>
    </StyledModalWrap>
  );
};

const StyledModalWrap = styled(Modal)`
  .modal-content {
    min-height: 100vh;
  }
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
    .close {
      margin: 0;
    }
  }
  .status-badge {
    background-color: #3e6c09;
    margin-left: 12px;
  }
  .status-badge.animated.pulse {
    animation: pulse 1s infinite;
  }
  .request-id-badge {
    background-color: #007bff;
    margin-left: 18px;
  }
  .modal-footer {
    position: sticky;
    bottom: 0;
    z-index: 99;
    background-color: #f8f8f8;
  }
`;

const HeaderText = styled.div`
  font-size: 18px;
`;

const HeaderBadgeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StatusBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StatusLabel = styled.div`
  font-size: 14px;
  margin-right: 5px;
`;

const RequestIDWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default RefundRequestSlideModal;
