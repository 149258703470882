import React, { useState, useEffect, Fragment } from "react";
import SlideModal from "../../../@core/components/common/slide-modal/index";
import { Col, Row, Input, FormGroup, Label } from "reactstrap";
import { useHostelList } from "../../../api/dataSource";
import {
  useCreateOpsHostelVendor,
  useUpdateOpsHostelVendor,
} from "../../../api/OpsHostelVendor/Mutation";
import Select from "react-select";
import toaster from "@components/common/Toaster";
import AttachmentComponent from "./AttachmentComponent";
import Flatpickr from "react-flatpickr";
import { selectThemeColors, dateFormatter } from "@utils";
import makeAnimated from "react-select/animated";
import { indianStateList } from "../../../utility/Utils";
import SwitchIcon from "@components/common/switch-icon";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [email, setEmail] = useState("");
  const [services, setServices] = useState("");
  const [vendorGSTNumber, setVendorGSTNumber] = useState(null);
  const [vendorBankAccNumber, setVendorBankAccNumber] = useState("");
  const [vendorIFSC, setVendorIFSC] = useState("");

  //!
  const [panNo, setPanNo] = useState("");
  const [pocName, setPocName] = useState("");
  const [accountType, setAccountType] = useState(null);
  const [bankDetailImage, setBankDetailImage] = useState([]);

  //! Attachment (Bank Detail Image) useState
  const [dummyStateBankDetail] = useState(null);
  const [vendorHead, setVendorHead] = useState(null);
  const [vendorSubHead, setVendorSubHead] = useState(null);
  const [vendorType, setVendorType] = useState(null);
  const [vendorPriority, setVendorPriority] = useState(null);
  const [addressDetails, setAddressDetails] = useState("");
  const [agreement, setAgreement] = useState([]);
  const [vendorCategory, setVendorCategory] = useState(null);
  const [vendorStatus, setVendorStatus] = useState(true);

  //! Attachment (Agreement Image) useState
  const [dummyStateAgreement] = useState(null);
  const [agreementStartDate, setAgreementStartDate] = useState(null);
  const [agreementEndDate, setAgreementEndDate] = useState(null);
  const [paymentCycle, setPaymentCycle] = useState(null);
  const [logs, setLogs] = useState([]);

  //!

  const [vendorState, setVendorState] = useState([]);

  const { data: allHostelsData } = useHostelList();
  const [createOpsHostelVendorHandler, { data: createdHostelVendorData }] =
    useCreateOpsHostelVendor();

  const [updateOpsHostelVendorHandler, { data: updateHostelVendorData }] =
    useUpdateOpsHostelVendor();

  const animatedComponents = makeAnimated();

  const paymentCycleList = [
    { label: "Monthly", value: "Monthly" },
    { label: "Biweekly", value: "Biweekly" },
    { label: "Billing", value: "Billing" },
  ];

  const vendorPriorityList = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
  ];

  const accountTypeList = [
    { label: "Current", value: "Current" },
    { label: "Saving", value: "Saving" },
  ];

  const vendorHeadList = [
    { label: "Hostel", value: "Hostel" },
    { label: "F&B", value: "F&B" },
    { label: "Ancillary", value: "Ancillary" },
    { label: "CAPEX", value: "CAPEX" },
  ];

  const name = localStorage.getItem("name");
  const department = localStorage.getItem("department");

  const vendorLogs = editData?.logs?.slice().reverse();

  let vendorSubHeadList;

  if (vendorHead?.value === "Hostel") {
    vendorSubHeadList = [
      { label: "Utilities", value: "Utilities" },
      { label: "R&M", value: "R&M" },
      { label: "HK", value: "HK" },
      { label: "General", value: "General" },
    ];
  } else if (vendorHead?.value === "F&B") {
    vendorSubHeadList = [
      { label: "Material", value: "Material" },
      // { label: "Staff Welfare", value: "Staff Welfare" },
      // { label: "Staff travel", value: "Staff travel" },
      // { label: "Salary", value: "Salary" },
      // { label: "Advance Salary", value: "Advance Salary" },
    ];
  } else if (vendorHead?.value === "Ancillary") {
    vendorSubHeadList = [
      { label: "Material", value: "Material" },
      // { label: "Payout", value: "Payout" },
      { label: "Vendor Payout", value: "Vendor Payout" },
    ];
  } else if (vendorHead?.value === "CAPEX") {
    vendorSubHeadList = [
      { label: "Hostel", value: "Hostel" },
      { label: "F&B", value: "F&B" },
      { label: "Ancillary", value: "Ancillary" },
    ];
  }

  const HostelUtilitiesType = [
    // { label: "Electricity/DG/Fuel", value: "Electricity/DG/Fuel" },
    { label: "Electricity", value: "Electricity" },
    { label: "Fuel", value: "Fuel" },
    // { label: "Water", value: "Water" },
    { label: "Water/Tanks", value: "Water/Tanks" },
    { label: "Internet", value: "Internet" },
    // { label: "TV", value: "TV" },
    { label: "TV/OTT", value: "TV/OTT" },
    { label: "Software", value: "Software" },
    { label: "Others", value: "Others" },
  ];

  const R_M_SubFieldsType = [
    { label: "Civil", value: "Civil" },
    { label: "Furniture", value: "Furniture" },
    { label: "Furnishing", value: "Furnishing" },
    { label: "Fabrication", value: "Fabrication" },
    { label: "Electrical", value: "Electrical" },
    { label: "Electronics/Equipments", value: "Electronics/Equipments" },
    { label: "Plumbing", value: "Plumbing" },
    // { label: "Others", value: "Others" },
    { label: "Miscellaneous", value: "Miscellaneous" },
  ];

  const HostelHKType = [
    { label: "Toiletries", value: "Toiletries" },
    { label: "Laundry", value: "Laundry" },
    { label: "Garbage", value: "Garbage" },
    { label: "Room Amenities", value: "Room Amenities" },
    { label: "Pest Control", value: "Pest Control" },
    { label: "Others", value: "Others" },
  ];

  const HostelGeneralType = [
    { label: "Stationery & Printing", value: "Stationery & Printing" },
    { label: "Legal & Liaisoning", value: "Legal & Liaisoning" },
    // { label: "Local Transportation", value: "Local Transportation" },
    { label: "Courier", value: "Courier" },
    { label: "Staff Welfare", value: "Staff Welfare" },
    { label: "Staff Travel", value: "Staff Travel" },
    { label: "Others", value: "Others" },
  ];

  const F_AND_B_MATERIAL_TYPE = [
    { label: "Dairy", value: "Dairy" },
    { label: "Non-Dairy", value: "Non-Dairy" },
    { label: "Vegetables/Fruits", value: "Vegetables/Fruits" },
    // { label: "Supplies", value: "Supplies" },
    { label: "Supplies (Gas, etc)", value: "Supplies (Gas, etc)" },
    // { label: "Others", value: "Others" },
    { label: "Others (R&M, etc)", value: "Others (R&M, etc)" },
  ];

  const AncillaryPayoutVendorType = [
    { label: "Guest Laundry", value: "Guest Laundry" },
    { label: "Transportation", value: "Transportation" },
    { label: "Activity/Adventure", value: "Activity/Adventure" },
    { label: "Accommodation", value: "Accommodation" },
    { label: "Others", value: "Others" },
  ];

  const AncillaryMaterialVendorType = [
    { label: "Lock", value: "Lock" },
    { label: "Warmee", value: "Warmee" },
    { label: "Toiletry Kit", value: "Toiletry Kit" },
    { label: "Others", value: "Others" },
  ];

  const CapexSubFieldsType = [
    { label: "Civil", value: "Civil" },
    { label: "Furniture", value: "Furniture" },
    { label: "Furnishing", value: "Furnishing" },
    { label: "Fabrication", value: "Fabrication" },
    { label: "Electrical", value: "Electrical" },
    { label: "Electronics/Equipments", value: "Electronics/Equipments" },
    { label: "Plumbing", value: "Plumbing" },
    { label: "Others", value: "Others" },
  ];

  const vendorCategoryList = [
    { label: "OPEX", value: "OPEX" },
    { label: "CAPEX", value: "CAPEX" },
    { label: "CAPEX + OPEX", value: "CAPEX + OPEX" },
  ];

  const uploadTypeBankDetail = [
    { label: "imageURL", value: "bankDetailImage" },
  ];

  const uploadTypeAgreement = [{ label: "imageURL", value: "agreement" }];

  useEffect(() => {
    if (createdHostelVendorData?.createHostelVendor?.vendorName) {
      toaster("success", "Vendor Added successfully");
      refetch();
      handleToggle();
    }
  }, [createdHostelVendorData]);

  useEffect(() => {
    console.log(updateHostelVendorData);
    if (updateHostelVendorData?.updateHostelVendor) {
      toaster("success", "Vendor updated successfully");
      refetch();
      handleToggle();
    }
  }, [updateHostelVendorData]);

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  const handleChange = function (HostelsList) {
    return setHostel(HostelsList);
  };

  const handleVendorState = (vendorState) => {
    return setVendorState(vendorState);
  };

  useEffect(() => {
    if (editData?._id) {
      let hotelSelectedData = [];
      editData?.hostel?.map((hostel) => {
        allHostelsData?.getHostelList?.map((hostellist) => {
          if (hostel?._id === hostellist?._id) {
            hotelSelectedData?.push({
              label: hostel?.name,
              value: hostel?._id,
            });
          }
        });
      });

      setHostel(hotelSelectedData);
      // setHostel(
      //   editData?.hostel?.name
      //     ? { label: editData?.hostel?.name, value: editData?.hostel?._id }
      //     : null
      // );
      setContactNo(editData?.contactNo !== null ? editData?.contactNo : "");
      setVendorName(editData?.vendorName !== null ? editData?.vendorName : "");
      setEmail(editData?.email !== null ? editData?.email : "");
      setServices(editData?.services !== null ? editData?.services : "");
      setVendorGSTNumber(
        editData?.vendorGSTNumber !== null ? editData?.vendorGSTNumber : ""
      );
      setVendorBankAccNumber(
        editData?.vendorBankAccNumber !== null
          ? editData?.vendorBankAccNumber
          : ""
      );
      setVendorIFSC(editData?.vendorIFSC !== null ? editData?.vendorIFSC : "");
      setPanNo(editData?.panNo !== null ? editData?.panNo : "");
      setPocName(editData?.pocName !== null ? editData?.pocName : "");
      setAccountType(
        editData?.accountType !== null
          ? { label: editData?.accountType, value: editData?.accountType }
          : ""
      );

      setBankDetailImage(
        editData?.bankDetailImage !== null ? editData?.bankDetailImage : []
      );
      setAgreement(editData?.agreement !== null ? editData?.agreement : []);
      setAgreementStartDate(
        editData?.agreementStartDate !== null
          ? editData?.agreementStartDate
          : null
      );
      setAgreementEndDate(
        editData?.agreementEndDate !== null ? editData?.agreementEndDate : null
      );
      setPaymentCycle(
        editData?.paymentCycle !== null
          ? { label: editData?.paymentCycle, value: editData?.paymentCycle }
          : null
      );
      setVendorHead(
        editData?.vendorHead !== null
          ? { label: editData?.vendorHead, value: editData?.vendorHead }
          : null
      );
      setVendorSubHead(
        editData?.vendorSubHead !== null
          ? { label: editData?.vendorSubHead, value: editData?.vendorSubHead }
          : null
      );
      setVendorType(
        editData?.vendorType !== null
          ? { label: editData?.vendorType, value: editData?.vendorType }
          : null
      );
      setVendorPriority(
        editData?.vendorPriority !== null
          ? { label: editData?.vendorPriority, value: editData?.vendorPriority }
          : null
      );
      setAddressDetails(
        editData?.addressDetails !== null ? editData?.addressDetails : ""
      );
      setVendorCategory(
        editData?.vendorCategory !== null
          ? { label: editData?.vendorCategory, value: editData?.vendorCategory }
          : null
      );
      let selectedState = [];
      editData?.vendorState?.length &&
        editData?.vendorState?.map((item) => {
          selectedState.push({
            label: item,
            value: item,
          });
        });
      setVendorState(selectedState);
      setVendorStatus(
        editData?.vendorStatus !== null ? editData?.vendorStatus : true
      );
    } else {
      setHostel([]);
      setVendorName("");
      setContactNo("");
      setEmail("");
      setServices("");
      setVendorGSTNumber("");
      setVendorBankAccNumber("");
      setVendorIFSC("");
      setPanNo("");
      setPocName("");
      setAccountType(null);
      setBankDetailImage([]);
      setAgreement([]);
      setAgreementStartDate(null);
      setAgreementEndDate(null);
      setPaymentCycle(null);
      setVendorHead(null);
      setVendorSubHead(null);
      setVendorType(null);
      setVendorPriority(null);
      setAddressDetails("");
      setVendorCategory(null);
      setVendorState([]);
      setVendorStatus(true);
    }
  }, [editData]);

  const onSubmitHandler = () => {
    const hostelsArray = [];

    hostel?.map((item) => {
      hostelsArray?.push(item?.value);
    });

    const vendorStateList = vendorState?.length
      ? vendorState?.map((item) => item?.value)
      : [];

    const data = {
      // hostel: hostel?.value,
      hostel: JSON.parse(JSON.stringify(hostelsArray)),
      vendorName: vendorName,
      contactNo,
      email,
      services,
      vendorBankAccNumber,
      vendorIFSC,
      panNo,
      pocName,
      accountType: accountType?.value,
      bankDetailImage,
      agreement,
      agreementStartDate,
      agreementEndDate,
      paymentCycle: paymentCycle?.value,
      vendorHead: vendorHead?.value,
      vendorSubHead: vendorSubHead?.value,
      vendorType: vendorType?.value,
      vendorPriority: vendorPriority?.value,
      addressDetails,
      vendorCategory: vendorCategory?.value,
      name,
      department,
      vendorState: vendorStateList.length > 0 ? vendorStateList : [],
      vendorStatus,
      logs: editData?.logs !== null ? editData?.logs : [],
    };
    if (vendorGSTNumber) data.vendorGSTNumber = vendorGSTNumber;

    if (
      data?.vendorName &&
      data?.vendorPriority &&
      data?.pocName &&
      data?.contactNo &&
      data?.vendorHead &&
      data?.vendorSubHead &&
      data?.vendorType &&
      data?.vendorCategory &&
      data?.vendorBankAccNumber &&
      data?.vendorIFSC
    ) {
      if (editData?._id) {
        updateOpsHostelVendorHandler(editData?._id, data);
      } else {
        createOpsHostelVendorHandler(data);
      }

      setHostel([]);
      setVendorName("");
      setContactNo("");
      setEmail("");
      setServices("");
      setVendorGSTNumber("");
      setVendorBankAccNumber("");
      setVendorIFSC("");
      setPanNo("");
      setPocName("");
      setAccountType(null);
      setBankDetailImage([]);
      setAgreement([]);
      setAgreementStartDate(null);
      setAgreementEndDate(null);
      setPaymentCycle(null);
      setVendorHead(null);
      setVendorSubHead(null);
      setVendorType(null);
      setVendorPriority(null);
      setAddressDetails("");
      setVendorCategory(null);
      setVendorState([]);
      setVendorStatus(true);
    } else {
      toaster("error", "Please fill all the required details marked with *");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={() => onSubmitHandler()}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="vendorName">
                Vendor company name*
              </Label>
              <Input
                type="text"
                value={vendorName}
                id="vendorName"
                name="vendorName"
                placeholder="Add vendor company name"
                onChange={(e) =>
                  e.target.value[0] !== " "
                    ? setVendorName(e.target.value)
                    : toaster("error", "initial space not allowed")
                }
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="vendorPriority">
                Vendor priority*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="vendorPriority"
                name="vendorPriority"
                options={vendorPriorityList}
                value={vendorPriority}
                onChange={(value) => setVendorPriority(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="pocName">
                Vendor POC name*
              </Label>
              <Input
                type="text"
                value={pocName}
                id="pocName"
                name="pocName"
                placeholder="Add Vendor POC name"
                onChange={(e) =>
                  e.target.value[0] !== " "
                    ? setPocName(e.target.value)
                    : toaster("error", "initial space not allowed")
                }
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="contactNo">
                Contact number*
              </Label>
              <Input
                type="text"
                value={contactNo}
                id="contactNo"
                name="contactNo"
                placeholder="Add contact number"
                // onChange={(e) => { !isNaN(e.target.value) && { setContactNo(e.target.value) } }}
                onChange={(e) => {
                  if (!isNaN(e.target.value) && e.target.value[0] != " ") {
                    setContactNo(e.target.value);
                  }
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                Hostels
              </Label>
              <Select
                onChange={handleChange}
                isClearable={false}
                isSearchable={true}
                theme={selectThemeColors}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                id="hostel"
                name="hostel"
                options={HostelsList}
                value={hostel}
                // onChange={(value) => setHostel(value)}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                State
              </Label>
              <Select
                onChange={handleVendorState}
                isClearable={true}
                theme={selectThemeColors}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                id="vendorState"
                name="vendorState"
                value={vendorState}
                options={indianStateList}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="vendorCategory">
                Vendor category*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                options={vendorCategoryList}
                value={vendorCategory}
                className="basic-multi-select"
                classNamePrefix="select"
                name="vendorCategory"
                onChange={(value) => {
                  setVendorCategory(value);
                }}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="vendorHead">
                Vendor head*
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                // theme={selectThemeColors}
                // isMulti
                options={vendorHeadList}
                value={vendorHead}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(module) => {
                  // setSubHead(null);
                  setVendorHead(module);
                }}
                // isDisabled={editData?._id}
              />
            </FormGroup>
          </Col>

          {vendorHead && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="vendorSubHead">
                  Vendor sub-head*
                </Label>
                <Select
                  isClearable={false}
                  isSearchable={true}
                  id="vendorSubHead"
                  name="vendorSubHead"
                  value={vendorSubHead}
                  options={vendorSubHeadList}
                  required
                  onChange={(value) => setVendorSubHead(value)}
                  classNamePrefix="select"
                  // isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}

          {((vendorHead?.value === "Hostel" &&
            vendorSubHead?.value === "Utilities") ||
            (editData?.vendorHead === "Hostel" &&
              editData?.vendorSubHead === "Utilities")) && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="vendorType">
                  Vendor type*
                </Label>
                <Select
                  isClearable={false}
                  id="vendorType"
                  name="vendorType"
                  value={vendorType}
                  options={HostelUtilitiesType}
                  required
                  onChange={(value) => setVendorType(value)}
                  classNamePrefix="select"
                  // isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}

          {((vendorHead?.value === "Hostel" &&
            vendorSubHead?.value === "R&M") ||
            (editData?.vendorHead === "Hostel" &&
              editData?.vendorSubHead === "R&M")) && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="vendorType">
                  R&M type*
                </Label>
                <Select
                  isClearable={false}
                  id="vendorType"
                  name="vendorType"
                  value={vendorType}
                  options={R_M_SubFieldsType}
                  required
                  onChange={(value) => setVendorType(value)}
                  classNamePrefix="select"
                  // isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}

          {((vendorHead?.value === "Hostel" && vendorSubHead?.value === "HK") ||
            (editData?.vendorHead === "Hostel" &&
              editData?.vendorSubHead === "HK")) && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="vendorType">
                  HK type*
                </Label>
                <Select
                  isClearable={false}
                  id="vendorType"
                  name="vendorType"
                  value={vendorType}
                  options={HostelHKType}
                  required
                  onChange={(value) => setVendorType(value)}
                  classNamePrefix="select"
                  // isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}

          {((vendorHead?.value === "Hostel" &&
            vendorSubHead?.value === "General") ||
            (editData?.vendorHead === "Hostel" &&
              editData?.vendorSubHead === "General")) && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="vendorType">
                  General type*
                </Label>
                <Select
                  isClearable={false}
                  id="vendorType"
                  name="vendorType"
                  value={vendorType}
                  options={HostelGeneralType}
                  required
                  onChange={(value) => setVendorType(value)}
                  classNamePrefix="select"
                  // isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}

          {((vendorHead?.value === "F&B" &&
            vendorSubHead?.value === "Material") ||
            (editData?.vendorHead === "F&B" &&
              editData?.vendorSubHead === "Material")) && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="vendorType">
                  Material type*
                </Label>
                <Select
                  isClearable={false}
                  id="vendorType"
                  name="vendorType"
                  value={vendorType}
                  options={F_AND_B_MATERIAL_TYPE}
                  required
                  onChange={(value) => setVendorType(value)}
                  classNamePrefix="select"
                  // isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}

          {((vendorHead?.value === "Ancillary" &&
            vendorSubHead?.value === "Vendor Payout") ||
            (editData?.vendorHead === "Ancillary" &&
              editData?.vendorSubHead === "Vendor Payout")) && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="vendorType">
                  Vendor type*
                </Label>
                <Select
                  isClearable={false}
                  id="vendorType"
                  name="vendorType"
                  value={vendorType}
                  options={AncillaryPayoutVendorType}
                  required
                  onChange={(value) => setVendorType(value)}
                  classNamePrefix="select"
                  // isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}

          {((vendorHead?.value === "Ancillary" &&
            vendorSubHead?.value === "Material") ||
            (editData?.vendorHead === "Ancillary" &&
              editData?.vendorSubHead === "Material")) && (
            <Col sm="12">
              <FormGroup className="w-100">
                {/* <Label className="form-label" for="subHeadType">
            Vendor Type
          </Label> */}
                <Label className="form-label" for="vendorType">
                  Material type*
                </Label>
                <Select
                  isClearable={false}
                  id="vendorType"
                  name="vendorType"
                  value={vendorType}
                  options={AncillaryMaterialVendorType}
                  required
                  onChange={(value) => setVendorType(value)}
                  classNamePrefix="select"
                  // isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}

          {((vendorHead?.value === "CAPEX" &&
            (vendorSubHead?.value === "Hostel" ||
              vendorSubHead?.value === "F&B" ||
              vendorSubHead?.value === "Ancillary")) ||
            (editData?.vendorHead === "CAPEX" &&
              (editData?.vendorSubHead === "Hostel" ||
                editData?.vendorSubHead === "F&B" ||
                editData?.vendorSubHead === "Ancillary"))) && (
            <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="vendorType">
                  Capex type*
                </Label>
                <Select
                  isClearable={false}
                  id="vendorType"
                  name="vendorType"
                  value={vendorType}
                  options={CapexSubFieldsType}
                  required
                  onChange={(value) => setVendorType(value)}
                  classNamePrefix="select"
                  // isDisabled={editData?._id}
                />
              </FormGroup>
            </Col>
          )}

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="email">
                Email
              </Label>
              <Input
                type="email"
                value={email}
                id="email"
                name="email"
                placeholder="Add email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="addressDetails">
                Address
              </Label>
              <Input
                type="textarea"
                value={addressDetails}
                id="addressDetails"
                name="addressDetails"
                placeholder="Add address"
                onChange={(e) => setAddressDetails(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/* <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="services">
                Services*
              </Label>
              <Input
                type="text"
                value={services}
                id="services"
                name="services"
                placeholder="Add services"
                onChange={(e) => setServices(e.target.value)}
              />
            </FormGroup>
          </Col> */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="vendorGSTNumber">
                GST number
              </Label>
              <Input
                type="text"
                value={vendorGSTNumber}
                id="vendorGSTNumber"
                name="vendorGSTNumber"
                placeholder="Add GST number"
                onChange={(e) => setVendorGSTNumber(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="vendorBankAccNumber">
                Bank account number*
              </Label>
              <Input
                type="text"
                value={vendorBankAccNumber}
                id="vendorBankAccNumber"
                name="vendorBankAccNumber"
                placeholder="Add bank account number"
                onChange={(e) => setVendorBankAccNumber(e.target.value)}
                // onChange={(e) => {
                //   if (!isNaN(e.target.value) && e.target.value[0] != " ") {
                //     setVendorBankAccNumber(e.target.value);
                //   }
                // }}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="vendorIFSC">
                Bank IFSC*
              </Label>
              <Input
                type="text"
                value={vendorIFSC}
                id="vendorIFSC"
                name="vendorIFSC"
                placeholder="Add bank IFSC code"
                // onChange={(e) => setVendorIFSC(e.target.value)}
                onChange={(e) =>
                  e.target.value[0] !== " "
                    ? setVendorIFSC(e.target.value)
                    : toaster("error", "initial space not allowed")
                }
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="accountType">
                Account type
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="accountType"
                name="accountType"
                options={accountTypeList}
                value={accountType}
                onChange={(value) => setAccountType(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="panNo">
                PAN number
              </Label>
              <Input
                type="text"
                value={panNo}
                id="panNo"
                name="panNo"
                placeholder="Add PAN number"
                onChange={(e) => setPanNo(e.target.value)}
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="paymentCycle">
                Payment cycle
              </Label>
              <Select
                isClearable={false}
                isSearchable={true}
                id="paymentCycle"
                name="paymentCycle"
                options={paymentCycleList}
                value={paymentCycle}
                onChange={(value) => setPaymentCycle(value)}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <Label className="form-label" for="bankDetailImage">
              Upload bank detail image
            </Label>
            <AttachmentComponent
              imageList={bankDetailImage}
              setImageList={setBankDetailImage}
              editData={dummyStateBankDetail}
              uploadType={uploadTypeBankDetail}
            />
          </Col>
          <Col sm="12">
            <Label className="form-label" for="agreement">
              Upload agreement
            </Label>
            <AttachmentComponent
              imageList={agreement}
              setImageList={setAgreement}
              editData={dummyStateAgreement}
              uploadType={uploadTypeAgreement}
            />
          </Col>
          <Col sm="12">
            <FormGroup>
              <Label className="form-label" for="agreementStartDate">
                Agreement start date
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={agreementStartDate}
                onChange={(date) => setAgreementStartDate(date)}
                id="paymentDate"
                placeholder="Agreement start date"
                required
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup>
              <Label className="form-label" for="agreementEndDate">
                Agreement end date
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={agreementEndDate}
                onChange={(date) => setAgreementEndDate(date)}
                id="agreementEndDate"
                placeholder="Agreement end date"
                required
              />
            </FormGroup>
          </Col>

          <Col sm="12">
            <SwitchIcon
              style={{ position: "absolute", marginLeft: "10px", top: "10px" }}
              checked={vendorStatus}
              id="vendorStatusSwitch"
              name="vendorStatusSwitch"
              label="Status ?"
              handleChecked={(value) => setVendorStatus(value)}
            />
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="logs">
                <strong>Logs:</strong>
              </Label>
              <br />

              <ul>
                {vendorLogs?.map((i, index) => {
                  return (
                    <Fragment key={index}>
                      <li>{i}</li>
                    </Fragment>
                  );
                })}
              </ul>
            </FormGroup>
          </Col>
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
