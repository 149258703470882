import React from "react";
import { Button } from "reactstrap";
import { Plus, Check } from "react-feather";

const AddVideoUrlBtn = ({
  loading,
  addAction,
  disabled,
  user,
  dbStatus,
  CXuser,
}) => {
  return (
    <div className="d-flex flex-row-reverse mt-2">
      {/* <Button.Ripple
        color={disabled ? "secondary" : "light"}
        disabled={disabled}
        className='mr-2'
        type='submit'
      >
        <Check size={15} className='mr-1' />
        Save Changes
      </Button.Ripple> */}

      {/* {addAction && (
        <Button.Ripple
          type="button"
          className="btn-icon rounded mr-1"
          color="light"
          size="md"
          onClick={addAction}
          disabled={userRole}
        >
          <Plus size={14} className="mr-1" />
          Add Video URL
        </Button.Ripple>
      )} */}

      {addAction && (
        <Button.Ripple
          type="button"
          className="btn-icon rounded mr-1"
          color="light"
          size="md"
          onClick={addAction}
          disabled={
            dbStatus !== undefined &&
            !(
              (dbStatus === "Pending" && user)
              // || (dbStatus === "Approved" && CXuser)
            )
          }
        >
          <Plus size={14} /> Add Video URL
        </Button.Ripple>
      )}
    </div>
  );
};

export default AddVideoUrlBtn;
