import React, { useState } from "react";
import { useGetHostelReviewSummary } from "../../api/dataSource";
import { Table, Button, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { CSVLink } from "react-csv";

const HostelReviewSummary = () => {
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [isSearched, setIsSearched] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { loading, error, data, refetch } = useGetHostelReviewSummary(
    isSearched ? month : null,
    isSearched ? year : null
  );

  const handleSearch = () => {
    if (month && year) {
      setIsSearched(true);
      refetch({ month, year });
    } else {
      alert("Please select both month and year.");
    }
  };

  const handleReset = () => {
    setMonth(null);
    setYear(null);
    setIsSearched(false);
    setSearchTerm("");
  };

  const filteredData = data?.getHostelReviewSummary.filter((summary) =>
    summary.hostelName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const csvData = filteredData
    ? filteredData.map(
        ({
          hostelName,
          totalReviews,
          positiveReviews,
          negativeReviews,
          neutralReviews,
          ormScore,
          npsScore,
        }) => ({
          hostelName,
          totalReviews,
          positiveReviews,
          negativeReviews,
          neutralReviews,
          ormScore,
          npsScore,
        })
      )
    : [];

  return (
    <div style={{ position: "relative", marginTop: "10px" }}>
      <h2>Hostelwise Performance</h2>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="month">Month</Label>
            <Input
              type="select"
              name="month"
              id="month"
              value={month || ""}
              onChange={(e) => setMonth(parseInt(e.target.value))}
            >
              <option value="">Select Month</option>
              {Array.from({ length: 12 }, (_, index) => (
                <option key={index + 1} value={index + 1}>
                  {new Date(0, index).toLocaleString("default", {
                    month: "long",
                  })}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="year">Year</Label>
            <Input
              type="select"
              name="year"
              id="year"
              value={year || ""}
              onChange={(e) => setYear(parseInt(e.target.value))}
            >
              <option value="">Select Year</option>
              {Array.from({ length: 10 }, (_, index) => {
                const currentYear = new Date().getFullYear();
                return (
                  <option key={currentYear - index} value={currentYear - index}>
                    {currentYear - index}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label for="search">Search Hostel</Label>
            <Input
              type="text"
              id="search"
              value={searchTerm}
              placeholder="Type hostel name..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="d-flex justify-content-end">
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="white"
            type="button"
            onClick={handleSearch}
          >
            Search
          </Button>
          <Button
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginLeft: "10px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            type="button"
            onClick={handleReset}
          >
            Reset
          </Button>
          <CSVLink
            data={csvData}
            filename={"ORM_Hostel_Level_Performance.csv"}
            style={{ marginLeft: "10px", textDecoration: "none" }}
            target="_blank"
          >
            <Button
              style={{
                backgroundColor: "#28a745",
                height: "40px",
                borderRadius: "5px",
                padding: "10px 40px",
                outline: "none",
                border: "0",
              }}
              color="success"
              type="button"
            >
              Export
            </Button>
          </CSVLink>
        </Col>
      </Row>

      <div
        style={{
          position: "absolute",
          top: "0px",
          right: "5px",
          fontSize: "x-small",
        }}
      >
        <p style={{ fontWeight: "bold", margin: 0 }}>
          ORM Score = [(Positive Reviews - (Negative Reviews / 3)) / Total
          Reviews] * 100
        </p>
        <p style={{ fontWeight: "bold", margin: 0 }}>
          NPS = [(Positive Reviews / Total Reviews) * 100 - (Negative Reviews /
          Total Reviews) * 100]
        </p>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {filteredData && filteredData.length > 0 && (
        <Table striped style={{ marginTop: "20px" }}>
          <thead>
            <tr>
              <th>Hostel Name</th>
              <th>Total Reviews</th>
              <th>Positive Reviews</th>
              <th>Negative Reviews</th>
              <th>Neutral Reviews</th>
              <th>ORM Score</th>
              <th>NPS Score</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((summary, index) => (
              <tr key={index}>
                <td>{summary.hostelName}</td>
                <td>{summary.totalReviews}</td>
                <td>{summary.positiveReviews}</td>
                <td>{summary.negativeReviews}</td>
                <td>{summary.neutralReviews}</td>
                <td>{summary.ormScore}</td>
                <td>{summary.npsScore}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default HostelReviewSummary;
