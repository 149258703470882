import { useEffect, useState } from "react";
import { Edit } from "react-feather";
import {
  Col,
  Card,
  Row,
  CardHeader,
  CardTitle,
  Button,
  Badge,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { dateFormatHandler, dateFormatter } from "@utils";
import { CSVLink } from "react-csv";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Res No",
  "Status",
  "Booking Date/Time",
  "Coupon",
  "Account Name",
  "Guest Name",
  "Mobile",
  "Hostel Name",
  "Checkin - Checkout",
  "Razorpay",
  "Wallet",
  "Refund Type/Id/Amount",
  "Booking Source",
  // "UTM Source",
  // "UTM Medium",
  // "UTM Campaign",
  "UTM",
  "Blacklisted",
];

const options = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  // second: "numeric",
  hour12: true,
  timeZone: "Asia/Kolkata",
};

const WebsiteBookingTable = ({ openModal, setId, data, refetch, count }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    if (data?.length > 0) {
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [data]);

  return (
    <Row>
      <Col>
        <Card>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <CardHeader>
              <CardTitle>
                <div>
                  <h2> Website Booking [Count: {count}]</h2>
                </div>
              </CardTitle>
            </CardHeader>
          </div>
          <Table responsive size="sm" hover bordered>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <tr
                  key={uuidv4()}
                  style={{
                    backgroundColor: item?.isBlackListed ? "black" : "",
                    color: item?.isBlackListed ? "#ffe700" : "",
                  }}
                >
                  <td>
                    <Button
                      className="rounded-circle btn-icon"
                      color="none"
                      onClick={(e) => {
                        e.preventDefault();
                        setId(item._id);
                        openModal();
                      }}
                    >
                      <Edit
                        color={reactFeatherIcon.iconColor}
                        size={reactFeatherIcon.iconSize}
                      />
                    </Button>
                  </td>
                  <td>
                    <Badge color="light-info">{item?.providerRefId}</Badge>
                  </td>
                  <td>
                    <Badge
                      color={
                        item?.status === "confirm"
                          ? "light-success"
                          : item?.status === "pending"
                          ? "light-info"
                          : item?.status === "failure"
                          ? "light-danger"
                          : item?.status === "cancel"
                          ? "light-danger"
                          : "light-warning"
                      }
                    >
                      {item?.status}
                    </Badge>
                  </td>
                  <td>
                    {new Intl.DateTimeFormat("en-IN", options).format(
                      new Date(item?.createdAt)
                    )}
                  </td>
                  <td>
                    <Badge className="text-dark" color="light-secondary">
                      {item?.coupon?.promoCode}
                    </Badge>
                  </td>
                  <td>{item?.userId?.fullName}</td>
                  <td>{item?.contactDetails?.name}</td>
                  <td>{item?.contactDetails?.mobile}</td>
                  <td>{item?.hostelId?.name}</td>
                  <td>
                    {item?.checkinDate
                      ? dateFormatHandler(item?.checkinDate)
                      : ""}
                    -
                    {item?.checkinDate
                      ? dateFormatHandler(item?.checkoutDate)
                      : ""}
                  </td>
                  <td>{item?.payableAmount}</td>
                  <td>{item?.walletDebit?.toFixed(2)}</td>
                  <td>
                    <Badge
                      className="text-dark"
                      color={item?.refundType === "Wallet" ? "primary" : "info"}
                    >
                      {item?.refundType}
                    </Badge>
                    {item?.refundType ? " / " : ""}
                    {item?.providerRefundRefId}
                    <br />
                    {item?.refundType && "Razorpay: "}
                    {item?.razorpayRefund?.toFixed(2)}
                    <br />
                    {item?.refundType && "Wallet: "}
                    {item?.walletRefund?.toFixed(2)}
                  </td>
                  <td>{item?.bookingSource}</td>
                  {/* <td>{item?.utm_source}</td>
                  <td>{item?.utm_medium}</td>
                  <td>{item?.utm_campaign}</td> */}
                  <td>
                    {item?.utm_source && `Source: ${item.utm_source}`}
                    {item?.utm_medium && (
                      <>
                        <br />
                        {`Medium: ${item?.utm_medium}`}
                      </>
                    )}

                    {item?.utm_campaign && (
                      <>
                        <br />
                        {`Campaign: ${item?.utm_campaign}`}
                      </>
                    )}
                  </td>
                  <td>{item?.isBlackListed ? "Yes" : "No"}</td>

                  {/* <td>{item?.refundStatus}</td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default WebsiteBookingTable;
