import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";

export const GET_CAFE_MENU_BY_CAFE_ID = gql`
  query GetCafewiseMenuByCafeId($cafeId: ID) {
    getCafewiseMenuByCafeId(cafeId: $cafeId) {
      _id
      itemName
      itemCategory
      startTime
      endTime
      startTime2
      endTime2
      startTime3
      endTime3
      active
      soldOut
      tat
      seasonCharge
      soldOutHrs
      soldOutStartTime
      soldOutEndTime
      soldOutLogs
    }
  }
`;

export const useGetCafeMenuByCafeId = (cafeId) => {
  const { loading, error, data, refetch } = useQuery(GET_CAFE_MENU_BY_CAFE_ID, {
    variables: {
      cafeId: cafeId,
    },
    skip: !cafeId,
  });

  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_CAFE_MENU_BY_CAFE_ID_ACTIVE = gql`
  query getCafeMenuByCafeIdStatusActive($cafeId: ID) {
    getCafeMenuByCafeIdStatusActive(cafeId: $cafeId) {
      _id

      cafe
      hostel
      item {
        _id
        itemName
        itemCategory
        weekDayPrice
        weekEndPrice
        options {
          name
          weekDayPrice
          weekEndPrice
        }
        extras {
          name
          weekDayPrice
          weekEndPrice
        }
      }
      startTime
      endTime
      tat
      seasonCharge
      soldOut
      active
    }
  }
`;

export const useGetCafeMenuByCafeIdActive = (cafeId) => {
  const { loading, error, data, refetch } = useQuery(
    GET_CAFE_MENU_BY_CAFE_ID_ACTIVE,
    {
      variables: {
        cafeId: cafeId,
      },
      skip: !cafeId,
    }
  );

  useLoadingHandler(loading);

  return { data, error, refetch };
};

export const GET_ACTIVE_CAFE_MENU_FOR_SOLDOUT_ITEM = gql`
  query getCafewiseActiveMenuForSoldout($cafeId: ID) {
    getCafewiseActiveMenuForSoldout(cafeId: $cafeId) {
      _id
      itemName
      itemCategory
      startTime
      endTime
      startTime2
      endTime2
      active
      soldOut
      tat
      seasonCharge
      soldOutHrs
      soldOutStartTime
      soldOutEndTime
      soldOutLogs
    }
  }
`;

export const useGetCafeMenuActiveForSoldout = (cafeId) => {
  const { loading, error, data, refetch } = useQuery(
    GET_ACTIVE_CAFE_MENU_FOR_SOLDOUT_ITEM,
    {
      variables: {
        cafeId: cafeId,
      },
      skip: !cafeId,
    }
  );

  useLoadingHandler(loading);

  return { data, error, refetch };
};


export const GET_CAFEWISE_MENU_ACTIVE_FOR_ITEMWISE = gql`
 query getCafewiseMenuActiveForItemwise($cafe:[ID]){
  getCafewiseMenuActiveForItemwise(cafe:$cafe){
    _id
    result{
      _id
      itemName
    }
  }
 }
`

export const useGetCafewiseMenuActiveForItemwise = (cafe) => {
  const { loading, error, data, refetch } = useQuery(GET_CAFEWISE_MENU_ACTIVE_FOR_ITEMWISE, {
    variables: {
      cafe
    }
  })

  useLoadingHandler(loading)


  return { data, error, refetch }
}




export const GET_CAFEWISE_MENU_FOR_SOLDOUT_REPORT = gql`
  query getCafewiseMenuForSoldoutReport{
    getCafewiseMenuForSoldoutReport{
      cafe
      data
    }
  }
`

export const useGetAllCafewiseMenuForSoldOut = () => {
  const { loading, data, error, refetch } = useQuery(GET_CAFEWISE_MENU_FOR_SOLDOUT_REPORT)
  useLoadingHandler(loading)

  return { data, refetch, error }
}