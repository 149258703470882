import React, { useEffect, useState } from "react";
import { Col, Row, Badge, Button, Input, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import {
  useUpdateFinalRemark,
  useUpdateFeedbackTicket,
  useUpdateStatusRemark,
} from "@api";
import toaster from "@components/common/Toaster";
import SlideModal from "@components/common/slide-modal";
import { dateFormatHandler } from "@utils";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const AddEditModal = ({ open, handleToggle, refetch, editData }) => {
  const [id, setId] = useState(null);
  const [bookingSource, setBookingSource] = useState("");
  const [bookingType, setBookingType] = useState("");
  const [checkinDate, setCheckinDate] = useState("");
  const [checkoutDate, setCheckoutDate] = useState("");
  const [customerRemark, setCustomerRemark] = useState("");
  const [customerFeedback, setCustomerFeedback] = useState("");
  const [propertyRemark, setPropertyRemark] = useState("");
  const [feedbackStatus, setfeedbackStatus] = useState();
  const [email, setEmail] = useState("");
  const [feedbackTime, setFeedbackTime] = useState("");
  const [salutation, setSalutation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [hostelName, setHostelName] = useState("");
  const [mobile, setMobile] = useState("");
  const [reservationId, setReservationId] = useState("");
  const [responseTime, setResponseTime] = useState("");
  const [finalRemark, setFinalRemark] = useState("");
  const [finalStatus, setFinalStatus] = useState(null);
  const [updatedAt, setUpdatedAt] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  const [ticketEmailSent, setTicketEmailSent] = useState(false);
  const [room, setRoom] = useState("");
  const [roomName, setRoomName] = useState("");

  const role = localStorage.getItem("role");

  //Export button is not disabled for anyone
  // if (
  //   role === "Manager" ||
  //   role === "Area Manager" ||
  //   role === "Ground Staff"
  // ) {
  //   const editableElements = document.querySelectorAll(
  //     "button:not(.export-button), textarea, .create-ticket-button"
  //   );
  //   editableElements.forEach((element) => {
  //     element.setAttribute("disabled", true);
  //   });
  // }

  const [updateFinalRemarkHandler, { data: updatedFinalRemarkData }] =
    useUpdateFinalRemark();

  const [updateFeedbackTicketHandler, { data: updatedFeedbackTicketData }] =
    useUpdateFeedbackTicket();

  const [updateStatusRemarkHandler] = useUpdateStatusRemark();

  const getTime = (dateParams) => {
    var created_date = new Date(dateParams);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var year = created_date.getFullYear();
    var month = months[created_date.getMonth()];
    var date = created_date.getDate();
    var hour = created_date.getHours();
    var min = created_date.getMinutes();
    var sec = created_date.getSeconds();
    var curtime =
      month + " " + date + " " + year + ", " + hour + ":" + min + ":" + sec;
    return curtime;
  };

  const finalStatusList = [{ label: "Closed", value: "Closed" }];
  const feedbackStatusList = [
    { label: "Resolved", value: "Resolved" },
    { label: "Not Resolved", value: "Not Resolved" },
    { label: "Cannot connect with guest", value: "Cannot connect with guest" },
  ];

  useEffect(() => {
    if (updatedFinalRemarkData) {
      refetch();
      handleToggle();
    }
  }, [updatedFinalRemarkData]);

  useEffect(() => {
    if (updatedFeedbackTicketData) {
      setTicketEmailSent(true);
    }
  }, [updatedFeedbackTicketData]);

  useEffect(() => {
    if (editData) {
      refetch();
    }
  }, [editData]);

  useEffect(() => {
    if (editData) {
      setId(editData?._id || null);
      setBookingSource(editData.bookingSource || "");
      setBookingType(editData.bookingType || "");
      setCheckinDate(editData.checkinDate || null);
      setCheckoutDate(editData.checkoutDate || null);
      // setfeedbackStatus(editData.feedbackStatus);
      setfeedbackStatus({
        label: editData.feedbackStatus,
        value: editData.feedbackStatus,
      });
      setCustomerRemark(editData.customerRemark || "");
      setCustomerFeedback(editData.customerFeedback || "");
      setPropertyRemark(editData.propertyRemark || "");
      setEmail(editData.email || "");
      setFeedbackTime(editData.feedbackTime || null);
      setFirstName(editData.firstName || "");
      setHostelName(editData.hostelName || "");
      setLastName(editData.lastName || "");
      setMobile(editData.mobile || "");
      setReservationId(editData.reservationId || "");
      setResponseTime(editData.responseTime || null);
      setSalutation(editData.salutation || "");
      setFinalRemark(editData.finalRemark || "");
      setFinalStatus({
        label: editData.finalStatus,
        value: editData.finalStatus,
      });
      setUpdatedAt(editData.updatedAt || null);
      setTicketEmailSent(editData.ticketEmailSent || false);
      setUpdatedBy(editData.updatedBy || "");
      setRoom(editData.room || "");
      setRoomName(editData.roomName || "");
    }
  }, [editData]);

  // const propertySubmitHandler = () => {
  //   const statusRemarkDetails = {
  //     feedbackStatus: feedbackStatus?.value,
  //     propertyRemark: propertyRemark,
  //   };

  //   if (editData) {
  //     const _id = id;
  //     updateStatusRemarkHandler(_id, statusRemarkDetails);
  //     toaster("success", "Data Updated successfully");
  //     refetch();
  //   } else {
  //     toaster("error", "Please fill all required details");
  //   }
  // };

  const propertySubmitHandler = () => {
    const statusRemarkDetails = {
      feedbackStatus: feedbackStatus?.value,
      propertyRemark: propertyRemark,
    };

    if (!propertyRemark) {
      toaster("error", "Property Remark is required");
      return;
    }

    if (editData) {
      const _id = id;
      updateStatusRemarkHandler(_id, statusRemarkDetails);
      toaster("success", "Data Updated successfully");
      refetch();
    } else {
      toaster("error", "Please fill all required details");
    }
  };

  const cxSubmitHandler = () => {
    const data = {
      finalStatus: finalStatus?.value,
      finalRemark: finalRemark,
    };

    if (reservationId.length) {
      if (editData) {
        const _id = id;
        updateFinalRemarkHandler(_id, data);
      }
      toaster("success", "Status Updated successfully");
      refetch();
    } else {
      toaster("error", "Please fill all required details");
    }
  };

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      // submitDataHandler={() => onSubmitHandler()}
      submitDataHandler={handleToggle}
    >
      <form onSubmit={() => console.log()}>
        <Row>
          {/*// ! Hostel Name*/}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="feedbackTime">
                <h4>
                  <strong>{hostelName}</strong>
                </h4>
              </Label>
              <hr />
            </FormGroup>
          </Col>

          {/*// ! Guest Name & Reservation Id*/}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="name">
                <strong>Name</strong>
              </Label>
              <br />
              <Label className="form-label font-weight-bold" for="name">
                {salutation} {firstName}
              </Label>
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="name">
                <strong>Reservation ID</strong>
              </Label>
              <br />
              <Label className="form-label font-weight-bold" for="name">
                {reservationId}
              </Label>
            </FormGroup>
          </Col>

          {/*// ! Mobile Number & Email */}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="mobile">
                <strong> Mobile Number</strong>
              </Label>
              <br />
              <Label className="form-label font-weight-bold" for="mobile">
                {mobile}
              </Label>
            </FormGroup>
          </Col>

          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="mobile">
                <strong> Email</strong>
              </Label>
              <br />
              <Label className="form-label font-weight-bold" for="email">
                {email}
              </Label>
            </FormGroup>
          </Col>

          {/*// ! Checkin Date - Checkout Date*/}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="checkinDate">
                <strong>Check-in & Check-out Date</strong>
              </Label>
              <br />
              <Label className="form-label font-weight-bold" for="checkinDate">
                {dateFormatHandler(checkinDate)}
              </Label>{" "}
              &{" "}
              <Label className="form-label font-weight-bold" for="checkoutDate">
                {dateFormatHandler(checkoutDate)}
              </Label>
            </FormGroup>
          </Col>

          {/*// ! Booking source */}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="bookingSource">
                <strong>Booking Source</strong>
              </Label>
              <br />
              <Label
                className="form-label font-weight-bold"
                for="bookingSource"
              >
                {bookingSource}
              </Label>
            </FormGroup>
          </Col>

          {/*// !  Room Type */}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="room">
                <strong>Room</strong>
              </Label>
              <br />
              <Label className="form-label font-weight-bold" for="roomType">
                {room}
              </Label>
            </FormGroup>
          </Col>

          {/*// !  roomName */}
          <Col sm="6">
            <FormGroup className="w-100">
              <Label className="form-label" for="room">
                <strong>Room Type</strong>
              </Label>
              <br />
              <Label className="form-label font-weight-bold" for="room">
                {roomName}
              </Label>
            </FormGroup>
          </Col>

          {/*// ! Customer Feedback & Remark*/}
          {/* {role !== "Manager" && role !== "Area Manager" && ( */}
          <Col sm="12">
            <hr />
            <FormGroup className="w-100">
              <Label className="form-label" for="customerRemark">
                <h6>
                  <strong>Guest feedback details</strong>
                </h6>
              </Label>
              <br />
              <Label
                className="form-label font-weight-bold"
                for="customerFeedback"
              >
                <td>
                  <strong>Feedback: </strong>
                  <Badge
                    pills
                    Component
                    color={
                      customerFeedback === "Excellent"
                        ? "success"
                        : customerFeedback === "Good"
                        ? "primary"
                        : customerFeedback === "Bad"
                        ? "danger"
                        : ""
                    }
                    className="mr-1"
                  >
                    {customerFeedback ? customerFeedback : ""}
                  </Badge>

                  <Label>
                    {customerFeedback === "Good" ||
                    customerFeedback === "Excellent" ? (
                      <Label>
                        {!["Manager", "Area Manager", "Ground Staff"].includes(
                          role
                        ) && (
                          <Button.Ripple
                            color="warning"
                            size="sm"
                            onClick={() => {
                              updateFeedbackTicketHandler(reservationId);
                            }}
                            disabled={ticketEmailSent}
                          >
                            {ticketEmailSent
                              ? "Ticket created"
                              : "Create Ticket"}
                          </Button.Ripple>
                        )}
                      </Label>
                    ) : null}
                  </Label>
                </td>
              </Label>
              <br />
              <Label
                className="form-label font-weight-bold"
                for="customerFeedback"
              >
                <strong>Remark: </strong> {customerRemark}
              </Label>
              <br />
              <Label className="form-label font-weight-bold" for="feedbackTime">
                <strong>Time: </strong>{" "}
                {feedbackTime ? getTime(feedbackTime) : ""}
              </Label>
            </FormGroup>
          </Col>
          {/* )} */}

          {/*// ! Status & Property Remark*/}
          {/* <Col sm="12">
            <hr />
            <FormGroup className="w-100">
              <Label className="form-label" for="feedbackStatus">
                <h6>
                  <strong>Status & Property Remark</strong>
                </h6>
              </Label>
              <br />
              <Label
                className="form-label font-weight-bold"
                for="customerFeedback"
              >
                <td>
                  <strong>Status: </strong>
                  <Badge
                    pills
                    Component
                    color={
                      feedbackStatus === "Closed"
                        ? "light-info"
                        : feedbackStatus === "Resolved"
                        ? "light-success"
                        : feedbackStatus === "Not Resolved"
                        ? "light-danger"
                        : ""
                    }
                    className="mr-1"
                  >
                    {feedbackStatus ? feedbackStatus : ""}
                  </Badge>
                </td>
              </Label>
              <br />
              <Label
                className="form-label font-weight-bold"
                for="feedbackStatus"
              ></Label>
              <Label
                className="form-label font-weight-bold"
                for="propertyRemark"
              >
                <strong>Remark: </strong>
                {propertyRemark}
              </Label>
              <br />
              <Label className="form-label font-weight-bold" for="responseTime">
                <strong>Time: </strong>
                {responseTime ? getTime(responseTime) : ""}
              </Label>
            </FormGroup>
          </Col> */}

          <Col sm="12">
            <hr></hr>
            <FormGroup className="w-100">
              <Label className="form-label" for="feedbackStatus">
                <h6>
                  <strong>Hostel Feedback Details</strong>
                </h6>
              </Label>
              <br />
              <Select
                required
                isClearable={false}
                id="feedbackStatus"
                name="feedbackStatus"
                value={feedbackStatus}
                options={feedbackStatusList}
                isDisabled={editData?.feedbackStatus !== ""}
                onChange={(value) => setfeedbackStatus(value)}
                classNamePrefix="select font-weight-bold"
              />

              <br />
              <FormGroup className="w-100">
                {/* <Label
                  className="form-label font-weight-bold"
                  htmlFor="propertyRemark"
                >
                  Justification
                </Label> */}
                <Label className="form-label" for="followupFeedback">
                  <strong>
                    {feedbackStatus && feedbackStatus.value === "Resolved"
                      ? "How did you resolve"
                      : feedbackStatus &&
                        feedbackStatus.value === "Not Resolved"
                      ? "Why can there be no resolution?"
                      : "Followup Feedback"}
                  </strong>
                </Label>
                <Input
                  required
                  type="textarea"
                  value={propertyRemark}
                  id="propertyRemark"
                  name="propertyRemark"
                  placeholder="Remark..."
                  disabled={editData?.propertyRemark}
                  onChange={(e) => setPropertyRemark(e.target.value)}
                />
              </FormGroup>
              <br />
              <Label className="form-label font-weight-bold" for="responseTime">
                <strong>Hostel Response Time: </strong>
                {responseTime ? getTime(responseTime) : ""}
              </Label>
            </FormGroup>
          </Col>

          {(role === "Customer Experience" ||
            role === "Super Admin" ||
            role === "Ground Staff" ||
            role === "Area Manager") && (
            <Button
              color={"success"}
              onClick={propertySubmitHandler}
              disabled={editData?.feedbackStatus && editData?.propertyRemark}
              className="ml-auto mr-2 cursor-pointer"
              style={{
                fontSize: "12px",
                padding: "10px 16px",
              }}
            >
              Submit hostel details
            </Button>
          )}

          {/*// Final Status & Remark*/}
          <Col sm="12">
            <hr></hr>
            <FormGroup className="w-100">
              <Label className="form-label" for="finalRemark">
                <h6>
                  <strong>CX Details</strong>
                </h6>
              </Label>
              <br />
              <Select
                required
                isClearable={false}
                id="finalStatus"
                name="finalStatus"
                value={finalStatus}
                options={finalStatusList}
                disabled={editData?.finalStatus?.length}
                onChange={(value) => setFinalStatus(value)}
                classNamePrefix="select font-weight-bold"
              />

              <br />
              <FormGroup className="w-100">
                <Label
                  className="form-label font-weight-bold"
                  htmlFor="finalRemark"
                >
                  Summary
                </Label>
                <Input
                  required
                  type="textarea"
                  value={finalRemark}
                  id="finalRemark"
                  name="finalRemark"
                  placeholder="Final Remark"
                  disabled={editData?.finalRemark?.length}
                  onChange={(e) => setFinalRemark(e.target.value)}
                />
              </FormGroup>
              <br />
              <Label className="form-label font-weight-bold" for="updatedAt">
                <strong>Closure Time: </strong>{" "}
                {updatedAt ? getTime(updatedAt) : ""}
              </Label>
              <br />
              <Label className="form-label font-weight-bold" for="updatedAt">
                <strong>Closed by: </strong>
                {updatedBy ? updatedBy : ""}
              </Label>
            </FormGroup>
          </Col>
          {(role === "Customer Experience" || role === "Super Admin") && (
            <Button
              color={"success"}
              onClick={cxSubmitHandler}
              className="ml-auto mr-2 cursor-pointer"
              style={{
                fontSize: "12px",
                padding: "10px 16px",
              }}
            >
              Submit CX Details
            </Button>
          )}
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
