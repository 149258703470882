import React, { useEffect, useState } from "react";
import { Col, Card, Row, CardHeader, CardTitle, Table } from "reactstrap";
import { v4 as uuidv4 } from "uuid";

const tableHeaderHostelData = [
  "Hostel Name",
  "Source",
  "Room Sold",
  "Occupancy",
  "ADR",
  "Revenue",
  "%Rev",
];
const tableHeaderData = [
  "Source",
  "Room Sold",
  "Occupancy",
  "ADR",
  "Revenue",
  "%Rev",
];

const CarOTAwiseReportTable = ({ data, isHostel }) => {
  const [tableData, setTableData] = useState(data);

  useEffect(() => {
    setTableData(data?.length > 0 ? data : []);
  }, [data]);

  const calculateTotals = (data) => {
    const totalRoomSold = data?.reduce(
      (acc, curr) => acc + (curr?.RoomSold || 0),
      0
    );
    const totalOccupancy = data?.reduce(
      (acc, curr) => acc + (curr?.occupancy || 0),
      0
    );
    const totalRevenue = data?.reduce(
      (acc, curr) => acc + (curr?.Revenue || 0),
      0
    );
    const totalPercentRev = data?.reduce(
      (acc, curr) => acc + (Number(curr?.percentRev) || 0),
      0
    );

    return {
      totalRoomSold,
      totalOccupancy: totalOccupancy.toFixed(2),
      totalADR: (totalRoomSold > 0 ? totalRevenue / totalRoomSold : 0).toFixed(
        2
      ),
      totalRevenue: totalRevenue.toFixed(2),
      totalPercentRev: totalPercentRev.toFixed(2),
    };
  };

  const renderTotalRow = (label, totals) => (
    <tr>
      <td>
        <strong>{label}</strong>
      </td>
      <td>
        <strong>{totals?.totalRoomSold}</strong>
      </td>
      <td>
        <strong>
          {Number(totals?.totalOccupancy) === 99.99 ||
          Number(totals?.totalOccupancy) === 100.01
            ? 100.0?.toFixed(2)
            : Number(totals?.totalOccupancy)?.toFixed(2)}
        </strong>
      </td>
      <td>
        <strong>{Number(totals?.totalADR)?.toFixed(2)}</strong>
      </td>
      <td>
        <strong>{Number(totals?.totalRevenue)?.toFixed(2)}</strong>
      </td>
      <td>
        <strong>
          {Number(totals?.totalPercentRev)?.toFixed(2) === 99.99 ||
          Number(totals?.totalPercentRev) === 100.01
            ? 100.0?.toFixed(2)
            : totals.totalPercentRev}
        </strong>
      </td>
    </tr>
  );

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <CardTitle>
              <h2>
                {isHostel?.value
                  ? "Contribution Analysis Report OTAwise"
                  : "CAR OTAwise Report"}
              </h2>
            </CardTitle>
          </CardHeader>
          <Table responsive bordered size="sm" className="table-striped">
            <thead>
              <tr>
                {(isHostel?.value
                  ? tableHeaderHostelData
                  : tableHeaderData
                ).map((item, index) => (
                  <th key={index}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item) => (
                <React.Fragment key={uuidv4()}>
                  {isHostel?.value && (
                    <tr>
                      <td rowSpan={item.ota?.length + item.non_ota?.length + 5}>
                        <h5>{item?.hostelName}</h5>
                      </td>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <strong>OTAs</strong>
                      </td>
                    </tr>
                  )}

                  {item?.ota?.map((otaData) => (
                    <tr key={uuidv4()}>
                      <td>{otaData?.Source}</td>
                      <td>{otaData?.RoomSold}</td>
                      <td>{otaData?.occupancy?.toFixed(2)}</td>
                      <td>{otaData?.ADR?.toFixed(2)}</td>
                      <td>{otaData?.Revenue?.toFixed(2)}</td>
                      <td>{Number(otaData?.percentRev)?.toFixed(2)}</td>
                    </tr>
                  ))}

                  {renderTotalRow("Total OTAs", calculateTotals(item?.ota))}

                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      <strong>Non OTAs</strong>
                    </td>
                  </tr>

                  {item?.non_ota?.map((nonOtaData) => (
                    <tr key={uuidv4()}>
                      <td>{nonOtaData?.Source}</td>
                      <td>{nonOtaData?.RoomSold}</td>
                      <td>{nonOtaData?.occupancy?.toFixed(2)}</td>
                      <td>{nonOtaData?.ADR?.toFixed(2)}</td>
                      <td>{nonOtaData?.Revenue?.toFixed(2)}</td>
                      <td>{Number(nonOtaData?.percentRev)?.toFixed(2)}</td>
                    </tr>
                  ))}

                  {renderTotalRow(
                    "Total Non-OTAs",
                    calculateTotals(item?.non_ota)
                  )}

                  {/* <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      <strong>Overall Total</strong>
                    </td>
                  </tr> */}

                  {renderTotalRow(
                    "Overall (OTAs + Non OTAs)",
                    calculateTotals([...item?.ota, ...item?.non_ota])
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default CarOTAwiseReportTable;

// import React, { useEffect, useState } from "react";
// import { Col, Card, Row, CardHeader, CardTitle, Table } from "reactstrap";
// import { v4 as uuidv4 } from "uuid";

// const tableHeaderHostelData = [
//   "Hostel Name",
//   "Source",
//   "Room Sold",
//   "Occupancy",
//   "ADR",
//   "Revenue",
//   "%Rev",
// ];
// const tableHeaderData = [
//   "Source",
//   "Room Sold",
//   "Occupancy",
//   "ADR",
//   "Revenue",
//   "%Rev",
// ];

// const CarOTAwiseReportTable = ({
//   openModal,
//   setId,
//   data,
//   refetch,
//   isHostel,
// }) => {
//   const [tableData, setTableData] = useState(data);

//   useEffect(() => {
//     setTableData(data?.length > 0 ? data : []);
//   }, [data]);

//   const handleTotal = (label, data) => {
//     const totalRoomSold = data?.reduce(
//       (accumulator, currentValue) =>
//         accumulator + (currentValue?.RoomSold || 0),
//       0
//     );

//     const totalOccupancy = data?.reduce(
//       (accumulator, currentValue) =>
//         accumulator + (currentValue?.occupancy || 0),
//       0
//     );

//     const totalADR = data?.reduce(
//       (accumulator, currentValue) => accumulator + (currentValue?.ADR || 0),
//       0
//     );

//     const totalRevenue = data?.reduce(
//       (accumulator, currentValue) => accumulator + (currentValue?.Revenue || 0),
//       0
//     );

//     const totalPercentReview = data?.reduce(
//       (accumulator, currentValue) =>
//         accumulator + (Number(currentValue?.percentRev) || 0),
//       0
//     );
//     return (
//       <tr>
//         <td>
//           <strong> {label}</strong>
//         </td>
//         <td>
//           <strong> {totalRoomSold}</strong>
//         </td>
//         <td>
//           <strong> {totalOccupancy.toFixed(2)}</strong>
//         </td>
//         <td>
//           <strong>
//             {" "}
//             {(totalRoomSold > 0 ? totalRevenue / totalRoomSold : 0).toFixed(2)}
//           </strong>
//         </td>
//         <td>
//           <strong> {totalRevenue.toFixed(2)}</strong>
//         </td>
//         <td>
//           <strong> {Number(totalPercentReview).toFixed(2)}</strong>
//         </td>
//       </tr>
//     );
//   };

//   if (isHostel?.value === true) {
//     return (
//       <Row>
//         <Col>
//           <Card>
//             <CardHeader>
//               <CardTitle>
//                 <div>
//                   <h2>Contribution Analysis Report OTAwise </h2>
//                 </div>
//               </CardTitle>
//             </CardHeader>
//             <Table responsive bordered size="sm">
//               <thead>
//                 <tr>
//                   {tableHeaderHostelData.map((item, index) => (
//                     <th key={index}>{item}</th>
//                   ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {tableData?.map((item) => (
//                   <React.Fragment key={uuidv4()}>
//                     {/* Hostel Name Row */}
//                     <tr>
//                       <td rowSpan={item.ota?.length + item.non_ota?.length + 4}>
//                         <h5>{item?.hostelName}</h5>
//                       </td>
//                       <td colSpan="6" style={{ textAlign: "center" }}>
//                         <strong>OTAs</strong>
//                       </td>
//                     </tr>

//                     {/* OTA and Non-OTA Rows */}
//                     {item?.ota?.map((otaData) => (
//                       <tr key={uuidv4()}>
//                         <td>{otaData?.Source}</td>
//                         <td>{otaData?.RoomSold}</td>
//                         <td>{otaData?.occupancy?.toFixed(2)}</td>
//                         <td>{otaData?.ADR?.toFixed(2)}</td>
//                         <td>{otaData?.Revenue?.toFixed(2)}</td>
//                         <td>{Number(otaData?.percentRev)?.toFixed(2)}</td>
//                       </tr>
//                     ))}
//                     {handleTotal("Total OTA", item?.ota)}

//                     <tr>
//                       <td colSpan="6" style={{ textAlign: "center" }}>
//                         <strong>Non OTAs</strong>
//                       </td>
//                     </tr>

//                     {item?.non_ota?.map((nonOtaData) => (
//                       <tr key={uuidv4()}>
//                         <td>{nonOtaData?.Source}</td>
//                         <td>{nonOtaData?.RoomSold}</td>
//                         <td>{nonOtaData?.occupancy?.toFixed(2)}</td>
//                         <td>{nonOtaData?.ADR?.toFixed(2)}</td>
//                         <td>{nonOtaData?.Revenue?.toFixed(2)}</td>
//                         <td>{Number(nonOtaData?.percentRev)?.toFixed(2)}</td>
//                       </tr>
//                     ))}
//                     {handleTotal("Total Non-OTAs", item?.non_ota)}
//                   </React.Fragment>
//                 ))}
//               </tbody>
//             </Table>
//           </Card>
//         </Col>
//       </Row>
//     );
//   } else {
//     return (
//       <Row>
//         <Col>
//           <Card>
//             <CardHeader>
//               <CardTitle>
//                 <div>
//                   <h2>CAR OTAwise Report</h2>
//                 </div>
//               </CardTitle>
//             </CardHeader>
//             <Table responsive bordered size="sm">
//               <thead>
//                 <tr>
//                   {tableHeaderData.map((item, index) => (
//                     <th key={index}>{item}</th>
//                   ))}
//                 </tr>
//               </thead>
//               <tbody>
//                 {tableData?.map((item) => (
//                   <React.Fragment key={uuidv4()}>
//                     {/* Hostel Name Row */}
//                     <tr>
//                       <td colSpan="6" style={{ textAlign: "center" }}>
//                         <strong>OTAs</strong>
//                       </td>
//                     </tr>

//                     {/* OTA and Non-OTA Rows */}
//                     {item?.ota?.map((otaData) => (
//                       <tr key={uuidv4()}>
//                         <td>{otaData?.Source}</td>
//                         <td>{otaData?.RoomSold}</td>
//                         <td>{otaData?.occupancy?.toFixed(2)}</td>
//                         <td>{otaData?.ADR?.toFixed(2)}</td>
//                         <td>{otaData?.Revenue?.toFixed(2)}</td>
//                         <td>{Number(otaData?.percentRev)?.toFixed(2)}</td>
//                       </tr>
//                     ))}
//                     {handleTotal("Total OTAs", item?.ota)}

//                     <tr>
//                       <td colSpan="6" style={{ textAlign: "center" }}>
//                         <strong>Non OTAs</strong>
//                       </td>
//                     </tr>

//                     {item?.non_ota?.map((nonOtaData) => (
//                       <tr key={uuidv4()}>
//                         <td>{nonOtaData?.Source}</td>
//                         <td>{nonOtaData?.RoomSold}</td>
//                         <td>{nonOtaData?.occupancy?.toFixed(2)}</td>
//                         <td>{nonOtaData?.ADR?.toFixed(2)}</td>
//                         <td>{nonOtaData?.Revenue?.toFixed(2)}</td>
//                         <td>{Number(nonOtaData?.percentRev)?.toFixed(2)}</td>
//                       </tr>
//                     ))}
//                     {handleTotal("Total Non-OTAs", item?.non_ota)}
//                   </React.Fragment>
//                 ))}
//               </tbody>
//             </Table>
//           </Card>
//         </Col>
//       </Row>
//     );
//   }
// };

// export default CarOTAwiseReportTable;
