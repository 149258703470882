import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import TableList from "../common/TableList";
import {
  useGetPaginatedEventsAndActivitiesList,
  useHostelList,
} from "../../../api/dataSource";
import { TABLE_TYPE } from "../common/TableList/constant";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import Select from "react-select";
import { selectThemeColors } from "@utils";

const tableHeaderList = [
  "Action",
  "",
  "Title",
  "Hostel",
  "Category",
  "Price",
  "Frequency",
  "Event Date",
  "Status",
];

const publishedOptions = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];
const EventsAndActivitiesTable = ({ AddEditModal }) => {
  const [limit, setLimit] = useState(20);
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [editId, setEditId] = useState("");
  const [editData, setEditData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [hostelIdArray, setHostelIdArray] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const { data: allHostelsData } = useHostelList();
  const [filterParams, setFilterParams] = useState(null);
  const [category, setCategory] = useState(null);
  const [price, setPrice] = useState(null);
  const [published, setPublished] = useState(null);

  const filter = {
    limit,
    page,
    hostelIdArray:
      filterParams?.hostelIdArray?.length > 0
        ? filterParams.hostelIdArray.map((hostel) => hostel.value)
        : JSON.parse(localStorage.getItem("hostels")),
    category: filterParams?.category ? filterParams?.category.value : null,
    price: filterParams?.price,
    published: filterParams?.published ? filterParams?.published?.value : null,
  };

  const { data, refetch } = useGetPaginatedEventsAndActivitiesList(filter);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const submitHandler = () => {
    setFilterParams({
      hostelIdArray,
      category,
      price,
      published,
    });
    setPage(1);
  };

  const refreshHandler = () => {
    try {
      setHostelIdArray([]);
      setCategory(null);
      setPrice(null);
      setFilterParams(null);
      setPage(1);
      setPublished(null);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const allowedHostel = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostel !== null && allowedHostel?.length !== 0
          ? allowedHostel?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelList);
    }
  }, [allHostelsData?.getHostelList, data?.getHostelList]);

  const priceOptions = [
    { label: "Free", value: 0 },
    { label: "Paid", value: 1 },
  ];

  const history = useHistory();

  const handleAdd = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    if (data) {
      let EventsAndActivitiesList = [];
      data?.getPaginatedEventsAndActivitiesList?.data?.map((item) => {
        EventsAndActivitiesList.push({
          id: item?._id,
          title: item?.title,
          hostel: item?.hostel?.name,
          hostelID: item?.hostel?._id,
          category: item?.category,
          price: item?.price,
          // date: item?.eventDate,
          frequency: item?.schedulingFrequency,
          published: item?.published,
          eventDate: item?.eventDate,
          eventStartDate: item?.eventStartDate,
          eventEndDate: item?.eventEndDate,
        });
      });
      setTableData(EventsAndActivitiesList);
      setTotalPage(
        Math.ceil(data?.getPaginatedEventsAndActivitiesList?.count / limit)
      );
    }
  }, [data]);

  useEffect(() => {
    if (editId && data?.getPaginatedEventsAndActivitiesList?.data?.length) {
      let editData = data?.getPaginatedEventsAndActivitiesList?.data.filter(
        (item) => item._id === editId
      );
      setEditData(editData);
    } else {
      setEditData(null);
    }
  }, [editId, data]);

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      history.push(`/webApp/edit/events&activities/${id}`);
    } else {
      setEditId(id);
    }
  };

  const handleCreateUpdateEventsAndActivities = (id) => {
    history.push(`/webApp/edit/events&activities/${id}`);
    setEditId(id);
  };
  return (
    <div>
      <Row>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="hostel">
              <strong>Hostel Name</strong>
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              isMulti
              id="hostel"
              name="hostel"
              value={hostelIdArray}
              options={HostelsList}
              onChange={(value) => {
                setHostelIdArray(value);
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="product">
              <strong>Category Type</strong>
            </Label>
            <Select
              isClearable={true}
              id="category"
              name="category"
              value={category}
              options={[
                { label: "Event", value: "Event" },
                { label: "Activity", value: "Activity" },
              ]}
              onChange={(value) => {
                setCategory(value);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="product">
              <strong>Pricing Type</strong>
            </Label>
            <Select
              isClearable={true}
              id="price"
              name="price"
              value={priceOptions.find((option) => option.value === price)}
              options={priceOptions}
              onChange={(selectedOption) => {
                setPrice(selectedOption?.value);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="product">
              <strong>Status</strong>
            </Label>
            <Select
              isClearable={true}
              id="published"
              name="published"
              value={published}
              options={publishedOptions}
              onChange={(selectedOption) => {
                setPublished(selectedOption);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col className="mb-2">
          <Button
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="white"
            appearance="primary"
            type="button"
            onClick={() => {
              submitHandler();
            }}
          >
            Search
          </Button>

          <Button
            className="pull-right"
            style={{
              backgroundColor: "#ff9999",
              height: "40px",
              marginTop: "22px",
              marginLeft: "40px",
              borderRadius: "5px",
              padding: "10px 40px",
              outline: "none",
              border: "0",
            }}
            color="danger"
            appearance="primary"
            type="button"
            onClick={() => {
              refreshHandler();
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>
      <TableList
        handleAdd={AddEditModal ? handleAdd : null}
        // filterHeader="All events and activities"
        // tableHeader="Events and activities"
        tableHeader="Hostelwise Events & Activities Configuration"
        type={TABLE_TYPE.EVENTS_AND_ACTIVITIES}
        tableData={tableData}
        tableHeaderList={tableHeaderList}
        openModal={() => setOpenModal(!openModal)}
        handleEdit={handleEdit}
        handleCreateUpdateEventsAndActivities={
          handleCreateUpdateEventsAndActivities
        }
        setPage={setPage}
        page={page}
        totalPage={totalPage}
        refetch={refetch}
      />
      {AddEditModal && (
        <AddEditModal
          open={openModal}
          handleToggle={() => setOpenModal(!openModal)}
          refetch={refetch}
          editData={editData}
          setEditId={setEditId}
        />
      )}
    </div>
  );
};

export default EventsAndActivitiesTable;
