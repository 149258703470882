import React, { useEffect, useState } from 'react';
import { Col, Card, Row, Table, Badge } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { useUploadBulkDocument } from '../../../api/dataSource';
import { AlertCircle, Check, Clock } from 'react-feather';

const tableHeaderData = ["File name", "Status", "Size"];

const UploadFileProgress = ({ files }) => {
    const [progressList, setProgressList] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [uploadBulkEmployeeDocumentsHandler] = useUploadBulkDocument();

    useEffect(() => {
        if (files) {
            const fileArray = Array.from(files);
            const initialProgress = fileArray.map((file) => ({
                status: "pending",
                file: file,
                fileSize: file.size > 5242880 ? 0 : 1, // 5mb is 5242880
            }));
            setProgressList(initialProgress);
            setIsProcessing(true);
        } else {
            setProgressList([]);
        }
    }, [files]);

    const saveToDB = async () => {
        const updatedProgressList = await Promise.all(
            progressList.map(async (file) => {
                if (file.fileSize) {
                    try {
                        const response = await uploadBulkEmployeeDocumentsHandler(file.file);
                        if (response?.data?.uploadBulkEmployeeDocuments) {
                            return { ...file, status: response.data.uploadBulkEmployeeDocuments };
                        }
                    } catch (error) {
                        console.error('Upload error:', error);
                        return { ...file, status: "error" };
                    }
                } else {
                    return { ...file, status: "failed" };
                }
                return file;
            })
        );

        setProgressList(updatedProgressList);
        setIsProcessing(false);
    };

    useEffect(() => {
        if (isProcessing) {
            saveToDB();
        }
    }, [isProcessing]);

    const statusIcons = {
        pending: <Clock size={25} />,
        failed: <AlertCircle size={25} />,
        success: <Check size={25} />,
    };

    return (
        <Row>
            <Col>
                <Card>
                    <Table>
                        <thead>
                            <tr>
                                {tableHeaderData.map((item) => (
                                    <th key={uuidv4()}>{item}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {progressList.map((file, index) => {

                                return (
                                    <tr key={index}>
                                        <td>{file.file?.name}</td>
                                        <td>
                                            {statusIcons[file.status] || null}
                                        </td>

                                        <td>{file.fileSize ? "OK" : "Exceed"}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Card>
            </Col>
        </Row>
    );
};

export default UploadFileProgress;
