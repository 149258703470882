import { gql, useMutation } from "@apollo/client";
import { useLoadingHandler } from "../../dataSource";

export const CREATE_BLACKLISTED_GUESTS = gql`
  mutation createBlacklistedGuests(
    $blacklistedGuestsInput: BlacklistedGuestsInput
  ) {
    createBlacklistedGuests(blacklistedGuestsInput: $blacklistedGuestsInput) {
      _id
    }
  }
`;

export const useCreateBlacklistedGuests = () => {
  const [createBlacklistedGuests, { loading, error, data, refetch }] =
    useMutation(CREATE_BLACKLISTED_GUESTS);

  useLoadingHandler(loading);

  const createBlacklistedGuestsHandler = (blacklistedGuestsInput) => {
    createBlacklistedGuests({
      variables: {
        blacklistedGuestsInput,
      },
    });
  };

  return [createBlacklistedGuestsHandler, { loading, error, data, refetch }];
};

export const UPDATE_BLACKLISTED_GUESTS = gql`
  mutation updateBlacklistedGuests(
    $id: ID
    $blacklistedGuestsUpdate: BlacklistedGuestsInput
  ) {
    updateBlacklistedGuests(
      id: $id
      blacklistedGuestsUpdate: $blacklistedGuestsUpdate
    ) {
      _id
    }
  }
`;

export const useUpdateBlacklistedGuests = () => {
  const [updateBlacklistedGuests, { loading, error, data, refetch }] =
    useMutation(UPDATE_BLACKLISTED_GUESTS);

  useLoadingHandler(loading);

  const updateBlacklistedGuestsHandler = (id, blacklistedGuestsUpdate) => {
    updateBlacklistedGuests({
      variables: { id, blacklistedGuestsUpdate },
    });
  };

  return [updateBlacklistedGuestsHandler, { loading, error, data, refetch }];
};
