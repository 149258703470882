import gql from "graphql-tag";
import { useLoadingHandler } from "../dataSource";
import { useMutation, useQuery } from "@apollo/client";

export const GET_PAGINATED_EMPLOYEE_PROFILE_LIST = gql`
  query getPaginatedEmployeeProfileList($filter: filter) {
    getPaginatedEmployeeProfileList(filter: $filter) {
      data {
        _id
        hostel {
          _id
          name
        }
        prefix
        fullName
        spouseFullName
        fathersFullName
        empCode
        dob
        gender
        bloodGroup
        maritalStatus
        personalEmail
        presentAddress {
          addressLine1
          addressLine2
          city
          state
          postalCode
          country
        }
        permanentAddress {
          addressLine1
          addressLine2
          city
          state
          postalCode
          country
        }
        mobileNo
        skilledType
        doj
        empType
        empStatus
        department
        subDepartment
        designation
        businessUnit
        location
        uan
        esic
        aadhaar
        pan
        bankDetails {
          bankName
          branchName
          accountNumber
          ifscCode
        }
        emergencyName
        emergencyNo
        images {
          image
          key
          type
        }
        dateOfExit
        resignedStatus
        exitReason
        agencyName
        trainingStatus
      }
      count
      page
      limit
    }
  }
`;

export const GET_EMPLOYEE_PROFILE_BY_ID = gql`
  query getEmployeeProfileById($id: ID) {
    getEmployeeProfileById(id: $id) {
      _id
      hostel {
        _id
        name
      }
      prefix
      fullName
      spouseFullName
      fathersFullName
      empCode
      dob
      gender
      bloodGroup
      maritalStatus
      personalEmail
      presentAddress {
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      permanentAddress {
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      mobileNo
      skilledType
      doj
      empType
      empStatus
      department
      subDepartment
      designation
      businessUnit
      location
      uan
      esic
      aadhaar
      pan
      bankDetails {
        bankName
        branchName
        accountNumber
        ifscCode
      }
      emergencyName
      emergencyNo
      images {
        image
        key
        type
      }
      dateOfExit
      resignedStatus
      currentCTC
      newCTC
      appraisedDate
      appraisalDate
      nextAppraisalDate
      performanceGrade
      remarks
      appraisedPDF
      exitReason
      appraisalLogs {
        currentCTC
        newCTC
        designation
        newDesignation
        appraisedDate
        appraisalDate
        nextAppraisalDate
        performanceGrade
        remarks
        appraisedPDF
      }
      dateOfResignation
      attachments
      trainingDate
      trainingStatus
      trainingScore
      trainingRemark
      exitReasonCategory
    }
  }
`;

export const GET_EMPLOYEE_PROFILE_BY_HOSTEL_ID = gql`
  query getEmployeeProfileListByHostelId($hostelId: ID) {
    getEmployeeProfileListByHostelId(hostelId: $hostelId) {
      _id
      hostel {
        _id
        name
      }
      prefix
      fullName
      spouseFullName
      fathersFullName
      empCode
      dob
      gender
      bloodGroup
      maritalStatus
      personalEmail
      presentAddress {
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      permanentAddress {
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      mobileNo
      skilledType
      doj
      empType
      empStatus
      department
      subDepartment
      designation
      businessUnit
      location
      uan
      esic
      aadhaar
      pan
      bankDetails {
        bankName
        branchName
        accountNumber
        ifscCode
      }
      emergencyName
      emergencyNo
      images {
        image
        key
        type
      }
      dateOfExit
      resignedStatus
      exitReason
      transferDate
      confirmationDate
    }
  }
`;
export const GET_EMP_PROFILE_FOR_ATTENDANCE = gql`
  query getEmployeeProfileForAttendance(
    $hostelId: ID
    $attendanceMonth: Int
    $attendanceYear: Int
  ) {
    getEmployeeProfileForAttendance(
      hostelId: $hostelId
      attendanceMonth: $attendanceMonth
      attendanceYear: $attendanceYear
    ) {
      _id
      hostel {
        _id
        name
      }
      prefix
      fullName
      spouseFullName
      fathersFullName
      empCode
      dob
      gender
      bloodGroup
      maritalStatus
      personalEmail
      presentAddress {
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      permanentAddress {
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      mobileNo
      skilledType
      doj
      empType
      empStatus
      department
      subDepartment
      designation
      businessUnit
      location
      uan
      esic
      aadhaar
      pan
      bankDetails {
        bankName
        branchName
        accountNumber
        ifscCode
      }
      emergencyName
      emergencyNo
      images {
        image
        key
        type
      }
      dateOfExit
      resignedStatus
      exitReason
      transferDate
      confirmationDate
      currentCTC
      newCTC
      appraisedDate
      appraisalDate
      nextAppraisalDate
      performanceGrade
      remarks
      appraisedPDF
      appraisalLogs {
        currentCTC
        newCTC
        designation
        newDesignation
        appraisedDate
        appraisalDate
        nextAppraisalDate
        performanceGrade
        remarks
        appraisedPDF
      }
      logs
      dateOfResignation
      attachments
      transferLogs {
        fromHostel
        toHostel
        date
      }
    }
  }
`;

export const GET_EMPLOYEE_LEAVE_BALANCE_BY_HOSTEL_ID = gql`
  query getEmployeeLeaveBalanceListByHostelId($hostelId: ID) {
    getEmployeeLeaveBalanceListByHostelId(hostelId: $hostelId) {
      _id
      hostelId {
        _id
        name
      }
      empId {
        _id
        fullName
        empCode
      }
      empCode
      year
      month
      leaveType
      balance
    }
  }
`;

export const GET_EMPLOYEE_LEAVE_BALANCE_BY_EMPLOYEE_ID = gql`
  query getEmployeeLeaveBalanceById($id: ID) {
    getEmployeeLeaveBalanceById(id: $id) {
      _id
      hostelId {
        _id
        name
      }
      empId {
        _id
        fullName
        empCode
      }
      empCode
      year
      month
      leaveType
      balance
    }
  }
`;

export const GET_EMPLOYEE_ATTENDANCE_BY_HOSTEL_ID = gql`
  query ($hostelId: ID, $month: Int, $year: Int) {
    getEmployeeAttendanceByHostelId(
      hostelId: $hostelId
      month: $month
      year: $year
    ) {
      _id
      attendance
      attendanceDate
      empCode
      logs
      empId {
        _id
        fullName
        empCode
        dob
        designation
        subDepartment
        empStatus
        empType
        agencyName
        doj
        dateOfExit
      }
      hostelId {
        _id
        name
      }
      overTime
    }
  }
`;

export const GET_HOSTELWISE_LEAVE_BALANCE = gql`
  query ($hostelId: ID, $year: Int, $month: Int) {
    getHostelwiseLeaveBalance(hostelId: $hostelId, year: $year, month: $month) {
      empId
      PLCount
      CLCount
    }
  }
`;

export const GET_EMPLOYEE_PAYSLIPS = gql`
  query ($getPaySlipInput: getPaySlipInput) {
    getEmployeePaySlips(getPaySlipInput: $getPaySlipInput) {
      empId
      payslip
    }
  }
`;

export const GET_EMPLOYEE_PROFILE_LIST = gql`
  query getEmployeeProfileList {
    getEmployeeProfileList {
      _id
      hostel {
        _id
        name
      }
      prefix
      fullName
      spouseFullName
      fathersFullName
      empCode
      dob
      gender
      bloodGroup
      maritalStatus
      personalEmail
      presentAddress {
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      permanentAddress {
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
      }
      mobileNo
      skilledType
      doj
      empType
      empStatus
      department
      subDepartment
      designation
      businessUnit
      location
      uan
      esic
      aadhaar
      pan
      bankDetails {
        bankName
        branchName
        accountNumber
        ifscCode
      }
      emergencyName
      emergencyNo
      images {
        image
        key
        type
      }
      dateOfExit
      resignedStatus
      exitReason
      transferDate
      confirmationDate
      currentCTC
      newCTC
      newDesignation
      appraisedDate
      appraisalDate
      nextAppraisalDate
      performanceGrade
      remarks
      agencyName
    }
  }
`;



export const GET_CURRENT_MONTH_PAYSLIPDATA = gql`
query getCurrentMonthPublishedPayslip($month:Int, $year:Int){
    getCurrentMonthPublishedPayslip(month:$month, year:$year)
}
`

export const useGetMonthlyPublishedPayslip = (month, year) => {
  const { loading, error, data, refetch } = useQuery(
    GET_CURRENT_MONTH_PAYSLIPDATA,
    {
      variables: { month, year },
    }
  );

  return { loading, error, data, refetch };
};